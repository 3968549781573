import { Moment } from 'moment';
import { EuiSpacer, EuiErrorBoundary } from '@elastic/eui';
import { SntConfigRequestsFilters } from 'layout/config/snt/requests/filters/snt-config-requests-filters';
import { SntConfigRequestsTable } from 'layout/config/snt/requests/table/snt-config-requests-table';
import type { CommandConfigurationRequestRow } from 'gqlHooks';

import { SIM_MESSAGE_TABLE_COLUMNS } from './table/table-columns';

interface SntConfigRequestsViewProps {
  /** True if the message data is loading or reloading */
  isLoading: boolean;
  /** True if the auto refresh should be enabled */
  isPaused: boolean;
  /** If set, then the query returned an error message and likely failed to load any data */
  messageError?: string;
  /** Messages to be displayed*/
  requests: CommandConfigurationRequestRow[];
  /** Method invoked when the user wants to cancel a request */
  onRequestCancel: (requestId: number) => void;
  /** Informs that a request has been made to run a search */
  onSearch: (startDate: Moment, endDate: Moment) => void;
  /** How often the data should refresh */
  refreshInterval: number;
  /** Sets if the auto refresh should be enabled/disabled */
  setIsPaused: (paused: boolean) => void;
  /** Sets how often the messages page should poll for new data */
  setRefreshInterval: (interval: number) => void;
}

/** Displays config request messages returned by the trackers */
export const SntConfigRequestsView = (props: SntConfigRequestsViewProps) => {
  return (
    <EuiErrorBoundary id="snt-config-requests-view">
      <SntConfigRequestsFilters
        onSearch={props.onSearch}
        refreshInterval={props.refreshInterval}
        setRefreshInterval={props.setRefreshInterval}
        setIsPaused={props.setIsPaused}
        isPaused={props.isPaused}
        isLoading={props.isLoading}
      />
      <SntConfigRequestsTable
        onRequestCancel={props.onRequestCancel}
        tableColumns={SIM_MESSAGE_TABLE_COLUMNS}
        errorText={props.messageError}
        isLoading={props.isLoading}
        requests={props.requests}
      />
      <EuiSpacer size="m" />
    </EuiErrorBoundary>
  );
};

export default SntConfigRequestsView;
