import { Motion } from 'app/generated/graphql';
import { MlEuiCommentType, buildEuiComment } from 'utils/configTranslator';

// Report Interval Values
export const REPORT_INTERVAL_DISABLED = 0;
export const REPORT_INTERVAL_DEFAULT = REPORT_INTERVAL_DISABLED;
export const REPORT_INTERVAL_MIN = 120; // seconds
export const REPORT_INTERVAL_MAX = 43200; // seconds

// Stop Timer Values
export const STOP_TIMER_DEFAULT = 300; // seconds
export const STOP_TIMER_MIN = 60; // seconds
export const STOP_TIMER_MAX = 43200; // seconds

// Motion Threshold
export const THS_DEFAULT = 288; // milliG
export const THS_MIN = 160; // milliG
export const THS_MAX = 1000; // milliG

export const MOTION_CONFIG_DEFAULTS: Motion = {
  reportInterval: REPORT_INTERVAL_DEFAULT,
  stopTimer: STOP_TIMER_DEFAULT,
  ths: THS_DEFAULT, // Motion threshold
};

export const MOTION_GROUP_CONFIG_DEFAULTS: Motion = {
  //@ts-expect-error form fields accept empty string for unset number fields
  reportInterval: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  stopTimer: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  ths: '', // Motion threshold
};

// Builds items for an EUI comment list, in translated human readable format
export const motionConfigTranslator = (motionConfig: Motion | undefined | null) => {
  if (!motionConfig) {
    return undefined;
  }
  const builtItems: Array<MlEuiCommentType> = [];

  if (motionConfig.reportInterval) {
    const description =
      motionConfig.reportInterval === REPORT_INTERVAL_DISABLED
        ? 'Disabled'
        : `Every ${motionConfig.reportInterval} seconds`;

    builtItems.push(buildEuiComment('Motion Report Interval', description));
  }
  if (motionConfig.stopTimer) {
    builtItems.push(buildEuiComment('Stop Timer', `${motionConfig.stopTimer} seconds`));
  }
  if (motionConfig.ths) {
    builtItems.push(buildEuiComment('Motion Threshold', `${motionConfig.ths} milliG`));
  }

  return builtItems;
};
