import PageTemplate from 'layout/page/page';
import { GenericRow, useGetGroupsQuery } from 'app/generated/graphql';
import { UserInfoContextData, useUserInfo } from 'app/context/userInfo';
import { DevicesByOrg } from 'app/layout/device-list/devices-by-org';
import { errorToastMessage } from 'app/utils/toast-messages';
import { useEffect, useState } from 'react';
import { SelectOrganization } from 'app/components/forms/select-organization';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

export const DeviceListView = () => {
  const {
    queryData: { userInfo },
  }: UserInfoContextData = useUserInfo();

  const [toasts, setToasts] = useState<Toast[]>([]);
  const addToastMessage = (message: Toast) => setToasts(toasts.concat(message));
  const [groups, setGroups] = useState<GenericRow[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<string>('');

  useGetGroupsQuery({
    variables: { organizationId: selectedOrganization, limit: 1000 },
    onError: () => addToastMessage(errorToastMessage('Failed to load groups')),
    onCompleted: data => {
      // Check if there are groups
      data?.groups?.items?.length > 0 ? setGroups(data.groups.items) : setGroups([]);
    },
  });

  useEffect(() => {
    setSelectedOrganization(userInfo?.organizationId ?? '');
  }, [userInfo?.organizationId]);

  return (
    <PageTemplate
      documentTitle="Devices"
      title="Devices"
    >
      {userInfo?.role === 'administrator' ? (
        <SelectOrganization
          label="Select organization"
          onChange={setSelectedOrganization}
          selectedOrganization={selectedOrganization}
        />
      ) : null}
      {
        <DevicesByOrg
          addToastMessage={addToastMessage}
          organization={selectedOrganization}
          groups={groups}
        />
      }
    </PageTemplate>
  );
};
