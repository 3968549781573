import { EuiLink, EuiTableFieldDataColumnType } from '@elastic/eui';
import { useDSLink } from '@equipmentshare/ds2-link';

type DeviceLinkProps = {
  id: string;
};

function DeviceLink(props: DeviceLinkProps) {
  return <EuiLink {...useDSLink(`/devices/${props.id}`)}>{props.id}</EuiLink>;
}

const deviceId: EuiTableFieldDataColumnType<any> = {
  field: 'identifier',
  name: 'Device IMEI',
  dataType: 'string',
  truncateText: false,
  sortable: true,
  render: (identifier: string) => <DeviceLink id={identifier} />,
};

const label: EuiTableFieldDataColumnType<any> = {
  field: 'label',
  name: 'Device Name',
  dataType: 'string',
  truncateText: false,
  sortable: true,
};

export const GROUPS_DEVICE_TABLE_COLUMNS: EuiTableFieldDataColumnType<any>[] = [deviceId, label];
