import {
  EuiInMemoryTable,
  EuiErrorBoundary,
  EuiScreenReaderOnly,
  DefaultItemAction,
  EuiSearchBarProps,
  EuiButton,
  EuiContextMenu,
  EuiPopover,
  EuiTableSelectionType,
  EuiSpacer,
  EuiProgress,
} from '@elastic/eui';
import { GenericRow } from 'gqlHooks';
import { useState, useRef } from 'react';

import { GROUPS_TABLE_COLUMNS } from './columns';

export interface GroupsTableProps {
  /**
   * Groups to be displayed in the table
   */
  groups?: GenericRow[];
  /**
   * True if the table should show a loading status
   */
  isLoading?: boolean;
  /** Invoked when the user clicks the create group button */
  onCreateGroup?: () => void;
  /** Method invoked when the users wish to delete a group */
  onDeleteGroup?: (groupId: string) => void;
  /** Invoked when the user hits one of the manage actions */
  onManageSelected: (type: string) => void;
  /** Currently selected groups */
  selection: GenericRow[];
  /** Invoked when the selection changes */
  setSelection: (items: GenericRow[]) => void;
  /** True if the delete action should be available */
  showDeleteAction?: boolean;
  /** True if the actions for managing groups should be visible */
  showManageButtons?: boolean;
}

/* Table for displaying groups */
export const GroupsTable = (props: GroupsTableProps) => {
  const [isManagePopoverOpen, setIsManagePopoverOpen] = useState(false);
  const tableRef = useRef<EuiInMemoryTable<GenericRow> | null>(null);

  const onButtonClick = () => {
    setIsManagePopoverOpen(true);
  };
  const onClosePopover = () => setIsManagePopoverOpen(false);
  // Action for deleting a group
  const deleteGroup = (group: GenericRow) => {
    if (props.onDeleteGroup !== undefined) {
      props.onDeleteGroup(group.identifier ?? '');
    }
  };

  const deleteAction: DefaultItemAction<GenericRow> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Delete group</span>
      </EuiScreenReaderOnly>
    ),
    available: () => props.showDeleteAction == true && props.onDeleteGroup !== undefined,
    description: 'Delete group',
    icon: 'trash',
    color: 'danger',
    type: 'icon',
    onClick: deleteGroup,
    isPrimary: true,
  };

  const actions = [deleteAction];

  const button = (
    <EuiButton
      id="manage-selected-groups-select-action"
      iconType="arrowDown"
      color="primary"
      fill
      iconSide="right"
      onClick={onButtonClick}
      isDisabled={props.selection.length <= 0}
    >
      Manage selected groups
    </EuiButton>
  );

  const selectionValue: EuiTableSelectionType<GenericRow> = {
    onSelectionChange: (selection: GenericRow[]) => {
      props.setSelection(selection);
    },
    initialSelected: props.selection,
  };

  const panels = [
    {
      id: 0,
      title: 'Available actions',
      items: [
        {
          name: 'Send FOTA request',
          icon: 'mobile',
          onClick: () => {
            props.onManageSelected('fota');
            setIsManagePopoverOpen(false);
          },
        },
        {
          name: 'Update configuration',
          icon: 'gear',
          onClick: () => {
            props.onManageSelected('config');
            setIsManagePopoverOpen(false);
          },
        },
        {
          name: 'OTA credential update',
          icon: 'article',
          onClick: () => {
            props.onManageSelected('cred');
            setIsManagePopoverOpen(false);
          },
        },
        {
          name: 'Send command',
          icon: 'wrench',
          onClick: () => {
            props.onManageSelected('command');
            setIsManagePopoverOpen(false);
          },
        },
      ],
    },
  ];

  const renderToolsRight = () => {
    if (!props.showManageButtons) {
      return [];
    }
    return [
      <EuiButton
        id="open-add-new-group"
        key="loadUsers"
        iconType="plus"
        onClick={props.onCreateGroup}
        isDisabled={props.isLoading}
      >
        Add group
      </EuiButton>,
      <EuiPopover
        key="manage"
        id={'manageGroupsPopover'}
        button={button}
        isOpen={isManagePopoverOpen}
        closePopover={onClosePopover}
        panelPaddingSize="none"
        anchorPosition="downLeft"
      >
        <EuiContextMenu
          initialPanelId={0}
          panels={panels}
        />
      </EuiPopover>,
    ];
  };

  const search: EuiSearchBarProps = {
    toolsRight: renderToolsRight(),
    box: {
      incremental: true,
      schema: true,
    },
  };

  return (
    <EuiErrorBoundary>
      {props.isLoading ? <EuiProgress size="s" /> : <EuiSpacer size="m" />}
      {/* @ts-expect-error I have no idea how to fix this ts error */}
      <EuiInMemoryTable
        items={props.groups ?? []}
        ref={tableRef}
        itemId="identifier"
        columns={[
          ...GROUPS_TABLE_COLUMNS,
          {
            name: 'Actions',
            actions,
          },
        ]}
        search={search}
        pagination={true}
        sorting={true}
        selection={selectionValue}
        isSelectable={true}
      />
    </EuiErrorBoundary>
  );
};

export default GroupsTable;
