import { EuiAccordion, EuiHorizontalRule } from '@elastic/eui';
import { useState } from 'react';

type DeviceDetailAccordionProps = {
  children: React.ReactNode;
  id: string;
  title: string;
};

export const DeviceDetailAccordion = (props: DeviceDetailAccordionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  function handleAccordionToggle() {
    return setIsCollapsed(!isCollapsed);
  }

  return (
    <>
      <EuiAccordion
        id={props.id}
        buttonContent={props.title}
        initialIsOpen
        paddingSize="s"
        onToggle={handleAccordionToggle}
      >
        {props.children}
      </EuiAccordion>
      {isCollapsed && <EuiHorizontalRule margin="xs" />}
    </>
  );
};
