import { useState } from 'react';
import { Module } from 'gqlHooks';
import {
  EuiErrorBoundary,
  EuiForm,
  EuiSpacer,
  EuiFlexItem,
  EuiFlexGroup,
  EuiButton,
} from '@elastic/eui';

import SntCredModuleSelector from './update/select-update';

interface OtaCredUpdateViewProps {
  /** True if the cred request is loading/sending data */
  isLoading: boolean;
  /** Calls for the page to reload the current set of data using the existing query variables */
  onSendCredRequest: (module: Module) => void;
}

/**
 * @return {React.Element} Input fields for selecting/sending an OTA credential update.
 */
export function OtaCredUpdateView({ isLoading, onSendCredRequest }: OtaCredUpdateViewProps) {
  const [selectedModule, setSelectedModule] = useState(Module.Fota);

  const handleSubmit = () => {
    onSendCredRequest(selectedModule);
  };

  return (
    <EuiErrorBoundary>
      <EuiForm>
        <EuiFlexItem>
          <SntCredModuleSelector
            selectedModule={selectedModule}
            setSelectedModule={setSelectedModule}
          />
        </EuiFlexItem>
        <EuiSpacer />
        <EuiSpacer />
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiButton
              id="send-ota-cred-request"
              color="primary"
              type="submit"
              fill
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              Send request
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </EuiErrorBoundary>
  );
}

export default OtaCredUpdateView;
