import { Base } from 'models/Base';
import { CONSTANTS } from 'utils/constants';

export type DeviceDefaults = {
  id: string;
  type: string;
  vendor: string;
  lastPing: Date | string;
};

/**
 * Default device model extends DeviceBase taking
 * the given attributes and a default set of attributes
 * as `defaults` via this.defaults
 */
class Device extends Base {
  /**
   * @static minimumIdLength - Minimum length of device IMEI
   */
  static readonly minimumIdLength = 1;
  /**
   * Asserts that 'device.id' is a valid length.
   * Will throw if not.
   */
  constructor(attributes = {}) {
    super(attributes);
    Device.assertIsValidLength(this.id, Device.minimumIdLength, 'device imei');
  }

  /**
   * Configures the device's default values
   * @getter defaults
   */
  get defaults(): DeviceDefaults {
    return {
      id: this.id,
      type: this.type ?? CONSTANTS.TEXT_PLACEHOLDER,
      vendor: this.vendor ?? CONSTANTS.TEXT_PLACEHOLDER,
      lastPing: this.createdAt ?? CONSTANTS.TEXT_PLACEHOLDER,
    };
  }
}

export { Device };
