import { useCreateGroupCredMutation, Module } from 'gqlHooks';
import {
  EuiErrorBoundary,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
} from '@elastic/eui';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';
import OtaCredUpdateView from 'app/layout/ota-cred-update/ota-cred-update-view';

interface SendOtaCredRequetProps {
  addToastMessage: (message: any) => void;
  groupTokens: string[];
  organizationId: string;
  isOpen: boolean;
  onClose: () => void;
}

/** Sends a command to a snt group*/
export const SntGroupOtaCredRequest = (props: SendOtaCredRequetProps) => {
  const [sendGroupCredMutation, { loading }] = useCreateGroupCredMutation({
    onCompleted: () => {
      props.addToastMessage(successToastMessage('Request Sent'));
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to send request'));
    },
  });

  if (!props.isOpen) {
    return null;
  }

  // Builds the request data, then sends the request
  // Ca Cert are optional
  const sendGroupCred = (
    module: Module,
    caCert?: string,
    clientCert?: string,
    privateKey?: string,
    retainCurrent?: number
  ) => {
    const request: any = {
      module: module,
      caCert: caCert ? caCert : null,
      clientCert: clientCert ? caCert : null,
      privateKey: privateKey ? caCert : null,
      retainCurrent: retainCurrent ? caCert : null,
    };
    props.groupTokens.forEach(token => {
      sendGroupCredMutation({
        variables: {
          organizationId: props.organizationId,
          groupToken: token,
          module: request.module,
          ca: request.caCert,
          clientCertificate: request.clientCert,
          privateKey: request.privateKey,
          retainCurrent: request.retainCurrent,
        },
      });
    });
  };

  return (
    <EuiErrorBoundary>
      <EuiFlyout
        ownFocus
        onClose={props.onClose}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Send OTA request</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <OtaCredUpdateView
            isLoading={loading}
            onSendCredRequest={sendGroupCred}
          />
        </EuiFlyoutBody>
      </EuiFlyout>
    </EuiErrorBoundary>
  );
};
