import PageTemplate from 'layout/page/page';
import { EuiFlexGroup, EuiFlexItem, EuiGlobalToastList, EuiTab, EuiTabs } from '@elastic/eui';
import { hasPermissions } from 'utils/permissions';
import { useUserInfo } from 'context/userInfo';
import { ErrorMessage } from 'layout/utility/errorMessage/errorMessage';
import type { UserInfoContextData } from 'context/userInfo';
import type { UserInfo } from 'gqlHooks';
import ManageUsers from 'app/layout/access-management/manage-users/manage-users';
import { useMemo, useState } from 'react';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { CreateOrganizationForm } from 'app/layout/access-management/create-organization-form';
import { RemoveDeviceFromOrganizationForm } from 'app/layout/access-management/remove-device-from-organization';
import AddDevicesToOrg from 'app/layout/access-management/add-device-to-organization-form/add-devices-to-org';
import { EditDeviceFromOrganizationForm } from 'app/layout/access-management/edit-device-from-organization';

export type AccessManagementFormProps = {
  isLoading: boolean;
  isRestricted: boolean;
};

type BuildASection = {
  component: React.ReactElement;
  id: string;
  name: string;
  requiredPermissions: Array<string>;
};

export const AccessManagement = () => {
  const {
    queryData: { error: userInfoError, userInfo },
  }: UserInfoContextData = useUserInfo();
  const [selectedTabId, setSelectedTabId] = useState('add-device');

  const [toasts, setToasts] = useState<Toast[]>([]);
  const addToastMessage = (message: Toast) => setToasts(toasts.concat(message));
  const removeToast = (removedToast: { id: string }) => {
    setToasts(toasts.filter((toast: Toast) => toast.id !== removedToast.id));
  };

  /**
   * A list of user permissions is available in the Auhto0 dashboard
   * via Applications > APIs `device-manager-{env}` > Permissions tab
   */
  const sections: Array<BuildASection> = [
    {
      component: <AddDevicesToOrg addToastMessage={addToastMessage} />,
      id: 'add-device',
      name: 'Add device',
      requiredPermissions: ['associate:device:organization'],
    },
    {
      component: <RemoveDeviceFromOrganizationForm addToastMessage={addToastMessage} />,
      id: 'remove-device',
      name: 'Remove device',
      requiredPermissions: ['associate:device:organization'],
    },
    {
      component: <EditDeviceFromOrganizationForm addToastMessage={addToastMessage} />,
      id: 'edit-device',
      name: 'Edit device',
      requiredPermissions: ['associate:device:organization'],
    },
    {
      component: <ManageUsers addToastMessage={addToastMessage} />,
      id: 'manage-users',
      name: 'Manage users',
      requiredPermissions: ['create:user'],
    },
    {
      component: <CreateOrganizationForm addToastMessage={addToastMessage} />,
      id: 'create-org',
      name: 'Create organization',
      requiredPermissions: ['create:organization'],
    },
  ];

  const selectedTabContent = useMemo(() => {
    return sections.find(obj => obj.id === selectedTabId)?.component;
    // If we include sections, then we start getting render loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id);
  };

  const userPermissions: UserInfo['permissions'] = userInfo?.permissions ?? [];

  const renderTabs = () => {
    const tabs: Array<React.ReactElement> = [];
    sections.forEach(tab => {
      const showTab = hasPermissions(userPermissions, tab.requiredPermissions);
      if (!showTab) return;
      const tabby: React.ReactElement = (
        <EuiTab
          key={tab.id}
          onClick={() => onSelectedTabChanged(tab.id)}
          isSelected={tab.id === selectedTabId}
        >
          {tab.name}
        </EuiTab>
      );
      tabs.push(tabby);
    });
    return tabs;
  };

  return (
    <PageTemplate
      documentTitle="Access Management"
      title="Access Management"
    >
      <EuiGlobalToastList
        dismissToast={removeToast}
        toastLifeTimeMs={9000}
        toasts={toasts}
      />
      {userInfoError ? (
        <ErrorMessage
          isHidden={false}
          message={userInfoError}
        />
      ) : (
        <>
          <EuiTabs>{renderTabs()}</EuiTabs>
          <EuiFlexGroup>
            <EuiFlexItem>{selectedTabContent}</EuiFlexItem>
          </EuiFlexGroup>
        </>
      )}
    </PageTemplate>
  );
};
