import { useEffect, useState } from 'react';
import {
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';
import { SelectDeviceType } from 'app/components/forms/select-device-type';
import { FilePicker, parseCreateDeviceDataRows } from 'app/layout/file-picker/file-picker';
import {
  DeviceUploadRow,
  ColumnHeadersType,
  NO_SELECTION,
} from 'app/layout/access-management/add-device-to-organization-form/upload/constants';
import { ColumnSelect } from 'app/layout/access-management/add-device-to-organization-form/upload/column-select/column-select';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { SelectOrganization } from 'app/components/forms/select-organization';
import UploadPreviewTable from 'app/layout/file-picker/preview-table/preview-table';
import { UPLOAD_COLUMNS } from 'app/layout/file-picker/preview-table/table-columns';

type FileUploadBoxProps = {
  /** Handler for adding toasts to the global list */
  addToastMessage: (toasty: Toast) => void;
  /** How many jobs have completed their attempt at uploading */
  completedJobs: number;
  /** Rows that failed to upload */
  failedRows: DeviceUploadRow[];
  /** Rows whose data was deemed invalid for upload */
  invalidRows: DeviceUploadRow[];
  /** True if the user is an admin and should see admin specific upload options */
  isAdmin: boolean;
  /** True if there are jobs loading/running */
  isLoading: boolean;
  /** Called when the user wishes to try to upload the valid rows */
  onSubmit: () => void;
  /** Currently selected organization, used if the user is an admin */
  selectedOrganization: string;
  /** Currently selected tracker type */
  selectedTrackerType: string;
  /** Sets which rows this component thinks are invalid and can't be submitted */
  setInvalidRows: (rows: DeviceUploadRow[]) => void;
  /** Sets the currently selected organization, used if the user is an admin */
  setSelectedOrganization: (orgId: string) => void;
  /** Sets the selected tracker type */
  setSelectedTrackerType: (type: string) => void;
  /** Sets which rows this component thinks are valid for submission */
  setValidRows: (rows: DeviceUploadRow[]) => void;
  /** Rows that successfully uploaded */
  successRows: DeviceUploadRow[];
  /** How many jobs there are trying to upload total */
  totalJobs: number;
  /** Rows whose data seems to be valid/ready for upload */
  validRows: DeviceUploadRow[];
};

/* Displays the form for uploading a file */
export default function FileUploadBox(props: FileUploadBoxProps) {
  const [dataFromSheet, setDataFromSheet] = useState(null);
  const [columnHeaders, setColumnHeaders] = useState<ColumnHeadersType[]>([]);
  const [selectedDeviceIdColumn, setSelectedDeviceIdColumn] = useState(NO_SELECTION);
  const [selectedNameColumn, setSelectedNameColumn] = useState(NO_SELECTION);

  useEffect(() => {
    if (
      dataFromSheet !== null &&
      props.selectedTrackerType !== '' &&
      selectedDeviceIdColumn !== NO_SELECTION
    ) {
      const deviceIdIndex = parseInt(selectedDeviceIdColumn, 10);
      const deviceNameIndex = parseInt(selectedNameColumn, 10);

      parseCreateDeviceDataRows(
        dataFromSheet,
        deviceNameIndex,
        deviceIdIndex,
        props.setValidRows,
        props.setInvalidRows
      );
    } else {
      props.setValidRows([]);
      props.setInvalidRows([]);
    }
    // The props issue again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedTrackerType,
    columnHeaders,
    dataFromSheet,
    selectedDeviceIdColumn,
    selectedNameColumn,
  ]);

  // Given the parsed data from the file upload box - form is unknown
  const handleDataChange = (data: any) => {
    setSelectedDeviceIdColumn(NO_SELECTION);
    setSelectedNameColumn(NO_SELECTION);
    setDataFromSheet(data);
  };

  // Gets the newly selected device type
  const handleDeviceTypeChange = (deviceType: string) => {
    props.setSelectedTrackerType(deviceType);
  };

  return (
    <EuiErrorBoundary>
      <FilePicker
        addToastMessage={props.addToastMessage}
        isDisabled={props.isLoading}
        isLoading={props.isLoading}
        label="Select file"
        setColumnHeaders={setColumnHeaders}
        setDataFromSheet={handleDataChange}
      />
      {props.isLoading ? (
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiText>
              <p>{`Jobs ${props.completedJobs} of ${props.totalJobs} completed`}</p>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiProgress
              max={props.totalJobs}
              value={props.completedJobs}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <EuiSpacer size="xl" />
      )}
      <EuiFlexItem grow={false}>
        <ColumnSelect
          columnHeaders={columnHeaders}
          label="Device IMEI Column"
          isDisabled={props.isLoading}
          onSelectionChange={setSelectedDeviceIdColumn}
          selectedOption={selectedDeviceIdColumn}
        />
      </EuiFlexItem>
      <EuiSpacer />
      <EuiFlexItem grow={false}>
        <ColumnSelect
          columnHeaders={columnHeaders}
          label="Device Name (Optional)"
          isDisabled={props.isLoading}
          onSelectionChange={setSelectedNameColumn}
          selectedOption={selectedNameColumn}
        />
      </EuiFlexItem>
      <EuiSpacer />
      <EuiFlexItem>
        <SelectDeviceType
          hasNoInitialSelection
          selectedDeviceType={props.selectedTrackerType}
          onChange={handleDeviceTypeChange}
        />
      </EuiFlexItem>
      <EuiSpacer />
      {props.isAdmin ? (
        <EuiFlexItem>
          <SelectOrganization
            label="Select organization"
            onChange={props.setSelectedOrganization}
            selectedOrganization={props.selectedOrganization}
          />
        </EuiFlexItem>
      ) : null}
      <EuiSpacer />
      <UploadPreviewTable
        successRows={props.successRows}
        failedRows={props.failedRows}
        invalidRows={props.invalidRows ?? []}
        isLoading={props.isLoading}
        onSubmit={props.onSubmit}
        validRows={props.validRows ?? []}
        submitButtonText="Create devices"
        tableColumns={UPLOAD_COLUMNS}
      />
    </EuiErrorBoundary>
  );
}
