import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';

interface ConfirmDeleteRequestProps {
  isOpen?: boolean;
  onClose?: (
    event?:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | undefined
  ) => void; // Update the type of onClose
  /** Method invoked when the users wish to cancel a request */
  onRequestCancel: () => void;
}

/* Displays a dialog to confirm overwriting an existing asset */
export const ConfirmDeleteRequest: React.FC<ConfirmDeleteRequestProps> = (
  props: ConfirmDeleteRequestProps
) => {
  const onCancel = (
    event?: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (props.onClose) {
      props.onClose(event);
    }
  };

  return props.isOpen ? (
    <EuiOverlayMask>
      <EuiConfirmModal
        buttonColor="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        defaultFocusedButton="confirm"
        onCancel={onCancel}
        onConfirm={props.onRequestCancel}
        title="Are you sure?"
      >
        <p>{`Are you sure you want to delete this device command configuration?`}</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  ) : null;
};

export default ConfirmDeleteRequest;
