import setupLocatorUI from '@locator/runtime';
import { sentryInit } from 'services/sentry';
import { AppEnvironment } from 'services/host';

export function initializeEnvConfigs() {
  switch (APP_ENVIRONMENT) {
    case AppEnvironment.DEVELOPMENT:
      setupLocatorUI();
      break;
    case AppEnvironment.STAGING:
    case AppEnvironment.PRODUCTION:
      sentryInit();
      break;
    default:
      null;
  }
}
