import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSONString: { input: any; output: any; }
};

export type AdminPaginatedItems = {
  __typename?: 'AdminPaginatedItems';
  items: Array<GenericRow>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ApiDependency = {
  __typename?: 'ApiDependency';
  name: Scalars['String']['output'];
  status: DependencyStatus;
};

export type BaseResponse = {
  __typename?: 'BaseResponse';
  statusCode: Scalars['Int']['output'];
};

export type CmdCfgRequests = {
  __typename?: 'CmdCfgRequests';
  nextToken?: Maybe<Scalars['String']['output']>;
  requests: Array<CommandConfigurationRequestRow>;
};

export type Comm = {
  __typename?: 'Comm';
  apn?: Maybe<Scalars['String']['output']>;
  mqttBroker?: Maybe<Scalars['String']['output']>;
  pw?: Maybe<Scalars['String']['output']>;
  smsWakeupInterval?: Maybe<Scalars['Int']['output']>;
  timeout?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<CommType>;
  user?: Maybe<Scalars['String']['output']>;
};

export type CommInput = {
  apn?: InputMaybe<Scalars['String']['input']>;
  mqttBroker?: InputMaybe<Scalars['String']['input']>;
  pw?: InputMaybe<Scalars['String']['input']>;
  smsWakeupInterval?: InputMaybe<Scalars['Int']['input']>;
  timeout?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export enum CommType {
  Aws = 'AWS',
  Claim = 'CLAIM',
  Tls = 'TLS'
}

export enum Command {
  Claim = 'CLAIM',
  Config = 'CONFIG',
  Cred = 'CRED',
  Off = 'OFF',
  Reset = 'RESET',
  SimUpdateTwilioSuper = 'SIM_UPDATE_TWILIO_SUPER',
  Sysinfo = 'SYSINFO'
}

export type CommandConfigurationRequestRow = {
  __typename?: 'CommandConfigurationRequestRow';
  PK: Scalars['String']['output'];
  SK: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deviceId: Scalars['String']['output'];
  request: RequestMessage;
  requestId: Scalars['Int']['output'];
  requestState: RequestState;
  responseCode?: Maybe<Scalars['Int']['output']>;
  responseData?: Maybe<Scalars['JSONString']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  comm?: Maybe<Comm>;
  motion?: Maybe<Motion>;
  periodic?: Maybe<Periodic>;
  power?: Maybe<Power>;
  stolenAsset?: Maybe<StolenAsset>;
  update?: Maybe<Update>;
};

export type ConfigurationInput = {
  comm?: InputMaybe<CommInput>;
  motion?: InputMaybe<MotionInput>;
  periodic?: InputMaybe<PeriodicInput>;
  power?: InputMaybe<PowerInput>;
  stolenAsset?: InputMaybe<StolenAssetInput>;
  update?: InputMaybe<UpdateInput>;
};

export type CreateDeviceOrganizationXrefInput = {
  deviceId: Scalars['String']['input'];
  deviceType?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type CreateGroupInput = {
  deviceType?: InputMaybe<Scalars['String']['input']>;
  groupToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  parentGroupToken?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrganizationInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeleteGroupInput = {
  cascade?: InputMaybe<Scalars['Boolean']['input']>;
  groupToken: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export enum DependencyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type DeviceCommandConfigRequestInput = {
  deviceId: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  start: Scalars['String']['input'];
};

export type DeviceCreatedAtInput = {
  deviceId: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortBy?: SortBy;
  sortDirection?: InputMaybe<SortDirection>;
  start: Scalars['String']['input'];
  typeCode?: InputMaybe<TypeCode>;
};

export type DeviceFirmwareInput = {
  deviceType: DeviceType;
  firmwareType: FotaType;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
};

export type DeviceFirmwareResponse = {
  __typename?: 'DeviceFirmwareResponse';
  firmware: Array<FirmwareRow>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type DeviceFotaRequestInput = {
  deviceId: Scalars['String']['input'];
  deviceType: DeviceType;
  firmwareType: FotaType;
  firmwareVersion: Scalars['String']['input'];
};

export type DeviceFotaRequestInvalidateInput = {
  deviceId: Scalars['String']['input'];
  requestId: Scalars['Int']['input'];
};

export type DeviceFotaRequestRequestInput = {
  deviceId: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  start: Scalars['String']['input'];
};

export type DeviceGroupXrefInput = {
  deviceId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeviceGroupsInput = {
  deviceId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceInfoInput = {
  deviceId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeviceRequestCommandInput = {
  command: Command;
  deviceId: Scalars['String']['input'];
};

export type DeviceRequestConfigInput = {
  config: ConfigurationInput;
  deviceId: Scalars['String']['input'];
};

export type DeviceRequestCredInput = {
  ca?: InputMaybe<Scalars['String']['input']>;
  clientCertificate?: InputMaybe<Scalars['String']['input']>;
  commMqttBroker?: InputMaybe<Scalars['String']['input']>;
  commPw?: InputMaybe<Scalars['String']['input']>;
  commType?: InputMaybe<CommType>;
  commUser?: InputMaybe<Scalars['String']['input']>;
  deviceId: Scalars['String']['input'];
  module: Module;
  privateKey?: InputMaybe<Scalars['String']['input']>;
  retainCurrent?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceRequestInvalidateInput = {
  deviceId: Scalars['String']['input'];
  requestId: Scalars['Int']['input'];
};

export type DeviceShadowInput = {
  deviceId: Scalars['String']['input'];
};

export type DeviceShadowResponse = {
  __typename?: 'DeviceShadowResponse';
  batteryVoltage?: Maybe<StateComponent>;
  config?: Maybe<StateComponent>;
  deviceId: Scalars['String']['output'];
  deviceType?: Maybe<Scalars['String']['output']>;
  gps?: Maybe<StateComponent>;
  lastCheckin?: Maybe<StateComponent>;
  lastGoodGps?: Maybe<StateComponent>;
  lte?: Maybe<StateComponent>;
  motionStatus?: Maybe<StateComponent>;
  simIccid?: Maybe<Scalars['String']['output']>;
  simState?: Maybe<StateComponent>;
  stolenMode?: Maybe<StateComponent>;
  sysinfo?: Maybe<StateComponent>;
  temperature?: Maybe<StateComponent>;
};

export enum DeviceType {
  Snt = 'SNT'
}

export type DeviceTypeCodeInput = {
  deviceId: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortBy?: SortBy;
  sortDirection?: InputMaybe<SortDirection>;
  start: Scalars['String']['input'];
  typeCode: TypeCode;
};

export type DeviceTypeItems = {
  __typename?: 'DeviceTypeItems';
  items: Array<DeviceTypeRow>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type DeviceTypeRow = {
  __typename?: 'DeviceTypeRow';
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type FirmwareRow = {
  __typename?: 'FirmwareRow';
  PK: Scalars['String']['output'];
  SK: Scalars['String']['output'];
  deviceType: DeviceType;
  firmwareType: FotaType;
  url: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type FotaRequestInvalidationResponse = {
  __typename?: 'FotaRequestInvalidationResponse';
  statusCode: Scalars['Int']['output'];
};

export type FotaRequestMessage = {
  __typename?: 'FotaRequestMessage';
  requestId: Scalars['Int']['output'];
  type: FotaType;
  url: Scalars['String']['output'];
};

export type FotaRequestRow = {
  __typename?: 'FotaRequestRow';
  PK: Scalars['String']['output'];
  SK: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deviceId: Scalars['String']['output'];
  firmwareVersion: Scalars['String']['output'];
  request: FotaRequestMessage;
  requestId: Scalars['Int']['output'];
  requestState: FotaRequestState;
  responseCode?: Maybe<Scalars['Int']['output']>;
  responseData?: Maybe<Scalars['JSONString']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export enum FotaRequestState {
  Applying = 'APPLYING',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Downloading = 'DOWNLOADING',
  Failed = 'FAILED',
  Invalidated = 'INVALIDATED',
  Processing = 'PROCESSING'
}

export type FotaRequests = {
  __typename?: 'FotaRequests';
  nextToken?: Maybe<Scalars['String']['output']>;
  requests: Array<FotaRequestRow>;
};

export enum FotaType {
  App = 'APP',
  Modem = 'MODEM'
}

export type GenericRow = {
  __typename?: 'GenericRow';
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GetGroupOperationByGroupTokenInput = {
  groupToken: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  operationType: GroupOperationType;
  organizationId: Scalars['String']['input'];
};

export type GetGroupOperationInput = {
  operationType: GroupOperationType;
  requestId: Scalars['String']['input'];
};

export type GroupCommandConfigRequestInput = {
  groupRequestId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type GroupCommandRequest = {
  command: Command;
  groupToken: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type GroupConfigurationRequest = {
  config: ConfigurationInput;
  groupToken: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type GroupCredRequest = {
  ca?: InputMaybe<Scalars['String']['input']>;
  clientCertificate?: InputMaybe<Scalars['String']['input']>;
  commMqttBroker?: InputMaybe<Scalars['String']['input']>;
  commPw?: InputMaybe<Scalars['String']['input']>;
  commType?: InputMaybe<CommType>;
  commUser?: InputMaybe<Scalars['String']['input']>;
  groupToken: Scalars['String']['input'];
  module: Module;
  organizationId: Scalars['String']['input'];
  privateKey?: InputMaybe<Scalars['String']['input']>;
  retainCurrent?: InputMaybe<Scalars['Int']['input']>;
};

export type GroupDevicesInput = {
  groupToken: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  recursive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupFotaRequestByGroupRequestIdInput = {
  groupRequestId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type GroupFotaRequestInput = {
  deviceType: DeviceType;
  firmwareType: FotaType;
  firmwareVersion: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type GroupListInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  parentGroupToken?: InputMaybe<Scalars['String']['input']>;
};

export type GroupOperationInvalidationInput = {
  groupRequestId: Scalars['String']['input'];
  operationType: GroupOperationType;
  organizationId: Scalars['String']['input'];
};

export enum GroupOperationStatus {
  Active = 'ACTIVE',
  Applying = 'APPLYING',
  Cancelled = 'CANCELLED',
  Cancelling = 'CANCELLING'
}

export enum GroupOperationType {
  CmdConfig = 'CMD_CONFIG',
  Fota = 'FOTA'
}

export type Health = {
  __typename?: 'Health';
  dependencies: Array<ApiDependency>;
  msg: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ListDeviceTypeInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type MessagePage = {
  __typename?: 'MessagePage';
  items: Array<Messages>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type Messages = {
  __typename?: 'Messages';
  PK: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  data: Scalars['JSONString']['output'];
  deviceId: Scalars['String']['output'];
  ingestedAt?: Maybe<Scalars['String']['output']>;
  typeCode: Scalars['String']['output'];
};

export enum Module {
  Aws = 'AWS',
  Claim = 'CLAIM',
  Fota = 'FOTA'
}

export type Motion = {
  __typename?: 'Motion';
  reportInterval?: Maybe<Scalars['Int']['output']>;
  stopTimer?: Maybe<Scalars['Int']['output']>;
  ths?: Maybe<Scalars['Int']['output']>;
};

export type MotionInput = {
  reportInterval?: InputMaybe<Scalars['Int']['input']>;
  stopTimer?: InputMaybe<Scalars['Int']['input']>;
  ths?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDeviceToGroup: StatusCodeResponse;
  addDeviceToOrganization: StatusCodeResponse;
  createGroup: StatusCodeResponse;
  createOrganization: StatusCodeResponse;
  createUser: StatusCodeResponse;
  deleteDeviceCommandConfigRequest: BaseResponse;
  deleteDeviceFotaRequest: FotaRequestInvalidationResponse;
  deleteGroup: StatusCodeResponse;
  deleteUser: StatusCodeResponse;
  groupCommandRequest: StatusCodeResponse;
  groupConfigurationRequest: StatusCodeResponse;
  groupCredRequest: StatusCodeResponse;
  groupFotaRequest: StatusCodeResponse;
  invalidateGroupOperation: StatusCodeResponse;
  removeDeviceFromGroup: StatusCodeResponse;
  removeDeviceFromOrganization: StatusCodeResponse;
  restartGroupOperation: StrawberryRestartGroupOperation;
  sendDeviceCommand: BaseResponse;
  sendDeviceCred: BaseResponse;
  sendFotaRequest: BaseResponse;
  sendSmsCommand: SmsCommandResource;
  updateDevice: StatusCodeResponse;
  updateDeviceConfig: BaseResponse;
  updateGroup: StatusCodeResponse;
  updateUser: StatusCodeResponse;
};


export type MutationAddDeviceToGroupArgs = {
  deviceGroupXrefInput: DeviceGroupXrefInput;
};


export type MutationAddDeviceToOrganizationArgs = {
  deviceOrganizationXrefInput: CreateDeviceOrganizationXrefInput;
};


export type MutationCreateGroupArgs = {
  createGroupInput: CreateGroupInput;
};


export type MutationCreateOrganizationArgs = {
  createOrganizationInput: CreateOrganizationInput;
};


export type MutationCreateUserArgs = {
  userCreateUserInput: UserCreateUserInput;
};


export type MutationDeleteDeviceCommandConfigRequestArgs = {
  deviceRequestInvalidateInput: DeviceRequestInvalidateInput;
};


export type MutationDeleteDeviceFotaRequestArgs = {
  deviceFotaRequestInvalidateInput: DeviceFotaRequestInvalidateInput;
};


export type MutationDeleteGroupArgs = {
  deleteGroupInput: DeleteGroupInput;
};


export type MutationDeleteUserArgs = {
  userDeleteUserInput: UserDeleteUserInput;
};


export type MutationGroupCommandRequestArgs = {
  groupCommandRequest: GroupCommandRequest;
};


export type MutationGroupConfigurationRequestArgs = {
  groupConfigurationRequest: GroupConfigurationRequest;
};


export type MutationGroupCredRequestArgs = {
  groupCredRequest: GroupCredRequest;
};


export type MutationGroupFotaRequestArgs = {
  groupFotaRequest: GroupFotaRequestInput;
};


export type MutationInvalidateGroupOperationArgs = {
  groupOperationInvalidationInput: GroupOperationInvalidationInput;
};


export type MutationRemoveDeviceFromGroupArgs = {
  deviceGroupXrefInput: DeviceGroupXrefInput;
};


export type MutationRemoveDeviceFromOrganizationArgs = {
  deviceOrganizationXrefInput: RemoveDeviceOrganizationXrefInput;
};


export type MutationRestartGroupOperationArgs = {
  groupOperationInput: RestartGroupOperationInput;
};


export type MutationSendDeviceCommandArgs = {
  deviceRequestCommandInput: DeviceRequestCommandInput;
};


export type MutationSendDeviceCredArgs = {
  deviceRequestCredInput: DeviceRequestCredInput;
};


export type MutationSendFotaRequestArgs = {
  deviceFotaRequestInput: DeviceFotaRequestInput;
};


export type MutationSendSmsCommandArgs = {
  smsCommandSendInput: SmsCommandSendInput;
};


export type MutationUpdateDeviceArgs = {
  deviceOrganizationXrefInput: CreateDeviceOrganizationXrefInput;
};


export type MutationUpdateDeviceConfigArgs = {
  deviceRequestConfigInput: DeviceRequestConfigInput;
};


export type MutationUpdateGroupArgs = {
  updateGroupInput: UpdateGroupInput;
};


export type MutationUpdateUserArgs = {
  userUpdateUserInput: UserUpdateUserInput;
};

export type OrganizationDevicesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type OrganizationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type Periodic = {
  __typename?: 'Periodic';
  reportInitialOffset?: Maybe<Scalars['Int']['output']>;
  reportInitialTime?: Maybe<Scalars['Int']['output']>;
  reportInterval?: Maybe<Scalars['Int']['output']>;
};

export type PeriodicInput = {
  reportInitialOffset?: InputMaybe<Scalars['Int']['input']>;
  reportInitialTime?: InputMaybe<Scalars['Int']['input']>;
  reportInterval?: InputMaybe<Scalars['Int']['input']>;
};

export type Power = {
  __typename?: 'Power';
  offEnable?: Maybe<Scalars['Boolean']['output']>;
  resetEnable?: Maybe<Scalars['Boolean']['output']>;
};

export type PowerInput = {
  offEnable?: InputMaybe<Scalars['Boolean']['input']>;
  resetEnable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Query = {
  __typename?: 'Query';
  deviceCommandConfigRequest: CmdCfgRequests;
  deviceCreatedAt: MessagePage;
  deviceFirmwareRequest: DeviceFirmwareResponse;
  deviceFotaRequestRequest: FotaRequests;
  deviceGroups: AdminPaginatedItems;
  deviceInfo: GenericRow;
  deviceShadow: DeviceShadowResponse;
  deviceTypeCode: MessagePage;
  deviceTypes: DeviceTypeItems;
  devices: AdminPaginatedItems;
  groupCmdCfgRequestByGroupRequestId: CmdCfgRequests;
  groupDevices: AdminPaginatedItems;
  groupFotaRequestByGroupRequestId: FotaRequests;
  groupOperation: StrawberryGroupOperationResponse;
  groupOperationByGroupToken: StrawberryGroupOperationPage;
  groups: AdminPaginatedItems;
  health: Health;
  organizations: AdminPaginatedItems;
  roles: UserRolesResponse;
  simCreatedAt: QuerySimEventsResponse;
  smsCommandRequest: SmsCommandRequestsPage;
  smsCommandResource: SmsCommandResource;
  userInfo: UserInfo;
  userRow: UserRow;
  users: AdminPaginatedItems;
};


export type QueryDeviceCommandConfigRequestArgs = {
  deviceCommandConfigRequestInput: DeviceCommandConfigRequestInput;
};


export type QueryDeviceCreatedAtArgs = {
  deviceCreatedAtInput: DeviceCreatedAtInput;
};


export type QueryDeviceFirmwareRequestArgs = {
  deviceFirmwareInput: DeviceFirmwareInput;
};


export type QueryDeviceFotaRequestRequestArgs = {
  deviceFotaRequestRequestInput: DeviceFotaRequestRequestInput;
};


export type QueryDeviceGroupsArgs = {
  deviceGroupsInput: DeviceGroupsInput;
};


export type QueryDeviceInfoArgs = {
  deviceInfoInput: DeviceInfoInput;
};


export type QueryDeviceShadowArgs = {
  deviceShadowInput: DeviceShadowInput;
};


export type QueryDeviceTypeCodeArgs = {
  deviceTypeCodeInput: DeviceTypeCodeInput;
};


export type QueryDeviceTypesArgs = {
  deviceTypeInput: ListDeviceTypeInput;
};


export type QueryDevicesArgs = {
  organizationDevicesInput: OrganizationDevicesInput;
};


export type QueryGroupCmdCfgRequestByGroupRequestIdArgs = {
  groupCmdCfgRequestInput: GroupCommandConfigRequestInput;
};


export type QueryGroupDevicesArgs = {
  groupDevicesInput: GroupDevicesInput;
};


export type QueryGroupFotaRequestByGroupRequestIdArgs = {
  groupFotaRequestInput: GroupFotaRequestByGroupRequestIdInput;
};


export type QueryGroupOperationArgs = {
  getGroupOperationInput: GetGroupOperationInput;
};


export type QueryGroupOperationByGroupTokenArgs = {
  getGroupOperationByGroupTokenInput: GetGroupOperationByGroupTokenInput;
};


export type QueryGroupsArgs = {
  groupListInput: GroupListInput;
};


export type QueryOrganizationsArgs = {
  organizationInput: OrganizationInput;
};


export type QuerySimCreatedAtArgs = {
  simCreatedAtInput: SimCreatedAtInput;
};


export type QuerySmsCommandRequestArgs = {
  smsCommandRequestInput: SmsCommandRequestInput;
};


export type QuerySmsCommandResourceArgs = {
  smsCommandResourceInput: SmsCommandResourceInput;
};


export type QueryUserRowArgs = {
  userFetchUserInput: UserFetchUserInput;
};


export type QueryUsersArgs = {
  userListInput: UserListInput;
};

export type QuerySimEventsResponse = {
  __typename?: 'QuerySimEventsResponse';
  items: Array<Messages>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type RemoveDeviceOrganizationXrefInput = {
  deviceId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type RequestMessage = {
  __typename?: 'RequestMessage';
  ca?: Maybe<Scalars['String']['output']>;
  clientCertificate?: Maybe<Scalars['String']['output']>;
  commMqttBroker?: Maybe<Scalars['String']['output']>;
  commPw?: Maybe<Scalars['String']['output']>;
  commType?: Maybe<CommType>;
  commUser?: Maybe<Scalars['String']['output']>;
  command?: Maybe<Command>;
  config?: Maybe<Configuration>;
  module?: Maybe<Module>;
  privateKey?: Maybe<Scalars['String']['output']>;
  requestId: Scalars['Int']['output'];
  retainCurrent?: Maybe<Scalars['Int']['output']>;
  type: RequestType;
};

export type RequestMessageFotaRequestMessage = FotaRequestMessage | RequestMessage;

export enum RequestState {
  Applying = 'APPLYING',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
  Invalidated = 'INVALIDATED'
}

export enum RequestType {
  Command = 'COMMAND',
  Config = 'CONFIG',
  Cred = 'CRED'
}

export type RestartGroupOperationInput = {
  deviceIds: Array<Scalars['String']['input']>;
  groupRequestId: Scalars['String']['input'];
  operationType: GroupOperationType;
};

export enum Role {
  Administrator = 'administrator',
  Manager = 'manager',
  User = 'user'
}

export type SimCreatedAtInput = {
  deviceId: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  start: Scalars['String']['input'];
};

export type SmsCommandRequestInput = {
  deviceId: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  start: Scalars['String']['input'];
};

export type SmsCommandRequestsPage = {
  __typename?: 'SmsCommandRequestsPage';
  nextToken?: Maybe<Scalars['String']['output']>;
  requests: Array<SmsRequestRow>;
};

export type SmsCommandResource = {
  __typename?: 'SmsCommandResource';
  accountSid: Scalars['String']['output'];
  dateCreated: Scalars['String']['output'];
  dateUpdated: Scalars['String']['output'];
  direction: Scalars['String']['output'];
  payload: Scalars['String']['output'];
  sid: Scalars['String']['output'];
  simSid: Scalars['String']['output'];
  status: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SmsCommandResourceInput = {
  deviceId: Scalars['String']['input'];
  sid: Scalars['String']['input'];
};

export type SmsCommandSendInput = {
  deviceId: Scalars['String']['input'];
  smsCommandRequest: SmsCommandSendRequestInput;
};

export type SmsCommandSendRequestInput = {
  broker?: InputMaybe<Scalars['String']['input']>;
  payload: SntSmsCommand;
  port?: InputMaybe<Scalars['Int']['input']>;
};

export type SmsRequestRow = {
  __typename?: 'SmsRequestRow';
  PK: Scalars['String']['output'];
  SK: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  deviceId: Scalars['String']['output'];
  smsCommandType: SntSmsCommand;
  smsRequestSid: Scalars['String']['output'];
};

export enum SntSmsCommand {
  CallHome = 'CALL_HOME',
  MqttBroker = 'MQTT_BROKER',
  Reset = 'RESET'
}

export enum SortBy {
  CreatedAt = 'CREATED_AT',
  IngestedAt = 'INGESTED_AT',
  TypeCode = 'TYPE_CODE'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StateComponent = {
  __typename?: 'StateComponent';
  data: Scalars['JSONString']['output'];
  sourceMessageId: Scalars['String']['output'];
  sourceMessageTypeCode: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
};

export type StatusCodeResponse = {
  __typename?: 'StatusCodeResponse';
  statusCode: Scalars['Int']['output'];
};

export type StolenAsset = {
  __typename?: 'StolenAsset';
  enable?: Maybe<Scalars['String']['output']>;
  geofence?: Maybe<Scalars['Int']['output']>;
  reportInterval?: Maybe<Scalars['Int']['output']>;
  workingHoursEnd?: Maybe<Scalars['Int']['output']>;
  workingHoursStart?: Maybe<Scalars['Int']['output']>;
};

export type StolenAssetInput = {
  enable?: InputMaybe<Scalars['String']['input']>;
  geofence?: InputMaybe<Scalars['Int']['input']>;
  reportInterval?: InputMaybe<Scalars['Int']['input']>;
  workingHoursEnd?: InputMaybe<Scalars['Int']['input']>;
  workingHoursStart?: InputMaybe<Scalars['Int']['input']>;
};

export type StrawberryDeviceRestartStatus = {
  __typename?: 'StrawberryDeviceRestartStatus';
  deviceId: Scalars['String']['output'];
  exception?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type StrawberryGroupOperationAggregateStatus = {
  __typename?: 'StrawberryGroupOperationAggregateStatus';
  applying: Scalars['Int']['output'];
  completed: Scalars['Int']['output'];
  created: Scalars['Int']['output'];
  failed: Scalars['Int']['output'];
  invalidated: Scalars['Int']['output'];
};

export type StrawberryGroupOperationPage = {
  __typename?: 'StrawberryGroupOperationPage';
  nextToken?: Maybe<Scalars['String']['output']>;
  requests: Array<StrawberryGroupOperationResponse>;
};

export type StrawberryGroupOperationResponse = {
  __typename?: 'StrawberryGroupOperationResponse';
  createdAt: Scalars['String']['output'];
  request: RequestMessageFotaRequestMessage;
  requestId: Scalars['Int']['output'];
  requestState: GroupOperationStatus;
  requestSummary?: Maybe<StrawberryGroupOperationAggregateStatus>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type StrawberryRestartGroupOperation = {
  __typename?: 'StrawberryRestartGroupOperation';
  statuses: Array<StrawberryDeviceRestartStatus>;
};

export enum TypeCode {
  AwsLifecycleConnected = 'AWS_LIFECYCLE_CONNECTED',
  AwsLifecycleDisconnected = 'AWS_LIFECYCLE_DISCONNECTED',
  SntCdmResponse = 'SNT_CDM_RESPONSE',
  SntFotaProgress = 'SNT_FOTA_PROGRESS',
  SntReportBoot = 'SNT_REPORT_BOOT',
  SntReportMotion = 'SNT_REPORT_MOTION',
  SntReportPeriodic = 'SNT_REPORT_PERIODIC',
  SntReportStolen = 'SNT_REPORT_STOLEN',
  SntReportSyslog = 'SNT_REPORT_SYSLOG',
  TwilioAttachmentAccepted = 'TWILIO_ATTACHMENT_ACCEPTED',
  TwilioAttachmentFailed = 'TWILIO_ATTACHMENT_FAILED',
  TwilioAttachmentRejected = 'TWILIO_ATTACHMENT_REJECTED',
  TwilioDatasessionEnded = 'TWILIO_DATASESSION_ENDED',
  TwilioDatasessionFailed = 'TWILIO_DATASESSION_FAILED',
  TwilioDatasessionStarted = 'TWILIO_DATASESSION_STARTED',
  TwilioDatasessionUpdated = 'TWILIO_DATASESSION_UPDATED'
}

export type Update = {
  __typename?: 'Update';
  fotaServer?: Maybe<Scalars['String']['output']>;
};

export type UpdateGroupInput = {
  deviceType?: InputMaybe<Scalars['String']['input']>;
  groupToken: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type UpdateInput = {
  fotaServer?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateUserInput = {
  emailAddress: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  role?: InputMaybe<Role>;
};

export type UserDeleteUserInput = {
  emailAddress: Scalars['String']['input'];
};

export type UserFetchUserInput = {
  emailAddress: Scalars['String']['input'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  emailAddress: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  role: Scalars['String']['output'];
};

export type UserListInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type UserRole = {
  __typename?: 'UserRole';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserRolesResponse = {
  __typename?: 'UserRolesResponse';
  limit: Scalars['Int']['output'];
  roles: Array<UserRole>;
  start: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type UserRow = {
  __typename?: 'UserRow';
  auth0Id: Scalars['String']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UserUpdateUserInput = {
  emailAddress: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
};

export type AddDeviceToGroupMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
}>;


export type AddDeviceToGroupMutation = { __typename?: 'Mutation', addDeviceToGroup: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type AddDeviceToOrganizationMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
}>;


export type AddDeviceToOrganizationMutation = { __typename?: 'Mutation', addDeviceToOrganization: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type CancelGroupRequestMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupRequestId: Scalars['String']['input'];
  operationType: GroupOperationType;
}>;


export type CancelGroupRequestMutation = { __typename?: 'Mutation', invalidateGroupOperation: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type CreateGroupMutationVariables = Exact<{
  groupToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  deviceType: Scalars['String']['input'];
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type CreateGroupCredMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
  module: Module;
  ca?: InputMaybe<Scalars['String']['input']>;
  clientCertificate?: InputMaybe<Scalars['String']['input']>;
  privateKey?: InputMaybe<Scalars['String']['input']>;
  commPw?: InputMaybe<Scalars['String']['input']>;
  commUser?: InputMaybe<Scalars['String']['input']>;
  commMqttBroker?: InputMaybe<Scalars['String']['input']>;
  retainCurrent?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateGroupCredMutation = { __typename?: 'Mutation', groupCredRequest: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type CreateOrganizationMutationVariables = Exact<{
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type CreateUserMutationVariables = Exact<{
  name: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  organizationId: Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type DeleteDeviceCommandConfigRequestMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  requestId: Scalars['Int']['input'];
}>;


export type DeleteDeviceCommandConfigRequestMutation = { __typename?: 'Mutation', deleteDeviceCommandConfigRequest: { __typename?: 'BaseResponse', statusCode: number } };

export type DeleteDeviceFotaRequestMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  requestId: Scalars['Int']['input'];
}>;


export type DeleteDeviceFotaRequestMutation = { __typename?: 'Mutation', deleteDeviceFotaRequest: { __typename?: 'FotaRequestInvalidationResponse', statusCode: number } };

export type DeleteGroupMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
  cascade: Scalars['Boolean']['input'];
}>;


export type DeleteGroupMutation = { __typename?: 'Mutation', deleteGroup: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type DeleteUserMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type PatchUserMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
}>;


export type PatchUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type RemoveDeviceFromGroupMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
}>;


export type RemoveDeviceFromGroupMutation = { __typename?: 'Mutation', removeDeviceFromGroup: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type RemoveDeviceFromOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
}>;


export type RemoveDeviceFromOrganizationMutation = { __typename?: 'Mutation', removeDeviceFromOrganization: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type RestartGroupOperationDevicesMutationVariables = Exact<{
  groupRequestId: Scalars['String']['input'];
  operationType: GroupOperationType;
  deviceIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type RestartGroupOperationDevicesMutation = { __typename?: 'Mutation', restartGroupOperation: { __typename?: 'StrawberryRestartGroupOperation', statuses: Array<{ __typename?: 'StrawberryDeviceRestartStatus', deviceId: string, status: string, exception?: string | null, requestId?: string | null }> } };

export type SendDeviceCommandMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  command: Command;
}>;


export type SendDeviceCommandMutation = { __typename?: 'Mutation', sendDeviceCommand: { __typename?: 'BaseResponse', statusCode: number } };

export type DeviceCredRequestMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  module: Module;
}>;


export type DeviceCredRequestMutation = { __typename?: 'Mutation', sendDeviceCred: { __typename?: 'BaseResponse', statusCode: number } };

export type SendDeviceFotaRequestMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  deviceType: DeviceType;
  firmwareType: FotaType;
  firmwareVersion: Scalars['String']['input'];
}>;


export type SendDeviceFotaRequestMutation = { __typename?: 'Mutation', sendFotaRequest: { __typename?: 'BaseResponse', statusCode: number } };

export type SendGroupCommandMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
  command: Command;
}>;


export type SendGroupCommandMutation = { __typename?: 'Mutation', groupCommandRequest: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type SendGroupConfigMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
  config: ConfigurationInput;
}>;


export type SendGroupConfigMutation = { __typename?: 'Mutation', groupConfigurationRequest: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type SendGroupFotaMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
  deviceType: DeviceType;
  firmwareType: FotaType;
  firmwareVersion: Scalars['String']['input'];
}>;


export type SendGroupFotaMutation = { __typename?: 'Mutation', groupFotaRequest: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type SendSmsCommandMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  smsCommandRequest: SmsCommandSendRequestInput;
}>;


export type SendSmsCommandMutation = { __typename?: 'Mutation', sendSmsCommand: { __typename?: 'SmsCommandResource', accountSid: string, dateCreated: string, dateUpdated: string, direction: string, payload: string, sid: string, simSid: string, status: string, url: string } };

export type UpdateDeviceConfigMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  config: ConfigurationInput;
}>;


export type UpdateDeviceConfigMutation = { __typename?: 'Mutation', updateDeviceConfig: { __typename?: 'BaseResponse', statusCode: number } };

export type UpdateDeviceMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
  deviceType?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateDeviceMutation = { __typename?: 'Mutation', updateDevice: { __typename?: 'StatusCodeResponse', statusCode: number } };

export type GetDeviceCommandConfigRequestQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDeviceCommandConfigRequestQuery = { __typename?: 'Query', deviceCommandConfigRequest: { __typename?: 'CmdCfgRequests', nextToken?: string | null, requests: Array<{ __typename?: 'CommandConfigurationRequestRow', PK: string, responseData?: any | null, SK: string, createdAt: string, deviceId: string, requestId: number, requestState: RequestState, responseCode?: number | null, updatedAt?: string | null, request: { __typename?: 'RequestMessage', command?: Command | null, requestId: number, type: RequestType, config?: { __typename?: 'Configuration', comm?: { __typename?: 'Comm', apn?: string | null, mqttBroker?: string | null, pw?: string | null, smsWakeupInterval?: number | null, timeout?: number | null, type?: CommType | null, user?: string | null } | null, motion?: { __typename?: 'Motion', reportInterval?: number | null, stopTimer?: number | null, ths?: number | null } | null, periodic?: { __typename?: 'Periodic', reportInitialTime?: number | null, reportInterval?: number | null } | null, power?: { __typename?: 'Power', offEnable?: boolean | null, resetEnable?: boolean | null } | null, stolenAsset?: { __typename?: 'StolenAsset', enable?: string | null, geofence?: number | null, reportInterval?: number | null, workingHoursEnd?: number | null, workingHoursStart?: number | null } | null, update?: { __typename?: 'Update', fotaServer?: string | null } | null } | null } }> } };

export type GetDeviceFirmwaresQueryVariables = Exact<{
  deviceType: DeviceType;
  firmwareType: FotaType;
}>;


export type GetDeviceFirmwaresQuery = { __typename?: 'Query', deviceFirmwareRequest: { __typename?: 'DeviceFirmwareResponse', nextToken?: string | null, firmware: Array<{ __typename?: 'FirmwareRow', deviceType: DeviceType, firmwareType: FotaType, url: string, version: string }> } };

export type GetDeviceFotaRequestsQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDeviceFotaRequestsQuery = { __typename?: 'Query', deviceFotaRequestRequest: { __typename?: 'FotaRequests', requests: Array<{ __typename?: 'FotaRequestRow', createdAt: string, firmwareVersion: string, requestId: number, requestState: FotaRequestState, responseCode?: number | null, responseData?: any | null, updatedAt?: string | null, request: { __typename?: 'FotaRequestMessage', type: FotaType, url: string } }> } };

export type GetDeviceGroupsQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDeviceGroupsQuery = { __typename?: 'Query', deviceGroups: { __typename?: 'AdminPaginatedItems', nextToken?: string | null, items: Array<{ __typename?: 'GenericRow', identifier?: string | null, label?: string | null, organizationId?: string | null }> } };

export type GetDeviceInfoQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type GetDeviceInfoQuery = { __typename?: 'Query', deviceInfo: { __typename?: 'GenericRow', identifier?: string | null, label?: string | null, organizationId?: string | null } };

export type GetDeviceMessagesCreatedAtQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDeviceMessagesCreatedAtQuery = { __typename?: 'Query', deviceCreatedAt: { __typename?: 'MessagePage', nextToken?: string | null, items: Array<{ __typename?: 'Messages', PK: string, deviceId: string, createdAt: string, ingestedAt?: string | null, typeCode: string, data: any }> } };

export type GetDeviceMessagesByTypeCodeQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  typeCode: TypeCode;
  start: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDeviceMessagesByTypeCodeQuery = { __typename?: 'Query', deviceTypeCode: { __typename?: 'MessagePage', nextToken?: string | null, items: Array<{ __typename?: 'Messages', PK: string, deviceId: string, createdAt: string, ingestedAt?: string | null, typeCode: string, data: any }> } };

export type GetDeviceShadowQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
}>;


export type GetDeviceShadowQuery = { __typename?: 'Query', deviceShadow: { __typename?: 'DeviceShadowResponse', deviceId: string, deviceType?: string | null, simIccid?: string | null, gps?: { __typename?: 'StateComponent', data: any, timestamp: string, sourceMessageId: string, sourceMessageTypeCode: string } | null, batteryVoltage?: { __typename?: 'StateComponent', data: any, sourceMessageId: string, sourceMessageTypeCode: string, timestamp: string } | null, config?: { __typename?: 'StateComponent', data: any, sourceMessageId: string, sourceMessageTypeCode: string, timestamp: string } | null, lte?: { __typename?: 'StateComponent', data: any, timestamp: string, sourceMessageId: string, sourceMessageTypeCode: string } | null, simState?: { __typename?: 'StateComponent', data: any, timestamp: string, sourceMessageId: string, sourceMessageTypeCode: string } | null, sysinfo?: { __typename?: 'StateComponent', data: any, timestamp: string, sourceMessageId: string, sourceMessageTypeCode: string } | null, temperature?: { __typename?: 'StateComponent', data: any, sourceMessageId: string, sourceMessageTypeCode: string, timestamp: string } | null, lastCheckin?: { __typename?: 'StateComponent', data: any, sourceMessageId: string, sourceMessageTypeCode: string, timestamp: string } | null, lastGoodGps?: { __typename?: 'StateComponent', data: any, sourceMessageId: string, sourceMessageTypeCode: string, timestamp: string } | null, stolenMode?: { __typename?: 'StateComponent', data: any, sourceMessageId: string, sourceMessageTypeCode: string, timestamp: string } | null, motionStatus?: { __typename?: 'StateComponent', data: any, sourceMessageId: string, sourceMessageTypeCode: string, timestamp: string } | null } };

export type GetDeviceTypesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDeviceTypesQuery = { __typename?: 'Query', deviceTypes: { __typename?: 'DeviceTypeItems', nextToken?: string | null, items: Array<{ __typename?: 'DeviceTypeRow', identifier?: string | null, label?: string | null }> } };

export type GetDevicesByOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetDevicesByOrganizationQuery = { __typename?: 'Query', devices: { __typename?: 'AdminPaginatedItems', nextToken?: string | null, items: Array<{ __typename?: 'GenericRow', identifier?: string | null, label?: string | null, organizationId?: string | null, type?: string | null }> } };

export type GetGroupCmdCfgProgressQueryVariables = Exact<{
  groupRequestId: Scalars['String']['input'];
}>;


export type GetGroupCmdCfgProgressQuery = { __typename?: 'Query', groupCmdCfgRequestByGroupRequestId: { __typename?: 'CmdCfgRequests', requests: Array<{ __typename?: 'CommandConfigurationRequestRow', deviceId: string, requestState: RequestState, requestId: number, updatedAt?: string | null, responseCode?: number | null, responseData?: any | null }> } };

export type GetGroupConfigRequestsByGroupTokenQueryVariables = Exact<{
  groupToken: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetGroupConfigRequestsByGroupTokenQuery = { __typename?: 'Query', groupOperationByGroupToken: { __typename?: 'StrawberryGroupOperationPage', requests: Array<{ __typename?: 'StrawberryGroupOperationResponse', createdAt: string, requestId: number, requestState: GroupOperationStatus, updatedAt?: string | null, requestSummary?: { __typename?: 'StrawberryGroupOperationAggregateStatus', applying: number, completed: number, created: number, failed: number, invalidated: number } | null, request: { __typename?: 'FotaRequestMessage' } | { __typename?: 'RequestMessage', command?: Command | null, module?: Module | null, requestId: number, type: RequestType, config?: { __typename?: 'Configuration', comm?: { __typename?: 'Comm', smsWakeupInterval?: number | null, timeout?: number | null } | null, motion?: { __typename?: 'Motion', reportInterval?: number | null, stopTimer?: number | null, ths?: number | null } | null, periodic?: { __typename?: 'Periodic', reportInitialOffset?: number | null, reportInitialTime?: number | null, reportInterval?: number | null } | null, power?: { __typename?: 'Power', offEnable?: boolean | null, resetEnable?: boolean | null } | null, stolenAsset?: { __typename?: 'StolenAsset', enable?: string | null, geofence?: number | null, reportInterval?: number | null, workingHoursEnd?: number | null, workingHoursStart?: number | null } | null } | null } }> } };

export type GetGroupDevicesQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  groupToken: Scalars['String']['input'];
  recursive?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetGroupDevicesQuery = { __typename?: 'Query', groupDevices: { __typename?: 'AdminPaginatedItems', nextToken?: string | null, items: Array<{ __typename?: 'GenericRow', identifier?: string | null, label?: string | null, organizationId?: string | null }> } };

export type GetGroupFotaProgressQueryVariables = Exact<{
  groupRequestId: Scalars['String']['input'];
}>;


export type GetGroupFotaProgressQuery = { __typename?: 'Query', groupFotaRequestByGroupRequestId: { __typename?: 'FotaRequests', requests: Array<{ __typename?: 'FotaRequestRow', deviceId: string, requestState: FotaRequestState, requestId: number, updatedAt?: string | null, request: { __typename: 'FotaRequestMessage', requestId: number, type: FotaType, url: string } }> } };

export type GetGroupFotaRequestsByGroupTokenQueryVariables = Exact<{
  groupToken: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type GetGroupFotaRequestsByGroupTokenQuery = { __typename?: 'Query', groupOperationByGroupToken: { __typename?: 'StrawberryGroupOperationPage', requests: Array<{ __typename?: 'StrawberryGroupOperationResponse', createdAt: string, requestId: number, requestState: GroupOperationStatus, updatedAt?: string | null, requestSummary?: { __typename?: 'StrawberryGroupOperationAggregateStatus', applying: number, completed: number, created: number, failed: number, invalidated: number } | null, request: { __typename: 'FotaRequestMessage', requestId: number, type: FotaType, url: string } | { __typename?: 'RequestMessage' } }> } };

export type GetGroupsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetGroupsQuery = { __typename?: 'Query', groups: { __typename?: 'AdminPaginatedItems', items: Array<{ __typename?: 'GenericRow', label?: string | null, identifier?: string | null, type?: string | null }> } };

export type GetHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHealthQuery = { __typename?: 'Query', health: { __typename?: 'Health', status: string, msg: string, dependencies: Array<{ __typename?: 'ApiDependency', name: string, status: DependencyStatus }> } };

export type GetOrganizationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetOrganizationsQuery = { __typename?: 'Query', organizations: { __typename?: 'AdminPaginatedItems', nextToken?: string | null, items: Array<{ __typename?: 'GenericRow', identifier?: string | null, label?: string | null, organizationId?: string | null, type?: string | null }> } };

export type GetSimEventsCreatedAtQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSimEventsCreatedAtQuery = { __typename?: 'Query', simCreatedAt: { __typename?: 'QuerySimEventsResponse', nextToken?: string | null, items: Array<{ __typename?: 'Messages', PK: string, deviceId: string, createdAt: string, ingestedAt?: string | null, typeCode: string, data: any }> } };

export type GetSmsCommandRequestQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  start: Scalars['String']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSmsCommandRequestQuery = { __typename?: 'Query', smsCommandRequest: { __typename?: 'SmsCommandRequestsPage', nextToken?: string | null, requests: Array<{ __typename?: 'SmsRequestRow', PK: string, SK: string, createdAt: string, deviceId: string, smsCommandType: SntSmsCommand, smsRequestSid: string }> } };

export type GetSmsCommandResourceQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  sid: Scalars['String']['input'];
}>;


export type GetSmsCommandResourceQuery = { __typename?: 'Query', smsCommandResource: { __typename?: 'SmsCommandResource', accountSid: string, dateCreated: string, dateUpdated: string, direction: string, payload: string, sid: string, simSid: string, status: string, url: string } };

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = { __typename?: 'Query', userInfo: { __typename?: 'UserInfo', emailAddress: string, name: string, organizationId: string, role: string, permissions: Array<string> } };

export type GetUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserRolesQuery = { __typename?: 'Query', roles: { __typename?: 'UserRolesResponse', limit: number, start: number, total: number, roles: Array<{ __typename?: 'UserRole', description: string, id: string, name: string }> } };

export type GetUserRowQueryVariables = Exact<{
  emailAddress: Scalars['String']['input'];
}>;


export type GetUserRowQuery = { __typename?: 'Query', userRow: { __typename?: 'UserRow', identifier?: string | null, label?: string | null, organizationId: string, type?: string | null } };

export type GetUsersByOrganizationIdQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetUsersByOrganizationIdQuery = { __typename?: 'Query', users: { __typename?: 'AdminPaginatedItems', items: Array<{ __typename?: 'GenericRow', identifier?: string | null, label?: string | null, organizationId?: string | null, type?: string | null }> } };


export const AddDeviceToGroupDocument = gql`
    mutation AddDeviceToGroup($organizationId: String!, $deviceId: String!, $groupToken: String!) {
  addDeviceToGroup(
    deviceGroupXrefInput: {organizationId: $organizationId, deviceId: $deviceId, groupToken: $groupToken}
  ) {
    statusCode
  }
}
    `;
export type AddDeviceToGroupMutationFn = Apollo.MutationFunction<AddDeviceToGroupMutation, AddDeviceToGroupMutationVariables>;

/**
 * __useAddDeviceToGroupMutation__
 *
 * To run a mutation, you first call `useAddDeviceToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceToGroupMutation, { data, loading, error }] = useAddDeviceToGroupMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      deviceId: // value for 'deviceId'
 *      groupToken: // value for 'groupToken'
 *   },
 * });
 */
export function useAddDeviceToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddDeviceToGroupMutation, AddDeviceToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDeviceToGroupMutation, AddDeviceToGroupMutationVariables>(AddDeviceToGroupDocument, options);
      }
export type AddDeviceToGroupMutationHookResult = ReturnType<typeof useAddDeviceToGroupMutation>;
export type AddDeviceToGroupMutationResult = Apollo.MutationResult<AddDeviceToGroupMutation>;
export type AddDeviceToGroupMutationOptions = Apollo.BaseMutationOptions<AddDeviceToGroupMutation, AddDeviceToGroupMutationVariables>;
export const AddDeviceToOrganizationDocument = gql`
    mutation AddDeviceToOrganization($deviceId: String!, $organizationId: String!, $label: String, $deviceType: String) {
  addDeviceToOrganization(
    deviceOrganizationXrefInput: {deviceId: $deviceId, organizationId: $organizationId, label: $label, deviceType: $deviceType}
  ) {
    statusCode
  }
}
    `;
export type AddDeviceToOrganizationMutationFn = Apollo.MutationFunction<AddDeviceToOrganizationMutation, AddDeviceToOrganizationMutationVariables>;

/**
 * __useAddDeviceToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddDeviceToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceToOrganizationMutation, { data, loading, error }] = useAddDeviceToOrganizationMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      organizationId: // value for 'organizationId'
 *      label: // value for 'label'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useAddDeviceToOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<AddDeviceToOrganizationMutation, AddDeviceToOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDeviceToOrganizationMutation, AddDeviceToOrganizationMutationVariables>(AddDeviceToOrganizationDocument, options);
      }
export type AddDeviceToOrganizationMutationHookResult = ReturnType<typeof useAddDeviceToOrganizationMutation>;
export type AddDeviceToOrganizationMutationResult = Apollo.MutationResult<AddDeviceToOrganizationMutation>;
export type AddDeviceToOrganizationMutationOptions = Apollo.BaseMutationOptions<AddDeviceToOrganizationMutation, AddDeviceToOrganizationMutationVariables>;
export const CancelGroupRequestDocument = gql`
    mutation CancelGroupRequest($organizationId: String!, $groupRequestId: String!, $operationType: GroupOperationType!) {
  invalidateGroupOperation(
    groupOperationInvalidationInput: {organizationId: $organizationId, groupRequestId: $groupRequestId, operationType: $operationType}
  ) {
    statusCode
  }
}
    `;
export type CancelGroupRequestMutationFn = Apollo.MutationFunction<CancelGroupRequestMutation, CancelGroupRequestMutationVariables>;

/**
 * __useCancelGroupRequestMutation__
 *
 * To run a mutation, you first call `useCancelGroupRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGroupRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGroupRequestMutation, { data, loading, error }] = useCancelGroupRequestMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      groupRequestId: // value for 'groupRequestId'
 *      operationType: // value for 'operationType'
 *   },
 * });
 */
export function useCancelGroupRequestMutation(baseOptions?: Apollo.MutationHookOptions<CancelGroupRequestMutation, CancelGroupRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelGroupRequestMutation, CancelGroupRequestMutationVariables>(CancelGroupRequestDocument, options);
      }
export type CancelGroupRequestMutationHookResult = ReturnType<typeof useCancelGroupRequestMutation>;
export type CancelGroupRequestMutationResult = Apollo.MutationResult<CancelGroupRequestMutation>;
export type CancelGroupRequestMutationOptions = Apollo.BaseMutationOptions<CancelGroupRequestMutation, CancelGroupRequestMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($groupToken: String!, $name: String!, $organizationId: String!, $deviceType: String!) {
  createGroup(
    createGroupInput: {groupToken: $groupToken, name: $name, organizationId: $organizationId, deviceType: $deviceType}
  ) {
    statusCode
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      groupToken: // value for 'groupToken'
 *      name: // value for 'name'
 *      organizationId: // value for 'organizationId'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const CreateGroupCredDocument = gql`
    mutation CreateGroupCred($organizationId: String!, $groupToken: String!, $module: Module!, $ca: String, $clientCertificate: String, $privateKey: String, $commPw: String, $commUser: String, $commMqttBroker: String, $retainCurrent: Int) {
  groupCredRequest(
    groupCredRequest: {organizationId: $organizationId, groupToken: $groupToken, module: $module, ca: $ca, clientCertificate: $clientCertificate, privateKey: $privateKey, commPw: $commPw, commType: AWS, commUser: $commUser, retainCurrent: $retainCurrent, commMqttBroker: $commMqttBroker}
  ) {
    statusCode
  }
}
    `;
export type CreateGroupCredMutationFn = Apollo.MutationFunction<CreateGroupCredMutation, CreateGroupCredMutationVariables>;

/**
 * __useCreateGroupCredMutation__
 *
 * To run a mutation, you first call `useCreateGroupCredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupCredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupCredMutation, { data, loading, error }] = useCreateGroupCredMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      groupToken: // value for 'groupToken'
 *      module: // value for 'module'
 *      ca: // value for 'ca'
 *      clientCertificate: // value for 'clientCertificate'
 *      privateKey: // value for 'privateKey'
 *      commPw: // value for 'commPw'
 *      commUser: // value for 'commUser'
 *      commMqttBroker: // value for 'commMqttBroker'
 *      retainCurrent: // value for 'retainCurrent'
 *   },
 * });
 */
export function useCreateGroupCredMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupCredMutation, CreateGroupCredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupCredMutation, CreateGroupCredMutationVariables>(CreateGroupCredDocument, options);
      }
export type CreateGroupCredMutationHookResult = ReturnType<typeof useCreateGroupCredMutation>;
export type CreateGroupCredMutationResult = Apollo.MutationResult<CreateGroupCredMutation>;
export type CreateGroupCredMutationOptions = Apollo.BaseMutationOptions<CreateGroupCredMutation, CreateGroupCredMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($name: String!, $organizationId: String!) {
  createOrganization(
    createOrganizationInput: {name: $name, organizationId: $organizationId}
  ) {
    statusCode
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($name: String!, $emailAddress: String!, $role: Role, $organizationId: String!) {
  createUser(
    userCreateUserInput: {name: $name, emailAddress: $emailAddress, role: $role, organizationId: $organizationId}
  ) {
    statusCode
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      emailAddress: // value for 'emailAddress'
 *      role: // value for 'role'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteDeviceCommandConfigRequestDocument = gql`
    mutation DeleteDeviceCommandConfigRequest($deviceId: String!, $requestId: Int!) {
  deleteDeviceCommandConfigRequest(
    deviceRequestInvalidateInput: {deviceId: $deviceId, requestId: $requestId}
  ) {
    statusCode
  }
}
    `;
export type DeleteDeviceCommandConfigRequestMutationFn = Apollo.MutationFunction<DeleteDeviceCommandConfigRequestMutation, DeleteDeviceCommandConfigRequestMutationVariables>;

/**
 * __useDeleteDeviceCommandConfigRequestMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceCommandConfigRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceCommandConfigRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceCommandConfigRequestMutation, { data, loading, error }] = useDeleteDeviceCommandConfigRequestMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useDeleteDeviceCommandConfigRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceCommandConfigRequestMutation, DeleteDeviceCommandConfigRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceCommandConfigRequestMutation, DeleteDeviceCommandConfigRequestMutationVariables>(DeleteDeviceCommandConfigRequestDocument, options);
      }
export type DeleteDeviceCommandConfigRequestMutationHookResult = ReturnType<typeof useDeleteDeviceCommandConfigRequestMutation>;
export type DeleteDeviceCommandConfigRequestMutationResult = Apollo.MutationResult<DeleteDeviceCommandConfigRequestMutation>;
export type DeleteDeviceCommandConfigRequestMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceCommandConfigRequestMutation, DeleteDeviceCommandConfigRequestMutationVariables>;
export const DeleteDeviceFotaRequestDocument = gql`
    mutation DeleteDeviceFotaRequest($deviceId: String!, $requestId: Int!) {
  deleteDeviceFotaRequest(
    deviceFotaRequestInvalidateInput: {deviceId: $deviceId, requestId: $requestId}
  ) {
    statusCode
  }
}
    `;
export type DeleteDeviceFotaRequestMutationFn = Apollo.MutationFunction<DeleteDeviceFotaRequestMutation, DeleteDeviceFotaRequestMutationVariables>;

/**
 * __useDeleteDeviceFotaRequestMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceFotaRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceFotaRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceFotaRequestMutation, { data, loading, error }] = useDeleteDeviceFotaRequestMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useDeleteDeviceFotaRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceFotaRequestMutation, DeleteDeviceFotaRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceFotaRequestMutation, DeleteDeviceFotaRequestMutationVariables>(DeleteDeviceFotaRequestDocument, options);
      }
export type DeleteDeviceFotaRequestMutationHookResult = ReturnType<typeof useDeleteDeviceFotaRequestMutation>;
export type DeleteDeviceFotaRequestMutationResult = Apollo.MutationResult<DeleteDeviceFotaRequestMutation>;
export type DeleteDeviceFotaRequestMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceFotaRequestMutation, DeleteDeviceFotaRequestMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($organizationId: String!, $groupToken: String!, $cascade: Boolean!) {
  deleteGroup(
    deleteGroupInput: {organizationId: $organizationId, groupToken: $groupToken, cascade: $cascade}
  ) {
    statusCode
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      groupToken: // value for 'groupToken'
 *      cascade: // value for 'cascade'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($emailAddress: String!) {
  deleteUser(userDeleteUserInput: {emailAddress: $emailAddress}) {
    statusCode
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const PatchUserDocument = gql`
    mutation PatchUser($emailAddress: String!, $name: String, $role: Role) {
  updateUser(
    userUpdateUserInput: {emailAddress: $emailAddress, name: $name, role: $role}
  ) {
    statusCode
  }
}
    `;
export type PatchUserMutationFn = Apollo.MutationFunction<PatchUserMutation, PatchUserMutationVariables>;

/**
 * __usePatchUserMutation__
 *
 * To run a mutation, you first call `usePatchUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchUserMutation, { data, loading, error }] = usePatchUserMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *   },
 * });
 */
export function usePatchUserMutation(baseOptions?: Apollo.MutationHookOptions<PatchUserMutation, PatchUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PatchUserMutation, PatchUserMutationVariables>(PatchUserDocument, options);
      }
export type PatchUserMutationHookResult = ReturnType<typeof usePatchUserMutation>;
export type PatchUserMutationResult = Apollo.MutationResult<PatchUserMutation>;
export type PatchUserMutationOptions = Apollo.BaseMutationOptions<PatchUserMutation, PatchUserMutationVariables>;
export const RemoveDeviceFromGroupDocument = gql`
    mutation RemoveDeviceFromGroup($organizationId: String!, $deviceId: String!, $groupToken: String!) {
  removeDeviceFromGroup(
    deviceGroupXrefInput: {organizationId: $organizationId, deviceId: $deviceId, groupToken: $groupToken}
  ) {
    statusCode
  }
}
    `;
export type RemoveDeviceFromGroupMutationFn = Apollo.MutationFunction<RemoveDeviceFromGroupMutation, RemoveDeviceFromGroupMutationVariables>;

/**
 * __useRemoveDeviceFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveDeviceFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeviceFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeviceFromGroupMutation, { data, loading, error }] = useRemoveDeviceFromGroupMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      deviceId: // value for 'deviceId'
 *      groupToken: // value for 'groupToken'
 *   },
 * });
 */
export function useRemoveDeviceFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDeviceFromGroupMutation, RemoveDeviceFromGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDeviceFromGroupMutation, RemoveDeviceFromGroupMutationVariables>(RemoveDeviceFromGroupDocument, options);
      }
export type RemoveDeviceFromGroupMutationHookResult = ReturnType<typeof useRemoveDeviceFromGroupMutation>;
export type RemoveDeviceFromGroupMutationResult = Apollo.MutationResult<RemoveDeviceFromGroupMutation>;
export type RemoveDeviceFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveDeviceFromGroupMutation, RemoveDeviceFromGroupMutationVariables>;
export const RemoveDeviceFromOrganizationDocument = gql`
    mutation RemoveDeviceFromOrganization($organizationId: String!, $deviceId: String!) {
  removeDeviceFromOrganization(
    deviceOrganizationXrefInput: {organizationId: $organizationId, deviceId: $deviceId}
  ) {
    statusCode
  }
}
    `;
export type RemoveDeviceFromOrganizationMutationFn = Apollo.MutationFunction<RemoveDeviceFromOrganizationMutation, RemoveDeviceFromOrganizationMutationVariables>;

/**
 * __useRemoveDeviceFromOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveDeviceFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeviceFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeviceFromOrganizationMutation, { data, loading, error }] = useRemoveDeviceFromOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useRemoveDeviceFromOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDeviceFromOrganizationMutation, RemoveDeviceFromOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDeviceFromOrganizationMutation, RemoveDeviceFromOrganizationMutationVariables>(RemoveDeviceFromOrganizationDocument, options);
      }
export type RemoveDeviceFromOrganizationMutationHookResult = ReturnType<typeof useRemoveDeviceFromOrganizationMutation>;
export type RemoveDeviceFromOrganizationMutationResult = Apollo.MutationResult<RemoveDeviceFromOrganizationMutation>;
export type RemoveDeviceFromOrganizationMutationOptions = Apollo.BaseMutationOptions<RemoveDeviceFromOrganizationMutation, RemoveDeviceFromOrganizationMutationVariables>;
export const RestartGroupOperationDevicesDocument = gql`
    mutation RestartGroupOperationDevices($groupRequestId: String!, $operationType: GroupOperationType!, $deviceIds: [String!]!) {
  restartGroupOperation(
    groupOperationInput: {groupRequestId: $groupRequestId, operationType: $operationType, deviceIds: $deviceIds}
  ) {
    statuses {
      deviceId
      status
      exception
      requestId
    }
  }
}
    `;
export type RestartGroupOperationDevicesMutationFn = Apollo.MutationFunction<RestartGroupOperationDevicesMutation, RestartGroupOperationDevicesMutationVariables>;

/**
 * __useRestartGroupOperationDevicesMutation__
 *
 * To run a mutation, you first call `useRestartGroupOperationDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartGroupOperationDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartGroupOperationDevicesMutation, { data, loading, error }] = useRestartGroupOperationDevicesMutation({
 *   variables: {
 *      groupRequestId: // value for 'groupRequestId'
 *      operationType: // value for 'operationType'
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useRestartGroupOperationDevicesMutation(baseOptions?: Apollo.MutationHookOptions<RestartGroupOperationDevicesMutation, RestartGroupOperationDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartGroupOperationDevicesMutation, RestartGroupOperationDevicesMutationVariables>(RestartGroupOperationDevicesDocument, options);
      }
export type RestartGroupOperationDevicesMutationHookResult = ReturnType<typeof useRestartGroupOperationDevicesMutation>;
export type RestartGroupOperationDevicesMutationResult = Apollo.MutationResult<RestartGroupOperationDevicesMutation>;
export type RestartGroupOperationDevicesMutationOptions = Apollo.BaseMutationOptions<RestartGroupOperationDevicesMutation, RestartGroupOperationDevicesMutationVariables>;
export const SendDeviceCommandDocument = gql`
    mutation SendDeviceCommand($deviceId: String!, $command: Command!) {
  sendDeviceCommand(
    deviceRequestCommandInput: {deviceId: $deviceId, command: $command}
  ) {
    statusCode
  }
}
    `;
export type SendDeviceCommandMutationFn = Apollo.MutationFunction<SendDeviceCommandMutation, SendDeviceCommandMutationVariables>;

/**
 * __useSendDeviceCommandMutation__
 *
 * To run a mutation, you first call `useSendDeviceCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDeviceCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDeviceCommandMutation, { data, loading, error }] = useSendDeviceCommandMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      command: // value for 'command'
 *   },
 * });
 */
export function useSendDeviceCommandMutation(baseOptions?: Apollo.MutationHookOptions<SendDeviceCommandMutation, SendDeviceCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDeviceCommandMutation, SendDeviceCommandMutationVariables>(SendDeviceCommandDocument, options);
      }
export type SendDeviceCommandMutationHookResult = ReturnType<typeof useSendDeviceCommandMutation>;
export type SendDeviceCommandMutationResult = Apollo.MutationResult<SendDeviceCommandMutation>;
export type SendDeviceCommandMutationOptions = Apollo.BaseMutationOptions<SendDeviceCommandMutation, SendDeviceCommandMutationVariables>;
export const DeviceCredRequestDocument = gql`
    mutation DeviceCredRequest($deviceId: String!, $module: Module!) {
  sendDeviceCred(deviceRequestCredInput: {deviceId: $deviceId, module: $module}) {
    statusCode
  }
}
    `;
export type DeviceCredRequestMutationFn = Apollo.MutationFunction<DeviceCredRequestMutation, DeviceCredRequestMutationVariables>;

/**
 * __useDeviceCredRequestMutation__
 *
 * To run a mutation, you first call `useDeviceCredRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceCredRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceCredRequestMutation, { data, loading, error }] = useDeviceCredRequestMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      module: // value for 'module'
 *   },
 * });
 */
export function useDeviceCredRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeviceCredRequestMutation, DeviceCredRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceCredRequestMutation, DeviceCredRequestMutationVariables>(DeviceCredRequestDocument, options);
      }
export type DeviceCredRequestMutationHookResult = ReturnType<typeof useDeviceCredRequestMutation>;
export type DeviceCredRequestMutationResult = Apollo.MutationResult<DeviceCredRequestMutation>;
export type DeviceCredRequestMutationOptions = Apollo.BaseMutationOptions<DeviceCredRequestMutation, DeviceCredRequestMutationVariables>;
export const SendDeviceFotaRequestDocument = gql`
    mutation SendDeviceFotaRequest($deviceId: String!, $deviceType: DeviceType!, $firmwareType: FotaType!, $firmwareVersion: String!) {
  sendFotaRequest(
    deviceFotaRequestInput: {deviceId: $deviceId, deviceType: $deviceType, firmwareType: $firmwareType, firmwareVersion: $firmwareVersion}
  ) {
    statusCode
  }
}
    `;
export type SendDeviceFotaRequestMutationFn = Apollo.MutationFunction<SendDeviceFotaRequestMutation, SendDeviceFotaRequestMutationVariables>;

/**
 * __useSendDeviceFotaRequestMutation__
 *
 * To run a mutation, you first call `useSendDeviceFotaRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDeviceFotaRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDeviceFotaRequestMutation, { data, loading, error }] = useSendDeviceFotaRequestMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceType: // value for 'deviceType'
 *      firmwareType: // value for 'firmwareType'
 *      firmwareVersion: // value for 'firmwareVersion'
 *   },
 * });
 */
export function useSendDeviceFotaRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendDeviceFotaRequestMutation, SendDeviceFotaRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDeviceFotaRequestMutation, SendDeviceFotaRequestMutationVariables>(SendDeviceFotaRequestDocument, options);
      }
export type SendDeviceFotaRequestMutationHookResult = ReturnType<typeof useSendDeviceFotaRequestMutation>;
export type SendDeviceFotaRequestMutationResult = Apollo.MutationResult<SendDeviceFotaRequestMutation>;
export type SendDeviceFotaRequestMutationOptions = Apollo.BaseMutationOptions<SendDeviceFotaRequestMutation, SendDeviceFotaRequestMutationVariables>;
export const SendGroupCommandDocument = gql`
    mutation SendGroupCommand($organizationId: String!, $groupToken: String!, $command: Command!) {
  groupCommandRequest(
    groupCommandRequest: {organizationId: $organizationId, groupToken: $groupToken, command: $command}
  ) {
    statusCode
  }
}
    `;
export type SendGroupCommandMutationFn = Apollo.MutationFunction<SendGroupCommandMutation, SendGroupCommandMutationVariables>;

/**
 * __useSendGroupCommandMutation__
 *
 * To run a mutation, you first call `useSendGroupCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGroupCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGroupCommandMutation, { data, loading, error }] = useSendGroupCommandMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      groupToken: // value for 'groupToken'
 *      command: // value for 'command'
 *   },
 * });
 */
export function useSendGroupCommandMutation(baseOptions?: Apollo.MutationHookOptions<SendGroupCommandMutation, SendGroupCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendGroupCommandMutation, SendGroupCommandMutationVariables>(SendGroupCommandDocument, options);
      }
export type SendGroupCommandMutationHookResult = ReturnType<typeof useSendGroupCommandMutation>;
export type SendGroupCommandMutationResult = Apollo.MutationResult<SendGroupCommandMutation>;
export type SendGroupCommandMutationOptions = Apollo.BaseMutationOptions<SendGroupCommandMutation, SendGroupCommandMutationVariables>;
export const SendGroupConfigDocument = gql`
    mutation SendGroupConfig($organizationId: String!, $groupToken: String!, $config: ConfigurationInput!) {
  groupConfigurationRequest(
    groupConfigurationRequest: {organizationId: $organizationId, groupToken: $groupToken, config: $config}
  ) {
    statusCode
  }
}
    `;
export type SendGroupConfigMutationFn = Apollo.MutationFunction<SendGroupConfigMutation, SendGroupConfigMutationVariables>;

/**
 * __useSendGroupConfigMutation__
 *
 * To run a mutation, you first call `useSendGroupConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGroupConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGroupConfigMutation, { data, loading, error }] = useSendGroupConfigMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      groupToken: // value for 'groupToken'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useSendGroupConfigMutation(baseOptions?: Apollo.MutationHookOptions<SendGroupConfigMutation, SendGroupConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendGroupConfigMutation, SendGroupConfigMutationVariables>(SendGroupConfigDocument, options);
      }
export type SendGroupConfigMutationHookResult = ReturnType<typeof useSendGroupConfigMutation>;
export type SendGroupConfigMutationResult = Apollo.MutationResult<SendGroupConfigMutation>;
export type SendGroupConfigMutationOptions = Apollo.BaseMutationOptions<SendGroupConfigMutation, SendGroupConfigMutationVariables>;
export const SendGroupFotaDocument = gql`
    mutation SendGroupFota($organizationId: String!, $groupToken: String!, $deviceType: DeviceType!, $firmwareType: FotaType!, $firmwareVersion: String!) {
  groupFotaRequest(
    groupFotaRequest: {organizationId: $organizationId, groupToken: $groupToken, deviceType: $deviceType, firmwareType: $firmwareType, firmwareVersion: $firmwareVersion}
  ) {
    statusCode
  }
}
    `;
export type SendGroupFotaMutationFn = Apollo.MutationFunction<SendGroupFotaMutation, SendGroupFotaMutationVariables>;

/**
 * __useSendGroupFotaMutation__
 *
 * To run a mutation, you first call `useSendGroupFotaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendGroupFotaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendGroupFotaMutation, { data, loading, error }] = useSendGroupFotaMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      groupToken: // value for 'groupToken'
 *      deviceType: // value for 'deviceType'
 *      firmwareType: // value for 'firmwareType'
 *      firmwareVersion: // value for 'firmwareVersion'
 *   },
 * });
 */
export function useSendGroupFotaMutation(baseOptions?: Apollo.MutationHookOptions<SendGroupFotaMutation, SendGroupFotaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendGroupFotaMutation, SendGroupFotaMutationVariables>(SendGroupFotaDocument, options);
      }
export type SendGroupFotaMutationHookResult = ReturnType<typeof useSendGroupFotaMutation>;
export type SendGroupFotaMutationResult = Apollo.MutationResult<SendGroupFotaMutation>;
export type SendGroupFotaMutationOptions = Apollo.BaseMutationOptions<SendGroupFotaMutation, SendGroupFotaMutationVariables>;
export const SendSmsCommandDocument = gql`
    mutation SendSmsCommand($deviceId: String!, $smsCommandRequest: SmsCommandSendRequestInput!) {
  sendSmsCommand(
    smsCommandSendInput: {deviceId: $deviceId, smsCommandRequest: $smsCommandRequest}
  ) {
    accountSid
    dateCreated
    dateUpdated
    direction
    payload
    sid
    simSid
    status
    url
  }
}
    `;
export type SendSmsCommandMutationFn = Apollo.MutationFunction<SendSmsCommandMutation, SendSmsCommandMutationVariables>;

/**
 * __useSendSmsCommandMutation__
 *
 * To run a mutation, you first call `useSendSmsCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsCommandMutation, { data, loading, error }] = useSendSmsCommandMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      smsCommandRequest: // value for 'smsCommandRequest'
 *   },
 * });
 */
export function useSendSmsCommandMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsCommandMutation, SendSmsCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSmsCommandMutation, SendSmsCommandMutationVariables>(SendSmsCommandDocument, options);
      }
export type SendSmsCommandMutationHookResult = ReturnType<typeof useSendSmsCommandMutation>;
export type SendSmsCommandMutationResult = Apollo.MutationResult<SendSmsCommandMutation>;
export type SendSmsCommandMutationOptions = Apollo.BaseMutationOptions<SendSmsCommandMutation, SendSmsCommandMutationVariables>;
export const UpdateDeviceConfigDocument = gql`
    mutation UpdateDeviceConfig($deviceId: String!, $config: ConfigurationInput!) {
  updateDeviceConfig(
    deviceRequestConfigInput: {deviceId: $deviceId, config: $config}
  ) {
    statusCode
  }
}
    `;
export type UpdateDeviceConfigMutationFn = Apollo.MutationFunction<UpdateDeviceConfigMutation, UpdateDeviceConfigMutationVariables>;

/**
 * __useUpdateDeviceConfigMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceConfigMutation, { data, loading, error }] = useUpdateDeviceConfigMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useUpdateDeviceConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceConfigMutation, UpdateDeviceConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceConfigMutation, UpdateDeviceConfigMutationVariables>(UpdateDeviceConfigDocument, options);
      }
export type UpdateDeviceConfigMutationHookResult = ReturnType<typeof useUpdateDeviceConfigMutation>;
export type UpdateDeviceConfigMutationResult = Apollo.MutationResult<UpdateDeviceConfigMutation>;
export type UpdateDeviceConfigMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceConfigMutation, UpdateDeviceConfigMutationVariables>;
export const UpdateDeviceDocument = gql`
    mutation UpdateDevice($organizationId: String!, $deviceId: String!, $deviceType: String, $label: String) {
  updateDevice(
    deviceOrganizationXrefInput: {organizationId: $organizationId, deviceId: $deviceId, deviceType: $deviceType, label: $label}
  ) {
    statusCode
  }
}
    `;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<UpdateDeviceMutation, UpdateDeviceMutationVariables>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      deviceId: // value for 'deviceId'
 *      deviceType: // value for 'deviceType'
 *      label: // value for 'label'
 *   },
 * });
 */
export function useUpdateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument, options);
      }
export type UpdateDeviceMutationHookResult = ReturnType<typeof useUpdateDeviceMutation>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>;
export const GetDeviceCommandConfigRequestDocument = gql`
    query GetDeviceCommandConfigRequest($deviceId: String!, $start: String!, $end: String, $limit: Int, $sortDirection: SortDirection = DESC, $nextToken: String) {
  deviceCommandConfigRequest(
    deviceCommandConfigRequestInput: {deviceId: $deviceId, start: $start, end: $end, limit: $limit, sortDirection: $sortDirection, nextToken: $nextToken}
  ) {
    nextToken
    requests {
      PK
      responseData
      SK
      createdAt
      deviceId
      requestId
      requestState
      responseCode
      updatedAt
      request {
        command
        requestId
        type
        config {
          comm {
            apn
            mqttBroker
            pw
            smsWakeupInterval
            timeout
            type
            user
          }
          motion {
            reportInterval
            stopTimer
            ths
          }
          periodic {
            reportInitialTime
            reportInterval
          }
          power {
            offEnable
            resetEnable
          }
          stolenAsset {
            enable
            geofence
            reportInterval
            workingHoursEnd
            workingHoursStart
          }
          update {
            fotaServer
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDeviceCommandConfigRequestQuery__
 *
 * To run a query within a React component, call `useGetDeviceCommandConfigRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceCommandConfigRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceCommandConfigRequestQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      sortDirection: // value for 'sortDirection'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetDeviceCommandConfigRequestQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceCommandConfigRequestQuery, GetDeviceCommandConfigRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceCommandConfigRequestQuery, GetDeviceCommandConfigRequestQueryVariables>(GetDeviceCommandConfigRequestDocument, options);
      }
export function useGetDeviceCommandConfigRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceCommandConfigRequestQuery, GetDeviceCommandConfigRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceCommandConfigRequestQuery, GetDeviceCommandConfigRequestQueryVariables>(GetDeviceCommandConfigRequestDocument, options);
        }
export type GetDeviceCommandConfigRequestQueryHookResult = ReturnType<typeof useGetDeviceCommandConfigRequestQuery>;
export type GetDeviceCommandConfigRequestLazyQueryHookResult = ReturnType<typeof useGetDeviceCommandConfigRequestLazyQuery>;
export type GetDeviceCommandConfigRequestQueryResult = Apollo.QueryResult<GetDeviceCommandConfigRequestQuery, GetDeviceCommandConfigRequestQueryVariables>;
export const GetDeviceFirmwaresDocument = gql`
    query GetDeviceFirmwares($deviceType: DeviceType!, $firmwareType: FotaType!) {
  deviceFirmwareRequest(
    deviceFirmwareInput: {deviceType: $deviceType, firmwareType: $firmwareType}
  ) {
    nextToken
    firmware {
      deviceType
      firmwareType
      url
      version
    }
  }
}
    `;

/**
 * __useGetDeviceFirmwaresQuery__
 *
 * To run a query within a React component, call `useGetDeviceFirmwaresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceFirmwaresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceFirmwaresQuery({
 *   variables: {
 *      deviceType: // value for 'deviceType'
 *      firmwareType: // value for 'firmwareType'
 *   },
 * });
 */
export function useGetDeviceFirmwaresQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>(GetDeviceFirmwaresDocument, options);
      }
export function useGetDeviceFirmwaresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>(GetDeviceFirmwaresDocument, options);
        }
export type GetDeviceFirmwaresQueryHookResult = ReturnType<typeof useGetDeviceFirmwaresQuery>;
export type GetDeviceFirmwaresLazyQueryHookResult = ReturnType<typeof useGetDeviceFirmwaresLazyQuery>;
export type GetDeviceFirmwaresQueryResult = Apollo.QueryResult<GetDeviceFirmwaresQuery, GetDeviceFirmwaresQueryVariables>;
export const GetDeviceFotaRequestsDocument = gql`
    query GetDeviceFotaRequests($deviceId: String!, $start: String!, $end: String, $limit: Int = 10, $nextToken: String) {
  deviceFotaRequestRequest(
    deviceFotaRequestRequestInput: {deviceId: $deviceId, start: $start, end: $end, limit: $limit, nextToken: $nextToken}
  ) {
    requests {
      createdAt
      request {
        type
        url
      }
      firmwareVersion
      requestId
      requestState
      responseCode
      responseData
      updatedAt
    }
  }
}
    `;

/**
 * __useGetDeviceFotaRequestsQuery__
 *
 * To run a query within a React component, call `useGetDeviceFotaRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceFotaRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceFotaRequestsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetDeviceFotaRequestsQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceFotaRequestsQuery, GetDeviceFotaRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceFotaRequestsQuery, GetDeviceFotaRequestsQueryVariables>(GetDeviceFotaRequestsDocument, options);
      }
export function useGetDeviceFotaRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceFotaRequestsQuery, GetDeviceFotaRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceFotaRequestsQuery, GetDeviceFotaRequestsQueryVariables>(GetDeviceFotaRequestsDocument, options);
        }
export type GetDeviceFotaRequestsQueryHookResult = ReturnType<typeof useGetDeviceFotaRequestsQuery>;
export type GetDeviceFotaRequestsLazyQueryHookResult = ReturnType<typeof useGetDeviceFotaRequestsLazyQuery>;
export type GetDeviceFotaRequestsQueryResult = Apollo.QueryResult<GetDeviceFotaRequestsQuery, GetDeviceFotaRequestsQueryVariables>;
export const GetDeviceGroupsDocument = gql`
    query GetDeviceGroups($deviceId: String!, $limit: Int = 10, $nextToken: String) {
  deviceGroups(
    deviceGroupsInput: {deviceId: $deviceId, limit: $limit, nextToken: $nextToken}
  ) {
    items {
      identifier
      label
      organizationId
    }
    nextToken
  }
}
    `;

/**
 * __useGetDeviceGroupsQuery__
 *
 * To run a query within a React component, call `useGetDeviceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceGroupsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetDeviceGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>(GetDeviceGroupsDocument, options);
      }
export function useGetDeviceGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>(GetDeviceGroupsDocument, options);
        }
export type GetDeviceGroupsQueryHookResult = ReturnType<typeof useGetDeviceGroupsQuery>;
export type GetDeviceGroupsLazyQueryHookResult = ReturnType<typeof useGetDeviceGroupsLazyQuery>;
export type GetDeviceGroupsQueryResult = Apollo.QueryResult<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>;
export const GetDeviceInfoDocument = gql`
    query GetDeviceInfo($deviceId: String!, $organizationId: String!) {
  deviceInfo(
    deviceInfoInput: {deviceId: $deviceId, organizationId: $organizationId}
  ) {
    identifier
    label
    organizationId
  }
}
    `;

/**
 * __useGetDeviceInfoQuery__
 *
 * To run a query within a React component, call `useGetDeviceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceInfoQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetDeviceInfoQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>(GetDeviceInfoDocument, options);
      }
export function useGetDeviceInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>(GetDeviceInfoDocument, options);
        }
export type GetDeviceInfoQueryHookResult = ReturnType<typeof useGetDeviceInfoQuery>;
export type GetDeviceInfoLazyQueryHookResult = ReturnType<typeof useGetDeviceInfoLazyQuery>;
export type GetDeviceInfoQueryResult = Apollo.QueryResult<GetDeviceInfoQuery, GetDeviceInfoQueryVariables>;
export const GetDeviceMessagesCreatedAtDocument = gql`
    query GetDeviceMessagesCreatedAt($deviceId: String!, $start: String!, $end: String, $limit: Int = 10, $sortDirection: SortDirection = DESC, $nextToken: String) {
  deviceCreatedAt(
    deviceCreatedAtInput: {deviceId: $deviceId, start: $start, end: $end, limit: $limit, sortDirection: $sortDirection, nextToken: $nextToken}
  ) {
    items {
      PK
      deviceId
      createdAt
      ingestedAt
      typeCode
      data
    }
    nextToken
  }
}
    `;

/**
 * __useGetDeviceMessagesCreatedAtQuery__
 *
 * To run a query within a React component, call `useGetDeviceMessagesCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceMessagesCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceMessagesCreatedAtQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      sortDirection: // value for 'sortDirection'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetDeviceMessagesCreatedAtQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceMessagesCreatedAtQuery, GetDeviceMessagesCreatedAtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceMessagesCreatedAtQuery, GetDeviceMessagesCreatedAtQueryVariables>(GetDeviceMessagesCreatedAtDocument, options);
      }
export function useGetDeviceMessagesCreatedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceMessagesCreatedAtQuery, GetDeviceMessagesCreatedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceMessagesCreatedAtQuery, GetDeviceMessagesCreatedAtQueryVariables>(GetDeviceMessagesCreatedAtDocument, options);
        }
export type GetDeviceMessagesCreatedAtQueryHookResult = ReturnType<typeof useGetDeviceMessagesCreatedAtQuery>;
export type GetDeviceMessagesCreatedAtLazyQueryHookResult = ReturnType<typeof useGetDeviceMessagesCreatedAtLazyQuery>;
export type GetDeviceMessagesCreatedAtQueryResult = Apollo.QueryResult<GetDeviceMessagesCreatedAtQuery, GetDeviceMessagesCreatedAtQueryVariables>;
export const GetDeviceMessagesByTypeCodeDocument = gql`
    query GetDeviceMessagesByTypeCode($deviceId: String!, $typeCode: TypeCode!, $start: String!, $end: String, $limit: Int = 10, $sortDirection: SortDirection = DESC, $nextToken: String) {
  deviceTypeCode(
    deviceTypeCodeInput: {deviceId: $deviceId, typeCode: $typeCode, start: $start, end: $end, limit: $limit, sortDirection: $sortDirection, nextToken: $nextToken}
  ) {
    items {
      PK
      deviceId
      createdAt
      ingestedAt
      typeCode
      data
    }
    nextToken
  }
}
    `;

/**
 * __useGetDeviceMessagesByTypeCodeQuery__
 *
 * To run a query within a React component, call `useGetDeviceMessagesByTypeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceMessagesByTypeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceMessagesByTypeCodeQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      typeCode: // value for 'typeCode'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      sortDirection: // value for 'sortDirection'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetDeviceMessagesByTypeCodeQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceMessagesByTypeCodeQuery, GetDeviceMessagesByTypeCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceMessagesByTypeCodeQuery, GetDeviceMessagesByTypeCodeQueryVariables>(GetDeviceMessagesByTypeCodeDocument, options);
      }
export function useGetDeviceMessagesByTypeCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceMessagesByTypeCodeQuery, GetDeviceMessagesByTypeCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceMessagesByTypeCodeQuery, GetDeviceMessagesByTypeCodeQueryVariables>(GetDeviceMessagesByTypeCodeDocument, options);
        }
export type GetDeviceMessagesByTypeCodeQueryHookResult = ReturnType<typeof useGetDeviceMessagesByTypeCodeQuery>;
export type GetDeviceMessagesByTypeCodeLazyQueryHookResult = ReturnType<typeof useGetDeviceMessagesByTypeCodeLazyQuery>;
export type GetDeviceMessagesByTypeCodeQueryResult = Apollo.QueryResult<GetDeviceMessagesByTypeCodeQuery, GetDeviceMessagesByTypeCodeQueryVariables>;
export const GetDeviceShadowDocument = gql`
    query GetDeviceShadow($deviceId: String!) {
  deviceShadow(deviceShadowInput: {deviceId: $deviceId}) {
    deviceId
    deviceType
    gps {
      data
      timestamp
      sourceMessageId
      sourceMessageTypeCode
    }
    batteryVoltage {
      data
      sourceMessageId
      sourceMessageTypeCode
      timestamp
    }
    config {
      data
      sourceMessageId
      sourceMessageTypeCode
      timestamp
    }
    simIccid
    lte {
      data
      timestamp
      sourceMessageId
      sourceMessageTypeCode
    }
    simState {
      data
      timestamp
      sourceMessageId
      sourceMessageTypeCode
    }
    sysinfo {
      data
      timestamp
      sourceMessageId
      sourceMessageTypeCode
    }
    temperature {
      data
      sourceMessageId
      sourceMessageTypeCode
      timestamp
    }
    lastCheckin {
      data
      sourceMessageId
      sourceMessageTypeCode
      timestamp
    }
    lastGoodGps {
      data
      sourceMessageId
      sourceMessageTypeCode
      timestamp
    }
    stolenMode {
      data
      sourceMessageId
      sourceMessageTypeCode
      timestamp
    }
    motionStatus {
      data
      sourceMessageId
      sourceMessageTypeCode
      timestamp
    }
  }
}
    `;

/**
 * __useGetDeviceShadowQuery__
 *
 * To run a query within a React component, call `useGetDeviceShadowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceShadowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceShadowQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetDeviceShadowQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>(GetDeviceShadowDocument, options);
      }
export function useGetDeviceShadowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>(GetDeviceShadowDocument, options);
        }
export type GetDeviceShadowQueryHookResult = ReturnType<typeof useGetDeviceShadowQuery>;
export type GetDeviceShadowLazyQueryHookResult = ReturnType<typeof useGetDeviceShadowLazyQuery>;
export type GetDeviceShadowQueryResult = Apollo.QueryResult<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>;
export const GetDeviceTypesDocument = gql`
    query GetDeviceTypes($limit: Int = 25, $nextToken: String) {
  deviceTypes(deviceTypeInput: {limit: $limit, nextToken: $nextToken}) {
    items {
      identifier
      label
    }
    nextToken
  }
}
    `;

/**
 * __useGetDeviceTypesQuery__
 *
 * To run a query within a React component, call `useGetDeviceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetDeviceTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetDeviceTypesQuery, GetDeviceTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeviceTypesQuery, GetDeviceTypesQueryVariables>(GetDeviceTypesDocument, options);
      }
export function useGetDeviceTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceTypesQuery, GetDeviceTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeviceTypesQuery, GetDeviceTypesQueryVariables>(GetDeviceTypesDocument, options);
        }
export type GetDeviceTypesQueryHookResult = ReturnType<typeof useGetDeviceTypesQuery>;
export type GetDeviceTypesLazyQueryHookResult = ReturnType<typeof useGetDeviceTypesLazyQuery>;
export type GetDeviceTypesQueryResult = Apollo.QueryResult<GetDeviceTypesQuery, GetDeviceTypesQueryVariables>;
export const GetDevicesByOrganizationDocument = gql`
    query GetDevicesByOrganization($organizationId: String!, $limit: Int = 25, $nextToken: String) {
  devices(
    organizationDevicesInput: {organizationId: $organizationId, limit: $limit, nextToken: $nextToken}
  ) {
    items {
      identifier
      label
      organizationId
      type
    }
    nextToken
  }
}
    `;

/**
 * __useGetDevicesByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetDevicesByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesByOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetDevicesByOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetDevicesByOrganizationQuery, GetDevicesByOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDevicesByOrganizationQuery, GetDevicesByOrganizationQueryVariables>(GetDevicesByOrganizationDocument, options);
      }
export function useGetDevicesByOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDevicesByOrganizationQuery, GetDevicesByOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDevicesByOrganizationQuery, GetDevicesByOrganizationQueryVariables>(GetDevicesByOrganizationDocument, options);
        }
export type GetDevicesByOrganizationQueryHookResult = ReturnType<typeof useGetDevicesByOrganizationQuery>;
export type GetDevicesByOrganizationLazyQueryHookResult = ReturnType<typeof useGetDevicesByOrganizationLazyQuery>;
export type GetDevicesByOrganizationQueryResult = Apollo.QueryResult<GetDevicesByOrganizationQuery, GetDevicesByOrganizationQueryVariables>;
export const GetGroupCmdCfgProgressDocument = gql`
    query GetGroupCmdCfgProgress($groupRequestId: String!) {
  groupCmdCfgRequestByGroupRequestId(
    groupCmdCfgRequestInput: {groupRequestId: $groupRequestId, limit: 11000}
  ) {
    requests {
      deviceId
      requestState
      requestId
      updatedAt
      responseCode
      responseData
    }
  }
}
    `;

/**
 * __useGetGroupCmdCfgProgressQuery__
 *
 * To run a query within a React component, call `useGetGroupCmdCfgProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupCmdCfgProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupCmdCfgProgressQuery({
 *   variables: {
 *      groupRequestId: // value for 'groupRequestId'
 *   },
 * });
 */
export function useGetGroupCmdCfgProgressQuery(baseOptions: Apollo.QueryHookOptions<GetGroupCmdCfgProgressQuery, GetGroupCmdCfgProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupCmdCfgProgressQuery, GetGroupCmdCfgProgressQueryVariables>(GetGroupCmdCfgProgressDocument, options);
      }
export function useGetGroupCmdCfgProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupCmdCfgProgressQuery, GetGroupCmdCfgProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupCmdCfgProgressQuery, GetGroupCmdCfgProgressQueryVariables>(GetGroupCmdCfgProgressDocument, options);
        }
export type GetGroupCmdCfgProgressQueryHookResult = ReturnType<typeof useGetGroupCmdCfgProgressQuery>;
export type GetGroupCmdCfgProgressLazyQueryHookResult = ReturnType<typeof useGetGroupCmdCfgProgressLazyQuery>;
export type GetGroupCmdCfgProgressQueryResult = Apollo.QueryResult<GetGroupCmdCfgProgressQuery, GetGroupCmdCfgProgressQueryVariables>;
export const GetGroupConfigRequestsByGroupTokenDocument = gql`
    query GetGroupConfigRequestsByGroupToken($groupToken: String!, $organizationId: String!, $limit: Int) {
  groupOperationByGroupToken(
    getGroupOperationByGroupTokenInput: {groupToken: $groupToken, organizationId: $organizationId, operationType: CMD_CONFIG, limit: $limit}
  ) {
    requests {
      createdAt
      requestId
      requestSummary {
        applying
        completed
        created
        failed
        invalidated
      }
      requestState
      updatedAt
      request {
        ... on RequestMessage {
          command
          module
          requestId
          type
          config {
            comm {
              smsWakeupInterval
              timeout
            }
            motion {
              reportInterval
              stopTimer
              ths
            }
            periodic {
              reportInitialOffset
              reportInitialTime
              reportInterval
            }
            power {
              offEnable
              resetEnable
            }
            stolenAsset {
              enable
              geofence
              reportInterval
              workingHoursEnd
              workingHoursStart
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGroupConfigRequestsByGroupTokenQuery__
 *
 * To run a query within a React component, call `useGetGroupConfigRequestsByGroupTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupConfigRequestsByGroupTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupConfigRequestsByGroupTokenQuery({
 *   variables: {
 *      groupToken: // value for 'groupToken'
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetGroupConfigRequestsByGroupTokenQuery(baseOptions: Apollo.QueryHookOptions<GetGroupConfigRequestsByGroupTokenQuery, GetGroupConfigRequestsByGroupTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupConfigRequestsByGroupTokenQuery, GetGroupConfigRequestsByGroupTokenQueryVariables>(GetGroupConfigRequestsByGroupTokenDocument, options);
      }
export function useGetGroupConfigRequestsByGroupTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupConfigRequestsByGroupTokenQuery, GetGroupConfigRequestsByGroupTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupConfigRequestsByGroupTokenQuery, GetGroupConfigRequestsByGroupTokenQueryVariables>(GetGroupConfigRequestsByGroupTokenDocument, options);
        }
export type GetGroupConfigRequestsByGroupTokenQueryHookResult = ReturnType<typeof useGetGroupConfigRequestsByGroupTokenQuery>;
export type GetGroupConfigRequestsByGroupTokenLazyQueryHookResult = ReturnType<typeof useGetGroupConfigRequestsByGroupTokenLazyQuery>;
export type GetGroupConfigRequestsByGroupTokenQueryResult = Apollo.QueryResult<GetGroupConfigRequestsByGroupTokenQuery, GetGroupConfigRequestsByGroupTokenQueryVariables>;
export const GetGroupDevicesDocument = gql`
    query GetGroupDevices($organizationId: String!, $groupToken: String!, $recursive: Boolean, $limit: Int = 10, $nextToken: String) {
  groupDevices(
    groupDevicesInput: {organizationId: $organizationId, groupToken: $groupToken, recursive: $recursive, limit: $limit, nextToken: $nextToken}
  ) {
    items {
      identifier
      label
      organizationId
    }
    nextToken
  }
}
    `;

/**
 * __useGetGroupDevicesQuery__
 *
 * To run a query within a React component, call `useGetGroupDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupDevicesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      groupToken: // value for 'groupToken'
 *      recursive: // value for 'recursive'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetGroupDevicesQuery(baseOptions: Apollo.QueryHookOptions<GetGroupDevicesQuery, GetGroupDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupDevicesQuery, GetGroupDevicesQueryVariables>(GetGroupDevicesDocument, options);
      }
export function useGetGroupDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupDevicesQuery, GetGroupDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupDevicesQuery, GetGroupDevicesQueryVariables>(GetGroupDevicesDocument, options);
        }
export type GetGroupDevicesQueryHookResult = ReturnType<typeof useGetGroupDevicesQuery>;
export type GetGroupDevicesLazyQueryHookResult = ReturnType<typeof useGetGroupDevicesLazyQuery>;
export type GetGroupDevicesQueryResult = Apollo.QueryResult<GetGroupDevicesQuery, GetGroupDevicesQueryVariables>;
export const GetGroupFotaProgressDocument = gql`
    query GetGroupFotaProgress($groupRequestId: String!) {
  groupFotaRequestByGroupRequestId(
    groupFotaRequestInput: {groupRequestId: $groupRequestId, limit: 11000}
  ) {
    requests {
      deviceId
      requestState
      request {
        ... on FotaRequestMessage {
          __typename
          requestId
          type
          url
        }
      }
      requestId
      updatedAt
    }
  }
}
    `;

/**
 * __useGetGroupFotaProgressQuery__
 *
 * To run a query within a React component, call `useGetGroupFotaProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupFotaProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupFotaProgressQuery({
 *   variables: {
 *      groupRequestId: // value for 'groupRequestId'
 *   },
 * });
 */
export function useGetGroupFotaProgressQuery(baseOptions: Apollo.QueryHookOptions<GetGroupFotaProgressQuery, GetGroupFotaProgressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupFotaProgressQuery, GetGroupFotaProgressQueryVariables>(GetGroupFotaProgressDocument, options);
      }
export function useGetGroupFotaProgressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupFotaProgressQuery, GetGroupFotaProgressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupFotaProgressQuery, GetGroupFotaProgressQueryVariables>(GetGroupFotaProgressDocument, options);
        }
export type GetGroupFotaProgressQueryHookResult = ReturnType<typeof useGetGroupFotaProgressQuery>;
export type GetGroupFotaProgressLazyQueryHookResult = ReturnType<typeof useGetGroupFotaProgressLazyQuery>;
export type GetGroupFotaProgressQueryResult = Apollo.QueryResult<GetGroupFotaProgressQuery, GetGroupFotaProgressQueryVariables>;
export const GetGroupFotaRequestsByGroupTokenDocument = gql`
    query GetGroupFotaRequestsByGroupToken($groupToken: String!, $organizationId: String!) {
  groupOperationByGroupToken(
    getGroupOperationByGroupTokenInput: {groupToken: $groupToken, organizationId: $organizationId, operationType: FOTA}
  ) {
    requests {
      requestSummary {
        applying
        completed
        created
        failed
        invalidated
      }
      createdAt
      request {
        ... on FotaRequestMessage {
          __typename
          requestId
          type
          url
        }
      }
      requestId
      requestState
      updatedAt
    }
  }
}
    `;

/**
 * __useGetGroupFotaRequestsByGroupTokenQuery__
 *
 * To run a query within a React component, call `useGetGroupFotaRequestsByGroupTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupFotaRequestsByGroupTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupFotaRequestsByGroupTokenQuery({
 *   variables: {
 *      groupToken: // value for 'groupToken'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetGroupFotaRequestsByGroupTokenQuery(baseOptions: Apollo.QueryHookOptions<GetGroupFotaRequestsByGroupTokenQuery, GetGroupFotaRequestsByGroupTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupFotaRequestsByGroupTokenQuery, GetGroupFotaRequestsByGroupTokenQueryVariables>(GetGroupFotaRequestsByGroupTokenDocument, options);
      }
export function useGetGroupFotaRequestsByGroupTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupFotaRequestsByGroupTokenQuery, GetGroupFotaRequestsByGroupTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupFotaRequestsByGroupTokenQuery, GetGroupFotaRequestsByGroupTokenQueryVariables>(GetGroupFotaRequestsByGroupTokenDocument, options);
        }
export type GetGroupFotaRequestsByGroupTokenQueryHookResult = ReturnType<typeof useGetGroupFotaRequestsByGroupTokenQuery>;
export type GetGroupFotaRequestsByGroupTokenLazyQueryHookResult = ReturnType<typeof useGetGroupFotaRequestsByGroupTokenLazyQuery>;
export type GetGroupFotaRequestsByGroupTokenQueryResult = Apollo.QueryResult<GetGroupFotaRequestsByGroupTokenQuery, GetGroupFotaRequestsByGroupTokenQueryVariables>;
export const GetGroupsDocument = gql`
    query GetGroups($organizationId: String!, $limit: Int) {
  groups(groupListInput: {organizationId: $organizationId, limit: $limit}) {
    items {
      label
      identifier
      type
    }
  }
}
    `;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
      }
export function useGetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<typeof useGetGroupsLazyQuery>;
export type GetGroupsQueryResult = Apollo.QueryResult<GetGroupsQuery, GetGroupsQueryVariables>;
export const GetHealthDocument = gql`
    query GetHealth {
  health {
    status
    msg
    dependencies {
      name
      status
    }
  }
}
    `;

/**
 * __useGetHealthQuery__
 *
 * To run a query within a React component, call `useGetHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHealthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHealthQuery(baseOptions?: Apollo.QueryHookOptions<GetHealthQuery, GetHealthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHealthQuery, GetHealthQueryVariables>(GetHealthDocument, options);
      }
export function useGetHealthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHealthQuery, GetHealthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHealthQuery, GetHealthQueryVariables>(GetHealthDocument, options);
        }
export type GetHealthQueryHookResult = ReturnType<typeof useGetHealthQuery>;
export type GetHealthLazyQueryHookResult = ReturnType<typeof useGetHealthLazyQuery>;
export type GetHealthQueryResult = Apollo.QueryResult<GetHealthQuery, GetHealthQueryVariables>;
export const GetOrganizationsDocument = gql`
    query GetOrganizations($limit: Int = 25, $nextToken: String) {
  organizations(organizationInput: {limit: $limit, nextToken: $nextToken}) {
    items {
      identifier
      label
      organizationId
      type
    }
    nextToken
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetSimEventsCreatedAtDocument = gql`
    query GetSimEventsCreatedAt($deviceId: String!, $start: String!, $end: String, $limit: Int = 10, $sortDirection: SortDirection = DESC, $nextToken: String) {
  simCreatedAt(
    simCreatedAtInput: {deviceId: $deviceId, start: $start, end: $end, limit: $limit, sortDirection: $sortDirection, nextToken: $nextToken}
  ) {
    items {
      PK
      deviceId
      createdAt
      ingestedAt
      typeCode
      data
    }
    nextToken
  }
}
    `;

/**
 * __useGetSimEventsCreatedAtQuery__
 *
 * To run a query within a React component, call `useGetSimEventsCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimEventsCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimEventsCreatedAtQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      sortDirection: // value for 'sortDirection'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetSimEventsCreatedAtQuery(baseOptions: Apollo.QueryHookOptions<GetSimEventsCreatedAtQuery, GetSimEventsCreatedAtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSimEventsCreatedAtQuery, GetSimEventsCreatedAtQueryVariables>(GetSimEventsCreatedAtDocument, options);
      }
export function useGetSimEventsCreatedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimEventsCreatedAtQuery, GetSimEventsCreatedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSimEventsCreatedAtQuery, GetSimEventsCreatedAtQueryVariables>(GetSimEventsCreatedAtDocument, options);
        }
export type GetSimEventsCreatedAtQueryHookResult = ReturnType<typeof useGetSimEventsCreatedAtQuery>;
export type GetSimEventsCreatedAtLazyQueryHookResult = ReturnType<typeof useGetSimEventsCreatedAtLazyQuery>;
export type GetSimEventsCreatedAtQueryResult = Apollo.QueryResult<GetSimEventsCreatedAtQuery, GetSimEventsCreatedAtQueryVariables>;
export const GetSmsCommandRequestDocument = gql`
    query GetSmsCommandRequest($deviceId: String!, $start: String!, $end: String, $limit: Int, $sortDirection: SortDirection = DESC, $nextToken: String) {
  smsCommandRequest(
    smsCommandRequestInput: {deviceId: $deviceId, start: $start, end: $end, limit: $limit, sortDirection: $sortDirection, nextToken: $nextToken}
  ) {
    requests {
      PK
      SK
      createdAt
      deviceId
      smsCommandType
      smsRequestSid
    }
    nextToken
  }
}
    `;

/**
 * __useGetSmsCommandRequestQuery__
 *
 * To run a query within a React component, call `useGetSmsCommandRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsCommandRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsCommandRequestQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      limit: // value for 'limit'
 *      sortDirection: // value for 'sortDirection'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetSmsCommandRequestQuery(baseOptions: Apollo.QueryHookOptions<GetSmsCommandRequestQuery, GetSmsCommandRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsCommandRequestQuery, GetSmsCommandRequestQueryVariables>(GetSmsCommandRequestDocument, options);
      }
export function useGetSmsCommandRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsCommandRequestQuery, GetSmsCommandRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsCommandRequestQuery, GetSmsCommandRequestQueryVariables>(GetSmsCommandRequestDocument, options);
        }
export type GetSmsCommandRequestQueryHookResult = ReturnType<typeof useGetSmsCommandRequestQuery>;
export type GetSmsCommandRequestLazyQueryHookResult = ReturnType<typeof useGetSmsCommandRequestLazyQuery>;
export type GetSmsCommandRequestQueryResult = Apollo.QueryResult<GetSmsCommandRequestQuery, GetSmsCommandRequestQueryVariables>;
export const GetSmsCommandResourceDocument = gql`
    query GetSmsCommandResource($deviceId: String!, $sid: String!) {
  smsCommandResource(smsCommandResourceInput: {deviceId: $deviceId, sid: $sid}) {
    accountSid
    dateCreated
    dateUpdated
    direction
    payload
    sid
    simSid
    status
    url
  }
}
    `;

/**
 * __useGetSmsCommandResourceQuery__
 *
 * To run a query within a React component, call `useGetSmsCommandResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsCommandResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsCommandResourceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      sid: // value for 'sid'
 *   },
 * });
 */
export function useGetSmsCommandResourceQuery(baseOptions: Apollo.QueryHookOptions<GetSmsCommandResourceQuery, GetSmsCommandResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSmsCommandResourceQuery, GetSmsCommandResourceQueryVariables>(GetSmsCommandResourceDocument, options);
      }
export function useGetSmsCommandResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsCommandResourceQuery, GetSmsCommandResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSmsCommandResourceQuery, GetSmsCommandResourceQueryVariables>(GetSmsCommandResourceDocument, options);
        }
export type GetSmsCommandResourceQueryHookResult = ReturnType<typeof useGetSmsCommandResourceQuery>;
export type GetSmsCommandResourceLazyQueryHookResult = ReturnType<typeof useGetSmsCommandResourceLazyQuery>;
export type GetSmsCommandResourceQueryResult = Apollo.QueryResult<GetSmsCommandResourceQuery, GetSmsCommandResourceQueryVariables>;
export const GetUserInfoDocument = gql`
    query GetUserInfo {
  userInfo {
    emailAddress
    name
    organizationId
    role
    permissions
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const GetUserRolesDocument = gql`
    query GetUserRoles {
  roles {
    limit
    roles {
      description
      id
      name
    }
    start
    total
  }
}
    `;

/**
 * __useGetUserRolesQuery__
 *
 * To run a query within a React component, call `useGetUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
      }
export function useGetUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRolesQuery, GetUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRolesQuery, GetUserRolesQueryVariables>(GetUserRolesDocument, options);
        }
export type GetUserRolesQueryHookResult = ReturnType<typeof useGetUserRolesQuery>;
export type GetUserRolesLazyQueryHookResult = ReturnType<typeof useGetUserRolesLazyQuery>;
export type GetUserRolesQueryResult = Apollo.QueryResult<GetUserRolesQuery, GetUserRolesQueryVariables>;
export const GetUserRowDocument = gql`
    query GetUserRow($emailAddress: String!) {
  userRow(userFetchUserInput: {emailAddress: $emailAddress}) {
    identifier
    label
    organizationId
    type
  }
}
    `;

/**
 * __useGetUserRowQuery__
 *
 * To run a query within a React component, call `useGetUserRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRowQuery({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useGetUserRowQuery(baseOptions: Apollo.QueryHookOptions<GetUserRowQuery, GetUserRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRowQuery, GetUserRowQueryVariables>(GetUserRowDocument, options);
      }
export function useGetUserRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRowQuery, GetUserRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRowQuery, GetUserRowQueryVariables>(GetUserRowDocument, options);
        }
export type GetUserRowQueryHookResult = ReturnType<typeof useGetUserRowQuery>;
export type GetUserRowLazyQueryHookResult = ReturnType<typeof useGetUserRowLazyQuery>;
export type GetUserRowQueryResult = Apollo.QueryResult<GetUserRowQuery, GetUserRowQueryVariables>;
export const GetUsersByOrganizationIdDocument = gql`
    query GetUsersByOrganizationId($organizationId: String!, $limit: Int = 1000) {
  users(userListInput: {organizationId: $organizationId, limit: $limit}) {
    items {
      identifier
      label
      organizationId
      type
    }
  }
}
    `;

/**
 * __useGetUsersByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByOrganizationIdQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetUsersByOrganizationIdQuery(baseOptions: Apollo.QueryHookOptions<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>(GetUsersByOrganizationIdDocument, options);
      }
export function useGetUsersByOrganizationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>(GetUsersByOrganizationIdDocument, options);
        }
export type GetUsersByOrganizationIdQueryHookResult = ReturnType<typeof useGetUsersByOrganizationIdQuery>;
export type GetUsersByOrganizationIdLazyQueryHookResult = ReturnType<typeof useGetUsersByOrganizationIdLazyQuery>;
export type GetUsersByOrganizationIdQueryResult = Apollo.QueryResult<GetUsersByOrganizationIdQuery, GetUsersByOrganizationIdQueryVariables>;