import {
  EuiErrorBoundary,
  EuiFormRow,
  EuiText,
  EuiForm,
  EuiFieldNumber,
  EuiDescribedFormGroup,
  EuiRadioGroup,
} from '@elastic/eui';
import { Configuration } from 'app/generated/graphql';
import { Control, Controller } from 'react-hook-form';

import * as COMM_CONSTANTS from './comm-config-defaults';
import { ConfigPanel } from '../../panel/config-panel';
import { parseNumberOnChange } from '../snt-config-utils';

interface SntCommConfigViewProps {
  /** Used for handling the state of the panel. Collapsed or expanded. */
  isExpanded: boolean;
  /** react-hook-form controller for monitoring form state */
  control: Control<Configuration>;
}

const DISABLED_ID = `comm-disabled`;
const ENABLED_ID = `comm-enabled`;
const RADIO_BUTTONS = [
  { id: DISABLED_ID, label: 'Disable' },
  { id: ENABLED_ID, label: 'Enable' },
];

/** Comm configuration fields for a Slap and Track device */
export const SntCommConfig = (props: SntCommConfigViewProps) => {
  /** Methods */
  const handleFieldEnabledToggle = (radioValue: string, setValue: (value: number) => void) => {
    radioValue === DISABLED_ID
      ? setValue(COMM_CONSTANTS.WAKEUP_DISABLED)
      : setValue(COMM_CONSTANTS.WAKEUP_MIN);
  };

  const conjureSelectedId = (currentValue: number | string) => {
    // Start with the strict string comparison for empty string
    if (currentValue === '') {
      return '';
    }
    // configuration loads in with string '0' instead of number 0
    // 0 == '0' is truthy, 0 === '0' is falsey, 0 == '' is truthy
    if (COMM_CONSTANTS.WAKEUP_DISABLED == currentValue) {
      return DISABLED_ID;
    }
    return ENABLED_ID;
  };

  return (
    <EuiErrorBoundary>
      <EuiForm component="form">
        <ConfigPanel
          expandAll={props.isExpanded}
          title="Communication"
          icon="mobile"
          description="How the tracker talks"
          isLoading={false}
        >
          <Controller
            name="comm.smsWakeupInterval"
            control={props.control}
            // Disabled value is zero, actual min is 120
            rules={{
              min: COMM_CONSTANTS.WAKEUP_DISABLED,
              max: COMM_CONSTANTS.WAKEUP_MAX,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <EuiDescribedFormGroup
                title={<h3>SMS Wakeup Interval</h3>}
                description={
                  <EuiText size="s">
                    <p> The interval between wakeups to check for SMS Messages.</p>
                    <p>Default: Disabled</p>
                  </EuiText>
                }
              >
                <EuiFormRow label="SMS Wakeup Enabled">
                  <EuiRadioGroup
                    options={RADIO_BUTTONS}
                    idSelected={conjureSelectedId(value!)}
                    onChange={radioValue => handleFieldEnabledToggle(radioValue, onChange)}
                    name="enabled-disable-toggle"
                  />
                </EuiFormRow>
                <EuiFormRow
                  label="SMS Wakeup Interval"
                  helpText={`Valid range: ${COMM_CONSTANTS.WAKEUP_MIN} - ${COMM_CONSTANTS.WAKEUP_MAX} seconds`}
                >
                  <EuiFieldNumber
                    onBlur={onBlur}
                    min={
                      // To avoid having the field marked as invalid when disabled
                      value == COMM_CONSTANTS.WAKEUP_DISABLED
                        ? COMM_CONSTANTS.WAKEUP_DISABLED
                        : COMM_CONSTANTS.WAKEUP_MIN
                    }
                    max={COMM_CONSTANTS.WAKEUP_MAX}
                    value={value!}
                    disabled={value == COMM_CONSTANTS.WAKEUP_DISABLED}
                    onChange={event => onChange?.(parseNumberOnChange(event))}
                    append={['seconds']}
                  />
                </EuiFormRow>
              </EuiDescribedFormGroup>
            )}
          />
          <EuiDescribedFormGroup
            title={<h3>MQTT Timeout</h3>}
            description={
              <EuiText size="s">
                <p>
                  Maximum time to be connected to the MQTT broker. If config commands or a FOTA URL
                  is received, the connection is terminated on completion of the request instead of
                  on timeout.
                </p>
                <p>{`Default: ${COMM_CONSTANTS.TIMEOUT_DEFAULT} seconds`}</p>
              </EuiText>
            }
          >
            <Controller
              name="comm.timeout"
              control={props.control}
              rules={{
                min: COMM_CONSTANTS.TIMEOUT_MIN,
                max: COMM_CONSTANTS.TIMEOUT_MAX,
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => {
                return (
                  <EuiFormRow
                    isInvalid={invalid}
                    error={error?.message}
                    label="Timeout"
                    helpText={`Valid range: ${COMM_CONSTANTS.TIMEOUT_MIN} - ${COMM_CONSTANTS.TIMEOUT_MAX} seconds `}
                  >
                    <EuiFieldNumber
                      onBlur={onBlur}
                      min={COMM_CONSTANTS.TIMEOUT_MIN}
                      max={COMM_CONSTANTS.TIMEOUT_MAX}
                      value={value!}
                      onChange={event => onChange?.(parseNumberOnChange(event))}
                      append={['seconds']}
                    />
                  </EuiFormRow>
                );
              }}
            />
          </EuiDescribedFormGroup>
        </ConfigPanel>
      </EuiForm>
    </EuiErrorBoundary>
  );
};
