import { EuiErrorBoundary, EuiSpacer } from '@elastic/eui';
import { GenericRow } from 'app/generated/graphql';
import GroupsInMemoryTable from 'layout/groups/devices/in-memory-table/groups-in-memory-table';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

import { AddDeviceToGroup } from './add/single/add-device-form';
import { UploadDevicesToGroup } from './add/bulk/upload-devices-to-group';

export type DevicesCrudViewProps = {
  /** toaster strudel */
  addToastMessage: (message: Toast) => void;
  /** Groups to be displayed as facets that are part of the table (clickable tabs) */
  groups: any[];
  /** true if a loading indicator should be displayed */
  isLoading: boolean;
  /** Organization that is tied to the user */
  organizationId: string;
  /** Sets the currently selected group */
  setSelectedGroup: (selectedGroup: GenericRow | undefined) => void;
  /** The group that displays any/all devices tied to it */
  selectedGroup?: GenericRow;
  /** Method that gets called when adding a device */
  onAddDevices: (deviceId: string[]) => void;
  /** All devices that are tied to a group and to be displayed in the table*/
  devices: any[];
  /** Method that gets called when removing a device from a group */
  onDeviceRemoval: (deviceId: string) => void;
  /** True if the add device to group modal is open */
  isAddDeviceOpen: boolean;
  /** True if the upload devices file to group modal is open */
  isBulkAddDeviceOpen: boolean;
  /** True when adding devices to selected group. */
  isAddingDevices: boolean;
  /** Method for toggling if the add device dialogue is closed */
  setBulkAddOpen: (isBulkAddDeviceOpen: boolean) => void;
  /** Method for toggling if the add device dialogue is open */
  setSingleAddOpen: (isSingleAddFlyoutOpen: boolean) => void;
  /** True if user has the admin role */
  isUserAdmin: boolean;
};

export const DevicesCrudView = (props: DevicesCrudViewProps) => {
  return (
    <EuiErrorBoundary>
      <AddDeviceToGroup
        isLoading={props.isLoading}
        isAddDeviceOpen={props.isAddDeviceOpen}
        organizationId={props.organizationId}
        groupToken={props.selectedGroup?.identifier ?? ''}
        onSubmit={props.onAddDevices}
        closeFlyout={() => props.setSingleAddOpen(false)}
        assignedDevices={props.devices}
      />
      <UploadDevicesToGroup
        addToastMessage={props.addToastMessage}
        isUserAdmin={props.isUserAdmin}
        isLoading={props.isAddingDevices}
        isBulkAddOpen={props.isBulkAddDeviceOpen}
        organizationId={props.organizationId}
        groupToken={props.selectedGroup?.identifier ?? ''}
        groupLabel={props.selectedGroup?.label ?? ''}
        onSubmit={props.onAddDevices}
        closeFlyout={() => props.setBulkAddOpen(false)}
      />
      <EuiSpacer />
      <GroupsInMemoryTable
        isLoading={props.isLoading}
        groups={props.groups}
        devices={props.devices}
        selectedGroup={props.selectedGroup}
        setSelectedGroup={props.setSelectedGroup}
        onDeviceRemoval={props.onDeviceRemoval}
        onAddDevices={() => props.setSingleAddOpen(true)}
        onBulkAddDevices={() => props.setBulkAddOpen(true)}
      />
    </EuiErrorBoundary>
  );
};
