import { Command, useSendGroupCommandMutation } from 'gqlHooks';
import {
  EuiErrorBoundary,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
} from '@elastic/eui';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';
import SntSendDeviceCommandView from 'layout/device-command/send-device-command-view';

interface SendDeviceCommandProps {
  addToastMessage: (message: any) => void;
  groupTokens: string[];
  organizationId: string;
  isOpen: boolean;
  onClose: () => void;
}

/** Sends a command to a snt group*/
export const SntSendGroupCommand = (props: SendDeviceCommandProps) => {
  const [sendGroupCommandMutation, { loading }] = useSendGroupCommandMutation({
    onCompleted: () => {
      props.addToastMessage(successToastMessage('Command Sent'));
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to send command'));
    },
  });

  if (!props.isOpen) {
    return null;
  }

  // Builds the request data, then sends the request
  // Port/Broker are optional - will only be sent on MQTT requests
  const sendSmsCommand = (command: Command) => {
    props.groupTokens.forEach(token => {
      sendGroupCommandMutation({
        variables: {
          organizationId: props.organizationId,
          groupToken: token,
          command: command,
        },
      });
    });
  };

  return (
    <EuiErrorBoundary>
      <EuiFlyout
        ownFocus
        onClose={props.onClose}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Send command</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <SntSendDeviceCommandView
            isLoading={loading}
            onSendCommand={sendSmsCommand}
          />
        </EuiFlyoutBody>
      </EuiFlyout>
    </EuiErrorBoundary>
  );
};
