import {
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiDatePicker,
  EuiDatePickerRange,
  EuiFormRow,
} from '@elastic/eui';
import moment, { Moment } from 'moment';

export type CustomDatePickerProps = {
  setStartDate?: (date: Moment) => void;
  setEndDate?: (date: Moment) => void;
  startDate?: Moment;
  endDate?: Moment;
  isVisible?: boolean;
  isLoading?: boolean;
};

export default function DatePicker(props: CustomDatePickerProps) {
  const handleBackButtonClick = () => {
    props.setStartDate?.(props.startDate!.clone().subtract(1, 'day').startOf('day'));
    props.setEndDate?.(props.startDate!.clone().subtract(1, 'day').endOf('day'));
  };

  const handleForwardButtonClick = () => {
    props.setStartDate?.(props.startDate!.clone().add(1, 'day').startOf('day'));
    props.setEndDate?.(props.startDate!.clone().add(1, 'day').endOf('day'));
  };

  const handleEndDateChange = (date: Moment) => {
    props.setEndDate?.(date);
  };
  const handleStartDateChange = (date: Moment) => {
    props.setStartDate?.(date);
  };

  const isForwardButtonEnabled = !props.endDate?.isSame(moment(), 'day');

  return (
    <EuiFormRow label="Select date range">
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            id="date-picker-go-back-one-day"
            iconType="arrowLeft"
            onClick={handleBackButtonClick}
            aria-label="Go back one day"
          />
        </EuiFlexItem>
        <EuiFlexItem style={{ minWidth: 400 }}>
          <EuiDatePickerRange
            startDateControl={
              <EuiDatePicker
                selected={props.startDate}
                onChange={handleStartDateChange}
                startDate={props.startDate}
                endDate={props.endDate}
                aria-label="Start date"
                showTimeSelect
              />
            }
            endDateControl={
              <EuiDatePicker
                selected={props.endDate}
                onChange={handleEndDateChange}
                startDate={props.startDate}
                endDate={props.endDate}
                aria-label="End date"
                showTimeSelect
              />
            }
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            id="date-picker-go-forward-one-day"
            iconType="arrowRight"
            onClick={handleForwardButtonClick}
            disabled={!isForwardButtonEnabled}
            aria-label="Go forward one day"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFormRow>
  );
}
