import type { UserInfo } from 'gqlHooks';

/**
 * Check if the user has the required permissions
 * @returns boolean
 */
export function hasPermissions(
  userPermissions: UserInfo['permissions'],
  requiredPermissions: UserInfo['permissions']
) {
  const hasPermissions = requiredPermissions.every(permission =>
    userPermissions.includes(permission)
  );

  return hasPermissions;
}
