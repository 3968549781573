import { useSendSmsCommandMutation } from 'gqlHooks';
import { EuiErrorBoundary } from '@elastic/eui';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';

import { SntSendSmsView } from './snt_send_sms_view';

interface SntConfigProps {
  addToastMessage: (message: any) => void;
  deviceSerial: string;
}

/** Sends an sms command to a snt device */
export const SntSendSms = ({ addToastMessage, deviceSerial }: SntConfigProps) => {
  const [sendSmsCommandMutation, { loading }] = useSendSmsCommandMutation({
    onCompleted: () => {
      addToastMessage(successToastMessage('Message Sent'));
    },
    onError: () => {
      addToastMessage(errorToastMessage('Failed to send message'));
    },
  });

  // Builds the request data, then sends the request
  // Port/Broker are optional - will only be sent on MQTT requests
  const sendSmsMessage = (command: string, port?: string, broker?: string) => {
    const request: any = {
      payload: command,
      port: null,
      broker: null,
    };
    request.port = port ? port : null;
    request.broker = broker ? broker : null;

    sendSmsCommandMutation({
      variables: {
        deviceId: deviceSerial,
        smsCommandRequest: request,
      },
    });
  };

  return (
    <EuiErrorBoundary>
      <SntSendSmsView
        isLoading={loading}
        onSendSms={sendSmsMessage}
      />
    </EuiErrorBoundary>
  );
};
