import { Battery } from 'models/install-verification-tags/tag/battery/Battery';
import {
  StatusType,
  StatusTypeDescriptor,
  StatusTypeToken,
} from 'models/install-verification-tags/types';
import type {
  IInstallVerificationTagData,
  ITag,
  ITagStatus,
  TagType,
} from 'models/install-verification-tags/types';

export class SlapAndTrackBattery extends Battery {
  constructor(tagType: TagType, tagData: IInstallVerificationTagData) {
    super(tagType, tagData);
  }

  getStatus(): ITagStatus {
    let status = StatusType.DANGER;

    if (this.battery && this.battery >= 6) {
      status = StatusType.SUCCESS;
    } else if (this.battery && this.battery >= 4.5) {
      status = StatusType.WARNING;
    }

    return {
      status: StatusType[status],
      statusToken: StatusTypeToken[status],
      statusDescriptor: StatusTypeDescriptor[status],
    };
  }

  createTag(): ITag {
    if (!this.isValidBatteryData()) {
      return this.createDefaultTag();
    }
    const statusMeta = this.getStatus();
    return {
      ...this.defaults,
      status: statusMeta.status,
      statusToken: statusMeta.statusToken,
      tagText: SlapAndTrackBattery.getTagTextTemplate(this.tagType, statusMeta.statusDescriptor),
      description: `${this.tagTitle} is at ${this.battery} volts`,
    };
  }
}
