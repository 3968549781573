import {
  EuiButton,
  EuiCallOut,
  EuiErrorBoundary,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from '@elastic/eui';
import { Control, Controller } from 'react-hook-form';
import { RemoveDeviceFromOrganizationMutationVariables } from 'gqlHooks';
import { SelectOrganization } from 'components/forms/select-organization';

const ERROR_MESSAGES = {
  deviceIdRequired: 'Device IMEI is required',
  organizationRequired: 'Organization is required',
};

type RemoveDeviceProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  control: Control<RemoveDeviceFromOrganizationMutationVariables>;
  isLoading: boolean;
  isAdmin: boolean;
  handleCalloutDismiss: () => void;
  errorMessage: string | undefined;
};

export default function RemoveDeviceFromOrganizationFormView(props: RemoveDeviceProps) {
  // preventing default as forms like refreshing the page
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit(event);
  };

  return (
    <EuiErrorBoundary>
      <EuiFlexGrid
        columns={1}
        style={{ width: '100%' }}
      >
        {props.errorMessage && (
          <EuiFlexItem>
            <EuiCallOut
              style={{ minWidth: '100%' }}
              onDismiss={props.handleCalloutDismiss}
              iconType="alert"
              title={'Failed to remove device'}
              color="danger"
            >
              <p>{props.errorMessage}</p>
            </EuiCallOut>
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          <EuiForm
            component="form"
            onSubmit={handleOnSubmit}
            css={{ minWidth: '300px' }}
          >
            <Controller
              name="deviceId"
              control={props.control}
              rules={{
                required: { value: true, message: ERROR_MESSAGES.deviceIdRequired },
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  label="Device IMEI"
                  isInvalid={invalid}
                  error={error?.message}
                  aria-required
                >
                  <EuiFieldText
                    placeholder="Enter a device IMEI"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
            {props.isAdmin ? (
              <Controller
                name="organizationId"
                control={props.control}
                rules={{
                  required: { value: true, message: ERROR_MESSAGES.organizationRequired },
                }}
                render={({
                  fieldState: { invalid, error },
                  field: { onChange, onBlur, value },
                }) => (
                  <SelectOrganization
                    label="Organization"
                    isInvalid={invalid}
                    errorMessage={error?.message}
                    onChange={onChange}
                    selectedOrganization={value}
                    onBlur={onBlur}
                  />
                )}
              />
            ) : null}
            <EuiFormRow hasEmptyLabelSpace>
              <EuiButton
                id="confirm-remove-device"
                type="submit"
                fill
                color="danger"
                isLoading={props.isLoading}
              >
                Remove Device
              </EuiButton>
            </EuiFormRow>
            <EuiSpacer size="m" />
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiErrorBoundary>
  );
}
