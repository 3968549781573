import { ApolloError, isApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';

interface SentryError extends Record<string, any> {
  readonly message: string;
  readonly status: number;
  readonly query?: string;
}

export interface CustomGraphQLError extends Record<string, any> {
  readonly error?: string;
  readonly status_code?: number;
  readonly query?: string;
}

export interface ExtractedGraphQLError {
  readonly errorMessage: string;
  readonly statusCode: number;
  readonly query: string;
}

type ApolloErrorHandlerCallback = (gqlError: CustomGraphQLError) => void;

export const extractGraphQLError = (error: ApolloError): ExtractedGraphQLError => {
  const graphQLError = error.graphQLErrors[0] as CustomGraphQLError;
  const errorMessage = graphQLError?.error ?? 'Unknown Error';
  const statusCode = graphQLError?.status_code ?? 500;
  const query = graphQLError?.query ?? 'Unknown Query';

  return { errorMessage, statusCode, query };
};

export const sendToSentry = (error: Error, query?: string, sentryError?: Partial<SentryError>) => {
  if (isApolloError(error)) {
    const { errorMessage, statusCode } = extractGraphQLError(error);
    const defaultSentryError: SentryError = {
      message: errorMessage,
      status: statusCode,
      query: query,
    };
    const finalSentryError = { ...defaultSentryError, ...sentryError };

    Sentry.withScope(scope => {
      scope.setExtras(finalSentryError);
      Sentry.captureException(error);
    });
  } else {
    const defaultSentryError: SentryError = {
      message: error.message,
      status: 500,
    };
    const finalSentryError = { ...defaultSentryError, ...sentryError };

    Sentry.withScope(scope => {
      if (query) {
        scope.setExtra('query', query);
      }
      scope.setExtras(finalSentryError);
      Sentry.captureException(error);
    });
  }
};

export const handleApolloError = (
  error: ApolloError,
  logToSentry = false,
  callback?: ApolloErrorHandlerCallback
) => {
  const gqlError = extractGraphQLError(error);
  if (logToSentry) {
    sendToSentry(error, undefined, { ...gqlError });
  }

  if (callback) {
    callback(gqlError);
  }

  return extractGraphQLError(error);
};
