import { Location } from 'models/install-verification-tags/tag/location/Location';
import {
  StatusType,
  StatusTypeDescriptor,
  StatusTypeToken,
} from 'models/install-verification-tags/types';
import type {
  IInstallVerificationTagData,
  ITag,
  ITagStatus,
  TagType,
} from 'models/install-verification-tags/types';

export class SlapAndTrackLocation extends Location {
  constructor(tagType: TagType, tagData: IInstallVerificationTagData) {
    super(tagType, tagData);
  }

  private hasUnknownStatus(): void {
    if (!this.location) {
      this.statusDetail = 'No location data';
      this.statusType = StatusType.UNKNOWN;
    }
  }

  private hasDangerStatus(): void {
    if (this.location && !this.validLatLng) {
      this.statusDetail = 'Lat/Lng is invalid';
      this.statusType = StatusType.DANGER;
    }
  }

  private hasSuccessStatus(): void {
    if (this.location && this.validLatLng) {
      this.statusDetail = 'Valid lat/lng and dates';
      this.statusType = StatusType.SUCCESS;
    }
  }

  private determineStatus(): void {
    this.hasUnknownStatus();
    this.hasDangerStatus();
    this.hasSuccessStatus();
  }

  getStatus(): ITagStatus {
    this.determineStatus();

    return {
      status: this.statusType,
      statusToken: StatusTypeToken[this.statusType],
      statusDescriptor: StatusTypeDescriptor[this.statusType],
    };
  }

  createTag(): ITag {
    const statusMeta = this.getStatus();
    return {
      ...this.defaults,
      status: statusMeta.status,
      statusToken: statusMeta.statusToken,
      tagText: SlapAndTrackLocation.getTagTextTemplate(this.tagType, statusMeta.statusDescriptor),
      description: `${this.tagTitle} ${this.statusDetail}`,
    };
  }
}
