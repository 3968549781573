import { CONFIG_TYPE_ICONS } from 'layout/config/snt/snt-config-utils';
import { EuiErrorBoundary, EuiCommentList, EuiCallOut } from '@elastic/eui';
import { Configuration } from 'gqlHooks';
import { SntConfigSummary } from 'layout/config/snt/requests/table/expand-section/summary/config/config-summary';
import { stolenConfigTranslator } from 'app/layout/config/snt/stolen/stolen-config-defaults';
import { powerConfigTranslator } from 'app/layout/config/snt/power/power-config-defaults';
import { periodicConfigTranslator } from 'app/layout/config/snt/periodic/periodic-config-defaults';
import { motionConfigTranslator } from 'app/layout/config/snt/motion/motion-config-defaults';
import { commConfigTranslator } from 'app/layout/config/snt/comm/comm-config-defaults';

export interface SntSummarySectionProps {
  /** The configuration message whose content should be displayed */
  config: Configuration | undefined;
}

export const SntGroupSummarySection = ({ config }: SntSummarySectionProps) => {
  if (config && Object.keys(config).length !== 0) {
    return (
      <EuiErrorBoundary id="snt-summary-section">
        <EuiCommentList>
          <SntConfigSummary
            translation={commConfigTranslator(config?.comm)}
            label={'Communication'}
            icon={CONFIG_TYPE_ICONS.COMM}
            showAll={false}
          />
          <SntConfigSummary
            translation={motionConfigTranslator(config?.motion)}
            label={'Motion'}
            icon={CONFIG_TYPE_ICONS.MOTION}
            showAll={false}
          />
          <SntConfigSummary
            translation={periodicConfigTranslator(config?.periodic)}
            label={'Periodic'}
            icon={CONFIG_TYPE_ICONS.PERIODIC}
            showAll={false}
          />
          <SntConfigSummary
            translation={powerConfigTranslator(config?.power)}
            label={'Button'}
            icon={CONFIG_TYPE_ICONS.POWER}
            showAll={false}
          />
          <SntConfigSummary
            translation={stolenConfigTranslator(config?.stolenAsset)}
            label={'Stolen Asset'}
            icon={CONFIG_TYPE_ICONS.STOLEN}
            showAll={false}
          />
        </EuiCommentList>
      </EuiErrorBoundary>
    );
  }
  return (
    <EuiCallOut
      style={{ minWidth: '100%' }}
      iconType="iInCircle"
      title={'No config request data found'}
    />
  );
};
