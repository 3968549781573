import { EuiConfirmModal } from '@elastic/eui';

type ConfirmDiscardDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function ConfirmDiscardDialog(props: ConfirmDiscardDialogProps) {
  return props.isOpen ? (
    <EuiConfirmModal
      title="Discard user changes?"
      onCancel={props.onClose}
      onConfirm={props.onConfirm}
      cancelButtonText="Keep editing"
      confirmButtonText="Discard changes"
      buttonColor="danger"
      defaultFocusedButton="confirm"
    >
      <p>You will lose all unsaved changes made to this user.</p>
    </EuiConfirmModal>
  ) : null;
}
