import * as XLSX from 'xlsx';
import { EuiFormRow, EuiFilePicker } from '@elastic/eui';
import { errorToastMessage } from 'app/utils/toast-messages';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

import {
  ColumnHeadersType,
  COL_INDEX,
  COL_LABEL,
  ROW_INDEX,
  DeviceUploadRow,
  DEVICE_NAME,
  DEVICE_ID,
} from '../access-management/add-device-to-organization-form/upload/constants';

type FilePickerProps = {
  /** Method for displaying the toast */
  addToastMessage: (toasty: Toast) => void;
  /** True if the file picker should be disabled */
  isDisabled: boolean;
  /** True if the file picker should display its loading state */
  isLoading: boolean;
  /** Title to describe the file picker */
  label: string;
  /** Sets the column headers that were read from the selected file */
  setColumnHeaders: (headers: Array<ColumnHeadersType>) => void;
  /** Sets the data that was read from the sheet*/
  setDataFromSheet: (data: Array<any> | undefined) => void;
};

const SHEET_HEADER_ROW = 0;

// File reader only allows a single file to be selected at a time for upload
function parseSpreadsheet(
  fileList: FileList,
  onReadError: (error: string) => void,
  setAvailableColumns: (headers: Array<ColumnHeadersType>) => void,
  setDataFromSheet: (data: Array<{ [key: string]: string }>) => void
) {
  // Iterate over each column, and get the labels for those columns
  const getAvailableColumns = (sheet: XLSX.Sheet) => {
    const availableColumns: Array<ColumnHeadersType> = [];

    for (const colIndex in sheet[SHEET_HEADER_ROW]) {
      availableColumns.push({
        [COL_LABEL]: sheet[SHEET_HEADER_ROW][colIndex].toString(),
        [COL_INDEX]: colIndex,
      });
    }
    setAvailableColumns(availableColumns);
  };

  const fileUpload = fileList.item(0);
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;

  reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
    /* Parse data */
    if (readerEvent == null || readerEvent.target == null) {
      return;
    }
    const bookstream = readerEvent.target.result;
    const workbook = XLSX.read(bookstream, { type: rABS ? 'binary' : 'array' });
    /* Get first worksheet */
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];

    // raw needs to be set to false, otherwise it tries to convert data from strings to ints/dates
    const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });

    getAvailableColumns(jsonSheet);
    jsonSheet.shift();
    // @ts-expect-error this is building an array of json objects
    setDataFromSheet(jsonSheet);
  };

  reader.onerror = () => {
    onReadError(reader.error?.name ?? 'failed to read file');
  };

  if (fileUpload == null) {
    return;
  } else if (rABS) {
    reader.readAsBinaryString(fileUpload);
  } else {
    reader.readAsArrayBuffer(fileUpload);
  }
}

// Helper for iterating over the datasheet to create objects from the data rows
export const parseCreateDeviceDataRows = (
  rowsFromSheet: Array<{ [key: string]: string }>,
  deviceNameColumnIndex: number,
  deviceIdColumnIndex: number,
  setValidRows: (items: DeviceUploadRow[]) => void,
  setInvalidRows: (items: DeviceUploadRow[]) => void
) => {
  const validRows: DeviceUploadRow[] = [];
  const invalidRows: DeviceUploadRow[] = [];

  rowsFromSheet?.forEach((row: any, rowIndex: number) => {
    const deviceNameData = row[deviceNameColumnIndex]?.toString()?.trim() ?? '';
    const deviceIdData = row[deviceIdColumnIndex]?.toString()?.trim();
    const isInvalid = !deviceNameData || !deviceIdData || deviceIdData === '';

    // Offset the index by 2 - csv files start numbering at 1
    // First row is the header, second is the first row we have
    const parsedRow: DeviceUploadRow = {
      [ROW_INDEX]: rowIndex + 2,
      [DEVICE_ID]: deviceIdData,
      [DEVICE_NAME]: deviceNameData,
    };
    isInvalid ? invalidRows.push(parsedRow) : validRows.push(parsedRow);
  });

  setValidRows(validRows);
  setInvalidRows(invalidRows);
};

// Helper for iterating over the datasheet to create objects from the data rows
export const parseAddDeviceDataRows = (
  rowsFromSheet: Array<{ [key: string]: string }>,
  deviceIdColumnIndex: number,
  setValidRows: (items: DeviceUploadRow[]) => void,
  setInvalidRows: (items: DeviceUploadRow[]) => void
) => {
  const validRows: DeviceUploadRow[] = [];
  const invalidRows: DeviceUploadRow[] = [];

  rowsFromSheet?.forEach((row: any, rowIndex: number) => {
    const deviceIdData = row[deviceIdColumnIndex]?.toString()?.trim();
    const isInvalid = !deviceIdData || deviceIdData === '';

    // Offset the index by 2 - csv files start numbering at 1
    // First row is the header, second is the first row we have
    const parsedRow: DeviceUploadRow = {
      [ROW_INDEX]: rowIndex + 2,
      [DEVICE_ID]: deviceIdData,
    };
    isInvalid ? invalidRows.push(parsedRow) : validRows.push(parsedRow);
  });

  setValidRows(validRows);
  setInvalidRows(invalidRows);
};

/* File picker for selecting and reading data from a csv style sheet */
export function FilePicker(props: FilePickerProps) {
  const onFileUploadSelection = (fileList: FileList | null) => {
    const onFileReadError = (readerError: string) =>
      props.addToastMessage(errorToastMessage('Failed to read selected file.\n\n' + readerError));

    if (fileList !== null && fileList.item(0) !== null) {
      parseSpreadsheet(fileList, onFileReadError, props.setColumnHeaders, props.setDataFromSheet);
    } else {
      props.setColumnHeaders([]);
      props.setDataFromSheet([]);
    }
  };

  return (
    <EuiFormRow
      helpText="Accepts csv, ods, xlsx, xlsm, xlsb, xls, and tsv formats"
      label={props.label}
    >
      <EuiFilePicker
        disabled={props.isDisabled}
        display="large"
        isLoading={props.isLoading}
        onChange={onFileUploadSelection}
        required
      />
    </EuiFormRow>
  );
}
