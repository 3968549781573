import { EuiBadge, EuiLink, EuiTableFieldDataColumnType } from '@elastic/eui';
import { getLocalTime } from 'utils/dates';
import { CommandConfigurationRequestRow, RequestState } from 'gqlHooks';
import { COLORS } from 'utils/constants';
import { useDSLink } from '@equipmentshare/ds2-link';

type DeviceLinkProps = {
  id: string;
};

function DeviceLink(props: DeviceLinkProps) {
  return <EuiLink {...useDSLink(`/devices/${props.id}`)}>{props.id}</EuiLink>;
}

const renderStatus = (status: string) => {
  let statusText = status;
  let statusColor = COLORS.INFO;
  let iconType = 'iInCircle';

  //Pending, Created, Cancelled, Failed
  switch (status) {
    case RequestState.Completed:
      statusText;
      statusColor = COLORS.SUCCESS;
      iconType = 'check';
      break;
    case RequestState.Created:
      statusText;
      statusColor = COLORS.ACCENT;
      iconType = 'clock';
      break;
    case RequestState.Failed:
      statusText;
      statusColor = COLORS.DANGER;
      iconType = 'error';
      break;
    case RequestState.Invalidated:
      statusText = 'CANCELLED';
      statusColor = COLORS.WARNING;
      iconType = 'iInCircle';
      break;
    default:
      statusText;
      statusColor = COLORS.INFO;
      iconType = 'iInCircle';
      break;
  }

  return (
    <EuiBadge
      iconType={iconType}
      color={statusColor}
    >
      {statusText}
    </EuiBadge>
  );
};

// Date is being sent without the TZ on it. Need to manually convert from utc to our local tz
const renderDate = (date: string) => {
  return date ? getLocalTime(date) : '';
};

const requestStatus: EuiTableFieldDataColumnType<CommandConfigurationRequestRow> = {
  field: 'requestState',
  name: 'Status',
  dataType: 'string',
  truncateText: false,
  render: renderStatus,
};

const deviceId: EuiTableFieldDataColumnType<CommandConfigurationRequestRow> = {
  field: 'deviceId',
  name: 'Device IMEI',
  dataType: 'string',
  truncateText: false,
  textOnly: true,
  render: (identifier: string) => <DeviceLink id={identifier} />,
};

const createdAtTime: EuiTableFieldDataColumnType<CommandConfigurationRequestRow> = {
  field: 'createdAt',
  name: 'Created',
  dataType: 'string',
  truncateText: false,
  render: renderDate,
};

const lastUpdateTime: EuiTableFieldDataColumnType<CommandConfigurationRequestRow> = {
  field: 'updatedAt',
  name: 'Most Recent Update',
  dataType: 'string',
  truncateText: false,
  render: renderDate,
};

export const SIM_MESSAGE_TABLE_COLUMNS: EuiTableFieldDataColumnType<CommandConfigurationRequestRow>[] =
  [requestStatus, createdAtTime, lastUpdateTime];

export const CMD_CFG_SUMMARY_COLUMNS: EuiTableFieldDataColumnType<CommandConfigurationRequestRow>[] =
  [requestStatus, deviceId, lastUpdateTime];
