import { useEffect, useState } from 'react';
import { useAddDeviceToOrganizationMutation } from 'app/generated/graphql';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

import FileUploadBox from './upload-devices-view';
import { DEVICE_ID, DEVICE_NAME, DeviceUploadRow } from './constants';

type AddDevicesToOrgProps = {
  /** Handler for adding toasts to the global list */
  addToastMessage: (toasty: Toast) => void;
  organizationId: string;
  isUserAdmin: boolean;
};

/**
 * Used for uploading files of devices to the backend
 */
export default function UploadDevicesToOrg(props: AddDevicesToOrgProps) {
  // List of jobs that should be run
  const [jobsToRun, setJobsToRun] = useState<DeviceUploadRow[]>([]);
  // Jobs that returned a success status
  const [successfulJobs, setSuccessfulJobs] = useState<DeviceUploadRow[]>([]);
  // Jobs that either errored out or returned a failed status
  const [failedJobs, setFailedJobs] = useState<DeviceUploadRow[]>([]);
  // True if there are jobs currently running
  const [isWorking, setIsWorking] = useState<boolean>(false);
  // Data of the job that is currently executing
  const [currentJob, setCurrentJob] = useState<DeviceUploadRow | undefined>(undefined);

  const [selectedTrackerType, setSelectedTrackerType] = useState('');
  // Rows that were parsed from the csv that contain data
  const [validRows, setValidRows] = useState<DeviceUploadRow[]>([]);
  // Rows that were parsed from the csv that do not contain data
  const [invalidRows, setInvalidRows] = useState<DeviceUploadRow[]>([]);
  // Either the users current organization, or if they are admin the organization they selected
  const [selectedOrganization, setSelectedOrganization] = useState<string | undefined>(
    props.organizationId
  );

  // If the users org somehow changes (most often due to the user info finally loading in) sets the org
  useEffect(() => {
    setSelectedOrganization(props.organizationId);
  }, [props.organizationId]);

  // Updates the queue with the new job, and removes it from the remaining jobs queue
  // Can't just for loop this or the UI will seize up
  const dropFirstItemFromJobs = () => {
    const newJobs = [...jobsToRun];
    const nextJob = newJobs.shift();
    setJobsToRun(newJobs);
    setCurrentJob(nextJob);
  };

  const [addDeviceToOrganizationMutation] = useAddDeviceToOrganizationMutation({
    onCompleted: () => {
      if (currentJob !== undefined) {
        setSuccessfulJobs(prevState => [currentJob, ...prevState]);
      }
      dropFirstItemFromJobs();
    },
    onError: () => {
      if (currentJob !== undefined) {
        setFailedJobs(prevState => [currentJob, ...prevState]);
      }
      dropFirstItemFromJobs();
    },
  });

  useEffect(() => {
    if (currentJob !== undefined) {
      setIsWorking(prevState => (!prevState ? true : prevState));
      addDeviceToOrganizationMutation({
        variables: {
          deviceId: currentJob[DEVICE_ID],
          label: currentJob[DEVICE_NAME] ?? '',
          organizationId: props.organizationId,
        },
      });
    } else {
      setIsWorking(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJob]);

  // Reset the success/fail to empty when the valid or invalid rows are changed
  useEffect(() => {
    setSuccessfulJobs([]);
    setFailedJobs([]);
  }, [validRows, invalidRows]);

  // Triggers the first iteration of adding a job to the current queue
  const handleSubmit = () => {
    dropFirstItemFromJobs();
  };

  const onValidChange = (rows: DeviceUploadRow[]) => {
    setValidRows(rows);
    setJobsToRun(rows);
  };

  return (
    <FileUploadBox
      addToastMessage={props.addToastMessage}
      isLoading={isWorking}
      successRows={successfulJobs}
      failedRows={failedJobs}
      totalJobs={validRows.length}
      completedJobs={successfulJobs.length + failedJobs.length}
      selectedTrackerType={selectedTrackerType}
      setSelectedTrackerType={setSelectedTrackerType}
      setInvalidRows={setInvalidRows}
      invalidRows={invalidRows}
      setValidRows={onValidChange}
      validRows={validRows}
      isAdmin={props.isUserAdmin}
      onSubmit={handleSubmit}
      selectedOrganization={selectedOrganization ?? ''}
      setSelectedOrganization={setSelectedOrganization}
    />
  );
}
