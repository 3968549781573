import { Location as DeviceLocation } from 'models/devices/Location';
import type { TimeRangeValue } from 'utils/dates';

export enum StatusType {
  DANGER = 'DANGER',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  INFORMATION = 'INFORMATION',
  BETA = 'BETA',
  UNKNOWN = 'UNKNOWN',
}

export enum StatusTypeToken {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFORMATION = 'primary',
  BETA = 'accent',
  UNKNOWN = 'warning',
}

export enum StatusTypeDescriptor {
  DANGER = 'Bad',
  WARNING = 'Caution',
  SUCCESS = 'Good',
  INFORMATION = 'Info',
  BETA = 'Beta',
  UNKNOWN = 'Unknown',
}

export enum TagType {
  BATTERY = 'BATTERY',
  FIRMWARE = 'FIRMWARE',
  LOCATION = 'LOCATION',
  STOLEN = 'STOLEN',
}

export interface IInstallVerificationTagData {
  readonly stolen: boolean | null | undefined;
  readonly battery: number | null | undefined;
  readonly firmware: string | null | undefined;
  readonly location: DeviceLocation | null | undefined;
}

export interface IInstallVerificationTags {
  readonly deviceId: string;
  readonly deviceType: string;
  readonly tagData: IInstallVerificationTagData;
  readonly tagFilterRange: TimeRangeValue;
  readonly tags: ITag[];
  readonly verificationTimeRange: string;
}

export interface ITag {
  /** Description of why the status is its current value */
  description: string;
  /** Status of the tag */
  status: StatusType;
  /** Token for calculating status display items */
  statusToken: StatusTypeToken;
  /** Short form of the status - used to display in a badge */
  tagText: string;
  /** Type of data the tag will be displaying */
  tagType: TagType;
  /** Title for the install entry card */
  title: string;
}

export interface ITagStatus {
  status: StatusType;
  statusDescriptor: StatusTypeDescriptor;
  statusToken: StatusTypeToken;
}

export interface TagEntry {
  deviceId: string;
  tagData: Array<ITag>;
}
