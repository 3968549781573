import { Stolen } from 'models/install-verification-tags/tag/stolen/Stolen';
import {
  StatusType,
  StatusTypeDescriptor,
  StatusTypeToken,
} from 'models/install-verification-tags/types';
import type {
  IInstallVerificationTagData,
  ITag,
  ITagStatus,
  TagType,
} from 'models/install-verification-tags/types';

export class SlapAndTrackStolen extends Stolen {
  constructor(tagType: TagType, tagData: IInstallVerificationTagData) {
    super(tagType, tagData);
  }

  getStatus(): ITagStatus {
    if (this.stolen) {
      this.statusType = StatusType.DANGER;
      this.statusDetail = 'Device is stolen';
    } else if (this.stolen === false) {
      this.statusType = StatusType.SUCCESS;
      this.statusDetail = 'Device is not stolen';
    }

    return {
      status: this.statusType,
      statusToken: StatusTypeToken[this.statusType],
      statusDescriptor: StatusTypeDescriptor[this.statusType],
    };
  }

  createTag(): ITag {
    const statusMeta = this.getStatus();
    return {
      ...this.defaults,
      status: statusMeta.status,
      statusToken: statusMeta.statusToken,
      tagText: this.statusDetail,
      description: `${this.statusDetail}`,
    };
  }
}
