import { useState } from 'react';
import {
  EuiErrorBoundary,
  EuiScreenReaderOnly,
  DefaultItemAction,
  EuiSearchBarProps,
  EuiInMemoryTable,
  EuiOverlayMask,
  EuiConfirmModal,
} from '@elastic/eui';
import { StrawberryGroupOperationResponse, FotaType, GroupOperationStatus } from 'gqlHooks';
import { GROUPS_FOTA_SUMMARY_COLUMNS } from 'layout/groups/progress/fota/table-columns';

export interface SntFotaRequestsTableProps {
  /** True if the table should show a loading status */
  isLoading: boolean;
  /** Messages to be displayed in the table */
  requests: StrawberryGroupOperationResponse[];
  /** Method invoked when the users wish to cancel a group request */
  onRequestCancel: (requestId: number) => void;
  /** If supplied, adds an action button for displaying a detailed view of the request. Aimed at groups. */
  onViewDetails: (requestId: number) => void;
}

/* Displays fota request data for a group in table form */
export default function GroupsSntFotaRequestsTable(props: SntFotaRequestsTableProps) {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [requestToCancel, setRequestToCancel] = useState<StrawberryGroupOperationResponse>();

  //Opens the "cancel group request" dialog
  const onOpenCancelDialog = (request: StrawberryGroupOperationResponse) => {
    setRequestToCancel(request);
    setIsCancelDialogOpen(true);
  };

  // Closes the "cancel group request" dialog
  const onCloseCancelDialog = () => {
    setRequestToCancel(undefined);
    setIsCancelDialogOpen(false);
  };

  const onConfirmCancel = () => {
    if (requestToCancel) {
      props.onRequestCancel(requestToCancel.requestId);
    }
    onCloseCancelDialog();
  };

  const handleDetailOpen = (request: StrawberryGroupOperationResponse) => {
    props.onViewDetails(request.requestId);
  };

  const openDetailsAction: DefaultItemAction<StrawberryGroupOperationResponse> = {
    name: (
      <EuiScreenReaderOnly>
        <span>View Request Details</span>
      </EuiScreenReaderOnly>
    ),
    description: 'View Request Details',
    icon: 'iInCircle',
    color: 'primary',
    type: 'icon',
    onClick: handleDetailOpen,
    isPrimary: true,
  };

  const search: EuiSearchBarProps | undefined = {
    box: {
      incremental: true,
    },
    filters: [
      {
        type: 'field_value_selection',
        field: 'request.type',
        name: 'Request Type',
        multiSelect: false,
        options: Object.values(FotaType).map(type => ({
          value: type,
        })),
      },
    ],
  };

  const cancelRequestDialog = (
    <EuiOverlayMask>
      <EuiConfirmModal
        buttonColor="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        defaultFocusedButton="confirm"
        onCancel={onCloseCancelDialog}
        onConfirm={onConfirmCancel}
        title="Are you sure?"
      >
        <p>{`Cancel this group configuration request?`}</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  );

  const cancelAction: DefaultItemAction<StrawberryGroupOperationResponse> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Cancel Request</span>
      </EuiScreenReaderOnly>
    ),
    enabled: (request: StrawberryGroupOperationResponse) => {
      const requestState = request.requestState;
      return (
        requestState === GroupOperationStatus.Active ||
        requestState === GroupOperationStatus.Applying
      );
    },
    description: 'Cancel Configuration Request',
    icon: 'trash',
    color: 'danger',
    type: 'icon',
    onClick: onOpenCancelDialog,
    isPrimary: true,
  };

  const actions = [cancelAction, openDetailsAction];

  return (
    <EuiErrorBoundary>
      {isCancelDialogOpen ? cancelRequestDialog : null}
      {/* @ts-expect-error I have no idea how to fix this ts error */}
      <EuiInMemoryTable
        search={search}
        columns={[
          ...GROUPS_FOTA_SUMMARY_COLUMNS,
          {
            name: 'Actions',
            actions,
          },
        ]}
        hasActions
        itemId={'requestId'}
        items={props.requests ?? []}
        loading={props.isLoading}
        tableCaption="Message Events"
      />
    </EuiErrorBoundary>
  );
}
