import { Tag } from 'models/install-verification-tags/tag/Tag';
import type { IInstallVerificationTagData, TagType } from 'models/install-verification-tags/types';

export abstract class Battery extends Tag {
  protected battery: IInstallVerificationTagData['battery'];

  constructor(tagType: TagType, tagData: IInstallVerificationTagData) {
    super(tagType, tagData);
    this.battery = tagData.battery;
  }

  protected isValidBatteryData(): boolean {
    if (!this.battery) {
      return false;
    }
    return true;
  }
}
