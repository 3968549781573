import {
  CommandConfigurationRequestRow,
  FotaRequestRow,
  FotaRequestState,
  RequestState,
} from 'app/generated/graphql';

export const completedFilter = (request: CommandConfigurationRequestRow | FotaRequestRow) => {
  return request.requestState === RequestState.Completed;
};

export const inProgressFilter = (request: CommandConfigurationRequestRow | FotaRequestRow) => {
  if (request.__typename === 'FotaRequestRow') {
    return [
      FotaRequestState.Applying,
      FotaRequestState.Downloading,
      FotaRequestState.Processing,
    ].includes(request.requestState);
  } else {
    return request.requestState === RequestState.Applying;
  }
};

export const failedFilter = (request: CommandConfigurationRequestRow | FotaRequestRow) => {
  return request.requestState === RequestState.Failed;
};

export const invalidatedFilter = (request: CommandConfigurationRequestRow | FotaRequestRow) => {
  return request.requestState === RequestState.Invalidated;
};

export const createdFilter = (request: CommandConfigurationRequestRow | FotaRequestRow) => {
  return request.requestState === RequestState.Created;
};
