import { useCallback, useEffect, useState } from 'react';
import { EuiErrorBoundary, EuiProgress, EuiSelectOption } from '@elastic/eui';
import { useGetDeviceGroupsQuery, useGetDeviceShadowQuery } from 'gqlHooks';
import type { GetDeviceGroupsQuery, GetDeviceShadowQuery } from 'gqlHooks';
import { SlapAndTrackDevice } from 'models/devices/slap-and-track-device/SlapAndTrackDevice';
import { CONSTANTS } from 'utils/constants';
import { FormatDeviceType } from 'app/types';
import { ErrorMessage } from 'layout/utility/errorMessage/errorMessage';
import { DeviceStatusInfoPanel } from 'layout/device-detail-header/device-status-info-panel';
import { useInstallVerificationTags } from 'app/hooks/useInstallVerificationTags';
import { extractGraphQLError, handleApolloError } from 'app/utils/errorHandlers';
import type { ApolloError } from '@apollo/client';
import { UserInfoContextData, useUserInfo } from 'app/context/userInfo';

export type DeviceDetailHeaderProps = {
  deviceId: string;
  setDeviceType: (deviceType: string) => void;
  setDeviceLabel: (deviceLabel: string | null | undefined) => void;
};

export const DeviceDetailHeader = (props: DeviceDetailHeaderProps) => {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [device, setDevice] = useState<SlapAndTrackDevice | null>(null);
  const [deviceGroups, setDeviceGroups] = useState<EuiSelectOption[]>([]);
  const [loadingInstallVerification, setLoadingInstallVerification] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<string | null>(null);
  const [userOrg, setUserOrg] = useState<string>('');

  const {
    queryData: { userInfo, loading: isUserInfoLoading },
  }: UserInfoContextData = useUserInfo();

  // Check the users organization on change. If undefined, display credential message
  useEffect(() => {
    const orgId = userInfo?.organizationId;
    setUserOrg(orgId ?? '');
    // Check if userOrg is null, undefined, or an empty string
    if (!orgId && !isUserInfoLoading) {
      const errorMessage = 'Failed to load user credentials. Contact your company administrator.';
      setErrorState(errorMessage);
    }
  }, [userInfo?.organizationId, isUserInfoLoading]);

  const handleError = useCallback((error: ApolloError) => {
    const gqlError = extractGraphQLError(error);
    if (gqlError.statusCode === 404) {
      setErrorState(gqlError.errorMessage);
    } else {
      handleApolloError(error, true, gqlError => setErrorState(gqlError.errorMessage));
    }
  }, []);

  const { loading: loadingDeviceMessages } = useGetDeviceShadowQuery({
    variables: {
      deviceId: props.deviceId,
    },
    pollInterval: CONSTANTS.FIFTEEN_SECONDS_IN_MILLISECONDS,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: GetDeviceShadowQuery) => {
      const { deviceShadow } = data;
      setDevice(
        new SlapAndTrackDevice(
          {
            id: props.deviceId,
          },
          deviceShadow
        )
      );
      // Assuming deviceShadow.deviceType comes back as 'snt'
      const formattedDeviceType =
        FormatDeviceType[deviceShadow?.deviceType as keyof typeof FormatDeviceType] ??
        'Slap N Track'; // Getting the human-readable device type
      props.setDeviceType(formattedDeviceType);

      setFirstRender(false);
    },
    onError: error => {
      handleError(error);
    },
  });

  useGetDeviceGroupsQuery({
    variables: {
      deviceId: props.deviceId,
    },
    onCompleted: (data: GetDeviceGroupsQuery) => {
      const { deviceGroups } = data;
      const formattedGroups = deviceGroups.items.map(item => ({
        text: item.label ?? 'No Label', // Provide a default value if label is missing
        value: item.identifier ?? 'No Group Name', // Provide a default value if groupName is missing
      }));
      setDeviceGroups(formattedGroups);
    },
    onError: error => {
      handleError(error);
    },
  });

  const tags = useInstallVerificationTags({
    deviceId: props.deviceId,
    setErrorState: setErrorState,
    setLoadingInstallVerification: setLoadingInstallVerification,
  });

  /** Derived Values */
  const isLoading = loadingDeviceMessages || isUserInfoLoading || loadingInstallVerification;

  if (
    (loadingDeviceMessages && firstRender) ||
    errorState ||
    (isUserInfoLoading && userOrg === '')
  ) {
    return (
      <EuiErrorBoundary id="device-detail-header-loading-error">
        {isLoading ? (
          <EuiProgress
            size="xs"
            color="primary"
          />
        ) : null}
        {errorState ? (
          <ErrorMessage
            isHidden={false}
            message={errorState}
          />
        ) : null}
      </EuiErrorBoundary>
    );
  }

  return (
    <EuiErrorBoundary id="device-detail-header-boundary">
      {device ? (
        <EuiErrorBoundary id="device-detail-header-info-panel-boundary">
          <DeviceStatusInfoPanel
            device={device}
            groups={deviceGroups}
            loading={isLoading}
            tagData={tags}
            firstRender={firstRender}
            userOrg={userOrg}
          />
        </EuiErrorBoundary>
      ) : null}
    </EuiErrorBoundary>
  );
};
