import { StolenAsset } from 'app/generated/graphql';
import { hourTimeIntToDate } from 'app/utils/dates';
import _ from 'lodash';
import moment from 'moment-timezone';
import { MlEuiCommentType, buildEuiComment } from 'utils/configTranslator';

// "Enable" Field options and default
export const MODE_AUTO = 'auto'; // determines stolen status using geofence and hours
export const MODE_DISABLED = 'disabled'; // stolen mode is disabled
export const MODE_FORCED = 'forced'; // Marks as stolen - will continue until state is changed
export const ENABLED_DEFAULT = 'disabled';

export const STOLEN_MODE_OPTIONS = [
  {
    id: MODE_AUTO,
    label: 'Auto',
  },
  {
    id: MODE_FORCED,
    label: 'Forced',
  },
  {
    id: MODE_DISABLED,
    label: 'Disabled',
  },
];

// Working hours
export const WORKING_HOURS_DISABLED_VALUE = 9999;
export const WORKING_HOURS_DEFAULT = WORKING_HOURS_DISABLED_VALUE;

// Geofence
export const GEOFENCE_DEFAULT = 100;
export const GEOFENCE_MAX = 10000;
export const GEOFENCE_MIN = 100;

// Report Interval
export const REPORT_INTERVAL_DEFAULT = 1800; // seconds
export const REPORT_INTERVAL_MAX = 86400; // seconds
export const REPORT_INTERVAL_MIN = 120; // seconds

export const DEFAULT_STOLEN_CONFIG: StolenAsset = {
  reportInterval: REPORT_INTERVAL_DEFAULT,
  enable: ENABLED_DEFAULT,
  workingHoursStart: WORKING_HOURS_DEFAULT,
  workingHoursEnd: WORKING_HOURS_DEFAULT,
  geofence: GEOFENCE_DEFAULT,
};

export const DEFAULT_GROUP_STOLEN_CONFIG: StolenAsset = {
  //@ts-expect-error form fields accept empty string for unset number fields
  reportInterval: '',
  enable: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  workingHoursStart: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  workingHoursEnd: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  geofence: '',
};

// Builds items for an EUI comment list, in translated human readable format
export const stolenConfigTranslator = (stolenConfig: StolenAsset | undefined | null) => {
  if (!stolenConfig) {
    return undefined;
  }
  const builtItems: Array<MlEuiCommentType> = [];
  const localTimezoneAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
  if (stolenConfig.enable) {
    builtItems.push(buildEuiComment('Stolen Asset Mode', _.capitalize(stolenConfig.enable ?? '')));
  }
  if (stolenConfig.reportInterval) {
    builtItems.push(
      buildEuiComment('Report Interval', `Every ${stolenConfig.reportInterval} seconds`)
    );
  }
  if (stolenConfig.workingHoursStart) {
    // If it's disabled, give the user an explanation string
    if (stolenConfig.workingHoursStart === WORKING_HOURS_DISABLED_VALUE) {
      builtItems.push(buildEuiComment('Working Hours Start Time', 'Disabled'));
    } else {
      const startTime = hourTimeIntToDate(stolenConfig.workingHoursStart).format('H:mm');
      const startTimeWithZone = `${startTime} ${localTimezoneAbbr}`;
      builtItems.push(buildEuiComment('Working Hours Start Time', startTimeWithZone));
    }
  }
  if (stolenConfig.workingHoursEnd) {
    // If it's disabled, give the user an explanation string
    if (stolenConfig.workingHoursEnd === WORKING_HOURS_DISABLED_VALUE) {
      builtItems.push({ title: 'Working Hours End Time', description: 'Disabled' });
    } else {
      const endTime = hourTimeIntToDate(stolenConfig.workingHoursEnd).format('H:mm');
      const endTimeWithZone = `${endTime} ${localTimezoneAbbr}`;
      builtItems.push({ title: 'Working Hours End Time', description: endTimeWithZone });
    }
  }
  if (stolenConfig.geofence) {
    builtItems.push({ title: 'Geofence Size', description: `${stolenConfig.geofence} meters` });
  }
  return builtItems;
};
