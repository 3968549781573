import { EuiBadge, EuiTableFieldDataColumnType, EuiLink } from '@elastic/eui';
import { useDSLink } from '@equipmentshare/ds2-link';
import { getLocalTime } from 'app/utils/dates';
import type { FotaRequestRow, FotaRequestMessage } from 'gqlHooks';
import { FotaRequestState } from 'gqlHooks';
import _ from 'lodash';
import { COLORS } from 'utils/constants';

type DeviceLinkProps = {
  id: string;
};

function DeviceLink(props: DeviceLinkProps) {
  return <EuiLink {...useDSLink(`/devices/${props.id}`)}>{props.id}</EuiLink>;
}

const renderFotaType = (rawMessage: FotaRequestMessage) => {
  return rawMessage.type;
};

const renderFotaUrl = (rawMessage: FotaRequestMessage, row: FotaRequestRow) => {
  const url = rawMessage.url;

  return (
    <EuiLink
      href={url}
      target="_blank"
    >
      {row.firmwareVersion}
    </EuiLink>
  );
};

// Green - completed
// Info - Created, applying, downloading, invalidated
// Red - Failed, DownloadFailed, DownloadFailedToStart
const renderStatus = (status: string) => {
  let statusText = _.startCase(status);
  let statusColor = COLORS.INFO;
  let iconType = 'iInCircle';

  switch (status) {
    case FotaRequestState.Completed:
      statusText;
      statusColor = COLORS.SUCCESS;
      iconType = 'check';
      break;
    case FotaRequestState.Created:
      statusText;
      statusColor = COLORS.ACCENT;
      iconType = 'clock';
      break;
    case FotaRequestState.Invalidated:
      statusText = 'CANCELLED';
      statusColor = COLORS.WARNING;
      iconType = 'iInCircle';
      break;
    case FotaRequestState.Applying:
    case FotaRequestState.Downloading:
      statusText;
      statusColor = COLORS.INFO;
      iconType = 'clock';
      break;
    case FotaRequestState.Failed:
      statusText;
      statusColor = COLORS.DANGER;
      iconType = 'error';
      break;
    default:
      statusText;
      statusColor = COLORS.INFO;
      iconType = 'iInCircle';
      break;
  }

  return (
    <EuiBadge
      iconType={iconType}
      color={statusColor}
    >
      {statusText}
    </EuiBadge>
  );
};

// Date is being sent without the TZ on it. Need to manually convert from utc to our local tz
const renderDate = (date: string) => {
  return date ? getLocalTime(date) : '';
};

const requestType: EuiTableFieldDataColumnType<FotaRequestRow> = {
  field: 'request',
  name: 'Type',
  dataType: 'string',
  truncateText: false,
  width: '10%',
  render: renderFotaType,
};

const requestUrl: EuiTableFieldDataColumnType<FotaRequestRow> = {
  field: 'request',
  name: 'Version',
  dataType: 'string',
  truncateText: false,
  textOnly: true,
  render: renderFotaUrl,
};

const deviceId: EuiTableFieldDataColumnType<FotaRequestRow> = {
  field: 'deviceId',
  name: 'Device IMEI',
  dataType: 'string',
  truncateText: false,
  textOnly: true,
  render: (identifier: string) => <DeviceLink id={identifier} />,
};

const requestState: EuiTableFieldDataColumnType<FotaRequestRow> = {
  field: 'requestState',
  name: 'Status',
  dataType: 'date',
  truncateText: false,
  render: renderStatus,
};

const createdAt: EuiTableFieldDataColumnType<FotaRequestRow> = {
  field: 'createdAt',
  name: 'Created',
  dataType: 'date',
  truncateText: false,
  width: '20%',
  render: renderDate,
};

const updatedAt: EuiTableFieldDataColumnType<FotaRequestRow> = {
  field: 'updatedAt',
  name: 'Last updated',
  dataType: 'date',
  truncateText: false,
  width: '20%',
  render: renderDate,
};

export const TABLE_COLUMNS: EuiTableFieldDataColumnType<FotaRequestRow>[] = [
  requestState,
  createdAt,
  updatedAt,
  requestType,
  requestUrl,
];

export const FOTA_SUMMARY_COLUMNS: EuiTableFieldDataColumnType<FotaRequestRow>[] = [
  requestState,
  deviceId,
  updatedAt,
];
