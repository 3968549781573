import { useState, useEffect, SyntheticEvent } from 'react';
import moment, { Moment } from 'moment';
import {
  EuiButton,
  EuiErrorBoundary,
  EuiRefreshInterval,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  OnRefreshChangeProps,
} from '@elastic/eui';
import DatePicker from 'app/components/forms/date-picker/date-picker';
import { Messages } from 'gqlHooks';

import TrackerMessagesExport from '../export/tracker-messages-export';

interface MessageFilterProps {
  /**
   * How often (in milliseconds) the page should be auto refreshing
   */
  refreshInterval: number;
  /**
   * Sets the auto refresh interval
   */
  onRefreshChange: (values: OnRefreshChangeProps) => void;
  /**
   * True if the auto refresh is paused
   */
  isPaused: boolean;
  /**
   * Invoked when a search is requested
   */
  onSearch: (startDate: Moment, endDate: Moment) => void;
  /**
   * True if the filters should be in their loading state
   */
  isLoading: boolean;
  messages: Messages[];
  messagesQuery: Messages[] | undefined;
}

/**
 *  Allows users to set search criteria for searching for messages
 */
export const MessageFilters = (props: MessageFilterProps) => {
  const [isStale, setIsStale] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days').startOf('day'));
  const [endDate, setEndDate] = useState(moment().add(1, 'days').endOf('day'));

  const onClose = () => {
    setIsOpen(false);
  };

  const refetch = () => {
    setIsOpen(true);
  };

  // Changes to start/end date only apply on search button click
  // Storing stale status to give users a visual prompt to hit the search button
  useEffect(() => setIsStale(true), [startDate, endDate]);

  // On initial load, run a search with default values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSearchButtonOnClick(null), []);

  // Invoke the parent search with the new data values, then reset
  // the stale value as the date is now what the search is using
  const handleSearchButtonOnClick = (event: SyntheticEvent | null) => {
    // Forms by default call an html refresh. This prevents that.
    event ? event.preventDefault() : null;
    props.onSearch(startDate, endDate);
    setIsStale(false);
  };

  return (
    <EuiErrorBoundary>
      <EuiForm
        component="form"
        onSubmit={handleSearchButtonOnClick}
      >
        <EuiSpacer size="m" />
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <EuiSpacer size="m" />
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiFormRow>
              <EuiButton
                id="refresh-device-messages"
                color={isStale ? 'success' : 'primary'}
                iconType={isStale ? 'kqlFunction' : 'refresh'}
                fill
                type="submit"
                isLoading={props.isLoading}
              >
                {isStale ? 'Update' : 'Refresh'}
              </EuiButton>
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <TrackerMessagesExport
              isOpen={isOpen}
              exportData={props.messagesQuery}
              refetch={refetch}
              onClose={onClose}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiRefreshInterval
              isPaused={props.isPaused}
              onRefreshChange={props.onRefreshChange}
              refreshInterval={props.refreshInterval}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </EuiErrorBoundary>
  );
};

export default MessageFilters;
