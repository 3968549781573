import { EuiCallOut, EuiText } from '@elastic/eui';

export type ErrorMessageProps = {
  className?: string;
  message?: string;
  title?: string;
  isHidden: boolean;
};

export const ErrorMessage = (props: ErrorMessageProps) => {
  return !props.isHidden ? (
    <EuiCallOut
      className={props.className}
      title={props.title ?? 'Sorry, there was an error'}
      color="danger"
      iconType="alert"
    >
      {props.message && props.message.length > 0 && (
        <EuiText>
          <p>{props.message}</p>
        </EuiText>
      )}
    </EuiCallOut>
  ) : null;
};
