import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

type AuthenticationDetails = {
  accessToken: string;
};

export const useAuthenticationDetails = (): AuthenticationDetails => {
  const { getAccessTokenSilently } = useAuth0();
  const [authenticationDetails, setAuthenticationDetails] = useState<AuthenticationDetails>({
    accessToken: '',
  });

  useEffect(() => {
    async function getAuthenticationDetails(): Promise<void> {
      setAuthenticationDetails({ accessToken: await getAccessTokenSilently() });
    }

    getAuthenticationDetails();
  }, [getAccessTokenSilently]);

  return authenticationDetails;
};
