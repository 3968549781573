import { ICON_TYPES } from '@elastic/eui';
import { ValuesType } from 'utility-types';
import { appendIconComponentCache } from '@elastic/eui/es/components/icon/icon';
import { icon as magnifyWithMinus } from '@elastic/eui/es/components/icon/assets/magnifyWithMinus';
import { icon as minus } from '@elastic/eui/es/components/icon/assets/minus';
import { icon as article } from '@elastic/eui/es/components/icon/assets/article';
import { icon as magnifyWithPlus } from '@elastic/eui/es/components/icon/assets/magnifyWithPlus';
import { icon as pencil } from '@elastic/eui/es/components/icon/assets/pencil';
import { icon as plus } from '@elastic/eui/es/components/icon/assets/plus';
import { icon as minimize } from '@elastic/eui/es/components/icon/assets/minimize';
import { icon as logoElastic } from '@elastic/eui/es/components/icon/assets/logo_elastic';
import { icon as lockOpen } from '@elastic/eui/es/components/icon/assets/lockOpen';
import { icon as training } from '@elastic/eui/es/components/icon/assets/training';
import { icon as logoSecurity } from '@elastic/eui/es/components/icon/assets/logo_security';
import { icon as pinFilled } from '@elastic/eui/es/components/icon/assets/pin_filled';
import { icon as logoKibana } from '@elastic/eui/es/components/icon/assets/logo_kibana';
import { icon as home } from '@elastic/eui/es/components/icon/assets/home';
import { icon as logoAWSMono } from '@elastic/eui/es/components/icon/assets/logo_aws_mono';
import { icon as logoAzureMono } from '@elastic/eui/es/components/icon/assets/logo_azure_mono';
import { icon as arrowRight } from '@elastic/eui/es/components/icon/assets/arrow_right';
import { icon as logoGCPMono } from '@elastic/eui/es/components/icon/assets/logo_gcp_mono';
import { icon as cross } from '@elastic/eui/es/components/icon/assets/cross';
import { icon as menu } from '@elastic/eui/es/components/icon/assets/menu';
import { icon as lock } from '@elastic/eui/es/components/icon/assets/lock';
import { icon as calendar } from '@elastic/eui/es/components/icon/assets/calendar';
import { icon as alert } from '@elastic/eui/es/components/icon/assets/alert';
import { icon as wrench } from '@elastic/eui/es/components/icon/assets/wrench';
import { icon as mapMarker } from '@elastic/eui/es/components/icon/assets/map_marker';
import { icon as visGauge } from '@elastic/eui/es/components/icon/assets/vis_gauge';
import { icon as visMapCoordinate } from '@elastic/eui/es/components/icon/assets/vis_map_coordinate';
import { icon as arrowDown } from '@elastic/eui/es/components/icon/assets/arrow_down';
import { icon as minusInCircle } from '@elastic/eui/es/components/icon/assets/minus_in_circle';
import { icon as check } from '@elastic/eui/es/components/icon/assets/check';
import { icon as empty } from '@elastic/eui/es/components/icon/assets/empty';
import { icon as arrowEnd } from '@elastic/eui/es/components/icon/assets/arrowEnd';
import { icon as arrowLeft } from '@elastic/eui/es/components/icon/assets/arrow_left';
import { icon as arrowStart } from '@elastic/eui/es/components/icon/assets/arrowStart';
import { icon as list } from '@elastic/eui/es/components/icon/assets/list';
import { icon as search } from '@elastic/eui/es/components/icon/assets/search';
import { icon as iInCircle } from '@elastic/eui/es/components/icon/assets/iInCircle';
import { icon as popout } from '@elastic/eui/es/components/icon/assets/popout';
import { icon as bolt } from '@elastic/eui/es/components/icon/assets/bolt';
import { icon as faceHappy } from '@elastic/eui/es/components/icon/assets/face_happy';
import { icon as questionInCircle } from '@elastic/eui/es/components/icon/assets/question_in_circle';
import { icon as faceNeutral } from '@elastic/eui/es/components/icon/assets/face_neutral';
import { icon as faceSad } from '@elastic/eui/es/components/icon/assets/face_sad';
import { icon as copy } from '@elastic/eui/es/components/icon/assets/copy';
import { icon as beta } from '@elastic/eui/es/components/icon/assets/beta';
import { icon as compute } from '@elastic/eui/es/components/icon/assets/compute';
import { icon as mobile } from '@elastic/eui/es/components/icon/assets/mobile';
import { icon as securitySignal } from '@elastic/eui/es/components/icon/assets/securitySignal';
import { icon as returnKey } from '@elastic/eui/es/components/icon/assets/return_key.js';
import { icon as trash } from '@elastic/eui/es/components/icon/assets/trash.js';
import { icon as grab } from '@elastic/eui/es/components/icon/assets/grab.js';
import { icon as clock } from '@elastic/eui/es/components/icon/assets/clock';
import { icon as gear } from '@elastic/eui/es/components/icon/assets/gear';
import { icon as timeline } from '@elastic/eui/es/components/icon/assets/timeline';
import { icon as error } from '@elastic/eui/es/components/icon/assets/error';
import { icon as user } from '@elastic/eui/es/components/icon/assets/user.js';
import { icon as help } from '@elastic/eui/es/components/icon/assets/help.js';
import { icon as link } from '@elastic/eui/es/components/icon/assets/link.js';
import { icon as users } from '@elastic/eui/es/components/icon/assets/users.js';
import { icon as sortRight } from '@elastic/eui/es/components/icon/assets/sortRight';
import { icon as cluster } from '@elastic/eui/es/components/icon/assets/cluster';
import { icon as kqlFunction } from '@elastic/eui/es/components/icon/assets/kql_function';
import { icon as sortUp } from '@elastic/eui/es/components/icon/assets/sort_up.js';
import { icon as sortDown } from '@elastic/eui/es/components/icon/assets/sort_down.js';
import { icon as refresh } from '@elastic/eui/es/components/icon/assets/refresh';
import { icon as userAvatar } from '@elastic/eui/es/components/icon/assets/userAvatar';
import { icon as plusInCircle } from '@elastic/eui/es/components/icon/assets/plus_in_circle';
import { icon as importAction } from '@elastic/eui/es/components/icon/assets/import.js';
import { icon as warning } from '@elastic/eui/es/components/icon/assets/warning.js';

type IconComponentNameType = ValuesType<typeof ICON_TYPES>;
type IconComponentCacheType = Partial<Record<IconComponentNameType, unknown>>;

const cachedIcons: IconComponentCacheType = {
  arrowDown,
  arrowEnd,
  arrowLeft,
  arrowRight,
  arrowStart,
  article,
  beta,
  bolt,
  calendar,
  check,
  compute,
  copy,
  cross,
  empty,
  faceHappy,
  faceNeutral,
  faceSad,
  home,
  iInCircle,
  list,
  lock,
  lockOpen,
  logoAWSMono,
  logoAzureMono,
  logoElastic,
  logoGCPMono,
  logoKibana,
  logoSecurity,
  magnifyWithMinus,
  magnifyWithPlus,
  mapMarker,
  menu,
  minimize,
  minus,
  minusInCircle,
  mobile,
  securitySignal,
  returnKey,
  trash,
  grab,
  clock,
  gear,
  timeline,
  error,
  user,
  help,
  link,
  users,
  sortRight,
  cluster,
  kqlFunction,
  sortUp,
  sortDown,
  refresh,
  userAvatar,
  importAction,
  pencil,
  pinFilled,
  plus,
  plusInCircle,
  popout,
  questionInCircle,
  search,
  training,
  visGauge,
  visMapCoordinate,
  alert,
  warning,
  wrench,
};

appendIconComponentCache(cachedIcons);
