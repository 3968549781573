import { EuiPinnableListGroup, EuiPinnableListGroupItemProps } from '@elastic/eui';
import { useDSLink } from '@equipmentshare/ds2-link';
import { UserInfoContextData, useUserInfo } from 'app/context/userInfo';
import { useCurrentPath } from 'hooks/useCurrentPath';
import {
  addLinkNameToUnpinTitle,
  alterLinksWithCurrentState,
  isItemActive,
} from 'layout/navigation/navUtils';

type TopNavLinkProps = {
  pinnedItems: EuiPinnableListGroupItemProps[];
  onPinClick: (item: EuiPinnableListGroupItemProps) => void;
};

export const TopNavLinks = ({ ...props }: TopNavLinkProps) => {
  const currentPath = useCurrentPath();

  const useDynamicRouteProps = (pathName: string) => {
    const isActive = isItemActive(currentPath, pathName);
    return {
      isActive,
      'aria-current': isActive,
      ...useDSLink(pathName),
    };
  };

  const {
    queryData: { userInfo },
  }: UserInfoContextData = useUserInfo();

  const isDeveloperTabVisible = userInfo?.role == 'administrator';

  // Conditionally determine the dynamic route props
  const developerRouteProps = useDynamicRouteProps('/developer');
  const developerTabItem = isDeveloperTabVisible
    ? {
        label: 'Developer',
        iconType: 'user',
        pinnable: true,
        ...developerRouteProps,
      }
    : null;

  // Combine other tabs and the "Developer" tab (if not null)
  const topNavLinkItems: EuiPinnableListGroupItemProps[] = [
    {
      label: 'Devices',
      iconType: 'mapMarker',
      pinnable: true,
      ...useDynamicRouteProps('/devices'),
    },
    {
      label: 'Groups',
      iconType: 'cluster',
      pinnable: true,
      ...useDynamicRouteProps('/groups-management'),
    },
    {
      label: 'Access Management',
      iconType: 'users',
      pinnable: true,
      ...useDynamicRouteProps('/access-management'),
    },
    ...(developerTabItem ? [developerTabItem] : []),
  ].filter(Boolean); // Filter out null values

  return (
    <EuiPinnableListGroup
      aria-label="Pinned links" // A11y : Since this group doesn't have a visible `title` it should be provided an accessible description
      listItems={alterLinksWithCurrentState(topNavLinkItems).concat(
        alterLinksWithCurrentState(props.pinnedItems, true)
      )}
      unpinTitle={addLinkNameToUnpinTitle}
      onPinClick={props.onPinClick}
      maxWidth="none"
      color="text"
      gutterSize="s"
      size="m"
    />
  );
};
