import { useState, useEffect } from 'react';
import {
  EuiAccordion,
  EuiText,
  EuiTextColor,
  EuiIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiTitle,
  useGeneratedHtmlId,
} from '@elastic/eui';

interface ConfigPanelProps {
  title: string;
  icon: string;
  description: string;
  isLoading: boolean;
  children: any;
  expandAll: boolean;
}

export const ConfigPanel = ({
  title,
  icon,
  description,
  isLoading,
  children,
  expandAll,
}: ConfigPanelProps) => {
  const [accordionState, setAccordionState] = useState<'open' | 'closed'>(
    expandAll ? 'open' : 'closed'
  );
  const handleAccordionStateChange = (isOpen: boolean) =>
    setAccordionState(isOpen ? 'open' : 'closed');

  useEffect(() => setAccordionState(expandAll ? 'open' : 'closed'), [expandAll]);
  const buttonContent = (
    <div>
      <EuiFlexGroup
        gutterSize="s"
        alignItems="center"
        responsive={false}
      >
        <EuiFlexItem grow={false}>
          <EuiIcon
            type={icon}
            size="m"
          />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiTitle size="xs">
            <h3>{title}</h3>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiText size="s">
        <p>
          <EuiTextColor color="subdued">{description}</EuiTextColor>
        </p>
      </EuiText>
    </div>
  );

  const formAccordionId__1 = useGeneratedHtmlId({
    prefix: 'formAccordion',
    suffix: 'first',
  });

  return (
    <EuiAccordion
      id={formAccordionId__1}
      element="fieldset"
      onToggle={handleAccordionStateChange}
      className="euiAccordionForm"
      buttonClassName="euiAccordionForm__button"
      buttonContent={buttonContent}
      paddingSize="l"
      forceState={accordionState}
      isLoading={isLoading}
      isLoadingMessage="Loading config"
    >
      {children}
    </EuiAccordion>
  );
};
