import { EuiPanel, EuiProgress, EuiSpacer } from '@elastic/eui';

type DeviceDetailPanelProps = {
  children: React.ReactNode;
  loading: boolean;
  firstRender: boolean;
};

export const DeviceDetailPanel = (props: DeviceDetailPanelProps) => {
  return (
    <EuiPanel color="subdued">
      {!props.firstRender && props.loading && (
        <EuiProgress
          size="xs"
          color="primary"
        />
      )}
      <EuiSpacer size="s" />
      {props.children}
      <EuiSpacer size="s" />
    </EuiPanel>
  );
};
