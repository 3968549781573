import { useSendDeviceCommandMutation, Command } from 'gqlHooks';
import { EuiErrorBoundary } from '@elastic/eui';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';

import SntSendDeviceCommandView from './send-device-command-view';

interface SendDeviceCommandProps {
  addToastMessage: (message: any) => void;
  deviceSerial: string;
}

/** Sends an sms command to a snt device */
export const SntSendDeviceCommand = ({ addToastMessage, deviceSerial }: SendDeviceCommandProps) => {
  const [sendDeviceCommandMutation, { loading }] = useSendDeviceCommandMutation({
    onCompleted: () => {
      addToastMessage(successToastMessage('Command Sent'));
    },
    onError: () => {
      addToastMessage(errorToastMessage('Failed to send command'));
    },
  });

  // Builds the request data, then sends the request
  // Port/Broker are optional - will only be sent on MQTT requests
  const sendSmsCommand = (command: Command) => {
    sendDeviceCommandMutation({
      variables: {
        deviceId: deviceSerial,
        command: command,
      },
    });
  };

  return (
    <EuiErrorBoundary>
      <SntSendDeviceCommandView
        isLoading={loading}
        onSendCommand={sendSmsCommand}
      />
    </EuiErrorBoundary>
  );
};
