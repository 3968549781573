import { EuiErrorBoundary, EuiSpacer } from '@elastic/eui';
import type { Messages } from 'gqlHooks';
import { SimEvenTypeCode } from 'app/types';
import MessagesAndSimTable from 'layout/messages/table/table';
import { SIM_MESSAGE_TABLE_COLUMNS } from 'app/layout/messages/snt/sim/table-columns';

interface SntSimMessagesProps {
  /** True if the message data is loading or reloading */
  isLoading: boolean;
  /** Optional error message */
  errorMessage?: string;
  /** Messages to be displayed */
  messages: Messages[];
  /** Sets message data used for CSV export **/
  setMessagesQuery: (messages: Messages[]) => void;
}

const SIM_EVENT_TYPE_OPTIONS = [
  ...Object.keys(SimEvenTypeCode).map(key => ({
    value: SimEvenTypeCode[key as keyof typeof SimEvenTypeCode],
    name: key.toLowerCase(),
    label: SimEvenTypeCode[key as keyof typeof SimEvenTypeCode].toLowerCase().split('/').pop(),
  })),
];

/** Displays messages returned by the trackers */
export default function SntSimMessages(props: SntSimMessagesProps) {
  return (
    <EuiErrorBoundary>
      <MessagesAndSimTable
        errorMessage={props.errorMessage}
        isLoading={props.isLoading}
        messages={props.messages}
        columns={SIM_MESSAGE_TABLE_COLUMNS}
        filters={[
          {
            type: 'field_value_selection',
            field: 'typeCode',
            name: 'Event type',
            multiSelect: 'or',
            options: SIM_EVENT_TYPE_OPTIONS,
          },
        ]}
        setMessagesQuery={props.setMessagesQuery}
      />
      <EuiSpacer size="m" />
    </EuiErrorBoundary>
  );
}
