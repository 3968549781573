import { useState } from 'react';
import _ from 'lodash';
import {
  EuiCollapsibleNav,
  EuiCollapsibleNavGroup,
  EuiErrorBoundary,
  EuiFlexItem,
  EuiImage,
  EuiHeader,
  EuiHeaderSectionItemButton,
  EuiHorizontalRule,
  EuiIcon,
  EuiListGroupItem,
  EuiShowFor,
} from '@elastic/eui';
import type { EuiPinnableListGroupItemProps } from '@elastic/eui';
import { TopNavLinks } from 'layout/navigation/TopNavLinks';
import machineLinkLogo from 'assets/machinelink_logo.png';
import LogoutButton from 'auth/LogoutButton';

const DOCK_NAV_KEY = 'navIsDocked';

type Props = {
  children?: JSX.Element;
};

export default function Navigation({ children }: Props) {
  const [navIsOpen, setNavIsOpen] = useState(
    JSON.parse(String(localStorage.getItem('navIsDocked'))) || true
  );
  const [navIsDocked, setNavIsDocked] = useState(
    JSON.parse(String(localStorage.getItem('navIsDocked'))) || true
  );

  /**
   * Pinning
   */
  const [pinnedItems, setPinnedItems] = useState<EuiPinnableListGroupItemProps[]>(
    JSON.parse(String(localStorage.getItem('pinnedItems'))) || []
  );

  const removePin = (item: any) => {
    const pinIndex = _.findIndex(pinnedItems, { label: item.label });
    if (pinIndex > -1) {
      item.pinned = false;
      const newPinnedItems = pinnedItems;
      newPinnedItems.splice(pinIndex, 1);
      setPinnedItems([...newPinnedItems]);
      localStorage.setItem('pinnedItems', JSON.stringify(newPinnedItems));
    }
  };

  // Updates the docked nav status
  const handleDockChange = () => {
    setNavIsDocked(!navIsDocked);
    localStorage.setItem(DOCK_NAV_KEY, JSON.stringify(!navIsDocked));
    setNavIsOpen(!navIsDocked);
  };

  const collapsibleNav = (
    <EuiCollapsibleNav
      aria-label="Main navigation"
      button={
        <EuiHeaderSectionItemButton
          aria-label="Toggle main navigation"
          onClick={() => setNavIsOpen(!navIsOpen)}
        >
          <EuiIcon
            aria-hidden="true"
            size="m"
            type="menu"
          />
        </EuiHeaderSectionItemButton>
      }
      id="guideCollapsibleNavAllExampleNav"
      isDocked={navIsDocked}
      isOpen={navIsOpen}
      onClose={() => setNavIsOpen(false)}
    >
      <EuiFlexItem grow={false}>
        {/* Docking button only for larger screens that can support it*/}
        <EuiShowFor sizes={['l', 'xl']}>
          <EuiCollapsibleNavGroup>
            <EuiListGroupItem
              color="subdued"
              iconType={navIsDocked ? 'lock' : 'lockOpen'}
              label={`${navIsDocked ? 'Undock' : 'Dock'} navigation`}
              onClick={handleDockChange}
              size="xs"
            />
          </EuiCollapsibleNavGroup>
        </EuiShowFor>
      </EuiFlexItem>

      {/* Shaded pinned section always with a home item */}
      <EuiFlexItem
        grow={false}
        style={{ flexShrink: 0 }}
      >
        <EuiCollapsibleNavGroup
          background="light"
          className="eui-yScroll"
          style={{ maxHeight: '40vh' }}
        >
          {/* TopNavigation Items */}
          <TopNavLinks
            pinnedItems={pinnedItems}
            onPinClick={removePin}
          />
        </EuiCollapsibleNavGroup>
      </EuiFlexItem>

      <EuiHorizontalRule margin="none" />

      {/* BOTTOM */}
      <EuiFlexItem className="eui-yScroll">
        {/* Docking button only for larger screens that can support it*/}
        <EuiShowFor sizes={['l', 'xl']}>
          <EuiCollapsibleNavGroup>
            <EuiListGroupItem
              size="xs"
              color="subdued"
              label={`${navIsDocked ? 'Undock' : 'Dock'} navigation`}
              onClick={() => {
                setNavIsDocked(!navIsDocked);
                localStorage.setItem('navIsDocked', JSON.stringify(!navIsDocked));
              }}
              iconType={navIsDocked ? 'lock' : 'lockOpen'}
            />
          </EuiCollapsibleNavGroup>
        </EuiShowFor>
      </EuiFlexItem>
    </EuiCollapsibleNav>
  );

  const leftSectionItems = [
    collapsibleNav,
    <EuiImage
      alt="Logo of the machinelink website"
      key="machineLinkLogo"
      url={machineLinkLogo}
      size="m"
      title="Equipment Share Logo"
    />,
  ];

  return (
    <EuiErrorBoundary>
      {/* TODO Pull this out into its own header since we'll have login/logout etc on it probably */}
      <EuiHeader
        position="fixed"
        sections={[
          {
            items: leftSectionItems,
          },
          {
            items: [
              <LogoutButton
                key="logout"
                title="Logout"
              />,
            ],
          },
        ]}
      />

      {children}
    </EuiErrorBoundary>
  );
}
