import { SetStateAction } from 'react';
import { Command } from 'gqlHooks';
import { EuiFormRow, EuiSelect } from '@elastic/eui';

interface SendDeviceCommandViewProps {
  /** Value of the currently selected SMS command */
  selectedCommand: string;
  /** Setter for informing about selected command changes */
  setSelectedCommand: (command: SetStateAction<Command>) => void;
}

// Available commands for a SnT device, with help text descriptions
const SNT_DEVICE_COMMANDS = [
  {
    value: Command.Claim,
    text: 'Claim',
    description: 'Start a new credential claim on the next connection attempt.',
  },
  { value: Command.Config, text: 'Config', description: 'Return the entire device configuration' },
  { value: Command.Off, text: 'Off', description: 'Powers off the device' },
  { value: Command.Reset, text: 'Reset', description: 'Resets the device.' },
  {
    value: Command.Sysinfo,
    text: 'Sys Info',
    description: 'Return the device system information in JSON format',
  },
  {
    value: Command.SimUpdateTwilioSuper,
    text: 'Update Twilio Super',
    description: 'Updates the Twilio SIM card',
  },
];

/**
 * @return {React.Element} Selector for Snt specific commands.
 */
export function DeviceCommandSelector({
  setSelectedCommand,
  selectedCommand,
}: SendDeviceCommandViewProps) {
  const handleSelectCommandChange = (event: { target: { value: any } }) => {
    setSelectedCommand(event.target.value);
  };

  const helpText =
    SNT_DEVICE_COMMANDS.find(command => command.value === selectedCommand)?.description ?? '';

  return (
    <EuiFormRow
      helpText={helpText}
      label="Select Command"
    >
      <EuiSelect
        id="commandSelect"
        onChange={handleSelectCommandChange}
        options={SNT_DEVICE_COMMANDS}
        value={selectedCommand}
      />
    </EuiFormRow>
  );
}

export default DeviceCommandSelector;
