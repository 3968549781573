import { EuiErrorBoundary } from '@elastic/eui';
import GroupsTable from 'layout/groups/view/groups-table';
import { GenericRow } from 'app/generated/graphql';

import { CreateGroup } from './create/create-group-view';

export type GroupsCrudViewProps = {
  groups: GenericRow[];
  onDelete: (groupTokenId: string) => void;
  onCreate: (groupName: string, groupToken: string, deviceType: string) => void;
  onManage: (type: string) => void;
  isLoading: boolean;
  isCreateOpen: boolean;
  setCreateOpen: (isOpen: boolean) => void;
  organizationId: string;
  selectedGroups: GenericRow[];
  setSelectedGroups: (selection: GenericRow[]) => void;
};

export const GroupsCrudView = (props: GroupsCrudViewProps) => {
  const handleFlyoutClose = () => {
    props.setCreateOpen(false);
  };

  const handleFlyoutOpen = () => {
    props.setCreateOpen(true);
  };

  return (
    <EuiErrorBoundary>
      {props.isCreateOpen ? (
        <CreateGroup
          organizationId={props.organizationId}
          onSubmit={props.onCreate}
          isLoading={props.isLoading}
          closeFlyout={handleFlyoutClose}
        />
      ) : null}
      <GroupsTable
        isLoading={props.isLoading}
        selection={props.selectedGroups}
        setSelection={props.setSelectedGroups}
        showManageButtons
        showDeleteAction
        groups={props.groups}
        onDeleteGroup={props.onDelete}
        onCreateGroup={handleFlyoutOpen}
        onManageSelected={props.onManage}
      />
    </EuiErrorBoundary>
  );
};
