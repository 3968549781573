import { EuiTableFieldDataColumnType, EuiLink } from '@elastic/eui';
import { getLocalTime } from 'app/utils/dates';
import { FotaRequestMessage, StrawberryGroupOperationResponse } from 'gqlHooks';
import _ from 'lodash';
import { renderGroupsStatus } from 'layout/config/snt/snt-config-utils';

const renderFotaType = (rawMessage: FotaRequestMessage) => {
  return rawMessage.type;
};

const renderFotaUrl = (rawMessage: FotaRequestMessage) => {
  const url = rawMessage.url;
  // The groups doesn't include the "firmwareVersion" field, so
  // we are pulling the filename from the URL instead
  const urlText = url.substring(url.lastIndexOf('/') + 1);
  return (
    <EuiLink
      href={url}
      target="_blank"
    >
      {urlText}
    </EuiLink>
  );
};

const groupsRequestStatus: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse> = {
  field: 'requestState',
  name: 'Status',
  dataType: 'string',
  truncateText: false,
  render: renderGroupsStatus,
};

// Date is being sent without the TZ on it. Need to manually convert from utc to our local tz
const renderDate = (date: string) => {
  return date ? getLocalTime(date) : '';
};

const requestType: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse> = {
  field: 'request',
  name: 'Type',
  dataType: 'string',
  truncateText: false,
  width: '10%',
  render: renderFotaType,
};

const requestUrl: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse> = {
  field: 'request',
  name: 'Version',
  dataType: 'string',
  truncateText: false,
  textOnly: true,
  render: renderFotaUrl,
};

const createdAt: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse> = {
  field: 'createdAt',
  name: 'Created',
  dataType: 'date',
  truncateText: false,
  width: '20%',
  render: renderDate,
};

const updatedAt: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse> = {
  field: 'updatedAt',
  name: 'Last updated',
  dataType: 'date',
  truncateText: false,
  width: '20%',
  render: renderDate,
};

export const GROUPS_FOTA_SUMMARY_COLUMNS: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse>[] =
  [groupsRequestStatus, createdAt, updatedAt, requestType, requestUrl];
