import {
  EuiErrorBoundary,
  EuiGlobalToastList,
  EuiSpacer,
  EuiTabbedContent,
  EuiText,
} from '@elastic/eui';
import PageTemplate from 'layout/page/page';
import PageSection from 'layout/section/section';
import { SntConfigTabs } from 'layout/config/snt/snt-config-tabs';
import { DeviceDetailHeader } from 'layout/device-detail-header';
import { SntFota } from 'layout/fota/snt/snt-fota';
import { InstallCheck } from 'layout/install/install-check';
import './deviceDetail.css';
import SntMessages from 'app/layout/messages/snt/messages';
import { useState } from 'react';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { useParams } from 'react-router-dom';

export const enum DeviceDetailTab {
  MESSAGES = 'messages',
  HEALTH = 'health',
  CONFIG = 'config',
  FIRMWARE = 'firmware',
}

export const DeviceDetail = () => {
  const { deviceId = '0' } = useParams<string>();

  const [deviceType, setDeviceType] = useState<string>('');
  const [deviceLabel, setDeviceLabel] = useState<string | null | undefined>('');
  const [toasts, setToasts] = useState<Toast[]>([]);
  const addToastMessage = (message: Toast) => setToasts(toasts.concat(message));
  const removeToast = (removedToast: { id: string }) => {
    setToasts(toasts.filter((toast: Toast) => toast.id !== removedToast.id));
  };

  const tabs = [
    {
      id: DeviceDetailTab.MESSAGES,
      name: DeviceDetailTab.MESSAGES.toUpperCase(),
      content: (
        <EuiErrorBoundary id="messages-tab-error-boundary">
          <EuiSpacer size="m" />
          <SntMessages
            deviceSerial={deviceId}
            addToastMessage={addToastMessage}
          />
          <EuiSpacer size="s" />
        </EuiErrorBoundary>
      ),
    },
    {
      id: DeviceDetailTab.HEALTH,
      name: DeviceDetailTab.HEALTH.toUpperCase(),
      content: (
        <EuiErrorBoundary id="install-tab-error-boundary">
          <EuiSpacer size="xl" />
          <InstallCheck deviceSerial={deviceId} />
          <EuiSpacer size="s" />
        </EuiErrorBoundary>
      ),
    },
    {
      id: DeviceDetailTab.CONFIG,
      name: DeviceDetailTab.CONFIG.toUpperCase(),
      content: (
        <EuiErrorBoundary id="config-tab-error-boundary">
          <EuiSpacer size="xl" />
          <SntConfigTabs
            addToastMessage={addToastMessage}
            deviceSerial={deviceId}
          />
          <EuiSpacer size="s" />
        </EuiErrorBoundary>
      ),
    },
    {
      id: DeviceDetailTab.FIRMWARE,
      name: DeviceDetailTab.FIRMWARE.toUpperCase(),
      content: (
        <EuiErrorBoundary id="fota-tab-error-boundary">
          <EuiSpacer size="xl" />
          <SntFota
            addToastMessage={addToastMessage}
            deviceSerial={deviceId}
          />
          <EuiSpacer size="s" />
        </EuiErrorBoundary>
      ),
    },
  ];

  return (
    <PageTemplate
      documentTitle={`Device ${deviceId}`}
      title={
        <span>
          {deviceType ? `${deviceType} Device` : 'Device'}{' '}
          <EuiText size="m">
            <h4>{deviceLabel}</h4>
          </EuiText>
          <EuiText size="m">{deviceId}</EuiText>
        </span>
      }
    >
      <EuiGlobalToastList
        dismissToast={removeToast}
        toastLifeTimeMs={6000}
        toasts={toasts}
      />
      <PageSection>
        <EuiErrorBoundary id="device-detail-index-header-boundary">
          <DeviceDetailHeader
            deviceId={deviceId}
            setDeviceLabel={setDeviceLabel}
            setDeviceType={setDeviceType}
          />
        </EuiErrorBoundary>
        <EuiTabbedContent
          tabs={tabs}
          expand
        />
      </PageSection>
    </PageTemplate>
  );
};

export default DeviceDetail;
