import { useCallback, useState, useEffect } from 'react';
import { useGetDeviceShadowQuery } from 'gqlHooks';
import { Location as DeviceLocation } from 'models/devices/Location';
import type { GetDeviceShadowQuery } from 'gqlHooks';
import { TimeRangeReverseMapping, TimeRange } from 'utils/dates';
import type { IInstallVerificationTagData } from 'models/install-verification-tags/types';
import { InstallVerificationTags } from 'models/install-verification-tags/InstallVerificationTags';
import { extractGraphQLError, handleApolloError } from 'app/utils/errorHandlers';
import type { ApolloError } from '@apollo/client';

const INSTALLATION_STATUS_TIME_RANGE = TimeRange.TWENTY_FOUR_HOURS;

/* Custom hook to fetch and manage install verification tags */

export type useInstallVerificationTagsProps = {
  deviceId: string;
  setErrorState: (error: string | null) => void;
  setLoadingInstallVerification: (loading: boolean) => void;
};

export const useInstallVerificationTags = (props: useInstallVerificationTagsProps) => {
  const [installVerificationTags, setInstallVerificationTags] = useState<any>();

  const handleError = useCallback(
    (error: ApolloError) => {
      const gqlError = extractGraphQLError(error);
      if (gqlError.statusCode === 404) {
        props.setErrorState(gqlError.errorMessage);
      } else {
        handleApolloError(error, true, gqlError => props.setErrorState(gqlError.errorMessage));
      }
    },
    [props]
  );

  const { loading: loadingInstallVerification } = useGetDeviceShadowQuery({
    variables: {
      deviceId: props.deviceId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: GetDeviceShadowQuery) => {
      if (!data) {
        props.setErrorState(null);
        return;
      }

      const deviceShadow = data?.deviceShadow;

      // Check if deviceShadow exists and has a deviceId
      if (!deviceShadow || !deviceShadow.deviceId) {
        props.setErrorState('Device Data not found');
        return;
      }

      const stolen = deviceShadow?.stolenMode?.data
        ? JSON.parse(deviceShadow.stolenMode.data).value
        : null;
      const battery = deviceShadow?.batteryVoltage?.data
        ? JSON.parse(deviceShadow.batteryVoltage.data).value
        : null;
      const sysinfoData = deviceShadow?.sysinfo?.data;
      const firmware = sysinfoData ? JSON.parse(sysinfoData).app_version : null;

      const gpsLocation = JSON.parse(deviceShadow.gps?.data);
      const lastValidLocation = JSON.parse(deviceShadow.lastGoodGps?.data);
      const location =
        gpsLocation.latitude == '0' && gpsLocation.longitude == '0'
          ? new DeviceLocation({ location: lastValidLocation })
          : new DeviceLocation({ location: gpsLocation });

      const tagData: IInstallVerificationTagData = {
        stolen,
        battery,
        firmware,
        location,
      };

      const tags = new InstallVerificationTags(
        deviceShadow?.deviceId,
        // TODO: Defaulting to snt for now, but this should be handled in the API
        deviceShadow?.deviceType ?? 'snt', // NOTE: Invalid Device Type handled within the InstallVerificationTags class
        tagData,
        TimeRangeReverseMapping[INSTALLATION_STATUS_TIME_RANGE]
      );

      setInstallVerificationTags(tags);
    },
    onError: error => {
      handleError(error);
    },
  });

  useEffect(() => {
    props.setLoadingInstallVerification(loadingInstallVerification);
  }, [loadingInstallVerification, props]);

  return installVerificationTags;
};
