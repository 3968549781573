import { useEffect, useState } from 'react';
import { EuiButton, EuiEmptyPrompt, EuiPageTemplate, EuiSpacer } from '@elastic/eui';
import { SelectOrganization } from 'components/forms/select-organization';
import SelectUser from 'app/components/forms/select-user/select-user';
import { useUserInfo } from 'context/userInfo';
import { hasPermissions } from 'utils/permissions';
import type { GenericRow } from 'gqlHooks';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { TitleBarPanel } from 'app/layout/title-bar';

import { EditUserForm } from '../edit-user-form/edit-user';
import ConfirmDiscardDialog from './confirm-discard';
import { CreateUserForm } from '../create-user-form/create-user';

type ManageUsersProps = {
  /** Method for adding toasts to display status messages to the user */
  addToastMessage: (toasty: Toast) => void;
};

/** Page for managing users - adding/editing/deleting */
export default function ManageUsers(props: ManageUsersProps) {
  const [selectedUser, setSelectedUser] = useState<GenericRow | undefined>();
  const [selectedOrganization, setSelectedOrganization] = useState<string | undefined>();
  const [canDeleteUser, setCanDeleteUser] = useState<boolean>(false);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [isAddingUser, setIsAddingUser] = useState<boolean>(false);

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isUserSelectConfirmOpen, setIsUserSelectConfirmOpen] = useState<boolean>(false);
  const [isDiscardChangesForAddOpen, setIsDiscardChangesForAddOpen] = useState<boolean>(false);
  const [tempUser, setTempUser] = useState<GenericRow | undefined>(undefined);

  const {
    queryData: { userInfo },
  } = useUserInfo();

  // Anytime a selected user enters context, the "add user" from should be closed
  // as we would be editing the user
  useEffect(() => {
    if (selectedUser !== undefined) {
      setIsAddingUser(false);
    }
  }, [selectedUser]);

  // Set canDeleteUser state when userInfo is available
  // As well as the users base organization
  useEffect(() => {
    const userPermissions = userInfo?.permissions ?? [];
    setCanDeleteUser(hasPermissions(userPermissions, ['deactivate:user']));
    const isAdmin = userInfo?.role === 'admin' || userInfo?.role === 'administrator';
    setIsUserAdmin(isAdmin);
    setSelectedOrganization(userInfo?.organizationId);
  }, [userInfo?.permissions, userInfo?.organizationId, userInfo?.role]);

  // Logic for if the user confirms they wish to swap to viewing a different
  // user after having to confirm due to dirty state
  const userSelectionConfirmed = () => {
    setSelectedUser(tempUser);
    setTempUser(undefined);
    setIsUserSelectConfirmOpen(false);
  };

  // Cancelling a user selection event while in a dirty state
  const cancelUserSelection = () => {
    setTempUser(undefined);
    setIsUserSelectConfirmOpen(false);
  };

  // Check if the state is dirty, if it is ask the user before leaving the page
  const handleSelectedUserOnChange = (user: GenericRow | undefined) => {
    if (isDirty) {
      setIsUserSelectConfirmOpen(true);
      setTempUser(user);
    } else {
      setSelectedUser(user);
    }
  };

  // When opening the add user page, remove the user in context for editing (if any)
  const handleOpenAddUser = () => {
    setSelectedUser(undefined);
    setIsDiscardChangesForAddOpen(false);
    setIsAddingUser(true);
  };

  const handleCancelAddUser = () => {
    setIsDiscardChangesForAddOpen(false);
  };

  // Check dirty state to make sure changes aren't being discarded
  const onAddUser = () => {
    isDirty ? setIsDiscardChangesForAddOpen(true) : handleOpenAddUser();
  };

  const handleEditClose = () => {
    setIsDirty(false);
    setSelectedUser(undefined);
  };

  const handleAddClose = () => {
    setIsDirty(false);
    setIsAddingUser(false);
  };

  return (
    <TitleBarPanel
      title="Manage Users"
      isLoading={false}
      iconType="userAvatar"
      sectionId="manage-users-section"
    >
      <EuiPageTemplate
        panelled
        bottomBorder
        grow
        offset={0}
      >
        <ConfirmDiscardDialog
          isOpen={isUserSelectConfirmOpen}
          onConfirm={userSelectionConfirmed}
          onClose={cancelUserSelection}
        />
        <ConfirmDiscardDialog
          isOpen={isDiscardChangesForAddOpen}
          onConfirm={handleOpenAddUser}
          onClose={handleCancelAddUser}
        />
        <EuiPageTemplate.Sidebar
          sticky={true}
          minWidth={350}
        >
          {isUserAdmin ? (
            <SelectOrganization
              label="Select organization"
              selectedOrganization={selectedOrganization}
              onChange={setSelectedOrganization}
            />
          ) : null}
          <EuiSpacer size="s" />
          <SelectUser
            disabled={!selectedOrganization}
            organizationId={selectedOrganization}
            setSelectedUser={handleSelectedUserOnChange}
            onAddUser={onAddUser}
            selectedUser={selectedUser}
          />
        </EuiPageTemplate.Sidebar>
        <EuiPageTemplate.Section grow={false}>
          {selectedUser && !isAddingUser ? (
            <EditUserForm
              addToastMessage={props.addToastMessage}
              setIsDirty={setIsDirty}
              onClose={handleEditClose}
              isAdmin={isUserAdmin}
              selectedUser={selectedUser}
              canDeleteUser={canDeleteUser}
            />
          ) : null}
          {isAddingUser ? (
            <CreateUserForm
              addToastMessage={props.addToastMessage}
              organizationId={selectedOrganization ?? ''}
              isUserAdmin={isUserAdmin}
              onClose={handleAddClose}
              setIsDirty={setIsDirty}
            />
          ) : null}
          {!isAddingUser && !selectedUser ? (
            <EuiEmptyPrompt
              iconType="userAvatar"
              title={<h2>Add users</h2>}
              body={<p>Add a new user or select a user to begin editing.</p>}
              actions={
                <EuiButton
                  id="add-new-user-empty-prompt"
                  color="primary"
                  fill
                  onClick={() => setIsAddingUser(true)}
                >
                  Add user
                </EuiButton>
              }
            />
          ) : null}
        </EuiPageTemplate.Section>
      </EuiPageTemplate>
    </TitleBarPanel>
  );
}
