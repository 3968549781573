import {
  EuiButton,
  EuiCallOut,
  EuiErrorBoundary,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from '@elastic/eui';
import { Control, Controller, useWatch } from 'react-hook-form';
import { CONSTANTS } from 'utils/constants';
import { UpdateDeviceMutationVariables } from 'gqlHooks';
import { SelectOrganization } from 'components/forms/select-organization';
import { SelectDevices } from 'app/components/forms/select-devices';

const ERROR_MESSAGES = {
  deviceIdRequired: 'Device IMEI is required',
  organizationRequired: 'Organization is required',
  labelMinimumLength: `Label must be at least ${CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH} characters`,
  deviceTypeRequired: 'Device type is required',
};

type EditDeviceProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  control: Control<UpdateDeviceMutationVariables>;
  isLoading: boolean;
  isAdmin: boolean;
  handleCalloutDismiss: () => void;
  errorMessage: string | undefined;
  userOrg: string;
};

export default function EditDeviceFromOrganizationFormView(props: EditDeviceProps) {
  // Doing the prevent default as forms like refreshing the page
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit(event);
  };

  // Watching the organizationId field for changes
  const organizationId = useWatch({
    control: props.control,
    name: 'organizationId', // This should match the name of the field you want to watch
  });

  return (
    <EuiErrorBoundary>
      <EuiFlexGrid
        columns={1}
        style={{ width: '100%' }}
      >
        {props.errorMessage && (
          <EuiFlexItem>
            <EuiCallOut
              style={{ minWidth: '100%' }}
              onDismiss={props.handleCalloutDismiss}
              iconType="alert"
              title={'Failed to edit device'}
              color="danger"
            >
              <p>{props.errorMessage}</p>
            </EuiCallOut>
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          <EuiForm
            component="form"
            onSubmit={handleOnSubmit}
            css={{ minWidth: '300px' }}
          >
            {props.isAdmin ? (
              <Controller
                name="organizationId"
                control={props.control}
                rules={{
                  required: { value: true, message: ERROR_MESSAGES.organizationRequired },
                }}
                render={({
                  fieldState: { invalid, error },
                  field: { onChange, onBlur, value },
                }) => (
                  <SelectOrganization
                    label="Organization"
                    isInvalid={invalid}
                    errorMessage={error?.message}
                    onChange={onChange}
                    selectedOrganization={value}
                    onBlur={onBlur}
                  />
                )}
              />
            ) : null}
            <Controller
              name="deviceId"
              control={props.control}
              rules={{
                required: { value: true, message: ERROR_MESSAGES.deviceIdRequired },
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <SelectDevices
                  onChange={onChange}
                  selectedDevice={value}
                  onBlur={onBlur}
                  isInvalid={invalid}
                  organizationId={props.isAdmin ? organizationId : props.userOrg}
                  isRequired
                  isSingleSelect={true}
                  errorMessage={error?.message}
                />
              )}
            />
            <Controller
              name="label"
              control={props.control}
              rules={{
                minLength: {
                  value: CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH,
                  message: ERROR_MESSAGES.labelMinimumLength,
                },
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  label="Device Name"
                  isInvalid={invalid}
                  error={error?.message}
                >
                  <EuiFieldText
                    placeholder="Enter a label"
                    value={value ?? ''}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
            <EuiFormRow hasEmptyLabelSpace>
              <EuiButton
                id="confirm-edit-device-information"
                type="submit"
                fill
                isLoading={props.isLoading}
              >
                Update device
              </EuiButton>
            </EuiFormRow>
            <EuiSpacer size="m" />
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiErrorBoundary>
  );
}
