import { useEffect, useState } from 'react';
import {
  useAddDeviceToGroupMutation,
  useGetGroupDevicesQuery,
  useRemoveDeviceFromGroupMutation,
  GenericRow,
} from 'gqlHooks';
import { useApolloClient } from '@apollo/client';
import { EuiErrorBoundary } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';

import { DevicesCrudView } from './devices-crud-view';

export type DeviceCrudProps = {
  /** Adds a toast to the global message queue */
  addToastMessage: (message: Toast) => void;
  /* Organization that is tied to the user */
  organizationId: string;
  /* Groups within an organization */
  groups: GenericRow[];
  /* True when loading groups tied to the selected organization */
  isLoadingGroups: boolean;
  /* True if the user has admin role */
  isUserAdmin: boolean;
};

export const DevicesCrud = (props: DeviceCrudProps) => {
  /** Hooks */
  const [selectedGroup, setSelectedGroup] = useState<GenericRow | undefined>(undefined);
  const [devices, setDevices] = useState<any[]>([]);
  const [isSingleAddFlyoutOpen, setIsSingleAddFlyoutOpen] = useState(false);
  const [isBulkAddFlyoutOpen, setIsBulkAddFlyoutOpen] = useState(false);
  // Initialize state to track the number of devices being added and the number of completed additions
  const [completedAdditionsCount, setCompletedAdditionsCount] = useState(0);
  const [devicesToAddCount, setDevicesToAddCount] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const client = useApolloClient();

  useEffect(() => {
    if (devicesToAddCount > 0 && completedAdditionsCount === devicesToAddCount) {
      client.refetchQueries({ include: ['GetGroupDevices'] });
      props.addToastMessage(successToastMessage('Successfully added device(s) to group'));
      // Reset the counts and flag
      setCompletedAdditionsCount(0);
      setDevicesToAddCount(0);
      setShowSuccessMessage(false);
    }
  }, [completedAdditionsCount, devicesToAddCount]);

  /// Add a device to the group
  const [addDeviceToGroup, { loading: isAddingDevice }] = useAddDeviceToGroupMutation({
    onCompleted: () => {
      setCompletedAdditionsCount(prevCount => prevCount + 1);
    },
    onError: () => props.addToastMessage(errorToastMessage('Failed to add device to group')),
  });

  // Check if all devices are added and show the toast message once
  if (showSuccessMessage) {
    setShowSuccessMessage(false);
  }

  // Use the count of devicesToAddCount to determine if all devices are processed
  const allDevicesProcessed =
    devicesToAddCount > 0 && devicesToAddCount === completedAdditionsCount;

  // Show the toast message only when all devices are processed
  if (allDevicesProcessed) {
    client.refetchQueries({ include: ['GetGroupDevices'] });
    props.addToastMessage(successToastMessage('Successfully added device(s) to group'));
  }

  // Remove a device from the group
  const [removeDeviceFromGroup, { loading: isRemovingDevice }] = useRemoveDeviceFromGroupMutation({
    onCompleted: () => {
      client.refetchQueries({ include: ['GetGroupDevices'] });
      props.addToastMessage(successToastMessage('Succesfully removed device(s) from group'));
    },
    onError: () => props.addToastMessage(errorToastMessage('Failed to remove device from group')),
  });

  // Load all the devices in the selectedGroup
  const { loading: groupDevicesLoading } = useGetGroupDevicesQuery({
    variables: {
      organizationId: props.organizationId,
      groupToken: selectedGroup?.identifier ?? '',
      limit: 1000,
      recursive: false,
    },
    onError: () => props.addToastMessage(errorToastMessage('Failed to load devices in group')),
    onCompleted: data => {
      setDevices(data?.groupDevices?.items);
      setIsSingleAddFlyoutOpen(false);
    },
  });

  /** Derived values */
  const isWorking =
    groupDevicesLoading || isRemovingDevice || isAddingDevice || props.isLoadingGroups;

  /** Methods */
  // When starting to add new devices, reset the completed additions count and set the number of devices being added
  const onAddDevice = (deviceIds: string[]) => {
    // Reset the counts and flag before adding new devices
    setCompletedAdditionsCount(0);
    setDevicesToAddCount(deviceIds.length);
    setShowSuccessMessage(false);

    deviceIds.forEach(deviceId =>
      addDeviceToGroup({
        variables: {
          organizationId: props.organizationId,
          deviceId: deviceId,
          groupToken: selectedGroup?.identifier ?? '',
        },
      })
    );
  };

  const onRemoveDevice = (deviceid: string) => {
    removeDeviceFromGroup({
      variables: {
        organizationId: props.organizationId,
        deviceId: deviceid,
        groupToken: selectedGroup?.identifier ?? '',
      },
    });
  };

  return (
    <EuiErrorBoundary>
      <DevicesCrudView
        addToastMessage={props.addToastMessage}
        isUserAdmin={props.isUserAdmin}
        isAddingDevices={isAddingDevice}
        isLoading={isAddingDevice || isWorking}
        groups={props.groups}
        organizationId={props.organizationId}
        setSelectedGroup={setSelectedGroup}
        selectedGroup={selectedGroup}
        onAddDevices={onAddDevice}
        devices={devices}
        onDeviceRemoval={onRemoveDevice}
        isAddDeviceOpen={isSingleAddFlyoutOpen}
        isBulkAddDeviceOpen={isBulkAddFlyoutOpen}
        setBulkAddOpen={setIsBulkAddFlyoutOpen}
        setSingleAddOpen={setIsSingleAddFlyoutOpen}
      />
    </EuiErrorBoundary>
  );
};
