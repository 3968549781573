import { Routes, Route } from 'react-router-dom';
import { DeviceListView } from 'views/Devices/DeviceList';
import { DeviceDetail } from 'views/Devices/DeviceDetail';

export const DeviceRouter = () => (
  <Routes>
    <Route
      index
      element={<DeviceListView />}
    />
    <Route
      path=":deviceId"
      element={<DeviceDetail />}
    />
  </Routes>
);
