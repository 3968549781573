import {
  EuiErrorBoundary,
  EuiFormRow,
  EuiText,
  EuiForm,
  EuiFieldNumber,
  EuiDescribedFormGroup,
  EuiRadioGroup,
} from '@elastic/eui';
import { Configuration } from 'app/generated/graphql';
import { Control, Controller, useWatch } from 'react-hook-form';

import * as MOTION_CONSTANTS from './motion-config-defaults';
import { ConfigPanel } from '../../panel/config-panel';
import { parseNumberOnChange } from '../snt-config-utils';

interface SntMotionConfigViewProps {
  /** Used for handling the state of the panel. Collapsed or expanded. */
  isExpanded: boolean;
  /** react-hook-form controller for monitoring form state */
  control: Control<Configuration>;
}

// Groups version has NEITHER option selected, so we have to use radio
// buttons instead of an on/off switch
const DISABLED_ID = `motion-disabled`;
const ENABLED_ID = `motion-enabled`;
const RADIO_BUTTONS = [
  { id: DISABLED_ID, label: 'Disable' },
  { id: ENABLED_ID, label: 'Enable' },
];

/** Motion detection and reporting configuration fields for a Slap and Track device */
export const SntMotionConfig = (props: SntMotionConfigViewProps) => {
  // Report interval "disables" multiple fields when toggled so watching to pass to all needed controllers
  const reportIntervalValue = useWatch({
    control: props.control,
    name: 'motion.reportInterval',
    defaultValue: MOTION_CONSTANTS.REPORT_INTERVAL_MIN, // default value before the render
  });

  /** Derived Values */
  const reportIntervalEnabled =
    // @ts-expect-error empty number form fields are empty string
    reportIntervalValue != MOTION_CONSTANTS.REPORT_INTERVAL_DISABLED && reportIntervalValue !== '';

  /** Methods */
  const handleReportIntervalEnabledToggle = (
    radioValue: string,
    setValue: (value: number) => void
  ) => {
    radioValue === DISABLED_ID
      ? setValue(MOTION_CONSTANTS.REPORT_INTERVAL_DISABLED)
      : setValue(MOTION_CONSTANTS.REPORT_INTERVAL_MIN);
  };

  const conjureSelectedId = (currentValue: number | string) => {
    // Start with the strict string comparison for empty string
    if (currentValue === '') {
      return '';
    }
    // configuration loads in with string '0' instead of number 0
    // 0 == '0' is truthy, 0 === '0' is falsey, 0 == '' is truthy
    if (MOTION_CONSTANTS.REPORT_INTERVAL_DISABLED == currentValue) {
      return DISABLED_ID;
    }
    return ENABLED_ID;
  };

  return (
    <EuiErrorBoundary>
      <EuiForm component="form">
        <ConfigPanel
          expandAll={props.isExpanded}
          title="Motion Reporting"
          icon="mapMarker"
          description="How the tracker reports asset movement"
          isLoading={false}
        >
          <Controller
            name="motion.reportInterval"
            control={props.control}
            // Disabled value is zero, actual min is 120
            rules={{
              min: MOTION_CONSTANTS.REPORT_INTERVAL_DISABLED,
              max: MOTION_CONSTANTS.REPORT_INTERVAL_MAX,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <EuiDescribedFormGroup
                title={<h3>Motion Report Interval</h3>}
                description={
                  <EuiText size="s">
                    <p> The interval in seconds between motion reports while in motion.</p>
                    <p>Default: Disabled</p>
                  </EuiText>
                }
              >
                <EuiFormRow label="Motion Reporting Enabled">
                  <EuiRadioGroup
                    options={RADIO_BUTTONS}
                    idSelected={conjureSelectedId(value!)}
                    onChange={radioValue => handleReportIntervalEnabledToggle(radioValue, onChange)}
                    name="enabled-disable-toggle"
                  />
                </EuiFormRow>
                <EuiFormRow
                  label="Report Interval"
                  helpText={`Valid range: ${MOTION_CONSTANTS.REPORT_INTERVAL_MIN} - ${MOTION_CONSTANTS.REPORT_INTERVAL_MAX} seconds`}
                >
                  <EuiFieldNumber
                    onBlur={onBlur}
                    min={
                      // To avoid having the field marked as invalid when disabled
                      reportIntervalEnabled
                        ? MOTION_CONSTANTS.REPORT_INTERVAL_MIN
                        : MOTION_CONSTANTS.REPORT_INTERVAL_DISABLED
                    }
                    max={MOTION_CONSTANTS.REPORT_INTERVAL_MAX}
                    value={value!}
                    disabled={!reportIntervalEnabled}
                    onChange={event => onChange?.(parseNumberOnChange(event))}
                    append={['seconds']}
                  />
                </EuiFormRow>
              </EuiDescribedFormGroup>
            )}
          />
          <EuiDescribedFormGroup
            title={<h3>Stop Timer</h3>}
            description={
              <EuiText size="s">
                <p>
                  The amount of time the device must be stationary before a stop report is issued.
                  This stops the device from reporting motion stop at every stop sign.
                </p>
                <p>{`Default: ${MOTION_CONSTANTS.STOP_TIMER_DEFAULT} seconds`}</p>
              </EuiText>
            }
          >
            <Controller
              name="motion.stopTimer"
              control={props.control}
              rules={{
                min: MOTION_CONSTANTS.STOP_TIMER_MIN,
                max: MOTION_CONSTANTS.STOP_TIMER_MAX,
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  label="Motion Stop Timer"
                  isInvalid={invalid}
                  isDisabled={!reportIntervalEnabled}
                  error={error?.message}
                  helpText={`Valid range: ${MOTION_CONSTANTS.STOP_TIMER_MIN} - ${MOTION_CONSTANTS.STOP_TIMER_MAX} seconds `}
                >
                  <EuiFieldNumber
                    onBlur={onBlur}
                    min={MOTION_CONSTANTS.STOP_TIMER_MIN}
                    max={MOTION_CONSTANTS.STOP_TIMER_MAX}
                    value={value!}
                    disabled={!reportIntervalEnabled}
                    onChange={event => onChange?.(parseNumberOnChange(event))}
                    append={['seconds']}
                  />
                </EuiFormRow>
              )}
            />
          </EuiDescribedFormGroup>
          <EuiDescribedFormGroup
            title={<h3>Motion Threshold</h3>}
            description={
              <EuiText size="s">
                <p>
                  Threshold for detecting motion. Decreasing this value will increase the
                  sensitivity of motion sensing. Increasing will decrease the sensitivity of motion
                  sensing.
                </p>
                <p>{`Default: ${MOTION_CONSTANTS.THS_DEFAULT} milliG`}</p>
              </EuiText>
            }
          >
            <Controller
              name="motion.ths"
              control={props.control}
              rules={{
                min: MOTION_CONSTANTS.THS_MIN,
                max: MOTION_CONSTANTS.THS_MAX,
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  isDisabled={!reportIntervalEnabled}
                  isInvalid={invalid}
                  error={error?.message}
                  label="Motion Threshold"
                  helpText={`Valid range: ${MOTION_CONSTANTS.THS_MIN} - ${MOTION_CONSTANTS.THS_MAX} milliG`}
                >
                  <EuiFieldNumber
                    onBlur={onBlur}
                    min={MOTION_CONSTANTS.THS_MIN}
                    max={MOTION_CONSTANTS.THS_MAX}
                    value={value!}
                    disabled={!reportIntervalEnabled}
                    onChange={event => onChange?.(parseNumberOnChange(event))}
                    append={['milliG']}
                  />
                </EuiFormRow>
              )}
            />
          </EuiDescribedFormGroup>
        </ConfigPanel>
      </EuiForm>
    </EuiErrorBoundary>
  );
};
