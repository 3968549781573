import type { DeviceShadowResponse as DeviceShadow } from 'app/generated/graphql';
import { celsiusToFahrenheit } from 'utils/transformations';
import { CONSTANTS } from 'utils/constants';
import { Location } from 'models/devices/Location';
import { Device } from 'models/devices/Device';
import { getRelativeTimeFromNow } from 'app/utils/dates';
import type { DeviceDefaults } from 'models/devices/Device';

const { TEXT_PLACEHOLDER } = CONSTANTS;

type SlapAndTrackDeviceMeta = DeviceDefaults & {
  id: string;
  type: string;
  vendor: string;
  lastPing: string;
};

type SlapAndTrackDeviceDetails = Pick<SlapAndTrackDeviceMeta, 'id' | 'type' | 'vendor'> & {
  source: string;
};

type SlapAndTrackDeviceStatus = {
  battery: number;
  location: Location;
  lastCheckIn: {
    lastCheckIn: string;
    formattedLastCheckin: string;
  };
  motionStatus: string;
  temperature: {
    celsius: number;
    fahrenheit: number;
  };
};

/**
 * Device model for the Slap And Track device
 */
class SlapAndTrackDevice extends Device {
  /**
   * @static defaultMessageData defaults
   */
  private static defaultMessageData = {
    battery_voltage: 0,
    gps: { latitude: 0, longitude: 0 },
    motion_status: 0,
    temperature: 0,
  };
  protected shadowResponse: DeviceShadow;

  constructor(attributes = {}, shadowResponse: DeviceShadow) {
    super(attributes);
    this.shadowResponse = shadowResponse;
  }

  /**
   * Retrieves device meta data useful
   * for displaying in the UI
   *
   * @getter meta
   */
  get meta(): SlapAndTrackDeviceMeta {
    return {
      id: this.id ?? this.defaults.id,
      type: 'Slap And Track',
      vendor: 'Morey Inc',
      lastPing: this.lastPing,
    };
  }

  /**
   * Retrieves device details data
   * for use in the DeviceDetail view
   *
   * @getter deviceDetails
   */
  get deviceDetails(): SlapAndTrackDeviceDetails {
    const { id, type, vendor } = this.meta;
    return {
      id,
      type,
      vendor,
      source: TEXT_PLACEHOLDER,
    };
  }

  /**
   * Retrieves device status data
   * for use in the DeviceStatus view
   *
   * @getter deviceStatus
   */
  get deviceStatus(): SlapAndTrackDeviceStatus {
    return {
      battery: this.extractBatteryVoltage(),
      lastCheckIn: {
        lastCheckIn: getRelativeTimeFromNow(this.extractLastCheckin()),
        formattedLastCheckin: SlapAndTrackDevice.formattedDate(this.extractLastCheckin()),
      },
      location: this.extractLastKnownLocation(),
      motionStatus: this.extractMotionStatus(),
      temperature: {
        celsius: this.extractTemperature(),
        fahrenheit: celsiusToFahrenheit(this.extractTemperature()),
      },
    };
  }

  private extractBatteryVoltage(): number {
    const { batteryVoltage } = this.shadowResponse;
    if (batteryVoltage && batteryVoltage.data) {
      const batteryData = JSON.parse(batteryVoltage.data);
      return parseFloat(batteryData.value);
    }
    return 0;
  }

  private extractLastKnownLocation(): Location {
    const { lastGoodGps, gps } = this.shadowResponse;
    if (gps && gps.data) {
      const gpsData = JSON.parse(gps.data);
      if (gpsData.longitude == '0' && gpsData.latitude == '0' && lastGoodGps) {
        const lastKnowLocation = JSON.parse(lastGoodGps.data);
        return new Location({ location: lastKnowLocation });
      } else if (gpsData.longitude !== '0' && gpsData.latitude !== '0') {
        return new Location({ location: gpsData });
      }
    }
    return new Location({});
  }

  private extractMotionStatus(): string {
    const { motionStatus } = this.shadowResponse;
    if (motionStatus && motionStatus.data) {
      const status = JSON.parse(motionStatus.data);
      return status.value == 'n/a' ? 'disabled' : status.value;
    }

    // Default to empty string if motion status cannot be determined
    return 'disabled';
  }

  private extractLastCheckin(): string {
    const { lastCheckin } = this.shadowResponse;
    if (lastCheckin && lastCheckin.data) {
      const status = JSON.parse(lastCheckin.data);
      return status;
    }

    // Default to empty string if last checkin status cannot be determined
    return 'n/a';
  }

  private extractTemperature(): number {
    const { temperature } = this.shadowResponse;
    if (temperature && temperature.data) {
      const temp = JSON.parse(temperature.data);
      return temp.value;
    }

    // Default to empty string if temperature  cannot be determined
    return 0;
  }
}

export { SlapAndTrackDevice };
