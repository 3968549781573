import { useEffect, useState } from 'react';
import {
  EuiDescriptionList,
  EuiFlexGroup,
  EuiErrorBoundary,
  EuiFlexItem,
  EuiLink,
  EuiFlyout,
  EuiSpacer,
  EuiText,
  EuiButtonIcon,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiComboBoxOptionOption,
  EuiButton,
  EuiForm,
  EuiHorizontalRule,
  EuiSelectOption,
} from '@elastic/eui';
import { SlapAndTrackDevice } from 'models/devices/slap-and-track-device/SlapAndTrackDevice';
import TrackerMap from 'layout/maps/trackerMap';
import { DeviceDetailAccordion } from 'layout/device-detail-header/device-detail-accordion';
import { DeviceDetailPanel } from 'layout/device-detail-header/device-detail-panel';
import { InstallBadge } from 'layout/install/entry/badge/badge';
import type { ITag, IInstallVerificationTags } from 'models/install-verification-tags/types';
import {
  useAddDeviceToGroupMutation,
  useRemoveDeviceFromGroupMutation,
} from 'app/generated/graphql';
import { useApolloClient } from '@apollo/client';

import { SelectGroup } from '../groups/select/groups';
import { SelectAssignedGroup } from '../groups/select/groups/assigned-groups';

type DeviceStatusInfoPanelProps = {
  device: SlapAndTrackDevice;
  groups: EuiSelectOption[] | undefined;
  tagData?: IInstallVerificationTags;
  loading: boolean;
  firstRender: boolean;
  userOrg: string;
};

export const DeviceStatusInfoPanel = (props: DeviceStatusInfoPanelProps) => {
  const [tags, setTags] = useState<Array<ITag>>([]);
  const [groupsToAdd, setGroupsToAdd] = useState<EuiComboBoxOptionOption[]>([]);
  const [groupsToRemove, setGroupsToRemove] = useState<EuiComboBoxOptionOption[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const client = useApolloClient();
  const handleAddGroupSelect = (group: EuiComboBoxOptionOption[]) => {
    setGroupsToAdd(group);
  };

  const handleRemoveGroupSelect = (group: EuiComboBoxOptionOption[]) => {
    setGroupsToRemove(group);
  };

  // Add a device to the group
  const [addDeviceToGroup, { loading: isAddingDevice }] = useAddDeviceToGroupMutation({
    onCompleted: () => {
      client.refetchQueries({ include: ['GetDeviceGroups', 'GetDeviceShadow'] });
      setIsOpen(false);
      setGroupsToAdd([]);
    },
    // onError: () => props.addToastMessage(errorToastMessage('Failed to add device to group')),
  });

  // Remove a device from the group
  const [removeDeviceFromGroup, { loading: isRemovingDevice }] = useRemoveDeviceFromGroupMutation({
    onCompleted: () => {
      client.refetchQueries({ include: ['GetDeviceGroups', 'GetDeviceShadow'] });
      setIsOpen(false);
      setGroupsToRemove([]);
    },
    // onError: () => props.addToastMessage(errorToastMessage('Failed to remove device from group')),
  });

  /** Methods */
  const onAddDevice = () => {
    addDeviceToGroup({
      variables: {
        organizationId: props.userOrg,
        deviceId: props.device.defaults.id,
        groupToken: (groupsToAdd[0]?.value as string) ?? '',
      },
    });
  };

  const onRemoveDevice = () => {
    removeDeviceFromGroup({
      variables: {
        organizationId: props.userOrg,
        deviceId: props.device.defaults.id,
        groupToken: (groupsToRemove[0]?.label as string) ?? '',
      },
    });
  };

  const deviceGroupsForm = (
    <EuiErrorBoundary id="device-groups-form">
      <EuiFlyout
        ownFocus
        onClose={() => setIsOpen(false)}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Update group assignments for device</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiForm>
            <EuiText>
              <h4>Add to group</h4>
            </EuiText>
            <EuiSpacer size="m" />
            <SelectGroup
              isSingleSelect
              onChange={handleAddGroupSelect}
              selectedGroups={groupsToAdd}
              organizationId={props.userOrg}
              label="Select Group"
            />
            <EuiButton
              id="add-device-to-group"
              type="submit"
              color="primary"
              fill
              isLoading={isAddingDevice}
              isDisabled={isAddingDevice || groupsToAdd.length < 1}
              onClick={onAddDevice}
            >
              Add
            </EuiButton>
            <EuiHorizontalRule />
            <EuiText>
              <h4>Remove from group</h4>
            </EuiText>
            <EuiSpacer size="m" />
            <SelectAssignedGroup
              isSingleSelect
              onChange={handleRemoveGroupSelect}
              selectedGroups={groupsToRemove}
              deviceId={props.device.defaults.id}
              label="Select Group"
            />
            <EuiButton
              id="remove-device-from-group"
              onClick={onRemoveDevice}
              type="submit"
              fill
              color="danger"
              isLoading={isRemovingDevice}
              isDisabled={isRemovingDevice || groupsToRemove.length < 1}
            >
              Remove
            </EuiButton>
          </EuiForm>
        </EuiFlyoutBody>
      </EuiFlyout>
    </EuiErrorBoundary>
  );

  useEffect(() => {
    if (!props.tagData) return;
    setTags(props.tagData.tags);
  }, [props.tagData]);

  const deviceStatusData = [
    {
      title: 'Last Check-in',
      description: (
        <EuiText size="s">
          <EuiText css={{ fontWeight: 700 }}>
            {props.device.deviceStatus.lastCheckIn.lastCheckIn}
          </EuiText>
          <EuiText size="xs">{props.device.deviceStatus.lastCheckIn.formattedLastCheckin}</EuiText>
        </EuiText>
      ),
    },
    {
      title: 'Motion Status',
      description: props.device.deviceStatus.motionStatus,
    },
    {
      title: 'Battery',
      description: `${props.device.deviceStatus.battery} Volts`,
    },
    {
      title: 'Temperature',
      description: `${props.device.deviceStatus.temperature.celsius}\xB0C | ${props.device.deviceStatus.temperature.fahrenheit}\xB0F`,
    },
    {
      title: 'Last Known Location',
      description:
        props.device.deviceStatus.location.shortenedCoords.shortenedLat === '0.00' &&
        props.device.deviceStatus.location.shortenedCoords.shortenedLng === '0.00' ? (
          'Unknown'
        ) : (
          <EuiLink
            href={props.device.deviceStatus.location.googleMapsUrl}
            target="_blank"
            external
          >
            {`${props.device.deviceStatus.location.shortenedCoords.shortenedLat},${props.device.deviceStatus.location.shortenedCoords.shortenedLng}`}
          </EuiLink>
        ),
    },
    {
      title: (
        <div>
          Groups
          <EuiButtonIcon
            id="edit-groups-device-belongs-to"
            iconType="pencil"
            aria-label="Edit Groups"
            color="text"
            onClick={() => {
              setIsOpen(true);
            }}
          />
        </div>
      ),
      description:
        props.groups && props.groups.length > 0 ? (
          <ul>
            {props.groups.map((group, index) => (
              <li key={index}>{group.text}</li>
            ))}
          </ul>
        ) : (
          'No assigned groups'
        ),
    },
  ];

  return (
    <EuiErrorBoundary id="device-status-info-panel">
      <DeviceDetailAccordion
        id="device-status-accordion"
        title="Device Status"
      >
        <DeviceDetailPanel
          loading={props.loading}
          firstRender={props.firstRender}
        >
          {isOpen ? deviceGroupsForm : null}
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiDescriptionList
                compressed
                listItems={deviceStatusData ?? []}
                textStyle="reverse"
                type="responsiveColumn"
              />
              <EuiSpacer size="l" />
              {<EuiText size="s">Status as of last check-in:</EuiText>}
              <EuiSpacer size="s" />
              <InstallBadge
                tags={tags}
                wrap
                alignItems="flexStart"
                justifyContent="flexStart"
              />
            </EuiFlexItem>
            <EuiFlexItem
              grow={2}
              style={{ minHeight: 250 }}
            >
              <TrackerMap
                center={props.device.deviceStatus.location.center}
                markerTitle={`Device IMEI: ${props.device.meta.id}`}
                markerDetails={
                  <>
                    <EuiText>{`Accuracy of ${props.device.deviceStatus.location.accuracy} meters`}</EuiText>
                    <EuiText>{`Altitude of ${props.device.deviceStatus.location.altitude} meters`}</EuiText>
                  </>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </DeviceDetailPanel>
      </DeviceDetailAccordion>
    </EuiErrorBoundary>
  );
};
