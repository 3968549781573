import { defaultsDeep } from 'lodash';
import { getLocalTime } from 'utils/dates';
import { CONSTANTS } from 'utils/constants';

/**
 * Abstract class for initializing a model with varying attributes
 *
 * @abstract
 * @static assertIsValidLength - Asserts that a given string is a valid length
 * @static formattedDate - Returns a formatted date
 */
abstract class Base {
  [x: string]: any;

  /**
   * Asserts that a given string is a valid length
   */
  static assertIsValidLength(el: string, len: number, descriptor: string) {
    const errMsg = `Given ${descriptor}: ${
      el ?? 'undefined'
    } is invalid. '${descriptor} must be ${len} characters long.`;
    if (!el) throw Error(errMsg);
    const elString = el.toString();
    const isValid = el && elString && elString.trim().length >= len;
    if (!isValid) {
      throw Error(errMsg);
    }
  }
  /**
   * Asserts that an object exists and has a given property and has a valid length
   */
  static assertPropertyExistsOfLength(obj: { [key: string]: any }, len: number, prop: string) {
    if (!obj || !obj[prop]) {
      throw Error(`Given object: ${obj} is invalid. '${prop}' is missing.`);
    } else {
      Base.assertIsValidLength(obj[prop], len, prop);
    }
  }
  /**
   * Returns a formatted date as 'MM/DD/YYYY hh:mm:ss'
   */
  static formattedDate(dt?: string | { value: string } | null) {
    if (!dt) return CONSTANTS.TEXT_PLACEHOLDER;
    const dateStr = typeof dt === 'string' ? dt : dt.value;
    if (!dateStr) return CONSTANTS.TEXT_PLACEHOLDER;
    return getLocalTime(dateStr);
  }
  /**
   * Takes an object of attributes and merges them with the instance
   * using defaultsDeep to recursively assign default properties.
   * `attributes` are open to any key/value pair allowing more specific
   * models to extend this class and expose their own attributes.
   */
  constructor(attributes = {}) {
    defaultsDeep(this, attributes);
  }
}

export { Base };
