import {
  EuiErrorBoundary,
  EuiFormRow,
  EuiForm,
  EuiText,
  EuiFieldNumber,
  EuiDescribedFormGroup,
} from '@elastic/eui';
import { SntTimeSelector } from 'layout/config/snt/time-selector/time-selector';
import { Configuration } from 'app/generated/graphql';
import { Control, Controller } from 'react-hook-form';

import * as PERIODIC_CONSTANTS from './periodic-config-defaults';
import { ConfigPanel } from '../../panel/config-panel';
import { parseNumberOnChange } from '../snt-config-utils';

interface SntPeriodicConfigViewProps {
  /** Used for handling the state of the panel. Collapsed or expanded. */
  isExpanded: boolean;
  /** react-hook-form controller for monitoring form state */
  control: Control<Configuration>;
}

/** Configuration for how the SnT should generate the periodic reports */
export const SntPeriodicConfig = (props: SntPeriodicConfigViewProps) => {
  return (
    <EuiErrorBoundary>
      <EuiForm component="form">
        <ConfigPanel
          expandAll={props.isExpanded}
          title="Periodic Reporting"
          icon="timeline"
          description="When and how often the tracker should checkin"
          isLoading={false}
        >
          <EuiDescribedFormGroup
            title={<h3>Reporting Interval</h3>}
            description={
              <EuiText size="s">
                <p>Determines how often the device sends periodic reports.</p>
                <p>{`Default: Every ${PERIODIC_CONSTANTS.REPORT_INTERVAL_DEFAULT} seconds`}</p>
              </EuiText>
            }
          >
            <Controller
              name="periodic.reportInterval"
              control={props.control}
              rules={{
                min: PERIODIC_CONSTANTS.REPORT_INTERVAL_MIN,
                max: PERIODIC_CONSTANTS.REPORT_INTERVAL_MAX,
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  isInvalid={invalid}
                  error={error?.message ?? ''}
                  label="Periodic Reporting Interval"
                  helpText={`Valid range: ${PERIODIC_CONSTANTS.REPORT_INTERVAL_MIN} - ${PERIODIC_CONSTANTS.REPORT_INTERVAL_MAX} seconds`}
                >
                  <EuiFieldNumber
                    min={PERIODIC_CONSTANTS.REPORT_INTERVAL_MIN}
                    max={PERIODIC_CONSTANTS.REPORT_INTERVAL_MAX}
                    value={value!}
                    append={['seconds']}
                    onChange={event => onChange?.(parseNumberOnChange(event))}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
          </EuiDescribedFormGroup>
          <EuiDescribedFormGroup
            title={<h3>Fixed Reporting Time</h3>}
            description={
              <EuiText size="s">
                <p>
                  Sets a time the tracker should always attempt to report in at. If enabled, the
                  report interval will use this as a start time. Fixed reporting time will always be
                  honored, even if it means the preceding report interval is truncated.
                </p>
                <p>Default: Disabled</p>
              </EuiText>
            }
          >
            <Controller
              name="periodic.reportInitialTime"
              control={props.control}
              rules={{
                min: 0,
                max: 9999,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <SntTimeSelector
                  onBlur={onBlur}
                  disabledValue={PERIODIC_CONSTANTS.INITIAL_TIME_DISABLED}
                  toggleTitle={'Fixed Report Time Enabled'}
                  timeFieldTitle="Fixed Report Time"
                  includeToggle
                  setTimeValue={onChange}
                  timeValue={value!}
                />
              )}
            />
          </EuiDescribedFormGroup>
          <EuiDescribedFormGroup
            title={<h3>Report Offset</h3>}
            description={
              <EuiText size="s">
                <p>
                  The maximum offset from the report time to allow for variance in reporting time.
                  Helps to relieve network congestion.
                </p>
                <p>{`Default: ${PERIODIC_CONSTANTS.OFFSET_DEFAULT} minutes`}</p>
              </EuiText>
            }
          >
            <Controller
              name="periodic.reportInitialOffset"
              control={props.control}
              rules={{
                min: PERIODIC_CONSTANTS.OFFSET_MIN,
                max: PERIODIC_CONSTANTS.OFFSET_MAX,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  label="Report Offset"
                  helpText={`Valid range: ${PERIODIC_CONSTANTS.OFFSET_MIN} - ${PERIODIC_CONSTANTS.OFFSET_MAX} minutes`}
                >
                  <EuiFieldNumber
                    min={PERIODIC_CONSTANTS.OFFSET_MIN}
                    max={PERIODIC_CONSTANTS.OFFSET_MAX}
                    value={value!}
                    onBlur={onBlur}
                    append={['minutes']}
                    onChange={event => onChange?.(parseNumberOnChange(event))}
                  />
                </EuiFormRow>
              )}
            />
          </EuiDescribedFormGroup>
        </ConfigPanel>
      </EuiForm>
    </EuiErrorBoundary>
  );
};
