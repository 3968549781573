import { useCallback, useState } from 'react';
import { noop } from 'lodash';
import {
  EuiButtonEmpty,
  EuiDescriptionList,
  EuiErrorBoundary,
  EuiFormRow,
  EuiPopover,
  EuiPopoverTitle,
  EuiSelect,
  useGeneratedHtmlId,
} from '@elastic/eui';
import { Role, useGetUserRolesQuery } from 'gqlHooks';
import { ErrorMessage } from 'layout/utility/errorMessage/errorMessage';
import { extractGraphQLError, handleApolloError } from 'utils/errorHandlers';
import { capitalize } from 'utils/transformations';
import type { EuiSelectOption } from '@elastic/eui';
import type { ApolloError } from '@apollo/client';

export type SelectRoleProps = {
  /**  Notified when a change occurs in the selected role */
  onChange: (event: React.ChangeEvent<Element>) => void;
  /** Nofies when the control is blurred so validation can be run */
  onBlur?: (event: string | React.ChangeEvent<Element>) => void;
  /** Label to display explaining what this form selector is for */
  label: string;
  /** True if the invalid display should be shown*/
  isInvalid: boolean;
  /** Message to display if isInvalid is true*/
  error: string | undefined;
  /**Currently selected role*/
  selectedRole?: Role | undefined;
  /** True if this form selector should be disabled */
  isDisabled?: boolean;
};

export type RoleDescription = {
  title: string;
  description: string;
};

/** Selector for getting a user role */
export const SelectRole = (props: SelectRoleProps) => {
  const [errorState, setErrorState] = useState<string | null>(null);
  const [options, setOptions] = useState<EuiSelectOption[]>([]);
  const [roleDescriptions, setRoleDescriptions] = useState<Array<RoleDescription>>([]);
  const [isRoleDetailsOpen, setIsRoleDetailsOpen] = useState<boolean>(false);

  // Handler for parsing and displaying role loading issues
  const handleError = useCallback((error: ApolloError) => {
    const gqlError = extractGraphQLError(error);
    if (gqlError.statusCode === 404) {
      setErrorState(gqlError.errorMessage);
    } else {
      handleApolloError(error, true, gqlError => setErrorState(gqlError.errorMessage));
    }
  }, []);

  const { loading: rolesLoading, error } = useGetUserRolesQuery({
    onError: handleError,
    onCompleted: data => {
      setRoleDescriptions(
        data?.roles?.roles?.map(role => ({
          title: role?.name ?? '',
          description: role?.description ?? '',
        })) ?? []
      );

      const opts = [
        ...data.roles.roles.map(role => ({
          text: capitalize(role?.name ?? ''),
          value: role?.id ?? '',
        })),
      ];

      setOptions(opts);
    },
  });

  const handleOpenRoleDetails = () => {
    setIsRoleDetailsOpen(true);
  };

  const handleCloseRoleDetails = () => {
    setIsRoleDetailsOpen(false);
  };

  const errorLoading = error ? 'Failed to load organizations' : '';
  const errors = [errorLoading, props.error];

  const renderError = errorState ? (
    <ErrorMessage
      isHidden={false}
      message={errorState}
    />
  ) : null;

  return (
    <EuiErrorBoundary>
      <EuiFormRow
        label={props.label}
        isInvalid={props.isInvalid || errorLoading !== ''}
        error={errors}
        aria-required
        labelAppend={
          <EuiPopover
            isOpen={isRoleDetailsOpen}
            closePopover={handleCloseRoleDetails}
            anchorPosition="rightUp"
            offset={8}
            button={
              <EuiButtonEmpty
                id="view-role-details"
                onClick={handleOpenRoleDetails}
                size="xs"
              >
                View role details
              </EuiButtonEmpty>
            }
          >
            <EuiPopoverTitle>Role Details</EuiPopoverTitle>
            <div style={{ width: '300px' }}>
              <EuiDescriptionList
                listItems={roleDescriptions}
                compressed
              />
            </div>
          </EuiPopover>
        }
      >
        <EuiSelect
          id={useGeneratedHtmlId({ prefix: 'selectRole' })}
          options={options}
          value={props.selectedRole}
          hasNoInitialSelection
          onChange={props.onChange}
          onBlur={props.onBlur ?? noop}
          aria-label={`Select ${props.label ?? 'role'}`}
          disabled={props.isDisabled}
          isLoading={rolesLoading}
        />
      </EuiFormRow>
      {renderError}
    </EuiErrorBoundary>
  );
};
