import {
  EuiErrorBoundary,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
} from '@elastic/eui';
import { useEffect, useState } from 'react';
import { useAddDeviceToGroupMutation } from 'app/generated/graphql';
import {
  DeviceUploadRow,
  DEVICE_ID,
} from 'app/layout/access-management/add-device-to-organization-form/upload/constants';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

import FileUploadToGroupBox from './upload-devices-to-group-view';

export type UploadDevicesToGroupProps = {
  /** toaster strudel */
  addToastMessage: (message: Toast) => void;
  /** True if the add device dialogue should be open */
  isBulkAddOpen: boolean;
  /** Id of the organization the user belongs to, used to load devices */
  organizationId: string;
  /** Id token of the group the device is being added to */
  groupToken: string;
  /** Display label of the group */
  groupLabel: string;
  /** Invoked when the user submits an add device to group request */
  onSubmit: (deviceIds: string[], groupId: string) => void;
  /** True if there is data loading or a device being added */
  isLoading?: boolean;
  /** Invoked when the flyout requests a close or exit */
  closeFlyout: () => void;
  isUserAdmin: boolean;
};

export const UploadDevicesToGroup = (props: UploadDevicesToGroupProps) => {
  // List of jobs that should be run
  const [jobsToRun, setJobsToRun] = useState<DeviceUploadRow[]>([]);
  // Jobs that returned a success status
  const [successfulJobs, setSuccessfulJobs] = useState<DeviceUploadRow[]>([]);
  // Jobs that either errored out or returned a failed status
  const [failedJobs, setFailedJobs] = useState<DeviceUploadRow[]>([]);
  // True if there are jobs currently running
  const [isWorking, setIsWorking] = useState<boolean>(false);
  // Data of the job that is currently executing
  const [currentJob, setCurrentJob] = useState<DeviceUploadRow | undefined>(undefined);
  // Rows that were parsed from the csv that contain data
  const [validRows, setValidRows] = useState<DeviceUploadRow[]>([]);
  // Rows that were parsed from the csv that do not contain data
  const [invalidRows, setInvalidRows] = useState<DeviceUploadRow[]>([]);

  // Updates the queue with the new job, and removes it from the remaining jobs queue
  // Can't just for loop this or the UI will seize up
  const dropFirstItemFromJobs = () => {
    const newJobs = [...jobsToRun];
    const nextJob = newJobs.shift();
    setJobsToRun(newJobs);
    setCurrentJob(nextJob);
  };

  const [addDevicesToGroup] = useAddDeviceToGroupMutation({
    onCompleted: () => {
      if (currentJob !== undefined) {
        setSuccessfulJobs(prevState => [currentJob, ...prevState]);
      }
      dropFirstItemFromJobs();
    },
    onError: () => {
      if (currentJob !== undefined) {
        setFailedJobs(prevState => [currentJob, ...prevState]);
      }
      dropFirstItemFromJobs();
    },
  });

  useEffect(() => {
    if (currentJob !== undefined) {
      setIsWorking(prevState => (!prevState ? true : prevState));
      addDevicesToGroup({
        variables: {
          organizationId: props.organizationId,
          deviceId: currentJob[DEVICE_ID],
          groupToken: props.groupToken,
        },
      });
    } else {
      setIsWorking(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJob]);

  // Reset the success/fail to empty when the valid or invalid rows are changed
  useEffect(() => {
    setSuccessfulJobs([]);
    setFailedJobs([]);
  }, [validRows, invalidRows]);

  // Triggers the first iteration of adding a job to the current queue
  const handleSubmit = () => {
    dropFirstItemFromJobs();
  };

  const onValidChange = (rows: DeviceUploadRow[]) => {
    setValidRows(rows);
    setJobsToRun(rows);
  };

  return props.isBulkAddOpen ? (
    <EuiErrorBoundary>
      <EuiFlyout
        ownFocus
        onClose={props.closeFlyout}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>
              {' '}
              Add devices to group:{'  '}
              <span style={{ color: '#36a2ef' }}>{props.groupLabel}</span>
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <FileUploadToGroupBox
            addToastMessage={props.addToastMessage}
            isLoading={isWorking}
            successRows={successfulJobs}
            failedRows={failedJobs}
            totalJobs={validRows.length}
            completedJobs={successfulJobs.length + failedJobs.length}
            setInvalidRows={setInvalidRows}
            invalidRows={invalidRows}
            setValidRows={onValidChange}
            validRows={validRows}
            isAdmin={props.isUserAdmin}
            onSubmit={handleSubmit}
          />
        </EuiFlyoutBody>
      </EuiFlyout>
    </EuiErrorBoundary>
  ) : null;
};
