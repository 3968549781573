import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EuiConfirmModal, EuiErrorBoundary, EuiFlexGrid, EuiFlexItem } from '@elastic/eui';
import { useDeleteUserMutation, useGetUserRowQuery, usePatchUserMutation } from 'gqlHooks';
import type { CreateUserMutationVariables, GenericRow, Role } from 'gqlHooks';
import type { SubmitHandler } from 'react-hook-form';
import ManageUserFormView from 'layout/access-management/manage-users/manage-users-form';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';

import ConfirmDiscardDialog from '../manage-users/confirm-discard';

type EditUserProps = {
  /** Method for adding toasts to display */
  addToastMessage: (toasty: Toast) => void;
  /** Informs the parent when the edit user form is dirty */
  setIsDirty: (isDirty: boolean) => void;
  /** Informs the parent when the user wants to close the edit form */
  onClose: () => void;
  /** Information on which user is currently in context for editing */
  selectedUser: GenericRow;
  /** True if the user should have the admin specific fields available */
  isAdmin: boolean;
  /** True if the user should have the delete user button available */
  canDeleteUser: boolean;
};

/** Form for edting a users information */
export const EditUserForm = (props: EditUserProps) => {
  const [isConfirmResetOpen, setIsConfirmResetOpen] = useState<boolean>(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<boolean>(false);
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<CreateUserMutationVariables>({
    name: props.selectedUser.label ?? '',
    emailAddress: props.selectedUser.identifier!,
    role: props.selectedUser.type as Role,
    organizationId: props.selectedUser.organizationId!,
  });

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
  } = useForm<CreateUserMutationVariables>({
    mode: 'all',
    defaultValues: defaultValues,
  });

  // Keep the parent update on the dirty state so it can somewhat focus trap them
  useEffect(() => {
    props.setIsDirty(isDirty);
    // Lint again wants "props"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setIsDirty, isDirty]);

  /**
   * On selected user change, reset the form to contain the new user details
   */
  // Get method for retrieving updated user information
  const { refetch } = useGetUserRowQuery({
    variables: {
      emailAddress: props.selectedUser?.identifier ? props.selectedUser?.identifier : '',
    },
    onError: () => props.addToastMessage(errorToastMessage('Failed to retrieve user info')),
    onCompleted: data => {
      const newDefaults = {
        name: data.userRow.label ?? '',
        emailAddress: data.userRow?.identifier ?? '',
        role: data?.userRow.type as Role,
        organizationId: data.userRow.organizationId,
      };
      reset(newDefaults);
      setDefaultValues(newDefaults);
    },
  });

  // Patch method for updating the user information
  const [patchUserMutation, { loading: isPatchUserSubmitting }] = usePatchUserMutation({
    onCompleted: () => {
      refetch();
      props.addToastMessage(successToastMessage('Updated user'));
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to update user'));
    },
    refetchQueries: ['GetUsersByOrganizationId'],
  });

  // Delete method for permanently deleting a user
  const [deleteUserMutation, { loading: isDeleteUserSubmitting }] = useDeleteUserMutation({
    onCompleted: () => {
      props.addToastMessage(successToastMessage('Deleted user'));
      // If this successfully completed, the user is no more so close the edit view
      props.onClose();
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to delete user'));
    },
    refetchQueries: ['GetUsersByOrganizationId'],
  });

  const onSubmit: SubmitHandler<CreateUserMutationVariables> = () => {
    const { name, role } = getValues(); // Access react-hook-form values
    const email = props.selectedUser?.identifier ?? '';
    patchUserMutation({
      variables: {
        name,
        emailAddress: email,
        role: role as Role,
      },
    }).then(() => {
      // Reset the dirty state after successful submission
      props.setIsDirty(false);
    });
  };
  const onDeleteUser = () => {
    const email = props.selectedUser?.identifier ?? '';
    deleteUserMutation({
      variables: {
        emailAddress: email,
      },
    });
  };

  // Combining dialog closes because why not
  const closeDialogs = () => {
    setIsConfirmCloseOpen(false);
    setIsConfirmDeleteOpen(false);
    setIsConfirmResetOpen(false);
  };

  // Open the confirmation dialog before deleting in case of fat fingers
  const handleDeleteUser = () => {
    setIsConfirmDeleteOpen(true);
  };

  // User has requested a cancel or to close the edit view
  const handleOnClose = () => {
    // If there are pending changes, display the confirmation dialog
    isDirty ? setIsConfirmCloseOpen(true) : props.onClose();
  };

  // Reset the form to its original
  const onReset = () => {
    reset(defaultValues);
    closeDialogs();
  };

  const onClose = () => {
    closeDialogs();
    props.onClose();
  };

  // Check dirty state to see if users need to confirm
  const handleOnReset = () => {
    isDirty ? setIsConfirmResetOpen(true) : onReset();
  };

  return (
    <EuiErrorBoundary>
      {isConfirmDeleteOpen ? (
        <EuiConfirmModal
          title="Delete user?"
          onCancel={closeDialogs}
          onConfirm={onDeleteUser}
          cancelButtonText="Cancel"
          confirmButtonText="Delete user"
          buttonColor="danger"
          defaultFocusedButton="cancel"
        >
          <p>This will delete the user.</p>
        </EuiConfirmModal>
      ) : null}
      <ConfirmDiscardDialog
        isOpen={isConfirmResetOpen || isConfirmCloseOpen}
        onClose={closeDialogs}
        onConfirm={isConfirmResetOpen ? onReset : onClose}
      />
      <EuiFlexGrid
        columns={1}
        style={{ width: '100%' }}
      >
        <EuiFlexItem>
          <ManageUserFormView
            onSubmit={handleSubmit(onSubmit)}
            onDeleteUser={handleDeleteUser}
            control={control}
            isLoading={isPatchUserSubmitting || isDeleteUserSubmitting}
            isEditMode
            canDeleteUser={props.canDeleteUser}
            onCloseAction={handleOnClose}
            onReset={handleOnReset}
          />
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiErrorBoundary>
  );
};
