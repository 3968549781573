import { Comm } from 'app/generated/graphql';
import { MlEuiCommentType, buildEuiComment } from 'app/utils/configTranslator';

// MQTT Timeout Values
export const TIMEOUT_DEFAULT = 10; //seconds
export const TIMEOUT_MIN = 5; // seconds
export const TIMEOUT_MAX = 120; // seconds

// SMS Wakeup Interval Values
export const WAKEUP_DISABLED = 0;
export const WAKEUP_DEFAULT = WAKEUP_DISABLED;
export const WAKEUP_MIN = 3600; // seconds
export const WAKEUP_MAX = 43200; // seconds

export const DEFAULT_COMM_CONFIG: Comm = {
  timeout: TIMEOUT_DEFAULT,
  smsWakeupInterval: WAKEUP_DEFAULT,
};

export const DEFAULT_GROUP_COMM_CONFIG: Comm = {
  //@ts-expect-error form fields accept empty string for unset number fields
  timeout: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  smsWakeupInterval: '',
};

// Builds items for an EUI comment list, in translated human readable format
export const commConfigTranslator = (commConfig: Comm | null | undefined) => {
  if (!commConfig) {
    return undefined;
  }
  const builtItems: Array<MlEuiCommentType> = [];

  if (commConfig.smsWakeupInterval) {
    const description =
      commConfig.smsWakeupInterval === WAKEUP_DISABLED
        ? 'Disabled'
        : `Every ${commConfig.smsWakeupInterval} seconds`;
    builtItems.push(buildEuiComment('SMS Wakeup Interval', description));
  }
  if (commConfig.timeout) {
    builtItems.push(buildEuiComment('MQTT Timeout', `${commConfig.timeout} seconds`));
  }

  return builtItems;
};
