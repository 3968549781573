import { useState } from 'react';
import _ from 'lodash';
import { useGetOrganizationsQuery } from 'gqlHooks';
import type { GetOrganizationsQueryVariables } from 'gqlHooks';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage } from 'app/utils/toast-messages';

export type OrgLabel = {
  /** ID of the company */
  id?: string;
  /** Label of the company */
  label?: string;
};

export type orgDetails = {
  /** OrgLabel of the company that matched the provided ID */
  idMatch?: OrgLabel;
  /** OrgLabel of the  */
  labelMatch?: OrgLabel;
  options: OrgLabel[];
};

export type GetOrgLabelProps = {
  /** If provided, idMatch will be populated with the matching company if found */
  id?: string;
  /** If provided, labelMatch will be populated with the matching company if found */
  label?: string;
  /** toaster strudel */
  addToastMessage: (message: Toast) => void;
};

/**
 * Loads all the available organizations, returns the full available org list
 * and matches if the optional props are filled
 * */
export const useGetOrgs = (props: GetOrgLabelProps) => {
  const [options, setOptions] = useState<OrgLabel[]>([]);

  const queryVars: GetOrganizationsQueryVariables = {
    limit: 1000,
    nextToken: null,
  };

  useGetOrganizationsQuery({
    variables: queryVars,
    onCompleted: data => {
      const opts = data?.organizations?.items?.map(org => {
        return {
          label: (org.label ? org.label : org.organizationId) ?? '',
          id: org.organizationId ?? '',
        };
      });

      setOptions(opts);
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to load organizations'));
    },
  });

  let idMatch = undefined;
  let labelMatch = undefined;
  if (props.id && props.id !== '') {
    idMatch = options.find(option => option.id === props.id);
  }

  if (props.label && props.label !== '') {
    const lowerCase = _.toLower(props.label);
    labelMatch = options.find(option => _.toLower(option.label) === lowerCase);
  }

  const values: orgDetails = {
    idMatch,
    labelMatch,
    options,
  };

  return values;
};
