import { Auth0Provider } from '@auth0/auth0-react';
import { useConfig } from 'context/config';

type Auth0WrapperProps = {
  children: JSX.Element;
};

const Auth0Wrapper = (props: Auth0WrapperProps) => {
  const { VITE_AUTH0_DOMAIN, VITE_AUTH0_CLIENTID, VITE_AUTH0_AUDIENCE } = useConfig();

  return (
    <Auth0Provider
      domain={VITE_AUTH0_DOMAIN}
      clientId={VITE_AUTH0_CLIENTID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: VITE_AUTH0_AUDIENCE,
      }}
    >
      {props.children}
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
