import { createContext, useContext, ReactElement } from 'react';
import type { ImportMetaEnv } from 'app/env';

const ConfigContext = createContext<ImportMetaEnv | undefined>(undefined);

type ConfigProviderProps = {
  children: ReactElement;
};

function ConfigProvider(props: ConfigProviderProps) {
  const {
    VITE_GRAPHQL_ENDPOINT,
    VITE_AUTH0_CLIENTID,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_AUDIENCE,
    VITE_GOOGLE_MAPS_API_KEY,
    VITE_SENTRY_DEBUG,
  } = import.meta.env;

  const configs: ImportMetaEnv = {
    VITE_GRAPHQL_ENDPOINT,
    VITE_AUTH0_CLIENTID,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_AUDIENCE,
    VITE_GOOGLE_MAPS_API_KEY,
    VITE_SENTRY_DEBUG,
  };

  return <ConfigContext.Provider value={configs}>{props.children}</ConfigContext.Provider>;
}

function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within `ConfigProvider`');
  }
  return context;
}

export { ConfigProvider, useConfig };
