import { EuiBadge, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { StatusTypeToken } from 'models/install-verification-tags/types';
import { getTagTypeIcon } from 'layout/install/utils';
import type {
  FlexGroupAlignItems,
  JUSTIFY_CONTENTS,
} from '@elastic/eui/src/components/flex/flex_group';
import type { ITag } from 'models/install-verification-tags/types';

type InstallBadgeProps = {
  tags: Array<ITag>;
  wrap?: boolean;
  alignItems?: FlexGroupAlignItems;
  justifyContent?: (typeof JUSTIFY_CONTENTS)[number];
};

export const InstallBadge = ({
  tags,
  wrap = true,
  alignItems = 'flexStart',
  justifyContent = 'flexStart',
}: InstallBadgeProps) => {
  return (
    <EuiFlexGroup
      alignItems={alignItems}
      gutterSize="s"
      justifyContent={justifyContent}
      wrap={wrap}
    >
      {tags.map(tag => {
        return (
          <EuiFlexItem
            grow={false}
            key={`badgeItem-${tag.tagType}`}
          >
            <EuiBadge
              key={tag.tagType}
              iconType={getTagTypeIcon(tag.tagType)}
              color={tag?.statusToken ?? StatusTypeToken.INFORMATION}
            >
              {tag.tagText}
            </EuiBadge>
          </EuiFlexItem>
        );
      })}
    </EuiFlexGroup>
  );
};
