import { EuiAvatar } from '@elastic/eui';
import { StatusTypeToken, TagType } from 'models/install-verification-tags/types';

/** Tries to decide what the primary status is given an array of statuses */
export const getPrimaryStatus = (statuses: Array<string>) => {
  if (statuses.includes(StatusTypeToken.DANGER)) return StatusTypeToken.DANGER;
  if (statuses.includes(StatusTypeToken.WARNING)) return StatusTypeToken.WARNING;
  if (statuses.includes(StatusTypeToken.BETA)) return StatusTypeToken.BETA;
  if (statuses.includes(StatusTypeToken.SUCCESS)) return StatusTypeToken.SUCCESS;

  return StatusTypeToken.INFORMATION;
};

export const getTagTypeIcon = (tagType: string) => {
  switch (tagType) {
    case TagType.BATTERY:
      return 'bolt';
    case TagType.FIRMWARE:
      return 'compute';
    case TagType.LOCATION:
      return 'mapMarker';
    // case TagType.SIM:
    //   return 'mobile';
    case TagType.STOLEN:
      return 'securitySignal';
    default:
      return 'iInCircle';
  }
};

export const getInstallDescription = (tagType: string) => {
  switch (tagType) {
    case 'success':
      return (
        <EuiAvatar
          name="Good Status"
          iconType="faceHappy"
          iconColor="success"
          color="plain"
          size="l"
        />
      );
    case 'warning':
      return (
        <EuiAvatar
          name="Warning Status"
          iconType="faceNeutral"
          iconColor="warning"
          color="plain"
          size="l"
        />
      );
    case 'beta':
      return (
        <EuiAvatar
          name="Warning Status"
          iconType="faceNeutral"
          iconColor="accent"
          color="plain"
          size="l"
        />
      );
    case 'danger':
      return (
        <EuiAvatar
          name="Danger Status"
          iconType="faceSad"
          iconColor="danger"
          color="plain"
          size="l"
        />
      );
    default:
      return (
        <EuiAvatar
          name="Info Status"
          iconType="iInCircle"
          iconColor="primary"
          color="plain"
          size="l"
        />
      );
  }
};

export const getStatusAvatar = (status: string) => {
  switch (status) {
    case StatusTypeToken.SUCCESS:
      return (
        <EuiAvatar
          name="Good Status"
          iconType="faceHappy"
          iconColor="success"
          color="plain"
          size="l"
        />
      );
    case StatusTypeToken.WARNING:
      return (
        <EuiAvatar
          name="Warning Status"
          iconType="faceNeutral"
          iconColor="warning"
          color="plain"
          size="l"
        />
      );
    case StatusTypeToken.DANGER:
      return (
        <EuiAvatar
          name="Danger Status"
          iconType="faceSad"
          iconColor="danger"
          color="plain"
          size="l"
        />
      );
    case StatusTypeToken.BETA:
      return (
        <EuiAvatar
          name="Warning Status"
          iconType="beta"
          iconColor="accent"
          color="plain"
          size="l"
        />
      );
    default:
      return (
        <EuiAvatar
          name="Info Status"
          iconType="iInCircle"
          iconColor="primary"
          color="plain"
          size="l"
        />
      );
  }
};
