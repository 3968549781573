import {
  Configuration,
  StrawberryGroupOperationResponse,
  GroupOperationStatus,
} from 'app/generated/graphql';
import _, { camelCase, isArray, isPlainObject } from 'lodash';
import { COLORS } from 'utils/constants';
import { EuiBadge } from '@elastic/eui';

import {
  DEFAULT_STOLEN_CONFIG,
  DEFAULT_GROUP_STOLEN_CONFIG,
} from './stolen/stolen-config-defaults';
import { DEFAULT_COMM_CONFIG, DEFAULT_GROUP_COMM_CONFIG } from './comm/comm-config-defaults';
import {
  MOTION_CONFIG_DEFAULTS,
  MOTION_GROUP_CONFIG_DEFAULTS,
} from './motion/motion-config-defaults';
import {
  PERIODIC_CONFIG_DEFAULTS,
  PERIODIC_GROUP_CONFIG_DEFAULTS,
} from './periodic/periodic-config-defaults';
import { POWER_CONFIG_DEFAULTS, POWER_GROUP_CONFIG_DEFAULTS } from './power/power-config-defaults';

export const CONFIG_TYPE_ICONS = {
  COMM: 'mobile',
  MOTION: 'mapMarker',
  PERIODIC: 'timeline',
  POWER: 'gear',
  STOLEN: 'securitySignal',
};

export interface ConfigBase {
  /** Setter for use when updating configuration values */
  setConfig: (configChanges: Partial<Configuration>) => void;
  /** Used for handling the state of the panel. Collapsed or expanded. */
  isExpanded: boolean;
}

/**
 * Helper to iterate through a js object and convert the keys from
 * snake_case to camelCase. API gives us snake, but expect camel back
 */
export function keysToCamel(obj: any) {
  if (isPlainObject(obj)) {
    const n = {};
    Object.keys(obj).forEach((k: any) => {
      const camelCaseKey: string = camelCase(k);
      // @ts-expect-error I don't know how to resolve this one
      n[camelCaseKey] = keysToCamel(obj[k]);
    });
    return n;
  } else if (isArray(obj)) obj.map(i => keysToCamel(i));
  return obj;
}

// Figures out the status of a groups operation based on the completion counts
// Used by both groups status tables, putting here so we aren't doubling up maintenance if changes are made
export const renderGroupsStatus = (_status: string, request: StrawberryGroupOperationResponse) => {
  let statusColor = COLORS.INFO;
  let iconType = 'iInCircle';
  let statusMessage = 'Unknown';
  const groupState = request.requestState;
  const createdCount = request.requestSummary?.created ?? 0;
  const applyingCount = request.requestSummary?.applying ?? 0;
  const completedCount = request.requestSummary?.completed ?? 0;
  const failedCount = request.requestSummary?.failed ?? 0;
  const invalidatedCount = request.requestSummary?.invalidated ?? 0;
  const totalDevices =
    createdCount + applyingCount + completedCount + failedCount + invalidatedCount;
  // Invalidated items were cancelled by the user, so count towards completion
  const finishedUpdates = completedCount + invalidatedCount;

  // If the overall request has been cancelled, that wins
  if (
    groupState == GroupOperationStatus.Cancelled ||
    groupState == GroupOperationStatus.Cancelling
  ) {
    statusColor = COLORS.WARNING;
    iconType = 'iInCircle';
    statusMessage = 'Cancelled';
  }
  // Failures are most important for the user to know about, so they can go retry them
  else if (failedCount > 0) {
    statusColor = COLORS.DANGER;
    iconType = 'iInCircle';
    statusMessage = `${failedCount} of ${totalDevices} failed`;
  }
  // Check if all work has finished, if it has give a simple completion notice
  else if (finishedUpdates == totalDevices) {
    statusColor = COLORS.SUCCESS;
    iconType = 'check';
    statusMessage = 'Complete';
  }
  // If there are complete requests, give users a count so they can see how close to done the request is
  // Cancelled counts as completed as the request is in an "end state" desired by the user
  else if (finishedUpdates > 0) {
    statusColor = COLORS.INFO;
    iconType = 'clock';
    statusMessage = `${finishedUpdates} of ${totalDevices} complete`;
  }
  // Check if any of the updates have started
  else if (applyingCount > 0 || createdCount > 0) {
    statusColor = COLORS.INFO;
    iconType = 'clock';
    statusMessage = `${applyingCount + createdCount} of ${totalDevices} in progress`;
  }
  // If we have only cancelled items, then the overall request has been cancelled even
  // if its overall status doesn't reflect this
  else if (invalidatedCount == totalDevices) {
    statusColor = COLORS.WARNING;
    iconType = 'iInCircle';
    statusMessage = `Cancelled`;
  }
  // If we somehow make it to this if, then we have only cancelled items with nothing in
  // any other state. Don't know how that could happen, but including a clause for it
  else if (invalidatedCount > 0) {
    statusColor = COLORS.WARNING;
    iconType = 'iInCircle';
    statusMessage = `${invalidatedCount} of ${totalDevices} cancelled`;
  }

  return (
    <EuiBadge
      iconType={iconType}
      color={statusColor}
    >
      {statusMessage}
    </EuiBadge>
  );
};

export const parseNumberOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;

  // If it isn't a number, return an empty string as that is the empty value for a number selector
  return !value || Number.isNaN(parseInt(value, 10)) ? '' : parseInt(value, 10);
};

// Map react-hook-form's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
// https://github.com/orgs/react-hook-form/discussions/1991
function buildDirtyFields(allDirtyFields: object | boolean, allValues: object): object {
  // Handles the array case. If *any* item in an array was modified, the entire array must be submitted,
  // because there's no way to indicate `dirtyFields` is `true` for leaves.
  if (allDirtyFields === true || Array.isArray(allDirtyFields)) return allValues;

  // Recursive iteration through all fields
  return Object.fromEntries(
    Object.keys(allDirtyFields).map((key: string) => [
      key,
      // @ts-expect-error recursively iterating through the object, so typing changes on each layer
      buildDirtyFields(allDirtyFields[key], allValues[key]),
    ])
  );
}

// Map react-hook-form's dirtyFields over the `data` received by `handleSubmit` and return the changed subset of that data.
// https://github.com/orgs/react-hook-form/discussions/1991
export function getDirtyConfigValues(
  allDirtyFields: object | boolean,
  allValues: Configuration
): object {
  // Handles the array case. If *any* item in an array was modified, the entire array must be submitted,
  // because there's no way to indicate `dirtyFields` is `true` for leaves.
  if (allDirtyFields === true || Array.isArray(allDirtyFields)) return allValues;

  // Build the modified config
  const modifiedConfig: Configuration = buildDirtyFields(allDirtyFields, allValues);

  if (modifiedConfig.power) {
    // The button is problematic - we NEED both values to figure out its status
    const offEnabledChanged = typeof modifiedConfig.power.offEnable === 'boolean';
    const resetEnabledChanged = typeof modifiedConfig.power.resetEnable === 'boolean';
    if (offEnabledChanged && !resetEnabledChanged) {
      modifiedConfig.power.resetEnable = allValues?.power?.resetEnable;
    }
    if (resetEnabledChanged && !offEnabledChanged) {
      modifiedConfig.power.offEnable = allValues?.power?.offEnable;
    }
  }

  // Return the built configery
  return modifiedConfig;
}

// This is mostly used in stories now as test display values
export const DEFAULT_SNT_CONFIGURATION: Configuration = {
  comm: DEFAULT_COMM_CONFIG,
  motion: MOTION_CONFIG_DEFAULTS,
  stolenAsset: DEFAULT_STOLEN_CONFIG,
  periodic: PERIODIC_CONFIG_DEFAULTS,
  power: POWER_CONFIG_DEFAULTS,
};

export const DEFAULT_GROUP_SNT_CONFIGURATION: Configuration = {
  comm: DEFAULT_GROUP_COMM_CONFIG,
  motion: MOTION_GROUP_CONFIG_DEFAULTS,
  stolenAsset: DEFAULT_GROUP_STOLEN_CONFIG,
  periodic: PERIODIC_GROUP_CONFIG_DEFAULTS,
  power: POWER_GROUP_CONFIG_DEFAULTS,
};
