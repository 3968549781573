import { SetStateAction } from 'react';
import { SntSmsCommand } from 'gqlHooks';
import { EuiFormRow, EuiSelect } from '@elastic/eui';

interface SntSendSmsViewProps {
  /** Value of the currently selected SMS command */
  selectedCommand: string;
  /** Setter for informing about selected command changes */
  setSelectedCommand: (command: SetStateAction<SntSmsCommand>) => void;
}

// Available commands for a SnT device, with help text descriptions
const SNT_SMS_COMMANDS = [
  { value: SntSmsCommand.CallHome, text: 'Call Home', description: 'User Requested Check In' },
  { value: SntSmsCommand.Reset, text: 'Reset', description: 'Reset the Device' },
  {
    value: SntSmsCommand.MqttBroker,
    text: 'Update MQTT',
    description: 'Update MQTT Broker and Port',
  },
];

/**
 * @return {React.Element} Selector for Snt specific commands.
 */
export function SntCommandSelector({ setSelectedCommand, selectedCommand }: SntSendSmsViewProps) {
  const handleSelectCommandChange = (event: { target: { value: any } }) => {
    setSelectedCommand(event.target.value);
  };

  const helpText =
    SNT_SMS_COMMANDS.find(command => command.value === selectedCommand)?.description ?? '';

  return (
    <EuiFormRow
      helpText={helpText}
      label="Select Command"
    >
      <EuiSelect
        id="commandSelect"
        onChange={handleSelectCommandChange}
        options={SNT_SMS_COMMANDS}
        value={selectedCommand}
      />
    </EuiFormRow>
  );
}

export default SntCommandSelector;
