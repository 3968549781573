import { useDeviceCredRequestMutation, Module } from 'gqlHooks';
import { EuiErrorBoundary } from '@elastic/eui';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';

import OtaCredUpdateView from './ota-cred-update-view';

interface OtaCredUpdateProps {
  addToastMessage: (message: any) => void;
  deviceSerial: string;
}

/** Sends an OTA credential update to a snt device */
export const OtaCredUpdate = ({ addToastMessage, deviceSerial }: OtaCredUpdateProps) => {
  const [sendOtaCredRequestMutation, { loading }] = useDeviceCredRequestMutation({
    onCompleted: () => {
      addToastMessage(successToastMessage('Request Sent'));
    },
    onError: () => {
      addToastMessage(errorToastMessage('Failed to send cred request'));
    },
  });

  // Builds the request data, then sends the request
  // CA Certificate, client certificate, private key, and retain current are optional
  const sendOtaCredRequest = (module: Module) => {
    const request: any = {
      module: module,
    };
    sendOtaCredRequestMutation({
      variables: {
        deviceId: deviceSerial,
        module: request.module,
      },
    });
  };

  return (
    <EuiErrorBoundary>
      <OtaCredUpdateView
        isLoading={loading}
        onSendCredRequest={sendOtaCredRequest}
      />
    </EuiErrorBoundary>
  );
};
