import { useState } from 'react';
import {
  EuiCommentList,
  EuiAccordion,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  htmlIdGenerator,
  EuiButtonIcon,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiDescriptionList,
  EuiFlyout,
} from '@elastic/eui';
import { InstallTag } from 'layout/install/entry/tag/tag';
import { InstallBadge } from 'layout/install/entry/badge/badge';
import { getPrimaryStatus, getInstallDescription } from 'layout/install/utils';
import type { ITag } from 'models/install-verification-tags/types';

import { installItemsInfo } from './install-status-info';

export type InstallTagsProps = {
  /** Array of tags containing the install information */
  tags: Array<ITag>;
  /** Serial of the tracker whose install information is being displayed */
  trackerSerial: string;
};

/** Entry to display the install status of a single tracker */
export const InstallEntry = ({ tags, trackerSerial }: InstallTagsProps) => {
  // Tags to be displayed in the data section
  const installTags: Array<JSX.Element> = [];
  // Collective statuses of all tags for calculating overall status
  const statuses: Array<string> = [];

  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

  tags?.map(tag => {
    statuses.push(tag.statusToken);
    installTags.push(
      <InstallTag
        key={`${htmlIdGenerator()()}`}
        title={`${tag.title} Status: `}
        status={tag.status}
        statusToken={tag.statusToken}
        tagText={tag.tagText}
        tagType={tag.tagType}
        description={tag.description}
      />
    );
  });

  //add info button here

  const primaryStatus = getPrimaryStatus(statuses);

  // The header goes in the accordion "button" section
  const headerContent = (
    <EuiFlexGroup
      alignItems="flexStart"
      justifyContent="center"
    >
      <EuiFlexItem grow={false}>{getInstallDescription(primaryStatus)}</EuiFlexItem>
      <EuiFlexItem grow={true}>
        <EuiTitle size="xs">
          <h3>{trackerSerial}</h3>
        </EuiTitle>
        <InstallBadge
          tags={tags ?? []}
          wrap
          alignItems="flexStart"
          justifyContent="flexStart"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  const infoAction = (
    <EuiButtonIcon
      id="view-install-check-info-panel"
      title="Open information"
      aria-label="Additional information"
      color="text"
      onClick={() => setIsFlyoutVisible(true)}
      iconType="iInCircle"
      size="m"
    />
  );

  let flyout;

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        size="s"
        onClose={() => setIsFlyoutVisible(false)}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Install Status Info</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiDescriptionList listItems={installItemsInfo} />
        </EuiFlyoutBody>
      </EuiFlyout>
    );
  }

  return (
    <EuiAccordion
      id="install-entry-accordion"
      key={trackerSerial}
      element="fieldset"
      className="euiAccordionForm"
      buttonClassName="euiAccordionForm__button"
      buttonContent={headerContent}
      extraAction={infoAction}
      paddingSize="l"
    >
      <EuiCommentList>{installTags}</EuiCommentList>
      {flyout}
    </EuiAccordion>
  );
};
