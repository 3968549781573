import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';
interface ConfirmDeleteGroupProps {
  /** True if the dialogue should be open */
  isOpen?: boolean;
  /** Called when the user either cancels or closes the dialogue */
  onClose: () => void;
  /**Method invoked when users confirm the delete group */
  onRequestDelete: () => void;
}

/* Dialogue to let user confirm they want to delete the group, its children, and remove all device associations */
export const ConfirmCascadeDelete: React.FC<ConfirmDeleteGroupProps> = (
  props: ConfirmDeleteGroupProps
) => {
  return props.isOpen ? (
    <EuiOverlayMask>
      <EuiConfirmModal
        buttonColor="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Remove all"
        defaultFocusedButton="cancel"
        onCancel={props.onClose}
        onConfirm={props.onRequestDelete}
        title="Are you sure?"
      >
        <p>{`Group still has device associations, or contains nested child groups. Delete this group and ALL nested child groups?`}</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  ) : null;
};

export default ConfirmCascadeDelete;
