import moment, { Moment } from 'moment-timezone';
import { CONSTANTS } from 'utils/constants';
import type { unitOfTime } from 'moment';

/**
 * TimeRange enum and helper functions
 */
export enum TimeRange {
  TWO_HOURS = '2 hours',
  FOUR_HOURS = '4 hours',
  TWELVE_HOURS = '12 hours',
  TWENTY_FOUR_HOURS = '24 hours',
  THREE_MONTHS = '3 months',
}

/**
 * TimeRangeValue is a type that is the union of all the keys of the TimeRange enum
 */
export type TimeRangeValue = keyof typeof TimeRange;

/**
 * TimeRangeReverseMapping is a mapping of the TimeRange enum values to the keys
 */
export const TimeRangeReverseMapping: Record<string, TimeRangeValue> = {} as any;

/**
 * Iterate over the TimeRange enum and add the values to the TimeRangeReverseMapping
 */
Object.entries(TimeRange).forEach(([key, value]) => {
  if (typeof value === 'string') {
    TimeRangeReverseMapping[value] = key as TimeRangeValue;
  }
});

export function isUnitOfTime(u: any): u is string {
  return CONSTANTS.VALID_UNIT_OF_TIME.includes(u);
}

export function getRangeParts(range: TimeRangeValue): {
  unit: number;
  duration: unitOfTime.DurationConstructor;
} {
  const rangeParts = TimeRange[range].split(/\s/g);
  // Exit early if rangeParts is not an array of length 2
  if (rangeParts.length !== 2) {
    throw new Error(`Invalid time range: {${range}} must be in the format of {number} {unit}`);
  }

  const [unit, duration] = rangeParts;
  const parts = { unit: Number(unit), duration: duration as unitOfTime.DurationConstructor };

  if (isNaN(Number(unit))) {
    throw new Error(`Invalid time range unit: {${unit}} must be a number 1, 2, 3, etc`);
  }

  if (!isUnitOfTime(duration)) {
    throw new Error(`Invalid time range duration: {${duration}} must be a valid moment duration`);
  }

  return parts;
}

export function getMomentFromRange(range: TimeRangeValue): Moment {
  const { unit, duration } = getRangeParts(range);
  return moment().subtract(unit, duration);
}

export function getDateFromRange(range: TimeRangeValue): Date {
  return getMomentFromRange(range).toDate();
}

export function getFormattedDateFromRange(range: TimeRangeValue, frmt?: string): string {
  return getMomentFromRange(range).format(frmt);
}

export function getAppSyncQueryDate(dt: Date = new Date()) {
  return moment(new Date(dt)).format('yy-MM-DD HH:mm:ss.SSSS');
}

/**
 * Converts  date that is missing the TZ information to UTC
 * then to the local date time. This is due to getting dates from the
 * API that are UTC, but not labeled as such so we have to manually convert
 */
export function getLocalTime(date?: Date | string): string {
  const localTimezone = moment.tz.guess();
  const utcMoment = moment.utc(date); // Parse input date as UTC moment

  return utcMoment.tz(localTimezone).format('DD MMMM YYYY HH:mm:ss z');
}

/**
 *
 * Convert date into a relative time string
 */
export function getRelativeTimeFromNow(
  date: Date | string | { value: string } = new Date()
): string {
  if (typeof date === 'object' && 'value' in date) {
    date = (date as { value: string }).value; // Use type assertion to treat it as a string
  }
  return moment(date).fromNow();
}

// Time int is in UTC
export function hourTimeIntToDate(timeInt: number) {
  const timeString = timeInt.toString().padStart(4, '0');
  // Explicitly tell moment it is utc, as it assumes local otherwise
  const utc = moment.utc(timeString, ['HHmm']);
  // Convert the utc to local time
  const toLocal = utc.local();
  return toLocal;
}

// This date should be in local time
export function dateTo24HourTimeInt(date: moment.Moment) {
  // Convert to UTC
  const toUtc = date.utc();
  // Format it into an integer string
  const timeInt = toUtc.format('HHmm');
  return parseInt(timeInt);
}
