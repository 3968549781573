import { CONSTANTS } from 'utils/constants';
import { capitalize } from 'utils/transformations';
import { getRelativeTimeFromNow } from 'utils/dates';
import { Base } from 'models/Base';
import {
  StatusType,
  StatusTypeDescriptor,
  StatusTypeToken,
} from 'models/install-verification-tags/types';
import type {
  IInstallVerificationTagData,
  ITag,
  ITagStatus,
  TagType,
} from 'models/install-verification-tags/types';

export abstract class Tag {
  static getTagTextTemplate(tagType: TagType, statusDescriptor: StatusTypeDescriptor): string {
    return `${statusDescriptor} ${tagType.toLowerCase()} status`;
  }
  protected assertIsValidLength: typeof Base.assertIsValidLength;
  protected assertPropertyExistsOfLength: typeof Base.assertPropertyExistsOfLength;
  protected tagTitle: string;
  protected _statusDetail!: string;

  protected _statusType!: StatusType;

  constructor(
    protected tagType: TagType,
    protected tagData: IInstallVerificationTagData
  ) {
    this.assertIsValidLength = Base.assertIsValidLength;
    this.assertPropertyExistsOfLength = Base.assertPropertyExistsOfLength;
    this.tagType = tagType;
    this.tagTitle = this.getTagTitle(tagType);
    this.tagData = tagData;
    /**
     * Setting _statusDetail and _statusType
     * to default values to protect against
     * a situation where the status checks
     * pass through the checks
     * Setting as DANGER to bring unknown detail forward
     */
    this._statusDetail = CONSTANTS.TEXT_PLACEHOLDER;
    this._statusType = StatusType.DANGER;
  }

  abstract createTag(): ITag;

  abstract getStatus(): ITagStatus;

  get statusDetail(): string {
    return this._statusDetail;
  }

  set statusDetail(detail: string) {
    this._statusDetail = detail;
  }

  get statusType(): StatusType {
    return this._statusType;
  }

  set statusType(type: StatusType) {
    this._statusType = type;
  }

  get defaults() {
    return {
      tagType: this.tagType,
      title: this.tagTitle,
    };
  }

  protected getTagTitle(tagType: TagType): string {
    return capitalize(tagType.toString());
  }

  protected relativeTimeOfTagIngestion(ingestedAt: string): string {
    return getRelativeTimeFromNow(ingestedAt);
  }

  protected createDefaultTag(): ITag {
    return {
      ...this.defaults,
      status: StatusType.UNKNOWN,
      statusToken: StatusTypeToken.UNKNOWN,
      tagText: Tag.getTagTextTemplate(this.tagType, StatusTypeDescriptor.UNKNOWN),
      description: `${
        this.tagTitle
      } is reporting an ${StatusTypeDescriptor.UNKNOWN.toLowerCase()} status`,
    };
  }
}
