import { useState } from 'react';
import { EuiErrorBoundary } from '@elastic/eui';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { UpdateDeviceMutationVariables, UserInfo, useUpdateDeviceMutation } from 'gqlHooks';
import { UserInfoContextData, useUserInfo } from 'app/context/userInfo';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';
import { TitleBarPanel } from 'app/layout/title-bar';

import EditDeviceToOrganizationFormView from './view';

const DEFAULT_VALUES: UpdateDeviceMutationVariables = {
  deviceId: '',
  organizationId: '',
  label: '',
};

type EditDeviceToOrgProps = {
  /** Handler for adding toasts to the global list */
  addToastMessage: (toasty: Toast) => void;
};

export const EditDeviceFromOrganizationForm = (props: EditDeviceToOrgProps) => {
  const [defaults, setDefaults] = useState(DEFAULT_VALUES);
  const [errorState, setErrorState] = useState<string | undefined>();

  const {
    queryData: { userInfo, loading },
  }: UserInfoContextData = useUserInfo();

  const userOrg: UserInfo['organizationId'] = userInfo?.organizationId ?? '';
  const isUserAdmin: boolean = userInfo?.role === 'administrator';

  const { control, getValues, handleSubmit, reset } = useForm<UpdateDeviceMutationVariables>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: defaults,
  });

  const [editDeviceToOrganizationMutation, { loading: isSubmitting }] = useUpdateDeviceMutation({
    onCompleted: () => {
      // Set the last used org to drop user pain a little on multi-entry
      const { organizationId } = getValues();
      const newDefaults = { ...defaults, organizationId };
      setDefaults(newDefaults);
      reset(newDefaults);
      props.addToastMessage(successToastMessage('Updated device label'));
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to update label'));
    },
  });

  const onSubmit: SubmitHandler<UpdateDeviceMutationVariables> = () => {
    const { deviceId, organizationId, label } = getValues(); // Access react-hook-form values
    editDeviceToOrganizationMutation({
      variables: {
        deviceId: deviceId?.[0],
        organizationId: isUserAdmin ? organizationId : userOrg,
        label: label,
      },
    });
  };

  const handleCalloutDismiss = () => {
    setErrorState(undefined);
  };

  return (
    <EuiErrorBoundary>
      <TitleBarPanel
        title={`Edit Device Label`}
        isLoading={loading}
        sectionId="edit-device-section"
        iconType="pencil"
      >
        <EditDeviceToOrganizationFormView
          userOrg={userOrg}
          onSubmit={handleSubmit(onSubmit)}
          control={control}
          isAdmin={isUserAdmin}
          isLoading={isSubmitting}
          handleCalloutDismiss={handleCalloutDismiss}
          errorMessage={errorState}
        />
      </TitleBarPanel>
    </EuiErrorBoundary>
  );
};
