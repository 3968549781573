import { SyntheticEvent, useState } from 'react';
import {
  DeviceType,
  FotaType,
  useGetDeviceFirmwaresQuery,
  useSendGroupFotaMutation,
} from 'gqlHooks';
import {
  EuiErrorBoundary,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiSelectOption,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage, infoToastMessage, successToastMessage } from 'app/utils/toast-messages';
import SntFotaSelect from 'app/layout/fota/snt/select/snt-fota-select';

interface SntGroupFotaProps {
  organizationId: string;
  /** Serial of the device whose config is being modified */
  groupTokens: string[];
  /** Adds a toast to the global message queue */
  addToastMessage: (message: Toast) => void;
  onClose: () => void;
  isOpen: boolean;
}

/** Configuration options for the SnT devices */
export const SntGroupFota = (props: SntGroupFotaProps) => {
  const [firmwareOptions, setFirmwareOptions] = useState<EuiSelectOption[]>([]);
  const [firmwareType, setFirmwareType] = useState(FotaType.App);
  const [selectedFirmware, setSelectedFirmware] = useState<string | undefined>('');

  // Retrieves all available firmwares for the selector
  // TODO when there are more types, this will need to be split out to load the specific tracker types firmware options
  useGetDeviceFirmwaresQuery({
    variables: {
      deviceType: DeviceType.Snt,
      firmwareType: firmwareType,
    },
    onCompleted: data => {
      const options: EuiSelectOption[] = [];

      data?.deviceFirmwareRequest?.firmware.forEach(version => {
        options.push({ value: version.version, text: version.version });
      });

      setFirmwareOptions(options);
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to load firmwares'));
    },
  });

  const [sendGroupFotaMutation, { loading: isSending }] = useSendGroupFotaMutation();

  if (!props.isOpen) {
    return null;
  }

  const handleSendFotaRequest = (event: SyntheticEvent | null) => {
    event?.preventDefault();
    props.groupTokens.forEach(token => {
      const shortName = token.split('/').pop();
      sendGroupFotaMutation({
        variables: {
          organizationId: props.organizationId,
          groupToken: token,
          deviceType: DeviceType.Snt,
          firmwareType: firmwareType,
          firmwareVersion: selectedFirmware ?? '',
        },
        onCompleted: () => {
          props.addToastMessage(successToastMessage(`Config changes sent to ${shortName}`));
        },
        onError: error => {
          // @ts-expect-error error IS on the return object. TS thinks it isnt.
          if (error?.graphQLErrors?.[0].error.includes('has no devices assigned')) {
            props.addToastMessage(infoToastMessage(`${shortName} contains no devices`));
          } else {
            //data.graphQLErrors[0].includes('has no devices assigned');
            props.addToastMessage(errorToastMessage(`Failed to send changes to ${shortName}`));
          }
        },
      });
    });
  };

  const handleFirmwareTypeChange = (type: FotaType) => {
    setFirmwareType(type);
    setSelectedFirmware(undefined);
  };

  return (
    <EuiErrorBoundary>
      <EuiFlyout
        ownFocus
        onClose={props.onClose}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Send Fota to group</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiText>
            <p>
              Fota request will be sent to devices within the group, and to all devices in any
              nested child groups.
            </p>
          </EuiText>
          <EuiSpacer size="s" />
          <SntFotaSelect
            firmwareOptions={firmwareOptions}
            firmwareType={firmwareType}
            isSubmitting={isSending}
            setSelectedFirmwareType={handleFirmwareTypeChange}
            setSelectedFirmware={setSelectedFirmware}
            onUpdateFirmware={handleSendFotaRequest}
            selectedFirmware={selectedFirmware}
          />
        </EuiFlyoutBody>
      </EuiFlyout>
    </EuiErrorBoundary>
  );
};
