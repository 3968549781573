import { EuiErrorBoundary, EuiFormRow, EuiComboBox, useGeneratedHtmlId } from '@elastic/eui';
import type { EuiComboBoxOptionOption } from '@elastic/eui';

export type SelectGroupViewProps = {
  /** Notified on a groups selection event */
  onChange: (event: any) => void;
  /** Array of group options to be selected from */
  options: EuiComboBoxOptionOption[];
  /** True if the selector should be disabled */
  disabled?: boolean;
  /** Array of currently selected group items */
  selectedGroups: EuiComboBoxOptionOption[];
  /** Override to change the default label of 'select group' */
  label?: string;
  /** True if the invalid indicator should be set for this form field */
  isInvalid: boolean;
  /** Error message to display if the field is invalid */
  errorMessage?: string | null;
  /** True if a loading indicator should display */
  isLoading: boolean;
  /** True if only one group at a time may be selected */
  isSingleSelect?: boolean;
  helpText?: string;
};

/** Selector for selecting a group or groups */
export const SelectGroupView = (props: SelectGroupViewProps) => {
  const handleOnChange = (event: any) => {
    props.onChange(event);
  };

  return (
    <EuiErrorBoundary>
      <EuiFormRow
        label={props.label ? props.label : 'Select group'}
        isDisabled={props.disabled}
        isInvalid={props.isInvalid}
        error={[props.errorMessage]}
        helpText={props.helpText}
      >
        <EuiComboBox
          id={useGeneratedHtmlId({ prefix: 'groupSelect' })}
          options={props.options}
          singleSelection={props.isSingleSelect}
          isClearable
          selectedOptions={props.selectedGroups}
          onChange={handleOnChange}
          aria-label={`Select ${props.label ?? 'group'}`}
          isDisabled={props.disabled}
          isLoading={props.isLoading}
        />
      </EuiFormRow>
    </EuiErrorBoundary>
  );
};
