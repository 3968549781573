import { Navigate } from 'react-router-dom';
import { ReactElement } from 'react';
import { DeviceRouter } from 'views/Devices/DeviceRouter';
import { AccessManagement } from 'views/AccessManagement';
import { GroupsManagement } from 'views/groups';
import { Error } from 'views/Error';
import { Developer } from 'app/views/Developer';

export type RouteConfig = {
  path: string;
  element: ReactElement;
  errorElement?: ReactElement;
};

export const appRoutes: RouteConfig[] = [
  // Redirect / to /devices
  {
    path: '/',
    element: (
      <Navigate
        to="/devices"
        replace
      />
    ),
  },
  {
    path: 'devices/*',
    element: <DeviceRouter />,
    errorElement: <Error />,
  },
  {
    path: '/access-management/*',
    element: <AccessManagement />,
    errorElement: <Error />,
  },
  {
    path: '/developer/*',
    element: <Developer />,
    errorElement: <Error />,
  },
  {
    path: '/groups-management/*',
    element: <GroupsManagement />,
    errorElement: <Error />,
  },
];
