import { UserInfoContextData, useUserInfo } from 'app/context/userInfo';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { EuiErrorBoundary, EuiFlexGrid, EuiFlexItem } from '@elastic/eui';
import { TitleBarPanel } from 'app/layout/title-bar';

import AddDevicesToOrgView from './add-devices-to-org-view';

type AddDevicesToOrgProps = {
  /** Handler for adding toasts to the global list */
  addToastMessage: (toasty: Toast) => void;
};

/**
 * Top level for adding devices to organizations
 */
export default function AddDevicesToOrg(props: AddDevicesToOrgProps) {
  const {
    queryData: { userInfo, loading },
  }: UserInfoContextData = useUserInfo();

  const userIsAdmin = userInfo?.role === 'administrator';

  return (
    <EuiErrorBoundary>
      <TitleBarPanel
        title={`Add Device`}
        isLoading={loading}
        sectionId="add-device-section"
        iconType="link"
      >
        <EuiFlexGrid columns={1}>
          <EuiFlexItem>
            <AddDevicesToOrgView
              isUserAdmin={userIsAdmin}
              organizationId={userInfo?.organizationId ?? ''}
              addToastMessage={props.addToastMessage}
            />
          </EuiFlexItem>
        </EuiFlexGrid>
      </TitleBarPanel>
    </EuiErrorBoundary>
  );
}
