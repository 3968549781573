import {
  EuiButton,
  EuiErrorBoundary,
  EuiHighlight,
  EuiSelectable,
  EuiSelectableOption,
  EuiText,
} from '@elastic/eui';
import { GenericRow } from 'gqlHooks';
import { EuiSelectableOnChangeEvent } from '@elastic/eui/src/components/selectable/selectable';

export interface SelectUserViewProps {
  /* Notifies when a user is selected */
  setSelectedUser: (
    newOptions: EuiSelectableOption[],
    _unused: EuiSelectableOnChangeEvent,
    changedOption: EuiSelectableOption<GenericRow>
  ) => void;
  /** Optional error message to display */
  errorMessage?: string;
  /** True if the selector should display a loading state */
  isLoading?: boolean;
  options: EuiSelectableOption<GenericRow>[];
  setOptions: (options: EuiSelectableOption<GenericRow>[]) => void;
  onAddUser: () => void;
}

/* Facet style selector for users */
export const SelectUserView = (props: SelectUserViewProps) => {
  const renderUser = (option: EuiSelectableOption<GenericRow>, searchValue: string) => {
    return (
      <>
        <EuiHighlight search={searchValue}>{option.identifier!}</EuiHighlight>
        <EuiText
          size="xs"
          color="subdued"
          className="eui-displayBlock"
        >
          <small>
            <EuiHighlight search={searchValue}>
              {`${option.type}${option?.type ? ': ' : ''}${option?.label}`}
            </EuiHighlight>
          </small>
        </EuiText>
      </>
    );
  };

  return (
    <EuiErrorBoundary>
      <EuiSelectable
        aria-label="Select user to edit"
        searchable
        searchProps={{ placeholder: 'Filter users' }}
        listProps={{
          isVirtualized: true,
          rowHeight: 50,
          showIcons: false,
        }}
        emptyMessage="No users found"
        height="full"
        singleSelection
        isLoading={props.isLoading}
        loadingMessage="Loading users"
        errorMessage={props.errorMessage ? props.errorMessage : undefined}
        renderOption={renderUser}
        options={props.options}
        onChange={props.setSelectedUser}
      >
        {(list, search) => (
          <>
            <EuiButton
              id="add-new-user"
              color="accent"
              onClick={props.onAddUser}
            >
              Add user
            </EuiButton>
            {search}
            {list}
          </>
        )}
      </EuiSelectable>
    </EuiErrorBoundary>
  );
};

export default SelectUserView;
