import { useState } from 'react';
import { SntSmsCommand } from 'gqlHooks';
import {
  EuiErrorBoundary,
  EuiForm,
  EuiSpacer,
  EuiFlexItem,
  EuiFlexGroup,
  EuiButton,
} from '@elastic/eui';

import { SntMqtt } from './mqtt/snt_mqtt';
import { SntCommandSelector } from './command/select-command';

interface SntSendSmsViewProps {
  /** True if the sms send is loading/sending data */
  isLoading: boolean;
  /** Calls for the page to reload the current set of data using the existing query variables */
  onSendSms: (message: string, broker?: string, port?: string) => void;
}

/**
 * @return {React.Element} Input fields for selecting/sending an sms command.
 */
export function SntSendSmsView({ isLoading, onSendSms }: SntSendSmsViewProps) {
  const [selectedCommand, setSelectedCommand] = useState(SntSmsCommand.CallHome);
  const [mqttBroker, setMqttBroker] = useState('');
  const [mqttPort, setMqttPort] = useState('');

  const mqttInputEnabled = selectedCommand === SntSmsCommand.MqttBroker;

  const handleSubmit = () => {
    if (mqttInputEnabled) {
      onSendSms(SntSmsCommand.MqttBroker, mqttBroker, mqttPort);
    } else {
      onSendSms(selectedCommand);
    }
  };

  const mqttFields = mqttInputEnabled ? (
    <SntMqtt
      port={mqttPort}
      broker={mqttBroker}
      setPort={setMqttPort}
      setBroker={setMqttBroker}
    />
  ) : null;

  return (
    <EuiErrorBoundary>
      <EuiForm>
        <EuiFlexGroup>
          <EuiFlexItem style={{ maxWidth: 300 }}>
            <SntCommandSelector
              selectedCommand={selectedCommand}
              setSelectedCommand={setSelectedCommand}
            />
          </EuiFlexItem>
          {mqttFields}
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiButton
              id="send-sms-command"
              color="primary"
              type="submit"
              fill
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              Send message
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </EuiErrorBoundary>
  );
}

export default SntSendSmsView;
