/**
 * Type Codes
 */
export enum SntDataTypeCode {
  PERIODIC = 'morey/snt/dt/report/periodic',
  BOOT = 'morey/snt/dt/report/boot',
  MOTION = 'morey/snt/dt/report/motion',
  STOLEN = 'morey/snt/dt/report/stolen',
  SYSLOG = 'morey/snt/dt/report/syslog',
  DISCONNECT = '$aws/events/presence/disconnected',
  CONNECT = '$aws/events/presence/connected',
}

export type SntDataTypeCodeValue = keyof typeof SntDataTypeCode;
export const SntDataTypeCodeReverseMapping: Record<string, SntDataTypeCodeValue> = {} as any;
Object.entries(SntDataTypeCode).forEach(([key, value]) => {
  if (typeof value === 'string') {
    SntDataTypeCodeReverseMapping[value] = key as SntDataTypeCodeValue;
  }
});

export enum SntCommandTypeCode {
  RESPONSE = 'morey/snt/cmd/response',
  FOTA_PROGRESS = 'morey/snt/cmd/fota/progress',
}

export type SntCommandTypeCodeValue = keyof typeof SntCommandTypeCode;
export const SntCommandTypeCodeReverseMapping: Record<string, SntCommandTypeCodeValue> = {} as any;
Object.entries(SntCommandTypeCode).forEach(([key, value]) => {
  if (typeof value === 'string') {
    SntCommandTypeCodeReverseMapping[value] = key as SntCommandTypeCodeValue;
  }
});

/**
 * Twilio SIM Events
 */
export enum SimEvenTypeCode {
  ATTACHMENT_ACCEPTED = 'attachment/accepted',
  ATTACHMENT_REJECTED = 'attachment/rejected',
  ATTACHMENT_FAILED = 'attachment/failed',
  DATA_STARTED = 'data-session/started',
  DATA_UPDATED = 'data-session/updated',
  DATA_ENDED = 'data-session/ended',
  DATA_FAILED = 'data-session/failed',
}

export type SimEvenTypeCodeValue = keyof typeof SimEvenTypeCode;
export const SimEvenTypeCodeReverseMapping: Record<string, SimEvenTypeCodeValue> = {} as any;
Object.entries(SimEvenTypeCode).forEach(([key, value]) => {
  if (typeof value === 'string') {
    SimEvenTypeCodeReverseMapping[value] = key as SimEvenTypeCodeValue;
  }
});

export enum DeviceType {
  SNT = 'snt',
}

export const FormatDeviceType: Record<DeviceType | string, string> = {
  [DeviceType.SNT]: 'Slap-N-Track',
  // Add more mappings if/when needed
};

// Event Messages Data
export type RawMessageProps = {
  /**
   * Voltage of the tracker in volts
   */
  battery_voltage: number;
  /**
   * Motion status of the tracker (ex: stationary)
   */
  motion_status: string;
  /**
   * Current temperature of the tracker in degrees celcius
   */
  temperature: number;
  /**
   * Date/time the message was created
   */
  time: string;
  /**
   * Type of the message (example: periodic)
   */
  type: string;
  /**
   * Type of the event (example: periodic)
   */
  event_type: string;
  /** Generated ID for the message, used by tables to track the expand/collapse state in the messages table  */
  PK: string;
  /** GPS location of the tracker */
  gps: {
    /** Accuracy of the GPS reading 10m is considered a good reading */
    accuracy: number;
    /** The z coordinate of the location */
    altitude: number;
    /** Latitude of the GPS location */
    latitude: number;
    /** Longitude of the GPS location */
    longitude: number;
  };
};
