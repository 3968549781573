import { Moment } from 'moment';
import { Messages } from 'gqlHooks';
import {
  EuiErrorBoundary,
  EuiFacetGroup,
  EuiFacetButton,
  EuiFlexItem,
  EuiFlexGroup,
  EuiSpacer,
  EuiText,
  OnRefreshChangeProps,
} from '@elastic/eui';
import SntEventMessages from 'layout/messages/snt/event/event-messages';
import SntSimMessages from 'layout/messages/snt/sim/sim-messages';
import { useState } from 'react';

import MessageFilters from '../filters/message-filters';
import { DeviceDetailTab } from './messages';

interface MessagesViewProps {
  /** True if a loading state should be displayed */
  isLoading: boolean;
  /** Messages to be displayed in the table */
  messages: Messages[];
  /** Currently selected message type being displayed - used to toggle table columns and filters */
  selectedMessageType: string;
  /** Invoked when the user wants to change which type of messages they are viewing */
  setSelectedMessageType: (type: string) => void;
  /** Invoked when the user requests a fresh search */
  handleSearch: (startDate: Moment, endDate: Moment) => void;
  /** Error message to be displayed in the table error section */
  errorMessage: string;
  /** invoked when the user wants different auto refresh options */
  onRefreshChange: (values: OnRefreshChangeProps) => void;
  /** True if the auto refresh is paused/disabled */
  isPaused: boolean;
  /** Refresh interval in milliseconds for the auto refresh */
  refreshInterval: number;
}

/**
 * View component for the snt messages. Handles selecting which type of messages to view,
 * displaying the messages, and displaying filters for the messages.
 */
export const MessagesView = (props: MessagesViewProps) => {
  /** Derived Values */
  const isEventTypeSelected = props.selectedMessageType === DeviceDetailTab.DEVICE;
  const [messagesQuery, setMessagesQuery] = useState<Messages[]>();

  /** Methods */
  // Helper for rendering the facets with the selected facet underlined and colored
  const renderFacet = (key: string, label: string) => {
    const isSelected = key === props.selectedMessageType;
    return (
      <EuiFacetButton
        key={key}
        id={key}
        isSelected={isSelected}
        onClick={() => props.setSelectedMessageType(key)}
        size="m"
      >
        <EuiText color={key === props.selectedMessageType ? 'accent' : ''}>
          {isSelected ? <u>{label}</u> : <p>{label}</p>}
        </EuiText>
      </EuiFacetButton>
    );
  };

  return (
    <EuiErrorBoundary>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFacetGroup>
            {renderFacet(DeviceDetailTab.DEVICE, 'Device')}
            {renderFacet(DeviceDetailTab.NETWORK, 'Network')}
          </EuiFacetGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <MessageFilters
            messagesQuery={messagesQuery}
            refreshInterval={props.refreshInterval}
            isPaused={props.isPaused}
            isLoading={props.isLoading}
            onSearch={props.handleSearch}
            onRefreshChange={props.onRefreshChange}
            messages={props.messages}
          />
          <EuiSpacer />
          {isEventTypeSelected ? (
            <EuiErrorBoundary>
              <SntEventMessages
                setMessagesQuery={setMessagesQuery}
                hideSerial={true}
                errorMessage={props.errorMessage}
                isLoading={props.isLoading}
                messages={props.messages}
              />
            </EuiErrorBoundary>
          ) : (
            <EuiErrorBoundary>
              <SntSimMessages
                setMessagesQuery={setMessagesQuery}
                errorMessage={props.errorMessage}
                isLoading={props.isLoading}
                messages={props.messages}
              />
            </EuiErrorBoundary>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiErrorBoundary>
  );
};

export default MessagesView;
