import { useState, useEffect, SyntheticEvent } from 'react';
import moment, { Moment } from 'moment';
import {
  EuiButton,
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiRefreshInterval,
  EuiSpacer,
} from '@elastic/eui';
import DatePicker from 'app/components/forms/date-picker/date-picker';

type RefreshChangeProps = {
  isPaused: boolean;
  refreshInterval: number;
};

interface SntConfigProps {
  /** How often (in milliseconds) the page should be auto refreshing */
  refreshInterval: number;
  /** Sets the auto refresh interval */
  setRefreshInterval: (refreshInterval: number) => void;
  /** Sets if the auto refresh should be paused */
  setIsPaused: (isPaused: boolean) => void;
  /** True if the auto refresh is paused */
  isPaused: boolean;
  /** Invoked when a search is requested */
  onSearch: (startDate: Moment, endDate: Moment) => void;
  /** True if the filters should be in their loading state */
  isLoading: boolean;
}

/** Filters for viewing all configuration requests */
export const SntConfigRequestsFilters = ({
  onSearch,
  refreshInterval,
  setRefreshInterval,
  setIsPaused,
  isPaused,
  isLoading,
}: SntConfigProps) => {
  // Tracks if the current data doesn't match the set filters
  const [isStale, setIsStale] = useState(false);
  // Filter Values for the start/end date
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days').startOf('day'));
  const [endDate, setEndDate] = useState(moment().add(1, 'days').endOf('day'));

  // Changes to start/end date only apply on search button click
  // Storing stale status to give users a visual prompt to hit the search button
  useEffect(() => setIsStale(true), [startDate, endDate]);

  // On initial load, run a search with default values
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleSearchButtonOnClick(null), []);

  const onRefreshChange = ({ isPaused: paused, refreshInterval: interval }: RefreshChangeProps) => {
    setIsPaused(paused);
    setRefreshInterval(interval);
  };

  const handleSearchButtonOnClick = (event: SyntheticEvent | null) => {
    // Forms by default call an html refresh. This prevents that.
    event ? event.preventDefault() : null;
    onSearch(startDate, endDate);
    setIsStale(false);
  };

  return (
    <EuiErrorBoundary id="snt-config-requests-filters">
      <EuiForm
        component="form"
        onSubmit={handleSearchButtonOnClick}
      >
        <DatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <EuiSpacer size="m" />
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiFormRow>
              <EuiButton
                id="snt-config-requests-refresh"
                color={isStale ? 'success' : 'primary'}
                iconType={isStale ? 'kqlFunction' : 'refresh'}
                fill
                type="submit"
                isLoading={isLoading}
              >
                {isStale ? 'Update' : 'Refresh'}
              </EuiButton>
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiRefreshInterval
              isPaused={isPaused}
              onRefreshChange={onRefreshChange}
              refreshInterval={refreshInterval}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </EuiErrorBoundary>
  );
};
