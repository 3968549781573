import { useState, useEffect } from 'react';
import { EuiErrorBoundary } from '@elastic/eui';
import { useInstallVerificationTags } from 'app/hooks/useInstallVerificationTags';
import { InstallCheckView } from 'layout/install/install-check-view';
import type { TagEntry } from 'models/install-verification-tags/types';

type InstallCheckProps = {
  deviceSerial: string;
};

export const InstallCheck = (props: InstallCheckProps) => {
  // Results and run query variables for the install check
  const [installVerificationTags, setInstallVerificationTags] = useState<TagEntry[]>();
  const [loadingInstallVerification, setLoadingInstallVerification] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<string | null>(null);

  const tagData = useInstallVerificationTags({
    deviceId: props.deviceSerial,
    setErrorState: setErrorState,
    setLoadingInstallVerification: setLoadingInstallVerification,
  })?.tags;

  useEffect(() => {
    const theTag = {
      deviceId: props.deviceSerial,
      tagData: tagData,
    };
    setInstallVerificationTags([theTag]);
  }, [props.deviceSerial, tagData]);

  return (
    <EuiErrorBoundary>
      <InstallCheckView
        isLoading={loadingInstallVerification}
        checkError={errorState}
        installInfo={installVerificationTags}
      />
    </EuiErrorBoundary>
  );
};
