import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';
interface ConfirmDeleteGroupProps {
  /** True if the dialogue should be open */
  isOpen?: boolean;
  /** Invoked when the user closes or cancels out of the dialogue */
  onClose: () => void;
  /** Method called when they confirm the group delete action */
  onRequestDelete: () => void;
}

/* Displays a dialog to confirm the deletion of a group */
export const ConfirmDeleteGroup: React.FC<ConfirmDeleteGroupProps> = (
  props: ConfirmDeleteGroupProps
) => {
  const cancelRequest = () => {
    props.onRequestDelete();
  };

  return props.isOpen ? (
    <EuiOverlayMask>
      <EuiConfirmModal
        buttonColor="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        defaultFocusedButton="confirm"
        onCancel={props.onClose}
        onConfirm={cancelRequest}
        title="Are you sure?"
      >
        <p>{`Are you sure this group should be deleted?`}</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  ) : null;
};

export default ConfirmDeleteGroup;
