// Constants shared between the various upload components

// Making all the file selectors agree on what NO SELECTION means
// EUI interprets undefined/null as an uncontrolled selector, so use empty string
export const NO_SELECTION = '';

// Row index from the csv file the data was read from
export const ROW_INDEX = 'row_index';
// Device ID - usually the IMEI
export const DEVICE_ID = 'device_id';
// Human readable device name
export const DEVICE_NAME = 'device_name';

export type DeviceUploadRow = {
  // Index is the row index
  [ROW_INDEX]: number;
  /** Device ID - for SnT this is the IMEI */
  [DEVICE_ID]: string;
  /** Name of the device - something human readable (hopefully) */
  [DEVICE_NAME]?: string;
};

// Column Index
export const COL_INDEX = 'col_index';
// Label for the selected column
export const COL_LABEL = 'label';

// Type for the parts that are used to define a column
export type ColumnHeadersType = {
  [COL_LABEL]: string;
  [COL_INDEX]: string;
};
