import { TagCollection } from 'models/install-verification-tags/Tags';
import { SlapAndTrackInstallVerificationTags } from 'models/install-verification-tags/slap-and-track-install-verification-tags/SlapAndTrackInstallVerificationTags';
import type {
  IInstallVerificationTags,
  IInstallVerificationTagData,
  ITag,
} from 'models/install-verification-tags/types';
import { TimeRange } from 'utils/dates';
import type { TimeRangeValue } from 'utils/dates';

class InstallVerificationTags extends TagCollection implements IInstallVerificationTags {
  protected _tags: ITag[] = [];

  constructor(
    public deviceId: string,
    public deviceType: string,
    public tagData: IInstallVerificationTagData,
    public tagFilterRange: TimeRangeValue
  ) {
    super();
    this.createDeviceTags();
  }

  get verificationTimeRange(): string {
    return TimeRange[this.tagFilterRange];
  }

  private createDeviceTags() {
    switch (this.deviceType) {
      case 'snt':
        return (super.tags = new SlapAndTrackInstallVerificationTags(
          this.tagData,
          this.tagFilterRange
        ).createTags());
      default:
        throw new Error(`Device type ${this.deviceType} not supported`);
    }
  }
}

export { InstallVerificationTags };
