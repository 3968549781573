import { CONFIG_TYPE_ICONS } from 'layout/config/snt/snt-config-utils';
import { EuiText, EuiErrorBoundary, EuiCommentList } from '@elastic/eui';
import { Maybe, RequestMessage, Scalars, RequestType } from 'gqlHooks';
import { SntConfigSummary } from 'layout/config/snt/requests/table/expand-section/summary/config/config-summary';
import { SntCommandSummary } from 'layout/config/snt/requests/table/expand-section/summary/command/command-summary';

import { SntOtaCredSummary } from './cred/cred-summary';

export interface SntSummarySectionProps {
  cmdRequest?: RequestMessage;
  requestResponse?: Maybe<Scalars['JSONString']['output']>;
}

// Figures out which of the three RequestType is being used, and returns the appropriate display
export const SntSummarySection = ({ cmdRequest, requestResponse }: SntSummarySectionProps) => {
  // Check if the request type was a command
  if (cmdRequest && cmdRequest.type === RequestType.Command) {
    const response = requestResponse ? JSON.parse(requestResponse) : undefined;

    return (
      <SntCommandSummary
        label={cmdRequest?.command ?? ''}
        icon={CONFIG_TYPE_ICONS.COMM}
        command={cmdRequest?.command ?? ''}
        response={response}
        isResponse={false}
      />
    );
  }

  // Check if it was a Cred request
  if (cmdRequest && cmdRequest.type === RequestType.Cred) {
    // Currently, only sysinfo should be returning response data
    // Including this generic in case that changes at least something will display
    const response = requestResponse ? JSON.parse(requestResponse) : undefined;

    return (
      <SntOtaCredSummary
        label={cmdRequest?.type}
        icon={'article'}
        response={response}
        isResponse={false}
      />
    );
  }

  // Only type left is Config. Responses can contain full RequestMessage config objects,
  // but without the typing. So check to see if we have a configuration to display
  const config = requestResponse ? requestResponse?.config : cmdRequest?.config;

  if (config) {
    return (
      <EuiErrorBoundary id="snt-summary-section">
        <EuiCommentList>
          <SntConfigSummary
            config={config?.comm}
            label={'Communication'}
            icon={CONFIG_TYPE_ICONS.COMM}
          />
          <SntConfigSummary
            config={config?.motion}
            label={'Motion'}
            icon={CONFIG_TYPE_ICONS.MOTION}
          />
          <SntConfigSummary
            config={config?.periodic}
            label={'Periodic'}
            icon={CONFIG_TYPE_ICONS.PERIODIC}
          />
          <SntConfigSummary
            config={config?.power}
            label={'Power'}
            icon={CONFIG_TYPE_ICONS.POWER}
          />
          <SntConfigSummary
            // Config has slightly different names for stolen asset on its response vs request
            config={requestResponse ? config?.stolen_asset : config.stolenAsset}
            label={'Stolen Asset'}
            icon={CONFIG_TYPE_ICONS.STOLEN}
          />
        </EuiCommentList>
      </EuiErrorBoundary>
    );
  }

  // If the request had either no typing or no config at all present, then display the no data found message
  return (
    <EuiText>
      <span>No request data found</span>
    </EuiText>
  );
};
