import { EuiErrorBoundary, EuiFormRow, EuiFieldText, EuiFlexItem } from '@elastic/eui';

interface SnTMqttProps {
  port: string;
  broker: string;
  setPort: (port: string) => void;
  setBroker: (broker: string) => void;
}

/** Additional SMS command fields used for setting MQTT Broker fields */
export const SntMqtt = ({ port, setPort, broker, setBroker }: SnTMqttProps) => {
  const smsBrokerOnChange = (event: { target: { value: string } }) => {
    setBroker(event.target.value);
  };

  const smsPortOnChange = (event: { target: { value: string } }) => {
    setPort(event.target.value);
  };

  return (
    <EuiErrorBoundary>
      <EuiFlexItem>
        <EuiFormRow label="MQTT Broker">
          <EuiFieldText
            onChange={smsBrokerOnChange}
            value={broker}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFormRow label="MQTT Port">
          <EuiFieldText
            onChange={smsPortOnChange}
            value={port}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiErrorBoundary>
  );
};
