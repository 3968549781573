import { useState, useEffect } from 'react';
import {
  EuiErrorBoundary,
  EuiFormRow,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFieldText,
  EuiFlyout,
  EuiForm,
  EuiFlyoutBody,
  EuiButton,
  EuiComboBoxOptionOption,
} from '@elastic/eui';
import { SelectGroup } from 'layout/groups/select/groups';
import { SelectDeviceType } from 'app/components/forms/select-device-type';

export type CreateGroupProps = {
  /** ID of the org the user is a part of */
  organizationId: string;
  /** Invoked when the user submits a new group to be created */
  onSubmit: (groupName: string, groupType: string, parentGroup: string) => void;
  /** True if there is data loading or a group creating */
  isLoading: boolean;
  /** Invoked when the flyout requests a close or exit */
  closeFlyout: () => void;
};

const MAX_PARENT_DEPTH = 9;
const MIN_GROUP_NAME_LENGTH = 3;
const INVALID_PARENT_DEPTH_MESSAGE =
  'Selected group has 10 layers of nested parents so cannot have a child added.';

export const CreateGroup = (props: CreateGroupProps) => {
  /** Hooks */
  const [groupName, setGroupName] = useState('');
  const [parentGroup, setParentGroup] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [generationalDepth, setGenerationalDepth] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState<EuiComboBoxOptionOption[]>([]);
  useEffect(() => {
    // Groups can go a max of 10 deep INCLUDING THE PARENT to prevent stupid.
    // Calculate depth based on number of / in the parent group path
    // sample token: parent/child1/child2/child3/child4/child5
    setGenerationalDepth((parentGroup?.match(/\//g) || []).length);
  }, [parentGroup]);

  /** Derived Values */
  const isParentGroupInvalid = generationalDepth >= MAX_PARENT_DEPTH;

  // Check the groupname contains only letters, numbers, spaces, or underscores
  const characterValidationRegex = new RegExp(`^[\\w\\s_]+$`);
  const isNameCharactersValid = characterValidationRegex.test(groupName);

  // Check the groupname meets the minimum length requirements
  const isGroupLengthValid = groupName.trim().length >= MIN_GROUP_NAME_LENGTH;
  const nameIsInvalid = !isNameCharactersValid || !isGroupLengthValid;

  // Build the validation messages to display
  const validationMessages = [];
  !isGroupLengthValid
    ? validationMessages.push('Name must contain at least three characters')
    : null;
  !isNameCharactersValid
    ? validationMessages.push('Name may only contain letters, numbers, spaces and underscores')
    : null;

  /** Methods */
  const handleParentGroupSelect = (parentGroupToken: EuiComboBoxOptionOption[]) => {
    setSelectedGroups(parentGroupToken);
    if (parentGroupToken.length > 0) {
      setParentGroup(parentGroupToken[0].key ?? '');
    } else {
      setParentGroup('');
    }
  };

  const handleGroupNameOnChange = (e: any) => {
    setGroupName(e.target.value);
  };

  const handleDeviceTypeChange = (deviceType: string) => {
    setDeviceType(deviceType);
  };

  const handleOnSubmit = (event: any) => {
    // Prevent default so the form doesnt refresh the page
    event.preventDefault();
    props.onSubmit(groupName, deviceType, parentGroup);
  };

  return (
    <EuiErrorBoundary>
      <EuiFlyout
        ownFocus
        onClose={props.closeFlyout}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Create group</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiForm
            component="form"
            onSubmit={handleOnSubmit}
          >
            <EuiFormRow
              label="Group name"
              isInvalid={nameIsInvalid}
              error={validationMessages}
            >
              <EuiFieldText
                placeholder="group name"
                value={groupName}
                required
                onChange={handleGroupNameOnChange}
                isInvalid={nameIsInvalid}
              />
            </EuiFormRow>
            <EuiFormRow label="Group device type">
              <SelectDeviceType
                onChange={handleDeviceTypeChange}
                selectedDeviceType={deviceType}
                hasNoInitialSelection
              />
            </EuiFormRow>
            <SelectGroup
              isSingleSelect
              onChange={handleParentGroupSelect}
              selectedGroups={selectedGroups}
              organizationId={props.organizationId}
              label="Parent group"
              isInvalid={isParentGroupInvalid}
              errorMessage={isParentGroupInvalid ? INVALID_PARENT_DEPTH_MESSAGE : ''}
            />
            <EuiButton
              id="create-group-submit"
              type="submit"
              color="primary"
              fill
              isLoading={props.isLoading}
              isDisabled={props.isLoading}
            >
              Create group
            </EuiButton>
          </EuiForm>
        </EuiFlyoutBody>
      </EuiFlyout>
    </EuiErrorBoundary>
  );
};
