import { Tag } from 'models/install-verification-tags/tag/Tag';
import type { IInstallVerificationTagData, TagType } from 'models/install-verification-tags/types';

export abstract class Firmware extends Tag {
  protected firmware: IInstallVerificationTagData['firmware'];

  constructor(tagType: TagType, tagData: IInstallVerificationTagData) {
    super(tagType, tagData);
    this.firmware = tagData.firmware?.trim();
  }

  protected isValidFirmwareData(): boolean {
    if (!this.firmware || !this.firmware.length) {
      return false;
    }
    return true;
  }
}
