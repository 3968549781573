import {
  EuiErrorBoundary,
  EuiFormRow,
  EuiSelectable,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import type { EuiSelectableOption } from '@elastic/eui';

export type SelectDevicesViewProps = {
  onChange: (event: any) => void;
  options: EuiSelectableOption[];
  disabled?: boolean;
  label?: string;
  isInvalid: boolean;
  errorMessage?: string | null;
  isLoading: boolean;
  isSingleSelect?: boolean;
  isRequired: boolean;
  onBlur?: (event: string | React.ChangeEvent<Element>) => void;
  inFlyout?: boolean;
};

export const SelectDevicesView = (props: SelectDevicesViewProps) => {
  const selectedOptions = props.options.filter(option => option.checked === 'on');
  const availableOptions = props.options.filter(option => option.checked !== 'on');

  if (props.inFlyout === true) {
    return (
      <EuiErrorBoundary>
        <EuiSelectable
          id={'flyoutDeviceSelect'}
          options={[...selectedOptions, ...availableOptions]}
          searchable
          onChange={props.onChange}
          aria-label={`Select ${props.label ?? 'device'}`}
          isLoading={props.isLoading}
          height={'full'}
          singleSelection={props.isSingleSelect}
        >
          {(list, search) => (
            <>
              <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                  <h2>{props.label}</h2>
                </EuiTitle>
                <EuiSpacer />
                {search}
              </EuiFlyoutHeader>
              <EuiSpacer size="xs" />
              {list}
            </>
          )}
        </EuiSelectable>
      </EuiErrorBoundary>
    );
  }
  return (
    <EuiErrorBoundary>
      <EuiFormRow label="Device IMEI">
        <EuiSelectable
          css={{ marginTop: '-25px' }}
          id={'deviceSelect'}
          options={[...selectedOptions, ...availableOptions]}
          searchable
          onChange={props.onChange}
          aria-label={`Select ${props.label ?? 'device'}`}
          isLoading={props.isLoading}
          height={300}
          singleSelection={props.isSingleSelect}
        >
          {(list, search) => (
            <>
              <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                  <h2>{props.label}</h2>
                </EuiTitle>
                <EuiSpacer />
                {search}
              </EuiFlyoutHeader>
              <EuiSpacer size="xs" />
              {list}
            </>
          )}
        </EuiSelectable>
      </EuiFormRow>
    </EuiErrorBoundary>
  );
};
