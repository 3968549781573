import { useEffect, useState } from 'react';
import { EuiFormRow, EuiSelect, EuiSelectOption } from '@elastic/eui';

import { COL_INDEX, COL_LABEL, ColumnHeadersType } from '../constants';

export const ROW = 'row';
export const NAME = 'name';
export const IS_VALID = 'valid';
export const NO_SELECTION = '';

type ColumnSelectProps = {
  /** The columns to be turned into selection options */
  columnHeaders: ColumnHeadersType[];
  /** True if the selector should be disabled */
  isDisabled: boolean;
  /** Label describing the selector */
  label: string;
  /** Notifies which column index is currently selected */
  onSelectionChange: (colIndex: string) => void;
  /** Column index identifier of currently selected option, defaults to no selection */
  selectedOption: string;
};

/* Takes the column headers created by the file picker, and creates a controlled drop down for selecting a column by its index */
export function ColumnSelect(props: ColumnSelectProps) {
  const [selectionOptions, setSelectionOptions] = useState<EuiSelectOption[]>([]);

  // If the options change, reset the selected option to no selection
  // Then regenerate the selection options
  useEffect(() => {
    props.onSelectionChange(NO_SELECTION);
    setSelectionOptions(
      props.columnHeaders.map((column: ColumnHeadersType) => {
        return { value: column[COL_INDEX], text: column[COL_LABEL] };
      })
    );
    // Again, the usual issue where it wants props itself
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columnHeaders, props.onSelectionChange]);

  const onSelectedColumnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onSelectionChange(event.target.value);
  };

  return (
    <EuiFormRow label={props.label}>
      <EuiSelect
        disabled={props.isDisabled}
        hasNoInitialSelection
        id="serialColumnSelect"
        onChange={onSelectedColumnChange}
        options={selectionOptions ?? []}
        required
        value={props.selectedOption}
      />
    </EuiFormRow>
  );
}
