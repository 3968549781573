import {
  EuiDescribedFormGroup,
  EuiErrorBoundary,
  EuiFieldNumber,
  EuiForm,
  EuiFormRow,
  EuiRadioGroup,
  EuiText,
} from '@elastic/eui';
import { Configuration } from 'app/generated/graphql';
import { ConfigPanel } from 'layout/config/panel/config-panel';
import { SntTimeSelector } from 'layout/config/snt/time-selector/time-selector';
import { Control, Controller } from 'react-hook-form';

import * as STOLEN_CONSTANTS from './stolen-config-defaults';
import { parseNumberOnChange } from '../snt-config-utils';

interface SntStolenConfigViewProps {
  /** Used for handling the state of the panel. Collapsed or expanded. */
  isExpanded: boolean;
  control: Control<Configuration>;
}

/**
 * Configuration options for detecting if the device has been stolen.
 * Enable/disable stolen mode, set thresholds for marking an asset as stolen
 */
export default function SntStolenConfig({ isExpanded, control }: SntStolenConfigViewProps) {
  const handleOnChange = (
    newValue: number,
    currentEndValue: number | string,
    startOnChange: (...event: any[]) => void,
    endOnChange: (...event: any[]) => void
  ) => {
    // If toggling to disabled, set start/end to disabled
    if (newValue === STOLEN_CONSTANTS.WORKING_HOURS_DISABLED_VALUE) {
      startOnChange(newValue);
      endOnChange(newValue);
    }
    // If swapping off disabled, switch both to not disabled
    else if (
      newValue !== STOLEN_CONSTANTS.WORKING_HOURS_DISABLED_VALUE &&
      (currentEndValue === STOLEN_CONSTANTS.WORKING_HOURS_DISABLED_VALUE || currentEndValue === '')
    ) {
      startOnChange(newValue);
      endOnChange(newValue);
    } else {
      startOnChange(newValue);
    }
  };

  const getModeText = (mode: string) => {
    switch (mode) {
      case STOLEN_CONSTANTS.MODE_AUTO:
        return `
    Auto: Determines stolen status using working hours and geofence. Requires working hours to be set.`;
      case STOLEN_CONSTANTS.MODE_FORCED:
        return `Forced: Force the device into Stolen mode. Note that this status will persist
    even through resets until changed to either "auto" or "disabled".`;
      case STOLEN_CONSTANTS.MODE_DISABLED:
        return `Disabled: Stolen asset determination is disabled.`;
      default:
        return '';
    }
  };

  return (
    <EuiErrorBoundary>
      <EuiForm component="form">
        <ConfigPanel
          expandAll={isExpanded}
          title="Stolen"
          icon="securitySignal"
          description="Stolen asset monitoring options"
          isLoading={false}
        >
          <Controller
            name="stolenAsset.enable"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <EuiDescribedFormGroup
                title={<h3>Stolen Asset Mode</h3>}
                description={
                  <EuiText size="s">
                    <p>Determines if the stolen mode should be enabled or disabled.</p>
                    <p>{getModeText(value!)}</p>
                    <p>Default: disabled</p>
                  </EuiText>
                }
              >
                <EuiRadioGroup
                  onBlur={onBlur}
                  options={STOLEN_CONSTANTS.STOLEN_MODE_OPTIONS}
                  idSelected={value!}
                  onChange={onChange}
                  name="stolen-mode-selector"
                />
              </EuiDescribedFormGroup>
            )}
          />
          <EuiDescribedFormGroup
            title={<h3>Report Interval</h3>}
            description={
              <EuiText size="s">
                <p>The interval in seconds between stolen asset reports.</p>
                <p>{`Default: ${STOLEN_CONSTANTS.REPORT_INTERVAL_DEFAULT} seconds`}</p>
              </EuiText>
            }
          >
            <Controller
              name="stolenAsset.reportInterval"
              control={control}
              rules={{
                min: STOLEN_CONSTANTS.REPORT_INTERVAL_MIN,
                max: STOLEN_CONSTANTS.REPORT_INTERVAL_MAX,
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  isInvalid={invalid}
                  error={error?.message}
                  label="Report Interval"
                  helpText={`Valid range: ${STOLEN_CONSTANTS.REPORT_INTERVAL_MIN} - ${STOLEN_CONSTANTS.REPORT_INTERVAL_MAX} seconds`}
                >
                  <EuiFieldNumber
                    min={STOLEN_CONSTANTS.REPORT_INTERVAL_MIN}
                    max={STOLEN_CONSTANTS.REPORT_INTERVAL_MAX}
                    value={value!}
                    append={['seconds']}
                    onChange={event => onChange?.(parseNumberOnChange(event))}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
          </EuiDescribedFormGroup>
          <EuiDescribedFormGroup
            title={<h3>Working Hours</h3>}
            description={
              <EuiText size="s">
                <p>
                  The time in 24 hour format that defines normal working hours. During work hours,
                  monitoring for a stolen asset is disabled. If start time and end time are the
                  same, working hours is disabled.
                </p>
                <p>Default: Disabled</p>
              </EuiText>
            }
          >
            {/**
             * Unsure how to set rules for numbers that skip 60-99 on each interval
             * from 0000-2459 but also includes 9999
             */}
            <Controller
              name="stolenAsset.workingHoursStart"
              control={control}
              rules={{
                min: 0,
                max: STOLEN_CONSTANTS.WORKING_HOURS_DISABLED_VALUE,
              }}
              render={({
                field: { onChange: startOnChange, onBlur: startOnBlur, value: startValue },
              }) => {
                return (
                  // Nesting the start/end time as they are being controlled by one on/off switch
                  <Controller
                    name="stolenAsset.workingHoursEnd"
                    control={control}
                    rules={{
                      min: 0,
                      max: 9999,
                    }}
                    render={({
                      field: { onChange: endOnChange, onBlur: endOnBlur, value: endValue },
                    }) => (
                      <EuiErrorBoundary id="nested-start-end-time">
                        <SntTimeSelector
                          onBlur={startOnBlur}
                          disabledValue={STOLEN_CONSTANTS.WORKING_HOURS_DISABLED_VALUE}
                          includeToggle
                          toggleTitle="Enable Working Hours"
                          timeFieldTitle="Working Hours Start"
                          setTimeValue={newValue =>
                            handleOnChange(newValue, endValue!, startOnChange, endOnChange)
                          }
                          timeValue={startValue!}
                        />
                        <SntTimeSelector
                          onBlur={endOnBlur}
                          includeToggle={false}
                          disabledValue={STOLEN_CONSTANTS.WORKING_HOURS_DISABLED_VALUE}
                          timeFieldTitle="Working Hours End"
                          setTimeValue={endOnChange}
                          timeValue={endValue!}
                        />
                      </EuiErrorBoundary>
                    )}
                  />
                );
              }}
            />
          </EuiDescribedFormGroup>
          <EuiDescribedFormGroup
            title={<p />}
            description={
              <EuiText size="s">
                <p>
                  Geofence triggers stolen asset mode if the asset moves further than the geofence
                  distance during non-work hours.
                </p>
                <p>{`Default: ${STOLEN_CONSTANTS.GEOFENCE_DEFAULT} meters`}</p>
              </EuiText>
            }
          >
            <Controller
              name="stolenAsset.geofence"
              control={control}
              rules={{
                min: STOLEN_CONSTANTS.GEOFENCE_MIN,
                max: STOLEN_CONSTANTS.GEOFENCE_MAX,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  label="Geofence Size"
                  helpText={`Valid range: ${STOLEN_CONSTANTS.GEOFENCE_MIN} - ${STOLEN_CONSTANTS.GEOFENCE_MAX} meters `}
                >
                  <EuiFieldNumber
                    onBlur={onBlur}
                    min={STOLEN_CONSTANTS.GEOFENCE_MIN}
                    max={STOLEN_CONSTANTS.GEOFENCE_MAX}
                    value={value!}
                    append={['meters']}
                    onChange={event => onChange?.(parseNumberOnChange(event))}
                  />
                </EuiFormRow>
              )}
            />
          </EuiDescribedFormGroup>
        </ConfigPanel>
      </EuiForm>
    </EuiErrorBoundary>
  );
}
