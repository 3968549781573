import { useState } from 'react';
import { EuiTab, EuiSpacer, EuiErrorBoundary, EuiTabs } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

import { AddDeviceToOrganizationForm } from './single-submit/index.js';
import UploadDevicesToOrg from './upload/upload-devices.js';

const UPLOAD = 'upload';
const SINGLE = 'single';

type AddDevicesToOrgViewProps = {
  /** Handler for adding toasts to the global list */
  addToastMessage: (toasty: Toast) => void;
  /** Identifier for the users organization */
  organizationId: string;
  /** True if the user is an admin and should have the admin specific options available */
  isUserAdmin: boolean;
};

/**
 * Display component - provides users ways to upload trackers and their phone numbers
 * @returns {React.Element} PhoneNumbersView
 */
export default function AddDevicesToOrgView(props: AddDevicesToOrgViewProps) {
  const [selectedTabId, setSelectedTabId] = useState<string>(SINGLE);

  // Define the tab content
  const tabs = [
    {
      id: SINGLE,
      name: 'Single Entry',
      content: (
        <AddDeviceToOrganizationForm
          userOrganizationId={props.organizationId}
          addToastMessage={props.addToastMessage}
        />
      ),
    },
    {
      id: UPLOAD,
      name: 'File Upload',
      content: (
        <EuiErrorBoundary>
          <UploadDevicesToOrg
            isUserAdmin={props.isUserAdmin}
            addToastMessage={props.addToastMessage}
            organizationId={props.organizationId}
          />
        </EuiErrorBoundary>
      ),
    },
  ];

  // Build the tabs
  const renderTabs = () => {
    return tabs.map(tab => (
      <EuiTab
        isSelected={tab.id === selectedTabId}
        key={tab.id}
        onClick={() => {
          setSelectedTabId(tab.id);
        }}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  // Finds the selected tab content
  const selectedTabContent = tabs.find(obj => obj.id === selectedTabId)?.content;

  return (
    <EuiErrorBoundary>
      <EuiTabs>{renderTabs()}</EuiTabs>
      <EuiSpacer size="m" />
      {selectedTabContent}
    </EuiErrorBoundary>
  );
}
