import { EuiTableFieldDataColumnType } from '@elastic/eui';

const renderParent = (groupToken: string) => {
  // tokens are form parent/child/child of child/this group
  // So if there are slashes in the name, getting the parent is the second to last group
  const parentTree = groupToken.split('/');
  if (parentTree.length > 1) {
    // Arrays index starting at 0. Length starts at 1, so subtract 2 to get the second to last entry
    return parentTree[parentTree.length - 2];
  }
  return groupToken;
};

const groupName: EuiTableFieldDataColumnType<any> = {
  field: 'label',
  name: 'Name',
  dataType: 'string',
  truncateText: false,
};

const groupType: EuiTableFieldDataColumnType<any> = {
  field: 'type',
  name: 'Device type',
  dataType: 'string',
  truncateText: false,
};

const groupParent: EuiTableFieldDataColumnType<any> = {
  field: 'identifier',
  name: 'Parent group',
  dataType: 'string',
  truncateText: false,
  render: renderParent,
};

export const GROUPS_TABLE_COLUMNS: EuiTableFieldDataColumnType<any>[] = [
  groupName,
  groupType,
  groupParent,
];
