import { EuiSpacer, EuiErrorBoundary, SearchFilterConfig } from '@elastic/eui';
import MessagesAndSimTable from 'app/layout/messages/table/table';
import type { Messages } from 'gqlHooks';
import { SntCommandTypeCode, SntDataTypeCode } from 'app/types';

import {
  MESSAGE_TABLE_COLUMNS_WITHOUT_SERIAL,
  MESSSAGE_TABLE_COLUMNS_WITH_SERIAL,
} from './table-columns';
import SNTMessageInfo from '../info/message-info';

interface SntEventMessagesProps {
  /** True if the message data is loading or reloading */
  isLoading: boolean;
  /** If set, then the query returned an error message and likely failed to load any data */
  errorMessage?: string;
  /** Messages to be displayed*/
  messages: Messages[];
  /** True if the table should hide the serials column - on single device message displays this column is 'noise' */
  hideSerial?: boolean;
  /** Sets message data used for CSV export **/
  setMessagesQuery: (messages: Messages[]) => void;
}

const MESSAGE_EVENT_TYPE_OPTIONS = [
  ...Object.keys(SntCommandTypeCode).map(key => ({
    value: SntCommandTypeCode[key as keyof typeof SntCommandTypeCode],
    name: key.toLowerCase(),
    label: key.toLowerCase().split('/').pop(),
  })),
  ...Object.keys(SntDataTypeCode).map(key => ({
    value: SntDataTypeCode[key as keyof typeof SntDataTypeCode],
    name: key.toLowerCase(),
    label: key.toLowerCase().split('/').pop(),
  })),
];

/** Displays messages returned by the trackers */
export function SntEventMessages(props: SntEventMessagesProps) {
  // Drop down filter for selecting message event types for the snt
  const sntEventTypeSelectorFilter: SearchFilterConfig = {
    type: 'field_value_selection',
    field: 'typeCode',
    name: 'Event type',
    multiSelect: 'or',
    options: MESSAGE_EVENT_TYPE_OPTIONS,
  };

  // Help button for seeing information about the snt messages
  const sntMessageHelp: SearchFilterConfig = {
    type: 'custom_component',
    component: () => <SNTMessageInfo />,
  };

  const eventMessageFilters: SearchFilterConfig[] = [sntEventTypeSelectorFilter, sntMessageHelp];

  return (
    <EuiErrorBoundary>
      <MessagesAndSimTable
        errorMessage={props.errorMessage}
        isLoading={props.isLoading}
        messages={props.messages}
        columns={
          props.hideSerial
            ? MESSAGE_TABLE_COLUMNS_WITHOUT_SERIAL
            : MESSSAGE_TABLE_COLUMNS_WITH_SERIAL
        }
        filters={eventMessageFilters}
        setMessagesQuery={props.setMessagesQuery}
      />
      <EuiSpacer size="m" />
    </EuiErrorBoundary>
  );
}

export default SntEventMessages;
