import { TagType } from 'models/install-verification-tags/types';
import { Tags } from 'models/install-verification-tags/Tags';
import { SlapAndTrackBattery } from 'models/install-verification-tags/tag/battery/slap-and-track-battery/SlapAndTrackBattery';
import { SlapAndTrackLocation } from 'models/install-verification-tags/tag/location/slap-and-track-location/SlapAndTrackLocation';
import { SlapAndTrackStolen } from 'models/install-verification-tags/tag/stolen/slap-and-track-stolen/SlapAndTrackStolen';
import { SlapAndTrackFirmware } from 'models/install-verification-tags/tag/firmware/slap-and-track-firmware/SlapAndTrackFirmware';
import type { IInstallVerificationTagData, ITag } from 'models/install-verification-tags/types';
import type { TimeRangeValue } from 'utils/dates';

class SlapAndTrackInstallVerificationTags extends Tags {
  constructor(tagData: IInstallVerificationTagData, tagFilterRange: TimeRangeValue) {
    super(tagData, tagFilterRange);
  }

  createTags() {
    return [
      this.createBatteryTag(),
      this.createLocationTag(),
      this.createStolenTag(),
      this.createFirmwareTag(),
    ].filter((tag): tag is ITag => tag !== undefined);
  }

  private createBatteryTag(): ITag {
    return new SlapAndTrackBattery(TagType.BATTERY, this.tagData).createTag();
  }

  private createLocationTag(): ITag {
    return new SlapAndTrackLocation(TagType.LOCATION, this.tagData).createTag();
  }

  private createStolenTag(): ITag | undefined {
    const tag = new SlapAndTrackStolen(TagType.STOLEN, this.tagData).createTag();
    return tag;
  }

  private createFirmwareTag(): ITag {
    return new SlapAndTrackFirmware(TagType.FIRMWARE, this.tagData).createTag();
  }
}

export { SlapAndTrackInstallVerificationTags };
