import {
  EuiErrorBoundary,
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageSection,
  EuiPanel,
} from '@elastic/eui';
import { Helmet } from 'react-helmet';
import Navigation from 'layout/navigation/navigation';
import metalPattern from 'assets/metal_header_pattern.png';

interface PageTemplateProps {
  documentTitle?: string;
  title?: string | JSX.Element;
  /* Content of the page body to be displayed */
  children: React.ReactNode;
  tabs?: Array<any>;
  description?: string;
  rightSideItems?: React.ReactNode[];
  subHeaderContent?: React.ReactNode;
}

/*
 * Default template for a page within the app. Displays the title with
 * the themed background image. Children should be contained in PageSection components
 */
export default function PageTemplate({
  documentTitle,
  title = '',
  children,
  tabs,
  description = '',
  rightSideItems,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  subHeaderContent,
}: PageTemplateProps) {
  // The machineline themed background image. Will display in the header.
  const backGroundPattern = () => ({ backgroundImage: `url(${metalPattern})` });

  return (
    <EuiErrorBoundary>
      <Helmet>
        <title>{documentTitle ? `${documentTitle} | ` : ''}Device Manager</title>
      </Helmet>
      <EuiPage paddingSize="s">
        <EuiPageBody
          css={backGroundPattern}
          paddingSize="none"
          panelled={true}
        >
          <EuiPageSection>
            <EuiPageHeader
              description={description}
              pageTitle={title}
              tabs={tabs}
              rightSideItems={rightSideItems}
            />
          </EuiPageSection>
          <EuiPanel>
            <>
              <Navigation />
              {children}
            </>
          </EuiPanel>
        </EuiPageBody>
      </EuiPage>
    </EuiErrorBoundary>
  );
}
