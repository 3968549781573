import { useState } from 'react';
import { Moment } from 'moment';
import {
  useLocalStorage,
  MESSAGES_REFRESH_INTERVAL,
  MESSAGES_REFRESH_ENABLED,
} from 'hooks/use-state-local-cache';
import { getAppSyncQueryDate } from 'utils/dates';
import {
  useGetDeviceMessagesCreatedAtQuery,
  GetDeviceMessagesCreatedAtQueryVariables,
  GetDeviceMessagesCreatedAtQuery,
  Messages,
  useGetSimEventsCreatedAtQuery,
  GetSimEventsCreatedAtQuery,
} from 'gqlHooks';
import { CONSTANTS } from 'utils/constants';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { EuiErrorBoundary, OnRefreshChangeProps } from '@elastic/eui';

import MessagesView from './message-view';

// Setting the limit to something it should never hit for SnT
// This way the in memory table can handle filtering
const MESSAGES_LIMIT = 10000;

export enum DeviceDetailTab {
  DEVICE = 'device',
  NETWORK = 'network',
}

interface MessagesProps {
  /** Serial of the device whose config is being modified */
  deviceSerial: string;
  /** Adds a toast to the global message queue */
  addToastMessage: (message: Toast) => void;
}

export const SntMessages = (props: MessagesProps) => {
  /** Hooks */
  const [selectedMessageType, setSelectedMessageType] = useState<string>(DeviceDetailTab.DEVICE);
  const [messages, setMessages] = useState<Messages[]>([]);
  const [simMessages, setSimMessages] = useState<Messages[]>([]);

  // Auto refresh of data - options saved to web browsers local storage
  const [refreshInterval, setRefreshInterval] = useLocalStorage(
    MESSAGES_REFRESH_INTERVAL,
    CONSTANTS.FIFTEEN_SECONDS_IN_MILLISECONDS
  );
  const [isPaused, setIsPaused] = useLocalStorage(MESSAGES_REFRESH_ENABLED, false);

  // Variables used by the getters to retrieve the messages data
  const [queryVar, setQueryVar] = useState<GetDeviceMessagesCreatedAtQueryVariables>({
    deviceId: props.deviceSerial,
    start: '',
    limit: MESSAGES_LIMIT,
    end: '',
    nextToken: null,
  });

  // Loads the device event messages
  const {
    loading: isLoadingEvents,
    error,
    refetch,
  } = useGetDeviceMessagesCreatedAtQuery({
    variables: queryVar,
    onCompleted: (data: GetDeviceMessagesCreatedAtQuery) => {
      const messagey = data?.deviceCreatedAt?.items ?? [];
      setMessages(messagey as Messages[]);
    },
    pollInterval: isPaused ? 0 : refreshInterval,
    notifyOnNetworkStatusChange: true,
  });

  // Loads the devices sim messages
  const {
    loading: isLoadingSim,
    error: simError,
    refetch: refetchSimMessages,
  } = useGetSimEventsCreatedAtQuery({
    variables: queryVar,
    onCompleted: (data: GetSimEventsCreatedAtQuery) => {
      const messagey = data?.simCreatedAt?.items ?? [];
      setSimMessages(messagey as Messages[]);
    },
    pollInterval: isPaused ? 0 : refreshInterval,
    notifyOnNetworkStatusChange: true, // loading status will be updated when doing polling with this set
  });

  /** Derived Values */
  const isEventTypeSelected = selectedMessageType === DeviceDetailTab.DEVICE;
  const isLoading = isEventTypeSelected ? isLoadingEvents : isLoadingSim;
  const hasError = isEventTypeSelected ? error !== undefined : simError !== undefined;

  /** Methods */
  // Checks if any variables have changed for the query
  const compareQueryVars = (startDate: string, endDate: string) => {
    if (!queryVar) {
      return null;
    }
    const { start, end } = queryVar;
    return startDate === start && endDate === end;
  };

  const handleSearch = (startDate: Moment, endDate: Moment) => {
    // Parse the dates into the format the API is expecting
    const parsedStartDate = getAppSyncQueryDate(startDate.toDate());
    const parsedEndDate = getAppSyncQueryDate(endDate.toDate());

    // If no query variables have changed, then need to call a refetch
    // as apollo will normally only trigger a reload on query variable change
    if (compareQueryVars(parsedStartDate, parsedEndDate)) {
      isEventTypeSelected ? refetch() : refetchSimMessages();
      return;
    }

    // Update the query variables so apollo will fetch the new data
    setQueryVar({
      deviceId: props.deviceSerial,
      start: parsedStartDate,
      limit: MESSAGES_LIMIT,
      end: parsedEndDate,
      nextToken: null,
    });
  };

  const onRefreshChange = ({
    isPaused: paused,
    refreshInterval: interval,
  }: OnRefreshChangeProps) => {
    setIsPaused(paused);
    setRefreshInterval(interval);
  };

  return (
    <EuiErrorBoundary id="snt-messages-view-error-boundary">
      <MessagesView
        isLoading={isLoading}
        messages={isEventTypeSelected ? messages : simMessages}
        selectedMessageType={selectedMessageType}
        setSelectedMessageType={setSelectedMessageType}
        handleSearch={handleSearch}
        errorMessage={hasError ? 'Failed to load messages for device' : ''}
        onRefreshChange={onRefreshChange}
        isPaused={isPaused}
        refreshInterval={refreshInterval}
      />
    </EuiErrorBoundary>
  );
};

export default SntMessages;
