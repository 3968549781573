import { FotaType } from 'gqlHooks';
import {
  EuiButton,
  EuiButtonGroup,
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiSelectOption,
} from '@elastic/eui';
import { SyntheticEvent } from 'react';

interface SntFotaSelectProps {
  /** Available firmware options the device may be updated to */
  firmwareOptions: EuiSelectOption[];
  /** Type of firmware that should be loaded */
  firmwareType: FotaType;
  /** True if the cancel or submit fota is processing */
  isSubmitting: boolean;
  /** Handler for the user requesting a firmaware update */
  onUpdateFirmware: (event: SyntheticEvent | null) => void;
  /** ID of the currently selected type, may be null if no type has been selected*/
  selectedFirmware?: string | number | undefined;
  /**Handler for change in selected firmware*/
  setSelectedFirmware: (selectedType: string) => void;
  /** Handler for changing the type of firmware being displayed */
  setSelectedFirmwareType: (selectedType: FotaType) => void;
}

/** Select a fota updatee to be sent */
export const SntFotaSelect = (props: SntFotaSelectProps) => {
  const handleTypeChange = (event: { target: { value: string } }) =>
    props.setSelectedFirmware(event.target.value);

  const toggleFotaTypes = [
    {
      id: FotaType.App,
      label: 'App',
    },
    {
      id: FotaType.Modem,
      label: 'Modem',
    },
  ];

  return (
    <EuiErrorBoundary>
      <EuiForm
        component="form"
        onSubmit={props.onUpdateFirmware}
        isInvalid={!props.selectedFirmware}
        invalidCallout="none"
      >
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiFormRow label="Firmware type">
              <EuiButtonGroup
                legend="Selected firmware type"
                options={toggleFotaTypes}
                idSelected={props.firmwareType}
                onChange={id => props.setSelectedFirmwareType(id as FotaType)}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFormRow label="Firmware versions">
              <EuiSelect
                disabled={props.isSubmitting}
                hasNoInitialSelection
                id="firmwareVersionSelect"
                onChange={handleTypeChange}
                options={props.firmwareOptions ?? []}
                required
                value={props.firmwareOptions ? props.selectedFirmware : ''}
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiButton
          id="snt-submit-fota-request"
          type="submit"
          fill
          color="primary"
          isLoading={props.isSubmitting}
        >
          Submit request
        </EuiButton>
      </EuiForm>
    </EuiErrorBoundary>
  );
};

export default SntFotaSelect;
