import { Periodic } from 'app/generated/graphql';
import { hourTimeIntToDate } from 'app/utils/dates';
import { MlEuiCommentType, buildEuiComment } from 'utils/configTranslator';
import moment from 'moment-timezone';

// Report Interval Values
export const REPORT_INTERVAL_DEFAULT = 43200; // seconds
export const REPORT_INTERVAL_MIN = 3600; // seconds
export const REPORT_INTERVAL_MAX = 86400; // seconds

// Offset Values
export const OFFSET_MIN = 0; // minutes
export const OFFSET_DEFAULT = OFFSET_MIN; // minutes
export const OFFSET_MAX = 720; // minutes

// Initial Time
export const INITIAL_TIME_DISABLED = 9999;
export const INITIAL_TIME_DEFAULT = INITIAL_TIME_DISABLED;

export const PERIODIC_CONFIG_DEFAULTS: Periodic = {
  reportInterval: REPORT_INTERVAL_DEFAULT,
  reportInitialTime: INITIAL_TIME_DEFAULT,
  reportInitialOffset: OFFSET_DEFAULT,
};

export const PERIODIC_GROUP_CONFIG_DEFAULTS: Periodic = {
  //@ts-expect-error form fields accept empty string for unset number fields
  reportInterval: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  reportInitialTime: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  reportInitialOffset: '',
};

// Builds items for an EUI comment list, in translated human readable format
export const periodicConfigTranslator = (periodicConfig: Periodic | undefined | null) => {
  if (!periodicConfig) {
    return undefined;
  }
  const localTimezoneAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
  const builtItems: Array<MlEuiCommentType> = [];

  if (periodicConfig.reportInterval) {
    builtItems.push(
      buildEuiComment(
        'Periodic Reporting Interval',
        `Every ${periodicConfig.reportInterval} seconds`
      )
    );
  }
  if (periodicConfig.reportInitialTime) {
    let description;
    if (periodicConfig.reportInitialTime === INITIAL_TIME_DISABLED) {
      description = 'Disabled';
    } else {
      const initialTime = hourTimeIntToDate(periodicConfig.reportInitialTime).format('H:mm a');
      const initialTimeWithZone = `${initialTime} ${localTimezoneAbbr}`;
      description = initialTimeWithZone;
    }
    builtItems.push(buildEuiComment('Fixed Reporting Time', description));
  }
  if (periodicConfig.reportInitialOffset) {
    builtItems.push(
      buildEuiComment('Report Offset', `${periodicConfig.reportInitialOffset} minutes`)
    );
  }

  return builtItems;
};
