import { Tag } from 'models/install-verification-tags/tag/Tag';
import type { IInstallVerificationTagData, TagType } from 'models/install-verification-tags/types';

export abstract class Stolen extends Tag {
  protected stolen: IInstallVerificationTagData['stolen'];

  constructor(tagType: TagType, tagData: IInstallVerificationTagData) {
    super(tagType, tagData);
    this.stolen = tagData.stolen;
  }
}
