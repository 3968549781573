import { matchRoutes, useLocation } from 'react-router-dom';
import { appRoutes } from 'router/appRoutes';

export type CurrentPath =
  | {
      pathname: string;
      pathnameBase: string;
    }
  | undefined;

export const useCurrentPath = (): CurrentPath => {
  const location = useLocation();
  const route = matchRoutes(appRoutes, location);

  return route?.pop();
};
