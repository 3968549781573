import { useState } from 'react';
import { EuiErrorBoundary, EuiSelectableOption } from '@elastic/eui';
import { useGetUsersByOrganizationIdQuery } from 'gqlHooks';
import type { GenericRow } from 'gqlHooks';
import { EuiSelectableOnChangeEvent } from '@elastic/eui/src/components/selectable/selectable';

import SelectUserView from './select-user-view';

export type SelectUserRef = {
  getUserObjectByIdentifier: (userIdentifier: string) => GenericRow | undefined;
};

export type SelectUserProps = {
  /** ID of the organization whose users should be loaded */
  organizationId?: string;
  /** Notified when a user is selected  */
  setSelectedUser: (user: GenericRow | undefined) => void;
  /** True if the user selection component should be disabled */
  disabled: boolean;
  /**Invoked when the add user button is pressed */
  onAddUser: () => void;
  /** What user the parent thinks is selected */
  selectedUser: GenericRow | undefined;
};

export default function SelectUser(props: SelectUserProps) {
  const [options, setOptions] = useState<EuiSelectableOption<GenericRow>[]>([]);

  // Load the users for the current organization
  const { loading: usersLoading, error } = useGetUsersByOrganizationIdQuery({
    variables: {
      limit: 1000,
      organizationId: props.organizationId ?? '',
    },
    onCompleted: data => {
      const users = data.users.items;
      // Build the selectable/searchable user items
      setOptions(
        users.map(
          (user): EuiSelectableOption => ({
            label: `${user.label}`, // users name
            searchableLabel: `${user.label} ${user.identifier} ${user.type?.toString()}`,
            key: user.identifier!, // Users email address
            data: user,
          })
        )
      );

      // This is a hack because the API doesn't return the updated user info on edit
      // Parent NEEDS the updated selected user. But has no way of finding it when changed
      // without maintaining its own copy of the user list
      if (props.selectedUser !== undefined) {
        const found = users.find(user => user.identifier === props.selectedUser?.identifier);
        props.setSelectedUser(found);
      }
    },
    skip: props.organizationId === undefined || props.organizationId === '',
  });

  const handleOnUserChange = (
    newOptions: EuiSelectableOption[],
    _unused: EuiSelectableOnChangeEvent,
    changedOption: EuiSelectableOption<GenericRow>
  ) => {
    setOptions(newOptions);
    props.setSelectedUser(changedOption.data);
  };

  return (
    <EuiErrorBoundary>
      <SelectUserView
        setSelectedUser={handleOnUserChange}
        isLoading={usersLoading}
        errorMessage={error ? 'Failed to load users' : undefined}
        options={options}
        setOptions={setOptions}
        onAddUser={props.onAddUser}
      />
    </EuiErrorBoundary>
  );
}
