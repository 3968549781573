import { IInstallVerificationTagData, ITag } from 'models/install-verification-tags/types';
import { getFormattedDateFromRange } from 'utils/dates';
import type { TimeRangeValue } from 'utils/dates';

/**
 * Stores a collection of Install Verification Tags
 * for a given device
 */
abstract class TagCollection {
  protected _tags: ITag[] = [];

  get tags(): ITag[] {
    return this._tags;
  }
  set tags(tags: ITag[]) {
    this._tags.push(...tags);
  }
}

abstract class Tags {
  public tagData: IInstallVerificationTagData;
  protected tagFilterRangeDate: string;

  constructor(tagData: IInstallVerificationTagData, tagFilterRange: TimeRangeValue) {
    this.tagData = tagData;
    this.tagFilterRangeDate = getFormattedDateFromRange(tagFilterRange);
  }

  abstract createTags(): ITag[];
}

export { TagCollection, Tags };
