import { EuiErrorBoundary, EuiProgress, EuiCallOut } from '@elastic/eui';
import PageSection from 'layout/section/section';
import { InstallEntry } from 'app/layout/install/entry/install-entry';
import type { TagEntry } from 'models/install-verification-tags/types';

interface InstallCheckViewProps {
  /** True if the install check data is loading or reloading */
  isLoading: boolean;
  /** If set, then the query returned an error message and likely failed to load any data */
  checkError?: string | null;
  /** Install Check information to be displayed*/
  installInfo?: Array<TagEntry>;
}

/** Displays the tracker install check */
export const InstallCheckView = ({ checkError, installInfo, isLoading }: InstallCheckViewProps) => {
  // Create the install check entries
  const installEntries = installInfo?.[0]?.tagData
    ? installInfo.map(info => (
        <InstallEntry
          key={info.deviceId}
          tags={info.tagData}
          trackerSerial={info.deviceId}
        />
      ))
    : null;

  // Display the error message, if any
  let installCheckError = null;
  if (checkError) {
    installCheckError = (
      <EuiCallOut
        title="No status information available"
        color="danger"
        iconType="alert"
      >
        <p>This tracker has not sent any messages</p>
      </EuiCallOut>
    );
  }

  // No Data Message
  let installCheckNoData = null;
  if (installInfo && installInfo.length === 0 && !checkError && !isLoading) {
    installCheckNoData = (
      <EuiCallOut
        title="No tracker data found within the given search paramaters"
        color="warning"
        iconType="alert"
      >
        <p>{checkError}</p>
      </EuiCallOut>
    );
  }

  return (
    <EuiErrorBoundary>
      {isLoading ? (
        <EuiProgress
          size="s"
          color="primary"
        />
      ) : (
        <PageSection>
          {installCheckError}
          {installCheckNoData}
          {installEntries}
        </PageSection>
      )}
    </EuiErrorBoundary>
  );
};
