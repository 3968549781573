import React, { useCallback, useEffect, useState } from 'react';
import { EuiErrorBoundary } from '@elastic/eui';
import { extractGraphQLError, handleApolloError } from 'utils/errorHandlers';
import { CONSTANTS } from 'utils/constants';
import { useGetDevicesByOrganizationQuery, useGetGroupDevicesQuery } from 'gqlHooks';
import type { ApolloError } from '@apollo/client';
import type { GenericRow } from 'gqlHooks';
import { errorToastMessage } from 'app/utils/toast-messages';
import GroupsInMemoryTable from 'app/layout/groups/devices/in-memory-table/groups-in-memory-table';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

type DeviceListTableProps = {
  organization: string;
  groups: GenericRow[];
  addToastMessage: (message: Toast) => void;
};

export interface Device extends GenericRow {
  identifier: string;
  label: string;
  organizationId: string;
}

export const DeviceListTable = (props: DeviceListTableProps) => {
  const [devices, setDevices] = useState<any[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GenericRow | undefined>(undefined);
  const [showAllDevices, setShowAllDevices] = useState(true);

  const handleError = useCallback((error: ApolloError) => {
    const gqlError = extractGraphQLError(error);
    handleApolloError(error, true, () => console.error(gqlError.errorMessage));
  }, []);

  const onShowAllDevices = () => {
    setShowAllDevices(true);
    setSelectedGroup(undefined);
  };

  // Show all devices by default whenever switching between organizations or on initial load of page
  useEffect(() => {
    setShowAllDevices(true);
    setSelectedGroup(undefined);
  }, [props.organization]);

  // Load all the devices in the selectedGroup
  const { loading: loadingDevices, data: groupDevicesData } = useGetGroupDevicesQuery({
    variables: {
      organizationId: props.organization,
      groupToken: selectedGroup?.identifier ?? '',
      limit: 1000,
      recursive: false,
    },
    onCompleted: data =>
      // @ts-expect-error window heap is defined
      window.heap?.track('GroupDeviceLoad', {
        total: data?.groupDevices?.items?.length,
        organization: props.organization,
      }),
    onError: () => props.addToastMessage(errorToastMessage('Failed to load devices in group')),
  });

  // Load all devices regardless of group
  const { loading: deviceLoading, data: deviceData } = useGetDevicesByOrganizationQuery({
    variables: {
      organizationId: props.organization,
      limit: 500000,
      nextToken: null,
    },
    pollInterval: CONSTANTS.SIXTY_SECONDS_IN_MILLISECONDS,
    onCompleted: data =>
      // @ts-expect-error window heap is defined
      window.heap?.track('DeviceLoad', {
        total: data?.devices?.items?.length,
        organization: props.organization,
      }),
    onError: handleError,
  });

  /**
   * Update the component state when new data is received from the query.
   * This is necessary because the data property of the query result may
   * not be available immediately when the component renders.
   */
  useEffect(() => {
    if (deviceData && showAllDevices) {
      const devices =
        deviceData?.devices?.items?.map(org => ({
          ...org,
          identifier: org.identifier ?? '',
          label: org.label ?? '',
          organizationId: org.organizationId ?? '',
        })) ?? [];
      setDevices(devices);
    }
    if (groupDevicesData && showAllDevices == false) {
      setDevices(groupDevicesData?.groupDevices?.items);
    }
  }, [deviceData, showAllDevices, groupDevicesData]);

  return (
    <EuiErrorBoundary>
      <GroupsInMemoryTable
        setShowAllDevices={setShowAllDevices}
        onShowAllDevices={onShowAllDevices}
        isLoading={loadingDevices && deviceLoading}
        groups={props.groups}
        devices={devices}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
      />
    </EuiErrorBoundary>
  );
};

export const MemoizedDeviceListTable = React.memo(DeviceListTable);
