import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';
import { GenericRow } from 'app/generated/graphql';

interface ConfirmRemoveDeviceProps {
  /** True if the confirm modal should be open */
  isOpen?: boolean;
  /** Method invoked when the dialog requests a close action */
  onClose: () => void;
  /** The group the device is being removed from */
  group?: GenericRow;
  /** Method invoked when the users want to remove a device */
  onDeviceRemoval: () => void;
}

/** Displays a dialog to confirm the removal of a device from a group */
export const ConfirmRemoveDevice = (props: ConfirmRemoveDeviceProps) => {
  return props.isOpen ? (
    <EuiOverlayMask>
      <EuiConfirmModal
        buttonColor="danger"
        cancelButtonText="Cancel"
        confirmButtonText="Remove device"
        defaultFocusedButton="confirm"
        onCancel={props.onClose}
        onConfirm={props.onDeviceRemoval}
        title="Are you sure?"
      >
        <p>{`Are you sure you want to remove this device from group ${props.group?.label}`}</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  ) : null;
};

export default ConfirmRemoveDevice;
