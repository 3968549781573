import { EuiButtonEmpty, EuiTableFieldDataColumnType } from '@elastic/eui';
import type { Messages } from 'gqlHooks';
import { getLocalTime } from 'utils/dates';
import { Location } from 'app/models/devices/Location';

const renderMapLink = (_unused: string, rawMessage: Messages) => {
  const parsedRawMessage = JSON.parse(rawMessage.data);
  const location = new Location(parsedRawMessage);

  const locationUrl = location?.googleMapsUrl;
  const locationLat = location?.shortenedCoords.shortenedLat;
  const locationLng = location?.shortenedCoords.shortenedLng;

  if (!locationLat || !locationLng || locationLat === '0.00' || locationLng === '0.00') {
    return null;
  }

  return (
    <EuiButtonEmpty
      id="link-to-google-map"
      aria-label="Link to google map"
      href={locationUrl}
      iconType="mapMarker"
      target="_blank"
    >
      {`${locationLat}, ${locationLng}`}
    </EuiButtonEmpty>
  );
};

const renderDate = (date: string) => {
  return date ? getLocalTime(date) : '';
};

const ingestedAt: EuiTableFieldDataColumnType<Messages> = {
  field: 'createdAt',
  name: 'Message received',
  dataType: 'string',
  truncateText: false,
  render: renderDate,
};

const renderMessageType = (typeCode: string) => {
  return typeCode?.toLowerCase().split('/').pop() ?? '';
};

const messageType: EuiTableFieldDataColumnType<Messages> = {
  field: 'typeCode',
  name: 'Event type',
  truncateText: false,
  sortable: ({ typeCode }) => {
    return typeCode?.toLowerCase().split('/').pop() ?? '';
  },
  dataType: 'string',
  render: renderMessageType,
};

const mapLinkColumn: EuiTableFieldDataColumnType<Messages> = {
  field: 'location',
  name: 'Location',
  dataType: 'string',
  truncateText: false,
  render: renderMapLink,
};

export const SIM_MESSAGE_TABLE_COLUMNS: EuiTableFieldDataColumnType<Messages>[] = [
  ingestedAt,
  mapLinkColumn,
  messageType,
];
