import { EuiAvatar, EuiDescriptionList, EuiComment } from '@elastic/eui';
import { kebabCase } from 'lodash';

// Config keys are in camel case - splitting to make human readable
const configKeyToString = (configKey: string) => kebabCase(configKey);

type ListItem = {
  title: string;
  description: string;
};

const parseResponse = (response: Record<string, any>): ListItem[] => {
  const listItems: ListItem[] = [];

  const processValue = (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      // If the value is an object, recursively process it
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        processValue(`${key}.${nestedKey}`, nestedValue);
      }
    } else {
      // If the value is not an object, add it to the listItems
      listItems.push({ title: configKeyToString(key), description: value?.toString() ?? '--' });
    }
  };

  if (response) {
    for (const [key, value] of Object.entries(response)) {
      if (key.slice(0, 2).includes('__')) {
        continue;
      }
      processValue(key, value);
    }
  }

  return listItems;
};

export type ConfigSummaryProps = {
  command: string;
  label: string;
  icon: string;
  isResponse: boolean;
  /** Response message to the command */
  response?: undefined | null;
};

export const SntCommandSummary = ({
  command,
  label,
  icon,
  isResponse,
  response,
}: ConfigSummaryProps) => {
  if (!command) {
    return null;
  }

  const listItems = isResponse && response ? parseResponse(response) : [];

  const avatar = (
    <EuiAvatar
      name={label}
      iconType={icon}
      iconColor="success"
      color="plain"
      size="l"
    />
  );

  return (
    <EuiComment
      event={isResponse ? 'command response' : `command sent`}
      username={label}
      timelineAvatar={avatar}
    >
      {listItems.length > 0 ? <EuiDescriptionList listItems={listItems} /> : null}
    </EuiComment>
  );
};
