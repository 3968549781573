/**
 * To force the pattern of always exporting a constant within CONSTANTS
 * we only export the CONSTANTS object and not the individual constants
 */

/**
 * This reprents a valid unit of time in moment.js
 * as seen in the namespace unitOfTime.Base
 */
const VALID_UNIT_OF_TIME = [
  'year',
  'years',
  'y',
  'month',
  'months',
  'M',
  'week',
  'weeks',
  'w',
  'day',
  'days',
  'd',
  'hour',
  'hours',
  'h',
  'minute',
  'minutes',
  'm',
  'second',
  'seconds',
  's',
  'millisecond',
  'milliseconds',
  'ms',
];

const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export const COLORS = {
  SUCCESS: 'success',
  INFO: 'primary',
  DANGER: 'danger',
  WARNING: 'warning',
  ACCENT: 'accent',
};

export const CONSTANTS = {
  ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH: 3,
  COLORS,
  QUERY_LIMIT: 10,
  QUERY_LIMITS: [10, 25, 50, 100],
  GOOGLE_MAPS_URL: 'https://www.google.com/maps/place/',
  TEXT_PLACEHOLDER: '---',
  FIFTEEN_SECONDS_IN_MILLISECONDS: 15000,
  THIRTY_SECONDS_IN_MILLISECONDS: 30000,
  SIXTY_SECONDS_IN_MILLISECONDS: 60000,
  VALID_UNIT_OF_TIME,
  EMAIL_REGEX,
} as const;
