import ReactJson from 'react-json-view';
import { EuiErrorBoundary } from '@elastic/eui';

interface ThemedJsonProps {
  /**
   * The json values to be displayed
   */
  jsonValues?: any;
  /**
   * How many characters will display in a line before they are collapsed
   * Defaults to 30 if not set or if set to a zero or negative value
   */
  collapseLength?: number;
}

/**
 * Wrapper for react json display so it can display the correct theme colors
 */
export const ThemedJson = ({ jsonValues = [], collapseLength = 30 }: ThemedJsonProps) => {
  if (!jsonValues) {
    return null;
  }
  //   const isDark = localStorage.getItem('theme') === 'dark';

  return (
    <EuiErrorBoundary>
      <ReactJson
        collapseStringsAfterLength={collapseLength > 0 ? collapseLength : 30}
        collapsed={3}
        displayDataTypes={false}
        name={false}
        sortKeys
        src={jsonValues}
        style={{ overflow: 'scroll' }} // Strings aren't wrapping when they have no spaces in them
        theme="railscasts"
      />
    </EuiErrorBoundary>
  );
};

export default ThemedJson;
