import { createRoot } from 'react-dom/client';
import 'components/icon/icons';
import '@elastic/eui/dist/eui_theme_dark.css';
import { EuiProvider } from '@elastic/eui';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import Auth0Wrapper from 'auth/Auth0Wrapper';
import { initializeEnvConfigs } from 'configs/envInitializers';
import { ConfigProvider } from 'context/config';
import App from 'app/App';

import 'app/index.css';

initializeEnvConfigs();

const container: HTMLElement | null = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <SentryErrorBoundary>
      <ConfigProvider>
        <Auth0Wrapper>
          <EuiProvider colorMode="dark">
            <App />
          </EuiProvider>
        </Auth0Wrapper>
      </ConfigProvider>
    </SentryErrorBoundary>
  );
}
