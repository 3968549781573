import { ReactNode } from 'react';

// Eui isn't exporting the type for a comment list item - manually creating it
// to avoid repeated definitions
export interface MlEuiCommentType {
  title: NonNullable<ReactNode>;
  description: NonNullable<ReactNode>;
}

export const buildEuiComment = (
  title: MlEuiCommentType['title'],
  description: MlEuiCommentType['description']
) => {
  return { title: title, description: description };
};
