import { SyntheticEvent, useState } from 'react';
import moment from 'moment';
import { getAppSyncQueryDate } from 'utils/dates';
import {
  useSendDeviceFotaRequestMutation,
  useDeleteDeviceFotaRequestMutation,
  DeviceType,
  FotaType,
  useGetDeviceFirmwaresQuery,
  useGetDeviceFotaRequestsQuery,
  FotaRequestRow,
  DeviceFotaRequestInvalidateInput,
} from 'gqlHooks';
import { EuiErrorBoundary, EuiSelectOption } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';
import { SntFotaView } from 'layout/fota/snt/snt-fota-view';

interface SntFotaProps {
  /** Serial of the device whose config is being modified */
  deviceSerial: string;
  /** Adds a toast to the global message queue */
  addToastMessage: (message: Toast) => void;
}

/** Configuration options for the SnT devices */
export const SntFota = ({ deviceSerial, addToastMessage }: SntFotaProps) => {
  const [firmwareOptions, setFirmwareOptions] = useState<EuiSelectOption[]>([]);
  const [firmwareType, setFirmwareType] = useState(FotaType.App);
  const [selectedFirmware, setSelectedFirmware] = useState<string | number | undefined>('');
  const [firmwareRequests, setFirmwareRequests] = useState<FotaRequestRow[]>([]);

  // Filter Values for the start/end date
  const [startDate, setStartDate] = useState(moment().subtract(6, 'months').startOf('day'));
  const [endDate, setEndDate] = useState(moment().add(1, 'days').endOf('day'));

  // Retrieves all available firmwares for the selector
  useGetDeviceFirmwaresQuery({
    variables: {
      deviceType: DeviceType.Snt,
      firmwareType: firmwareType,
    },
    onCompleted: data => {
      const options: EuiSelectOption[] = [];

      data?.deviceFirmwareRequest?.firmware.forEach(version => {
        options.push({ value: version.version, text: version.version });
      });

      setFirmwareOptions(options);
    },
    onError: () => {
      addToastMessage(errorToastMessage('Failed to load firmwares'));
    },
  });

  // Loads all the current/historical fota requests
  const {
    refetch: refetchRequests,
    loading: requestsLoading,
    error: loadRequestsError,
  } = useGetDeviceFotaRequestsQuery({
    variables: {
      deviceId: deviceSerial,
      start: getAppSyncQueryDate(startDate.toDate()),
      end: getAppSyncQueryDate(endDate.toDate()),
      limit: 50,
      nextToken: undefined,
    },
    onCompleted: data => {
      const requests = data?.deviceFotaRequestRequest?.requests;
      requests ? setFirmwareRequests(requests as FotaRequestRow[]) : setFirmwareRequests([]);
    },
    onError: () => {
      addToastMessage(errorToastMessage('Failed to load request history'));
    },
  });

  // Mutator to request the cancel of a fota request
  const [deleteDeviceFotaRequestMutation, { loading: isDeleteLoading }] =
    useDeleteDeviceFotaRequestMutation();

  // Sends a request for a fota update
  const [sendDeviceFotaRequestMutation, { loading: isSending }] = useSendDeviceFotaRequestMutation({
    variables: {
      deviceId: deviceSerial,
      deviceType: DeviceType.Snt,
      firmwareType: firmwareType,
      // @ts-expect-error form validation should ensure submits only happen when this has a value
      // ts doesn't understand this as the field may be undefined if nothing was selected yet
      firmwareVersion: selectedFirmware,
    },
    onCompleted: () => {
      addToastMessage(successToastMessage('Update requested'));
      refetchRequests();
    },
    onError: () => {
      addToastMessage(errorToastMessage('Failed to send update request'));
      refetchRequests();
    },
  });

  const handleCancelFotaRequest = (requestId: number) => {
    const variables: DeviceFotaRequestInvalidateInput = {
      deviceId: deviceSerial,
      requestId: requestId,
    };
    deleteDeviceFotaRequestMutation({
      variables: variables,
      onCompleted: () => {
        addToastMessage(successToastMessage('Request cancelled'));
        refetchRequests();
      },
      onError: () => {
        addToastMessage(errorToastMessage('Error occurred while cancelling'));
        refetchRequests();
      },
    });
  };

  const handleSendFotaRequest = (event: SyntheticEvent | null) => {
    event?.preventDefault();
    sendDeviceFotaRequestMutation();
  };

  const handleFirmwareTypeChange = (type: FotaType) => {
    setFirmwareType(type);
    setSelectedFirmware(undefined);
  };

  return (
    <EuiErrorBoundary>
      <SntFotaView
        firmwareOptions={firmwareOptions}
        firmwareType={firmwareType}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        isLoading={requestsLoading || isSending || isDeleteLoading}
        setSelectedFirmwareType={handleFirmwareTypeChange}
        setSelectedFirmware={setSelectedFirmware}
        onUpdateFirmware={handleSendFotaRequest}
        selectedFirmware={selectedFirmware}
        fotaRequests={firmwareRequests}
        fotaRequestsLoadError={loadRequestsError ? true : false}
        onCancelFota={handleCancelFotaRequest}
      />
    </EuiErrorBoundary>
  );
};
