import { CSVLink } from 'react-csv';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiErrorBoundary,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from '@elastic/eui';

interface TrackerMessagesExportProps {
  /**
   * Data to be exported as a csv
   */
  exportData?: any[];
  /**
   * True if the export button should be disabled
   */
  isDisabled?: boolean;
  /**
   * True if the button should be in a loading state
   */
  isFetching?: boolean;
  /**
   * True if the download dialog should be open
   */
  isOpen: boolean;
  /**
   * Action called when the download dialog has a close request
   */
  onClose: () => void;
  /**
   * Action called when the export data button is pressed
   */
  refetch: () => void;
}

/**
 * Export button and dialog for running a search and exporting the results as csv.
 */
export const TrackerMessagesExport = ({
  exportData = [],
  isDisabled = false,
  isFetching = false,
  isOpen,
  onClose,
  refetch,
}: TrackerMessagesExportProps) => {
  const handleDialogClose = () => {
    onClose();
  };

  const output: any[] = exportData.map(item => {
    // Parse the "data" field within a message
    const data = JSON.parse(item.data);

    // Function to format nested data recursively
    const formatNestedData = (nestedData: any, depth = 0): string => {
      const prefix = '  '.repeat(depth); // Indentation for nesting

      return Object.entries(nestedData)
        .map(([key, value]) => {
          if (typeof value === 'object') {
            return `${prefix}${key}:\n${formatNestedData(value, depth + 1)}`;
          } else {
            return `${prefix}${key}: ${value}`;
          }
        })
        .join('\n');
    };

    // Create a single string with formatted data, including nesting
    const formattedData = formatNestedData(data);

    // Create an object with other fields, can add more whenever/if new fields get added
    const csvItem = {
      Type: item.__typename,
      PK: item.PK,
      createdAt: item.createdAt,
      deviceId: item.deviceId,
      ingestedAt: item.ingestedAt,
      typeCode: item.typeCode,
      data: formattedData, // Use the parsed formatted data here
    };

    return csvItem;
  });

  const isLoading = isOpen || isFetching;
  const downloadDialog = isOpen ? (
    <EuiOverlayMask>
      <EuiModal
        initialFocus="[name=closeButton]"
        onClose={handleDialogClose}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>Generated Export</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <CSVLink
            data={output ? output : ''}
            filename="message-export.csv"
          >
            Download Report
          </CSVLink>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty
            id="close-download-modal"
            onClick={handleDialogClose}
          >
            Close
          </EuiButtonEmpty>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  ) : null;

  return (
    <EuiErrorBoundary>
      {downloadDialog}
      <EuiButton
        id="download-device-messages"
        color="text"
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        onClick={refetch}
      >
        Download
      </EuiButton>
    </EuiErrorBoundary>
  );
};

export default TrackerMessagesExport;
