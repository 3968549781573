import {
  EuiErrorBoundary,
  EuiForm,
  EuiText,
  EuiRadioGroup,
  EuiDescribedFormGroup,
} from '@elastic/eui';
import { Configuration } from 'app/generated/graphql';
import { Control, Controller } from 'react-hook-form';

import { ConfigPanel } from '../../panel/config-panel';
import * as POWER_CONSTANTS from './power-config-defaults';

interface SntPowerConfigViewProps {
  /** Used for handling the state of the panel. Collapsed or expanded. */
  isExpanded: boolean;
  /** react-hook-form controller for monitoring form state */
  control: Control<Configuration>;
}

/**
 * Configuration options for how the snt devices single external button should function.
 * SNT has one button - this decides if it will do nothing, reset, or turn off/on the device
 */
export const SntPowerConfig = (props: SntPowerConfigViewProps) => {
  const handleModeChange = (
    optionId: string,
    setPowerOff: (value: boolean) => void,
    setResetEnable: (value: boolean) => void
  ) => {
    switch (optionId) {
      case POWER_CONSTANTS.MODE_RESET:
        setPowerOff(false);
        setResetEnable(true);
        break;
      case POWER_CONSTANTS.MODE_POWER:
        setPowerOff(true);
        setResetEnable(true);
        break;
      case POWER_CONSTANTS.MODE_DISABLED:
        setPowerOff(false);
        setResetEnable(false);
        break;
      default:
        break;
    }
  };

  return (
    <EuiErrorBoundary>
      <EuiForm component="form">
        <ConfigPanel
          expandAll={props.isExpanded}
          title="Button"
          icon="gear"
          description="What the button on the tracker should do"
          isLoading={false}
        >
          <EuiDescribedFormGroup
            title={<h3>Button Options</h3>}
            description={
              <EuiText size="s">
                <p> Sets how the SnT button will function.</p>
                <p>Default: Disabled</p>
              </EuiText>
            }
          >
            <Controller
              name="power.resetEnable"
              control={props.control}
              render={({
                field: {
                  onChange: onResetEnableChange,
                  onBlur: onResetEnableBlur,
                  value: resetEnable,
                },
              }) => (
                <Controller
                  name="power.offEnable"
                  control={props.control}
                  render={({
                    field: {
                      onChange: onPowerOffChange,
                      onBlur: onPowerOffBlur,
                      value: offEnabled,
                    },
                  }) => (
                    <EuiRadioGroup
                      options={POWER_CONSTANTS.POWER_MODE_OPTIONS}
                      idSelected={POWER_CONSTANTS.getDerivedPowerStatus(offEnabled, resetEnable)}
                      onBlur={() => {
                        onResetEnableBlur();
                        onPowerOffBlur();
                      }}
                      onChange={id => handleModeChange(id, onPowerOffChange, onResetEnableChange)}
                      name="power-mode-selector"
                    />
                  )}
                />
              )}
            />
          </EuiDescribedFormGroup>
        </ConfigPanel>
      </EuiForm>
    </EuiErrorBoundary>
  );
};
