import {
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiProgress,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';

type TitleBarPanelProps = {
  /** Title to display */
  title: string;
  /** When true, the loading bar will display instead of a horizontal divider */
  isLoading: boolean;
  /** Children belonging to this title bar */
  children: React.ReactNode;
  /** Optional icon to display next to the title */
  iconType?: string;
  /** ID for the section */
  sectionId: string;
};

export const TitleBarPanel = (props: TitleBarPanelProps) => {
  return (
    <EuiErrorBoundary>
      <EuiSpacer size="m" />
      <EuiFlexGroup
        alignItems="center"
        responsive={false}
      >
        {props.iconType && (
          <EuiFlexItem grow={false}>
            <EuiIcon
              type={props.iconType}
              size="m"
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          <EuiTitle size="s">
            <h2>{props.title}</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      {props.isLoading ? (
        <>
          <EuiSpacer size="l" />
          <EuiProgress
            size="xs"
            color="primary"
          />
          <EuiSpacer size="l" />
        </>
      ) : (
        <EuiHorizontalRule />
      )}
      <EuiFlexGroup>{props.children}</EuiFlexGroup>
    </EuiErrorBoundary>
  );
};
