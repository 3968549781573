import { useState, useMemo } from 'react';
import {
  EuiButtonEmpty,
  EuiBasicTable,
  EuiDescriptionList,
  EuiErrorBoundary,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiText,
  EuiTableFieldDataColumnType,
  EuiTitle,
  useGeneratedHtmlId,
  EuiButtonIcon,
} from '@elastic/eui';

const SNT_ACRONYMS = [
  {
    title: 'SNT',
    description: 'Slap and Track Device acronym',
  },
  {
    title: 'MQTT',
    description:
      'Message Queuing Telemetry Transport. Messaging protocol for communicating with the device',
  },
  {
    title: 'OASIS',
    description: 'A non-profit standards body',
  },
  {
    title: 'LTE-M',
    description: 'A 3GPP Cellular Standard for low power cellular communications',
  },
  {
    title: 'NB-IoT',
    description: 'A 3GPP Narrowband Cellular Standard for low power cellular communications',
  },
  {
    title: '3GPP',
    description:
      'The 3rd Generation Partnership Project, a mobile broadband and narrowband standards body.',
  },
  {
    title: 'Cell ID',
    description: 'Cell tower ID number',
  },
  {
    title: 'MCC',
    description: 'Mobile Country Code',
  },
  {
    title: 'MNC',
    description: 'Mobile Network Code',
  },
  {
    title: 'RSRP',
    description: 'Reference Signal Received Power',
  },
  {
    title: 'LAC',
    description: 'Location Area Code',
  },
  {
    title: 'Zephyr RTOS',
    description: 'An open source, scalable real-time operating system. A Linux Foundation project.',
  },
  {
    title: 'MCUboot',
    description: 'An open source, secure bootloader that enables easy software upgrade. ',
  },
  {
    title: 'SDK',
    description: 'Software Development Kit',
  },
  {
    title: 'PSM',
    description: 'Cellular communication Power Save Mode',
  },
  {
    title: 'AWS',
    description: 'Amazon Web Services',
  },
  {
    title: 'AWS IoT',
    description:
      'For purposes of this document references to AWS IoT refer to the Amazon AWS IoT Services MQTT Broker even though AWS IoT is far more than just an MQTT Broker.',
  },
];

const REPORT_TYPES = [
  {
    field: 'boot',
    description:
      'The device booted or rebooted. This will always be sent by the device on boot no matter what the settings are for periodic initial_report_time.',
  },
  {
    field: 'periodic',
    description: 'Sent at periodic intervals, interval time set by configuration',
  },
  {
    field: 'motion',
    description: 'Motion has started or is in progress, interval is set by configuration',
  },
  { field: 'stolen', description: 'Stolen asset mode has been triggered' },
];

const GPS_FIELDS = [
  {
    field: 'latitude',
    description: 'Device latitude in decimal degrees to at least 7 decimal places or 0 if invalid.',
  },
  {
    field: 'longitude',
    description:
      'Device longitude in decimal degrees to at least 7 decimal places or 0 if invalid.',
  },
  {
    field: 'accuracy',
    description: 'Accuracy of GPS in meters to at least 1 decimal place or 0 if invalid.',
  },
  {
    field: 'altitude',
    description: ' Altitude in meters to at least 1 decimal place or 0 if invalid.',
  },
];

const LTE_FIELDS = [
  {
    field: 'lac',
    description: 'Local Area Code of the connected cellular tower, or 0 if unknown',
  },
  {
    field: 'cell_id',
    description: 'Cell tower ID number of the connected cellular tower, or 0 if unknown',
  },
  {
    field: 'rsrp',
    description: 'Reference Signal Received Power of the connected cellular tower, or 0 if unknown',
  },
  {
    field: 'mccmnc',
    description:
      'Mobile Country Code and Mobile Network Code of the connected cellular tower, or 0 if unknown',
  },
];

const TOP_LEVEL_FIELD_DESCRIPTIONS = [
  {
    field: 'time',
    description: 'Current UTC system time in the format: "YYYY-MM-DD hh:mm:ss UTC"',
  },
  {
    field: 'type',
    description: 'Type of report. Valid values are listed below in the report type table',
  },
  {
    field: 'motion_status',
    description: 'The current device motion status. Valid values are stationary or moving.',
  },
  {
    field: 'battery_voltage',
    description: 'Current battery voltage to 2 decimal places, in volts',
  },
  {
    field: 'temperature',
    description: 'Current device temperature in degrees C',
  },
  {
    field: 'gps',
    description: 'Location information, details on fields in the GPS table below',
  },
  { field: 'lte', description: 'Cellular connection information' },
];

const REPORT_FIELD_COLUMNS: EuiTableFieldDataColumnType<ReportMessageFieldsInfo>[] = [
  {
    field: 'field',
    name: 'Field',
    width: '150px',
  },
  {
    field: 'description',
    name: 'Description',
  },
];

type ReportMessageTypeInfo = {
  type: string;
  default: string;
  min: string | number;
  canDisable: boolean;
};

type ReportMessageFieldsInfo = {
  field: string;
  description: string;
};

export const SNTMessageInfo = () => {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState('1');

  const complicatedFlyoutTitleId = useGeneratedHtmlId({
    prefix: 'complicatedFlyoutTitle',
  });

  const formatInfo = (
    <EuiErrorBoundary>
      <EuiText>
        <b>Sample Report Message</b>
      </EuiText>
      <EuiCodeBlock language="json">
        {`{
    "time": "2022-08-26 19:42:04 UTC",
    "type": "periodic",
    "motion_status": "stationary",
    "battery_voltage": 5.29,
    "temperature": 30,
    "gps": {
        "latitude": 41.784762565778,
        "longitude": -88.103124552331,
        "accuracy": 2.96,
        "altitude": 205.78
    },
    "lte": {
        "lac": 41709,
        "cell_id": 6255116,
        "rsrp": 53,
        "mccmnc": "310260"
    }
}`}
      </EuiCodeBlock>
      <EuiSpacer />
      <EuiTitle>
        <h3>Message Fields</h3>
      </EuiTitle>
      <EuiBasicTable
        columns={REPORT_FIELD_COLUMNS}
        tableCaption="Message Fields"
        items={TOP_LEVEL_FIELD_DESCRIPTIONS}
      />
      <EuiSpacer />
      <EuiTitle>
        <h3>Report Message Types</h3>
      </EuiTitle>
      <EuiBasicTable
        columns={REPORT_FIELD_COLUMNS}
        tableCaption="Report Types"
        items={REPORT_TYPES}
      />
      <EuiSpacer />
      <EuiTitle>
        <h3>GPS fields</h3>
      </EuiTitle>
      <EuiBasicTable
        columns={REPORT_FIELD_COLUMNS}
        tableCaption="GPS Fields"
        items={GPS_FIELDS}
      />
      <EuiSpacer />
      <EuiTitle>
        <h3>LTE Fields</h3>
      </EuiTitle>
      <EuiBasicTable
        columns={REPORT_FIELD_COLUMNS}
        tableCaption="LTE Fields"
        items={LTE_FIELDS}
      />
    </EuiErrorBoundary>
  );

  const acronymInfo = (
    <EuiErrorBoundary>
      <EuiDescriptionList listItems={SNT_ACRONYMS} />
    </EuiErrorBoundary>
  );

  const columns: EuiTableFieldDataColumnType<ReportMessageTypeInfo>[] = [
    {
      field: 'type',
      name: 'Report Type',
    },
    {
      field: 'default',
      name: 'Default Frequency',
    },
    {
      field: 'min',
      name: 'Minimum Frequency',
    },
    {
      field: 'canDisable',
      name: 'Can be disabled',
      dataType: 'boolean',
    },
  ];

  const items = [
    { type: 'Boot', default: 'N/A', min: 'N/A', canDisable: false },
    {
      type: 'Periodic',
      default: '12 hours',
      min: '300 seconds',
      canDisable: false,
    },
    {
      type: 'Motion',
      default: '300 seconds',
      min: '60 seconds',
      canDisable: true,
    },
    {
      type: 'Stolen Asset',
      default: '30 minutes',
      min: '300 seconds',
      canDisable: true,
    },
  ];

  const configInfo = (
    <EuiBasicTable
      columns={columns}
      tableCaption="Demo of EuiBasicTable"
      items={items}
    />
  );

  const tabs = [
    {
      id: '1',
      name: 'Report Message',
      content: formatInfo,
    },
    {
      id: '2',
      name: 'Configuration',
      content: configInfo,
    },
    {
      id: '3',
      name: 'Acronyms',
      content: acronymInfo,
    },
  ];

  const closeFlyout = () => setIsFlyoutVisible(false);

  const showFlyout = () => setIsFlyoutVisible(true);

  const renderTabs = tabs.map((tab, index) => (
    <EuiTab
      onClick={() => setSelectedTabId(tab.id)}
      isSelected={tab.id === selectedTabId}
      key={index}
    >
      {tab.name}
    </EuiTab>
  ));

  const selectedTabContent = useMemo(() => {
    return tabs.find(obj => obj.id === selectedTabId)?.content;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  let flyout;

  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={closeFlyout}
        hideCloseButton
        aria-labelledby={complicatedFlyoutTitleId}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2 id={complicatedFlyoutTitleId}>Message Information</h2>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiText color="subdued">
            <p>Information about fields on &apos;slap and track&apos; device messages.</p>
          </EuiText>
          <EuiTabs style={{ marginBottom: '-25px' }}>{renderTabs}</EuiTabs>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>{selectedTabContent}</EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                id="close-message-informational-flyout"
                iconType="cross"
                onClick={closeFlyout}
                flush="left"
              >
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    );
  }

  return (
    <EuiErrorBoundary>
      <EuiButtonIcon
        id="open-message-informational-flyout"
        color={'primary'}
        onClick={showFlyout}
        iconType="help"
        aria-label="Help"
        size="m"
      />
      {flyout}
    </EuiErrorBoundary>
  );
};

export default SNTMessageInfo;
