import GoogleMapReact from 'google-map-react';
import type { MapOptions } from 'google-map-react';
import { useConfig } from 'context/config';

import { TrackerMarker } from './trackerMarker';
import MapStyleNight from './styles/night.json';

export type TrackerMapProps = {
  zoom?: number;
  center: google.maps.LatLngLiteral;
  markerTitle: string;
  markerDetails?: JSX.Element;
  styles?: google.maps.MapTypeStyle[] | null;
};

export const TrackerMap = (props: TrackerMapProps) => {
  const { VITE_GOOGLE_MAPS_API_KEY } = useConfig();
  const mapOptions = (): MapOptions => {
    return {
      disableDefaultUI: true,
      fullscreenControl: true,
      keyboardShortcuts: false,
      styles: MapStyleNight,
      zoomControl: true,
    };
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: VITE_GOOGLE_MAPS_API_KEY,
      }}
      defaultZoom={12}
      center={props.center}
      zoom={props.zoom}
      options={mapOptions}
      resetBoundsOnResize
    >
      <TrackerMarker
        lat={props.center.lat}
        lng={props.center.lng}
        title={props.markerTitle}
        details={props.markerDetails}
      />
    </GoogleMapReact>
  );
};

export default TrackerMap;
