import { useState } from 'react';
import {
  EuiInMemoryTable,
  EuiFlexGroup,
  EuiSearchBarProps,
  EuiScreenReaderOnly,
  DefaultItemAction,
  EuiFlexItem,
  EuiButton,
  EuiErrorBoundary,
  EuiTitle,
} from '@elastic/eui';
import { GenericRow } from 'gqlHooks';
import { GROUPS_DEVICE_TABLE_COLUMNS } from 'layout/groups/devices/in-memory-table/columns';
import ConfirmRemoveDevice from 'layout/groups/devices/remove/cofirm-dialog/cofirm-removal';
import GroupFacetSelector from 'layout/groups/select/facet/facet-selector';

export interface GroupsInMemoryTableProps {
  /* True if the table should show a loading status */
  isLoading?: boolean;
  /* Groups to be displayed as facets that are part of the table (clickable tabs) */
  groups?: GenericRow[];
  /* Devices to be disaplyed in the table */
  devices?: GenericRow[];
  /* The group that is tied to the displayed devices */
  selectedGroup?: GenericRow;
  /* Sets the group that will be tied to the device */
  setSelectedGroup: (group: GenericRow | undefined) => void;
  /* Method that gets called when removing a device from a group */
  onDeviceRemoval?: (deviceId: string) => void;
  /* Method called when the user hits the add device button */
  onAddDevices?: () => void;
  /* Method called when the user hits the file upload button */
  onBulkAddDevices?: () => void;
  /* Optional - used for displaying all devices regardless of group assignment on the Devices page */
  onShowAllDevices?: () => void;
  /* Optional - If true, displays all devices*/
  setShowAllDevices?: (showAllDevices: boolean) => void;
}

/* Table for displaying devices that belong to a group */
export const GroupsInMemoryTable = (props: GroupsInMemoryTableProps) => {
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);
  const [deviceToRemove, setDeviceToRemove] = useState<string>('');

  const onConfirmRemoveClose = () => {
    setIsConfirmRemoveOpen(false);
  };

  const removeDevice = (device: GenericRow) => {
    setDeviceToRemove(device.identifier ?? '');
    setIsConfirmRemoveOpen(true);
  };

  const handleConfirmRemove = () => {
    setIsConfirmRemoveOpen(false);
    props.onDeviceRemoval ? props.onDeviceRemoval(deviceToRemove) : null;
  };

  const removeAction: DefaultItemAction<any> = {
    name: (
      <EuiScreenReaderOnly>
        <span>Remove Device</span>
      </EuiScreenReaderOnly>
    ),
    description: 'Remove device from group',
    icon: 'trash',
    available: () => props.onDeviceRemoval !== undefined,
    color: 'danger',
    type: 'icon',
    onClick: removeDevice,
    isPrimary: true,
  };

  const renderToolsRight = () => {
    return !props.onAddDevices
      ? [
          <EuiButton
            key={'showAllDevices'}
            isDisabled={props.groups && props.groups.length < 1}
            onClick={props.onShowAllDevices}
          >
            Show All Devices
          </EuiButton>,
        ]
      : [
          <EuiButton
            key={'modalForBulk'}
            id={'bulk-add-devices'}
            isDisabled={!props.selectedGroup}
            onClick={props.onBulkAddDevices}
            iconType="importAction"
          >
            Upload Device File
          </EuiButton>,
          <EuiButton
            size="s"
            id="add-device-to-group-open"
            key={'openModal'}
            fill
            isDisabled={props.groups && props.groups.length < 1}
            onClick={props.onAddDevices}
          >
            Add devices
          </EuiButton>,
        ];
  };

  const search: EuiSearchBarProps = {
    toolsRight: renderToolsRight(),
    box: {
      incremental: true,
      schema: true,
    },
  };

  // If a removal method is supplied, display the remove action
  const columns = [
    ...GROUPS_DEVICE_TABLE_COLUMNS,
    {
      name: 'Actions',
      actions: [removeAction],
    },
  ];

  return (
    <EuiErrorBoundary>
      <ConfirmRemoveDevice
        group={props.selectedGroup}
        isOpen={isConfirmRemoveOpen}
        onClose={onConfirmRemoveClose}
        onDeviceRemoval={handleConfirmRemove}
      />
      <EuiFlexGroup style={{ width: '100%', minHeight: '50vh' }}>
        <EuiFlexItem grow={1}>
          <GroupFacetSelector
            setShowAllDevices={props.setShowAllDevices}
            groups={props.groups ?? []}
            selectedGroup={props.selectedGroup}
            setSelectedGroup={props.setSelectedGroup}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={4}>
          <EuiTitle size="xs">
            {props.selectedGroup ? (
              <span>
                Devices assigned to{' '}
                <span style={{ color: '#36a2ef' }}>{props.selectedGroup?.identifier}</span>
              </span>
            ) : (
              <span>All devices</span>
            )}
          </EuiTitle>
          {/* @ts-expect-error I have no idea how to fix this ts error */}
          <EuiInMemoryTable
            loading={props.isLoading}
            tableCaption="Group Devices"
            items={props.devices ?? []}
            columns={columns}
            search={search}
            pagination={true}
            message={'Please select a group to view, add, and delete devices'}
            sorting={true}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiErrorBoundary>
  );
};

export default GroupsInMemoryTable;
