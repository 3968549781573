import { useAuth0 } from '@auth0/auth0-react';
import { EuiButtonEmpty } from '@elastic/eui';

type LogoutButtonProps = {
  title: string;
};

const LogoutButton = ({ ...props }: LogoutButtonProps) => {
  const { logout } = useAuth0();

  function handleLogout() {
    return logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  return (
    <EuiButtonEmpty
      id="logoutButton"
      iconType="minimize"
      onClick={handleLogout}
    >
      {props.title}
    </EuiButtonEmpty>
  );
};

export default LogoutButton;
