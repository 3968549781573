import { useState } from 'react';
import { useCreateGroupMutation, useDeleteGroupMutation, GenericRow } from 'gqlHooks';
import { EuiErrorBoundary } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage } from 'app/utils/toast-messages';

import { GroupsCrudView } from './groups-crud-view';
import ConfirmDeleteGroup from './delete/confirm-dialog/confirm-delete';
import ConfirmCascadeDelete from './delete/confirm-cascade/confirm-cascade';
import { SntGroupFota } from './edit/fota/snt-group-fota';
import { SntGroupConfig } from './edit/config/snt-group-config';
import { SntSendGroupCommand } from './edit/command/snt-group-command';
import { SntGroupOtaCredRequest } from './edit/cred/snt-group-ota-cred';

export type GroupsCrudProps = {
  /** Adds a toast to the global message queue */
  addToastMessage: (message: Toast) => void;
  organizationId: string;
  groups: GenericRow[];
  isLoadingGroups: boolean;
};

export const GroupsCrud = (props: GroupsCrudProps) => {
  const [createFlyoutOpen, setCreateFlyoutOpen] = useState(false);
  const [configFlyoutOpen, setConfigFlyoutOpen] = useState(false);
  const [fotaFlyoutOpen, setFotaFlyoutOpen] = useState(false);
  const [commandFlyoutOpen, setCommandFlyoutOpen] = useState(false);
  const [credFlyoutOpen, setCredFlyoutOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const [confirmCascadeDeleteOpen, setConfirmCascadeDeleteOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<GenericRow[]>([]);

  const [createGroupMutation, { loading: isSubmitting }] = useCreateGroupMutation({
    onCompleted: () => {
      setCreateFlyoutOpen(false);
    },
    refetchQueries: ['GetGroups'],
  });

  const [deleteGroupMutation, { loading: isDeleting }] = useDeleteGroupMutation({
    variables: {
      organizationId: props.organizationId,
      groupToken: groupToDelete,
      cascade: false,
    },
    onCompleted: () => {
      setConfirmDeleteOpen(false);
      setGroupToDelete('');
    },
    onError: error => {
      // @ts-expect-error error IS on the return object. TS thinks it isnt.
      if (error?.graphQLErrors?.[0].error.includes('delete group while it contains')) {
        setConfirmDeleteOpen(false);
        setConfirmCascadeDeleteOpen(true);
      } else {
        props.addToastMessage(errorToastMessage('Failed to delete group'));
      }
    },
    refetchQueries: ['GetGroups'],
  });

  const [deleteGroupCascade, { loading: isCascading }] = useDeleteGroupMutation({
    variables: {
      organizationId: props.organizationId,
      groupToken: groupToDelete,
      cascade: true,
    },
    onCompleted: () => {
      setConfirmCascadeDeleteOpen(false);
      setGroupToDelete('');
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to delete group'));
    },
    refetchQueries: ['GetGroups'],
  });

  /** Derived Values */
  const selectedGroupTokens = selectedGroups.map(group => group.identifier ?? '');
  const displayLoading = isCascading || isDeleting || isSubmitting || props.isLoadingGroups;

  /** Methods */
  const handleSubmit = (groupName: string, deviceType: string, parentGroup: string) => {
    // In case of accidental trailing/leading spaces so we don't end up with cursed ID tokens
    const trimmedName = groupName.trim();
    createGroupMutation({
      variables: {
        organizationId: props.organizationId,
        name: trimmedName,
        deviceType: deviceType,
        groupToken: `${parentGroup}/${trimmedName}`,
      },
    });
  };

  const handleDelete = (groupId: string) => {
    // Open the confirmation dialog
    setGroupToDelete(groupId);
    setConfirmDeleteOpen(true);
  };

  const handleCloseCascade = () => setConfirmCascadeDeleteOpen(false);
  const handleCloseConfirm = () => setConfirmDeleteOpen(false);
  const handleCloseFota = () => setFotaFlyoutOpen(false);
  const handleCloseOtaCred = () => setCredFlyoutOpen(false);
  const handleCloseConfig = () => setConfigFlyoutOpen(false);
  const handleCloseCommand = () => setCommandFlyoutOpen(false);

  const handleManage = (type: string) => {
    if (type == 'fota') {
      setFotaFlyoutOpen(true);
    } else if (type == 'config') {
      setConfigFlyoutOpen(true);
    } else if (type == 'command') {
      setCommandFlyoutOpen(true);
    } else if (type == 'cred') {
      setCredFlyoutOpen(true);
    }
  };

  return (
    <EuiErrorBoundary>
      <ConfirmDeleteGroup
        isOpen={confirmDeleteOpen}
        onRequestDelete={deleteGroupMutation}
        onClose={handleCloseConfirm}
      />
      <ConfirmCascadeDelete
        isOpen={confirmCascadeDeleteOpen}
        onRequestDelete={deleteGroupCascade}
        onClose={handleCloseCascade}
      />
      <SntGroupFota
        organizationId={props.organizationId}
        isOpen={fotaFlyoutOpen}
        groupTokens={selectedGroupTokens}
        addToastMessage={props.addToastMessage}
        onClose={handleCloseFota}
      />
      <SntGroupConfig
        organizationId={props.organizationId}
        isOpen={configFlyoutOpen}
        groupTokens={selectedGroupTokens}
        addToastMessage={props.addToastMessage}
        onClose={handleCloseConfig}
      />
      <SntGroupOtaCredRequest
        organizationId={props.organizationId}
        isOpen={credFlyoutOpen}
        groupTokens={selectedGroupTokens}
        addToastMessage={props.addToastMessage}
        onClose={handleCloseOtaCred}
      />
      <SntSendGroupCommand
        isOpen={commandFlyoutOpen}
        organizationId={props.organizationId}
        groupTokens={selectedGroupTokens}
        addToastMessage={props.addToastMessage}
        onClose={handleCloseCommand}
      />
      <GroupsCrudView
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        organizationId={props.organizationId}
        groups={props.groups}
        onDelete={handleDelete}
        onCreate={handleSubmit}
        isLoading={displayLoading}
        isCreateOpen={createFlyoutOpen}
        setCreateOpen={setCreateFlyoutOpen}
        onManage={handleManage}
      />
    </EuiErrorBoundary>
  );
};
