import {
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiStat,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiPanel,
  EuiTitle,
  EuiBadge,
  EuiFlyoutFooter,
  EuiButton,
  EuiSpacer,
} from '@elastic/eui';
import { CommandConfigurationRequestRow } from 'app/generated/graphql';
import SntConfigRequestsTable from 'app/layout/config/snt/requests/table/snt-config-requests-table';
import { CMD_CFG_SUMMARY_COLUMNS } from 'app/layout/config/snt/requests/table/table-columns';
import { COLORS } from 'app/utils/constants';
import { CSVLink } from 'react-csv';
import {
  createdFilter,
  inProgressFilter,
  failedFilter,
  invalidatedFilter,
  completedFilter,
} from 'app/utils/group-status-filters';

interface CmdCfgRequestSummaryProps {
  /** True if the table should show a loading status */
  isLoading: boolean;
  /** True if the configuration panel should be open */
  isOpen: boolean;
  /** Invoked when the configuration panel should be closed */
  onClose: () => void;
  /** Invoked when the user requests a cancel for an individual request */
  onRequestCancel: (requestId: number, deviceId: string) => void;
  /** Method invoked when the users wish to retry a failed request */
  onRetryRequests: (deviceIds: string[]) => void;
  /** Rows to be used in creating the summary */
  requests: CommandConfigurationRequestRow[];
}

const buildExportData = (requests: CommandConfigurationRequestRow[]) => {
  if (!requests || requests.length == 0) {
    // CSV export wants at a minimum an exmpty string
    return '';
  }
  // Build so the users get pretty column headers
  const exportData = [];
  exportData.push(['Device IMEI', 'Request State', 'Last Update']);
  requests.forEach((row: CommandConfigurationRequestRow) =>
    exportData.push([row.deviceId, row.requestState, row.updatedAt])
  );
  return exportData;
};

/** Summary of a group CMD/CNFG request - shows device by device status */
export const CmdCfgRequestSummary = (props: CmdCfgRequestSummaryProps) => {
  if (!props.isOpen) {
    return null;
  }

  const completedRequests = props.requests.filter(completedFilter);
  const inProgressRequests = props.requests.filter(inProgressFilter);
  const invalidatedRequests = props.requests.filter(invalidatedFilter);
  const createdRequests = props.requests.filter(createdFilter);
  const failedRequests = props.requests.filter(failedFilter);
  const exportData = buildExportData(props.requests);

  const buildStat = (
    devices: Array<CommandConfigurationRequestRow>,
    title: string,
    color: string,
    icon: string
  ) => {
    const count = devices.length;
    if (count == 0) return null;
    return (
      <EuiFlexItem
        grow={false}
        style={{ marginRight: '15px' }}
      >
        <EuiStat
          title={`${count} of ${props.requests.length}`}
          textAlign="left"
          titleColor={color}
          reverse
          description={
            <EuiBadge
              iconType={icon}
              color={color}
            >
              {title}
            </EuiBadge>
          }
        />
      </EuiFlexItem>
    );
  };

  const onRetryFailedRequests = () => {
    const deviceIds = failedRequests.map(
      (request: CommandConfigurationRequestRow) => request.deviceId
    );
    // Don't send a request if there are no failures to retry
    if (deviceIds.length > 0) {
      props.onRetryRequests(deviceIds);
    }
  };

  const summarySection = (
    <EuiErrorBoundary id="progress-details-summary">
      <EuiPanel hasBorder={true}>
        <EuiFlexGroup alignItems="center">
          {buildStat(createdRequests, 'Created', COLORS.ACCENT, 'iInCircle')}
          {buildStat(inProgressRequests, 'In Progress', COLORS.INFO, 'clock')}
          {buildStat(invalidatedRequests, 'Cancelled', COLORS.WARNING, 'iInCircle')}
          {buildStat(failedRequests, 'Failed', COLORS.DANGER, 'error')}
          {buildStat(completedRequests, 'Completed', COLORS.SUCCESS, 'check')}
        </EuiFlexGroup>
      </EuiPanel>
      <EuiSpacer />
    </EuiErrorBoundary>
  );

  const displaySummary = props.requests.length > 0;

  return (
    <EuiErrorBoundary>
      <EuiFlyout
        ownFocus
        onClose={props.onClose}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Request details</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {displaySummary ? summarySection : null}
          <EuiSpacer />
          <SntConfigRequestsTable
            isLoading={props.isLoading}
            tableColumns={CMD_CFG_SUMMARY_COLUMNS}
            requests={props.requests}
            onRequestCancel={props.onRequestCancel}
          />
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiButton onClick={onRetryFailedRequests}>Retry failures</EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <CSVLink
                data={exportData}
                filename="cmd-cfg-progress.csv"
              >
                Download Summary
              </CSVLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiErrorBoundary>
  );
};
