import { EuiBasicTableColumn, EuiButtonEmpty, EuiTableFieldDataColumnType } from '@elastic/eui';
import type { Messages } from 'gqlHooks';
import { getLocalTime } from 'app/utils/dates';
import { Location } from 'app/models/devices/Location';
import { RawMessageProps } from 'app/types';

const renderMapLink = (_unused: string, rawMessage: Messages) => {
  const parsedRawMessage = JSON.parse(rawMessage.data);
  const location = new Location(parsedRawMessage);

  const locationUrl = location?.googleMapsUrl;
  const locationLat = location?.shortenedCoords.shortenedLat;
  const locationLng = location?.shortenedCoords.shortenedLng;

  if (!locationLat || !locationLng || locationLat === '0.00' || locationLng === '0.00') {
    return null;
  }

  return (
    <EuiButtonEmpty
      id="map-location-link"
      aria-label="Link to google map"
      href={locationUrl}
      iconType="mapMarker"
      target="_blank"
    >
      {`${locationLat}, ${locationLng}`}
    </EuiButtonEmpty>
  );
};

const renderMessageType = (typeCode: string) => {
  return typeCode?.toLowerCase().split('/').pop() ?? '';
};

const renderBatteryVoltage = (_unused: string, rawMessage: Messages) => {
  const parsedRawMessage: RawMessageProps = JSON.parse(rawMessage?.data);
  const batteryVoltage = parsedRawMessage?.battery_voltage;
  return batteryVoltage;
};

const renderDate = (date: string) => {
  return date ? getLocalTime(date) : '';
};

const renderMotionStatus = (_unused: string, rawMessage: Messages) => {
  const parsedRawMessage: RawMessageProps = JSON.parse(rawMessage?.data);
  const motionStatus = parsedRawMessage?.motion_status;
  return motionStatus == 'n/a' ? 'disabled' : motionStatus;
};

const createdAt: EuiTableFieldDataColumnType<Messages> = {
  field: 'createdAt',
  name: 'Message created',
  dataType: 'string',
  sortable: true,
  truncateText: false,
  render: renderDate,
};

const ingestedAt: EuiTableFieldDataColumnType<Messages> = {
  field: 'ingestedAt',
  name: 'Message received',
  dataType: 'string',
  sortable: true,
  truncateText: false,
  render: renderDate,
};

const serialColumn: EuiTableFieldDataColumnType<Messages> = {
  field: 'deviceId',
  name: 'Device IMEI',
  dataType: 'string',
  truncateText: false,
};

const mapLinkColumn: EuiTableFieldDataColumnType<Messages> = {
  field: 'location',
  name: 'Location',
  dataType: 'string',
  truncateText: false,
  render: renderMapLink,
};

const messageType: EuiBasicTableColumn<Messages> = {
  field: 'typeCode',
  name: 'Event type',
  truncateText: false,
  sortable: ({ typeCode }) => {
    return typeCode?.toLowerCase().split('/').pop() ?? '';
  },
  dataType: 'string',
  render: renderMessageType,
};

const motionStatus: EuiTableFieldDataColumnType<Messages> = {
  field: 'motion_status',
  name: 'Motion Status',
  sortable: (rawMessage: Messages) => {
    return renderMotionStatus('', rawMessage);
  },
  truncateText: false,
  dataType: 'string',
  render: renderMotionStatus,
};

const batteryVoltage: EuiTableFieldDataColumnType<Messages> = {
  field: 'battery',
  name: 'Battery voltage',
  sortable: (rawMessage: Messages) => {
    return renderBatteryVoltage('', rawMessage);
  },
  truncateText: false,
  dataType: 'string',
  render: renderBatteryVoltage,
};

export const MESSAGE_TABLE_COLUMNS_WITHOUT_SERIAL: EuiTableFieldDataColumnType<Messages>[] = [
  createdAt,
  ingestedAt,
  mapLinkColumn,
  messageType,
  batteryVoltage,
  motionStatus,
];

export const MESSSAGE_TABLE_COLUMNS_WITH_SERIAL: EuiTableFieldDataColumnType<Messages>[] = [
  serialColumn,
  createdAt,
  ingestedAt,
  mapLinkColumn,
  messageType,
  batteryVoltage,
  motionStatus,
];
