import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiErrorBoundary,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiTitle,
} from '@elastic/eui';
import { Controller } from 'react-hook-form';
import { CONSTANTS } from 'utils/constants';
import { SelectRole } from 'components/forms/select-role';
import { SelectOrganization } from 'components/forms/select-organization';
import type { Control } from 'react-hook-form';
import type { CreateUserMutationVariables } from 'gqlHooks';

const ERROR_MESSAGES = {
  usernameMinLength: `Username must be at least ${CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH} characters`,
  usernameRequired: 'Username is required',
  emailInvalid: 'Email address is invalid',
  emailRequired: 'Email address is required',
  roleRequired: 'Role is required',
  organizationRequired: 'Organization is required',
};

type ManageUserFormViewProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  control: Control<CreateUserMutationVariables>;
  isLoading: boolean;
  isAdmin?: boolean;
  isEditMode: boolean;
  canDeleteUser: boolean;
  onDeleteUser: () => void;
  onCloseAction: () => void;
  onReset: () => void;
};

export default function ManageUserFormView(props: ManageUserFormViewProps) {
  // Doing the prevent default as forms like refreshing the page
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit(event);
  };

  return (
    <EuiErrorBoundary>
      <EuiFlexGrid
        columns={1}
        style={{ width: '100%' }}
      >
        <EuiFlexGroup>
          <EuiFlexItem grow={9}>
            <EuiTitle>
              <h2>{props.isEditMode ? 'Edit user' : 'Add user'}</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={1}>
            <EuiButtonIcon
              id="on-close-user-form"
              onClick={props.onCloseAction}
              display="base"
              iconType="cross"
              aria-label="Next"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem grow>
          <EuiForm
            component="form"
            onSubmit={handleOnSubmit}
            css={{ minWidth: '300px' }}
          >
            {props.isAdmin ? (
              <Controller
                name="organizationId"
                control={props.control}
                disabled={!props.isAdmin || props.isEditMode}
                rules={{
                  required: { value: true, message: ERROR_MESSAGES.organizationRequired },
                }}
                render={({
                  fieldState: { invalid, error },
                  field: { disabled, onChange, onBlur, value },
                }) => (
                  <SelectOrganization
                    disabled={disabled}
                    label="Organization"
                    isInvalid={invalid}
                    errorMessage={error?.message}
                    selectedOrganization={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            ) : null}
            <Controller
              name="emailAddress"
              control={props.control}
              disabled={props.isEditMode}
              rules={{
                required: { value: true, message: ERROR_MESSAGES.emailRequired },
                pattern: { value: CONSTANTS.EMAIL_REGEX, message: ERROR_MESSAGES.emailInvalid },
              }}
              render={({
                fieldState: { invalid, error },
                field: { disabled, onChange, onBlur, value },
              }) => (
                <EuiFormRow
                  label="Email"
                  isInvalid={invalid}
                  error={error?.message}
                  isDisabled={disabled}
                  aria-required
                >
                  <EuiFieldText
                    disabled={disabled}
                    placeholder="Enter an email address"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
            <Controller
              name="name"
              control={props.control}
              rules={{
                required: { value: true, message: ERROR_MESSAGES.usernameRequired },
                minLength: {
                  value: CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH,
                  message: ERROR_MESSAGES.usernameMinLength,
                },
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  label="Username"
                  isInvalid={invalid}
                  error={error?.message}
                  aria-required
                >
                  <EuiFieldText
                    placeholder="Enter a username"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
            <Controller
              name="role"
              control={props.control}
              rules={{
                required: { value: true, message: ERROR_MESSAGES.roleRequired },
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <SelectRole
                  label="Role"
                  isInvalid={invalid}
                  error={error?.message}
                  aria-required
                  selectedRole={value!}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            />
            <EuiFlexGroup direction="row">
              <EuiFormRow hasEmptyLabelSpace>
                <EuiButton
                  id="manage-users-form-submit"
                  type="submit"
                  fill
                  isLoading={props.isLoading}
                >
                  Submit
                </EuiButton>
              </EuiFormRow>
              {props.isEditMode && props.canDeleteUser ? (
                <EuiFormRow hasEmptyLabelSpace>
                  <EuiButtonEmpty
                    id="delete-user"
                    onClick={props.onDeleteUser}
                    type="button"
                    color="danger"
                    disabled={!props.canDeleteUser}
                    isLoading={props.isLoading}
                  >
                    Delete User
                  </EuiButtonEmpty>
                </EuiFormRow>
              ) : null}
            </EuiFlexGroup>
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiErrorBoundary>
  );
}
