import {
  EuiButton,
  EuiCallOut,
  EuiComboBoxOptionOption,
  EuiErrorBoundary,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from '@elastic/eui';
import { Control, Controller, useWatch } from 'react-hook-form';
import { CONSTANTS } from 'utils/constants';
import { AddDeviceToOrganizationMutationVariables, UserInfo } from 'gqlHooks';
import { SelectOrganization } from 'components/forms/select-organization';
import { SelectDeviceType } from 'components/forms/select-device-type';
import { SelectGroup } from 'app/layout/groups/select/groups';

const ERROR_MESSAGES = {
  deviceIdRequired: 'Device IMEI is required',
  organizationRequired: 'Organization is required',
  labelMinimumLength: `Label must be at least ${CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH} characters`,
  deviceTypeRequired: 'Device type is required',
};

type AddDeviceProps = {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  control: Control<AddDeviceToOrganizationMutationVariables>;
  isLoading: boolean;
  isAdmin: boolean;
  handleCalloutDismiss: () => void;
  errorMessage: string | undefined;
  selectedGroup: EuiComboBoxOptionOption[];
  setSelectedGroup: (group: EuiComboBoxOptionOption[]) => void;
  userOrg: UserInfo['organizationId'];
};

export default function AddDeviceToOrganizationFormView(props: AddDeviceProps) {
  // Doing the prevent default as forms like refreshing the page
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit(event);
  };

  // Watching the organizationId field for changes
  const organizationId = useWatch({
    control: props.control,
    name: 'organizationId', // This should match the name of the field you want to watch
  });

  const handleGroupOnChange = (group: EuiComboBoxOptionOption[]) => {
    props.setSelectedGroup(group);
  };

  return (
    <EuiErrorBoundary>
      <EuiFlexGrid
        columns={1}
        style={{ width: '100%' }}
      >
        {props.errorMessage && (
          <EuiFlexItem>
            <EuiCallOut
              style={{ minWidth: '100%' }}
              onDismiss={props.handleCalloutDismiss}
              iconType="alert"
              title={'Failed to add device'}
              color="danger"
            >
              <p>{props.errorMessage}</p>
            </EuiCallOut>
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          <EuiForm
            component="form"
            onSubmit={handleOnSubmit}
            css={{ minWidth: '300px' }}
          >
            <Controller
              name="deviceId"
              control={props.control}
              rules={{
                required: { value: true, message: ERROR_MESSAGES.deviceIdRequired },
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  label="Device IMEI"
                  isInvalid={invalid}
                  error={error?.message}
                  aria-required
                >
                  <EuiFieldText
                    placeholder="Enter a device IMEI"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
            <Controller
              name="label"
              control={props.control}
              rules={{
                minLength: {
                  value: CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH,
                  message: ERROR_MESSAGES.labelMinimumLength,
                },
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <EuiFormRow
                  label="Device Name (optional)"
                  isInvalid={invalid}
                  error={error?.message}
                >
                  <EuiFieldText
                    placeholder="Enter a label"
                    value={value ?? ''}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </EuiFormRow>
              )}
            />
            {props.isAdmin ? (
              <Controller
                name="organizationId"
                control={props.control}
                rules={{
                  required: { value: true, message: ERROR_MESSAGES.organizationRequired },
                }}
                render={({
                  fieldState: { invalid, error },
                  field: { onChange, onBlur, value },
                }) => (
                  <SelectOrganization
                    label="Organization"
                    isInvalid={invalid}
                    errorMessage={error?.message}
                    onChange={onChange}
                    selectedOrganization={value}
                    onBlur={onBlur}
                  />
                )}
              />
            ) : null}
            <Controller
              name="deviceType"
              control={props.control}
              rules={{
                required: { value: true, message: ERROR_MESSAGES.deviceTypeRequired },
                minLength: {
                  value: CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH,
                  message: ERROR_MESSAGES.labelMinimumLength,
                },
              }}
              render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
                <SelectDeviceType
                  isInvalid={invalid}
                  onChange={onChange}
                  selectedDeviceType={value ?? ''}
                  onBlur={onBlur}
                  hasNoInitialSelection
                />
              )}
            />
            <SelectGroup
              isSingleSelect
              onChange={handleGroupOnChange}
              selectedGroups={props.selectedGroup}
              organizationId={props.isAdmin ? organizationId : props.userOrg}
              label="Group (Optional)"
              helpText="The device will be added to the selected group upon creation"
            />
            <EuiFormRow hasEmptyLabelSpace>
              <EuiButton
                id="add-new-device"
                type="submit"
                fill
                isLoading={props.isLoading}
              >
                Add Device
              </EuiButton>
            </EuiFormRow>
            <EuiSpacer size="m" />
          </EuiForm>
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiErrorBoundary>
  );
}
