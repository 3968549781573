import { useState, useMemo } from 'react';
import { EuiFlexItem, EuiFlexGrid, EuiTabs, EuiTab, EuiErrorBoundary } from '@elastic/eui';
import ResponsivePanel from 'layout/panel/responsive-panel';
import ThemedJson from 'layout/json/themed-json';
import {
  CommandConfigurationRequestRow,
  Maybe,
  RequestMessage,
  Scalars,
  StrawberryGroupOperationResponse,
} from 'gqlHooks';
import { SntSummarySection } from 'layout/config/snt/requests/table/expand-section/summary/summary-section';

const FULL_MESSAGE_ID = 'full_message_tab';
const SUMMARY_ID = 'summary_tab';
const RETURN_SUMMARY = 'return_summary';

export interface SntExpandSectionProps {
  request: RequestMessage;
  /** Optional as not all cmd requests include a response */
  requestResponse?: Maybe<Scalars['JSONString']['output']>;
  /** The configuration message whose content should be displayed */
  fullMessage: CommandConfigurationRequestRow | StrawberryGroupOperationResponse;
}

export const SntExpandSection = (props: SntExpandSectionProps) => {
  const [selectedTabId, setSelectedTabId] = useState(SUMMARY_ID);

  const summaryTab = {
    id: SUMMARY_ID,
    name: 'Request Summary',
    content: <SntSummarySection cmdRequest={props.request} />,
  };

  const responseTab = {
    id: RETURN_SUMMARY,
    name: 'Response Summary',
    content: <SntSummarySection requestResponse={props.requestResponse} />,
  };

  const fullMessageTab = {
    id: FULL_MESSAGE_ID,
    name: 'Full Data',
    content: (
      <ResponsivePanel>
        <ThemedJson jsonValues={props.fullMessage} />
      </ResponsivePanel>
    ),
  };

  const tabs = props.requestResponse
    ? [summaryTab, responseTab, fullMessageTab]
    : [summaryTab, fullMessageTab];

  const selectedTabContent = useMemo(() => {
    return tabs.find(obj => obj.id === selectedTabId)?.content;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id);
  };

  const renderTabs = () => {
    return tabs.map((tab, index) => (
      <EuiTab
        key={index}
        onClick={() => onSelectedTabChanged(tab.id)}
        isSelected={tab.id === selectedTabId}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  return (
    <EuiErrorBoundary id="snt-expand-section">
      <EuiFlexGrid
        columns={1}
        style={{ width: '100%' }}
      >
        <EuiFlexItem>
          <EuiTabs>{renderTabs()}</EuiTabs>
        </EuiFlexItem>
        <EuiFlexItem>{selectedTabContent}</EuiFlexItem>
      </EuiFlexGrid>
    </EuiErrorBoundary>
  );
};
