import { useEffect, useState } from 'react';
import { EuiErrorBoundary, EuiSelectable, EuiSelectableOption, EuiTitle } from '@elastic/eui';
import { GenericRow } from 'gqlHooks';
import { EuiSelectableOnChangeEvent } from '@elastic/eui/src/components/selectable/selectable';

export interface GroupFacetSelectorProps {
  /* Groups to be displayed as selectable facets (clickable tabs) */
  groups: GenericRow[];
  /** Currently selected group in the facets */
  selectedGroup?: GenericRow;
  /* Notifies when a group is selected */
  setSelectedGroup: (group: GenericRow | undefined) => void;
  /* Optional -  Used to get out of "showing all devices" view, and drilling to specific group */
  setShowAllDevices?: (showAllDevices: boolean) => void;
}

/* Facet style selector for groups */
export const GroupFacetSelector = (props: GroupFacetSelectorProps) => {
  const [options, setOptions] = useState<EuiSelectableOption<GenericRow>[]>([]);

  useEffect(() => {
    setOptions([
      ...props.groups.map(
        (group): EuiSelectableOption => ({
          label: `${group.label}`,
          key: group.identifier!,
          data: group,
        })
      ),
    ]);
  }, [props.groups, props.setSelectedGroup]);

  const handleOnChange = (
    newOptions: EuiSelectableOption[],
    _unused: EuiSelectableOnChangeEvent,
    changedOption: EuiSelectableOption<GenericRow>
  ) => {
    //check if a group is selected
    const isChecked = newOptions.some(option => option.checked === 'on');

    props.setShowAllDevices?.(!isChecked);
    setOptions(newOptions);
    props.setSelectedGroup(isChecked ? changedOption.data : undefined);

    // If no grou is selected, display all devices regardless of group
    if (!isChecked) {
      props.setShowAllDevices?.(true);
    }
  };

  return (
    <EuiErrorBoundary>
      <EuiSelectable
        aria-label="Select group to view group data"
        searchable
        height="full"
        singleSelection
        options={options}
        onChange={handleOnChange}
        listProps={{}}
      >
        {(list, search) => (
          <>
            <EuiTitle size="xs">
              <h3>Select group</h3>
            </EuiTitle>
            {search}
            {list}
          </>
        )}
      </EuiSelectable>
    </EuiErrorBoundary>
  );
};

export default GroupFacetSelector;
