import {
  EuiButton,
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiTitle,
} from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { Configuration, useSendGroupConfigMutation } from 'gqlHooks';
import { errorToastMessage, infoToastMessage, successToastMessage } from 'app/utils/toast-messages';
import { SntConfigView } from 'app/layout/config/snt/snt-config-view';
import {
  DEFAULT_GROUP_SNT_CONFIGURATION,
  getDirtyConfigValues,
} from 'app/layout/config/snt/snt-config-utils';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { SntGroupSummarySection } from './snt-group-config-summary';

interface SntGroupConfigProps {
  organizationId: string;
  /** Serial of the device whose config is being modified */
  groupTokens: string[];
  /** Adds a toast to the global message queue */
  addToastMessage: (message: Toast) => void;
  /** Invoked when the configuration panel should be closed */
  onClose: () => void;
  /** True if the configuration panel should be open */
  isOpen: boolean;
}

/** Configuration options for the SnT devices */
export const SntGroupConfig = (props: SntGroupConfigProps) => {
  // Track if the user is reviewing the configuration or editing
  const [isEditing, setIsEditing] = useState(true);
  const [valuesToSubmit, setValuesToSubmit] = useState<Configuration | undefined>(undefined);

  const [updateDeviceConfigMutation, { loading, error }] = useSendGroupConfigMutation();

  const {
    control,
    formState: { dirtyFields },
    getValues,
    handleSubmit,
    reset,
  } = useForm<Configuration>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: DEFAULT_GROUP_SNT_CONFIGURATION,
  });

  // On open call the reset otherwise dirty state from last
  // use will still be present
  useEffect(() => {
    if (props.isOpen) {
      reset();
    }
  }, [props.isOpen, reset]);

  if (!props.isOpen) {
    return null;
  }

  const onReview = () => {
    const configValues = getValues();

    const dirty = getDirtyConfigValues(dirtyFields, configValues);
    setValuesToSubmit(dirty);
    setIsEditing(false);
  };

  const onBack = () => {
    setIsEditing(true);
  };

  const onSubmit = () => {
    const configValues = getValues();

    const dirty = getDirtyConfigValues(dirtyFields, configValues);
    if (Object.keys(dirty).length === 0) {
      props.addToastMessage(infoToastMessage(`No configuration changes to submit`));
      return;
    }

    props.groupTokens.forEach(token => {
      const shortName = token.split('/').pop();
      updateDeviceConfigMutation({
        variables: {
          organizationId: props.organizationId,
          groupToken: token,
          config: dirty,
        },
        onCompleted: () => {
          props.addToastMessage(successToastMessage(`Config changes sent to ${shortName}`));
        },
        onError: error => {
          // @ts-expect-error error IS on the return object. TS thinks it isnt.
          if (error?.graphQLErrors?.[0].error.includes('has no devices assigned')) {
            props.addToastMessage(infoToastMessage(`${shortName} contains no devices`));
          } else {
            props.addToastMessage(errorToastMessage(`Failed to send changes to ${shortName}`));
          }
        },
      });
    });
  };

  return (
    <EuiErrorBoundary>
      <EuiFlyout
        ownFocus
        onClose={props.onClose}
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Send configuration</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          {isEditing ? (
            <SntConfigView
              control={control}
              hideMessage
              hasError={error ? true : false}
              isLoading={loading}
            />
          ) : (
            <SntGroupSummarySection config={valuesToSubmit} />
          )}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          {isEditing ? (
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton
                  id="snt-review-group-config-changes"
                  color="primary"
                  onClick={() => onReview()}
                >
                  Review changes
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  id="snt-reset-group-config-changes"
                  color="text"
                  onClick={() => reset()}
                >
                  Reset form
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton
                  id="snt-return-to-editing-group-config"
                  color="text"
                  onClick={() => onBack()}
                >
                  Return to editing
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  id="snt-submit-group-config"
                  color="primary"
                  fill
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiErrorBoundary>
  );
};
