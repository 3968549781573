import { useState } from 'react';
import { EuiErrorBoundary } from '@elastic/eui';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import {
  RemoveDeviceFromOrganizationMutationVariables,
  UserInfo,
  useRemoveDeviceFromOrganizationMutation,
} from 'gqlHooks';
import { UserInfoContextData, useUserInfo } from 'app/context/userInfo';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { successToastMessage, errorToastMessage } from 'app/utils/toast-messages';
import { TitleBarPanel } from 'app/layout/title-bar';

import RemoveDeviceFromOrganizationFormView from './view';

const DEFAULT_VALUES: RemoveDeviceFromOrganizationMutationVariables = {
  deviceId: '',
  organizationId: '',
};

type RemoveDeviceFromOrgProps = {
  /** Method for adding toasts to display status messages to the user */
  addToastMessage: (toasty: Toast) => void;
};

export const RemoveDeviceFromOrganizationForm = (props: RemoveDeviceFromOrgProps) => {
  const [defaults, setDefaults] = useState(DEFAULT_VALUES);
  const [errorState, setErrorState] = useState<string | undefined>();

  const {
    queryData: { userInfo },
  }: UserInfoContextData = useUserInfo();

  const userOrg: UserInfo['organizationId'] = userInfo?.organizationId ?? '';
  const isUserAdmin: boolean = userInfo?.role === 'administrator';

  const { control, getValues, handleSubmit, reset } =
    useForm<RemoveDeviceFromOrganizationMutationVariables>({
      mode: 'all',
      shouldFocusError: true,
      defaultValues: defaults,
    });

  const [removeDeviceFromOrganizationMutation, { loading: isSubmitting }] =
    useRemoveDeviceFromOrganizationMutation({
      onCompleted: () => {
        const { organizationId, deviceId } = getValues();
        const newDefaults = { ...defaults, organizationId, deviceId };
        setDefaults(newDefaults);
        reset(newDefaults);
        props.addToastMessage(successToastMessage('Removed device'));
      },
      onError: () => {
        props.addToastMessage(errorToastMessage('Failed to remove device'));
      },
    });

  const onSubmit: SubmitHandler<RemoveDeviceFromOrganizationMutationVariables> = () => {
    const { deviceId, organizationId } = getValues(); // Access react-hook-form values
    removeDeviceFromOrganizationMutation({
      variables: {
        deviceId: deviceId,
        organizationId: isUserAdmin ? organizationId : userOrg,
      },
    });
  };

  const handleCalloutDismiss = () => {
    setErrorState(undefined);
  };

  return (
    <EuiErrorBoundary>
      <TitleBarPanel
        title="Remove Device"
        isLoading={isSubmitting}
        sectionId="remove-device-section"
        iconType="minusInCircle"
      >
        <RemoveDeviceFromOrganizationFormView
          onSubmit={handleSubmit(onSubmit)}
          control={control}
          isAdmin={isUserAdmin}
          isLoading={isSubmitting}
          handleCalloutDismiss={handleCalloutDismiss}
          errorMessage={errorState}
        />
      </TitleBarPanel>
    </EuiErrorBoundary>
  );
};
