import {
  EuiButton,
  EuiErrorBoundary,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
} from '@elastic/eui';
import { useForm, Controller } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { CONSTANTS } from 'utils/constants';
import { CreateOrganizationMutationVariables, useCreateOrganizationMutation } from 'gqlHooks';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';
import { TitleBarPanel } from 'app/layout/title-bar';

const ERROR_MESSAGES = {
  organizationNameMinLength: `Organization name must be at least ${CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH} characters`,
  organizationNameRequired: 'Organization name is required',
  organizationIdRequired: 'Organization ID is required',
  organizationIdMinLength: `Organization ID must be at least ${CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH} characters`,
};

type OrgFormProps = {
  /** Method for adding toasts to display status messages to the user */
  addToastMessage: (toasty: Toast) => void;
};

export const CreateOrganizationForm = (props: OrgFormProps) => {
  const defaultValues: CreateOrganizationMutationVariables = {
    name: '',
    organizationId: '',
  };

  const { control, getValues, handleSubmit, reset } = useForm<CreateOrganizationMutationVariables>({
    mode: 'all',
    defaultValues,
  });

  const [createOrganizationMutation, { loading: isSubmitting }] = useCreateOrganizationMutation({
    onCompleted: () => {
      props.addToastMessage(successToastMessage('Created organization'));
      reset();
    },
    onError: () => {
      props.addToastMessage(errorToastMessage('Failed to create organization'));
    },
    refetchQueries: ['GetOrganizations'],
  });

  const onSubmit: SubmitHandler<CreateOrganizationMutationVariables> = () => {
    const { name, organizationId } = getValues(); // Access react-hook-form values
    createOrganizationMutation({
      variables: {
        name: name,
        organizationId,
      },
    });
  };

  return (
    <EuiErrorBoundary>
      <TitleBarPanel
        isLoading={isSubmitting}
        title={'Create Organization'}
        sectionId={'create-org-section'}
        iconType="users"
      >
        <EuiForm
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          css={{ minWidth: '300px' }}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: { value: true, message: ERROR_MESSAGES.organizationNameRequired },
              minLength: {
                value: CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH,
                message: ERROR_MESSAGES.organizationNameMinLength,
              },
            }}
            render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
              <EuiFormRow
                label="Organization Name"
                isInvalid={invalid}
                error={error?.message}
                aria-required
              >
                <EuiFieldText
                  placeholder="Enter an organization name"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </EuiFormRow>
            )}
          />
          <Controller
            name="organizationId"
            control={control}
            rules={{
              required: { value: true, message: ERROR_MESSAGES.organizationIdRequired },
              minLength: {
                value: CONSTANTS.ACCESS_MANAGEMENT_FORM_INPUT_MIN_LENGTH,
                message: ERROR_MESSAGES.organizationIdMinLength,
              },
            }}
            render={({ fieldState: { invalid, error }, field: { onChange, onBlur, value } }) => (
              <EuiFormRow
                label="Organization ID"
                isInvalid={invalid}
                error={error?.message}
                aria-required
              >
                <EuiFieldText
                  placeholder="Enter an organization id"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </EuiFormRow>
            )}
          />
          <EuiFormRow hasEmptyLabelSpace>
            <EuiButton
              id="create-new-organization"
              type="submit"
              fill
              isLoading={isSubmitting}
            >
              Create organization
            </EuiButton>
          </EuiFormRow>
          <EuiSpacer size="m" />
        </EuiForm>
      </TitleBarPanel>
    </EuiErrorBoundary>
  );
};
