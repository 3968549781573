import { EuiErrorBoundary, EuiFormRow, EuiRadioGroup, EuiDatePicker } from '@elastic/eui';
import moment from 'moment-timezone';
import { hourTimeIntToDate, dateTo24HourTimeInt } from 'utils/dates';

interface TimeSelectorProps {
  /** When the timeValue matches the disabledValue the selector sets the enable/disable toggle to disabled */
  disabledValue: number;
  /** True if the enable/disable toggle should be available */
  includeToggle: boolean;
  /** Title to display for the toggle to give users context */
  toggleTitle?: string;
  /** Title explaining what the time selector is used for */
  timeFieldTitle: string;
  /** Setter invoked when the time value changes */
  setTimeValue: (timeValue: number) => void;
  /** The current time in UTC */
  timeValue: number;
  /** Notified when the focus on the object blurs */
  onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  /** True if the field should be disabled */
  isDisabled?: boolean;
}

const DEFAULT_TIME = 800; // 8:00AM

/** Time selector for selecting a time during the day in hours/minutes */
export const SntTimeSelector = ({
  disabledValue,
  includeToggle,
  toggleTitle,
  timeFieldTitle,
  setTimeValue,
  timeValue, // THIS IS UTC
  onBlur,
}: TimeSelectorProps) => {
  // If there is no toggle, field is always enabled.
  // If there is a toggle, field is enabled when the timeValue is not the disabledValue
  // @ts-expect-error empty controlled number fields are empty string, not undefined or null
  const isDisabledValue = disabledValue == timeValue || timeValue === '';
  const isFieldEnabled = !isDisabledValue;
  const localTimezoneAbbr = moment.tz(moment.tz.guess()).zoneAbbr();
  // If the timeValue is the disabled, display the default time value as a placeholder
  const selectedDate = isFieldEnabled
    ? hourTimeIntToDate(timeValue)
    : hourTimeIntToDate(DEFAULT_TIME);

  const handleTimeFieldOnChange = (date: moment.Moment) => {
    setTimeValue(dateTo24HourTimeInt(date));
  };

  const RADIO_BUTTONS = [
    { id: `${toggleTitle}-disabled`, label: 'Disable' },
    { id: `${toggleTitle}-enabled`, label: 'Enable' },
  ];

  const handleRadioToggle = (id: string) => {
    if (id.includes('enabled')) {
      setTimeValue(DEFAULT_TIME);
    }
    if (id.includes('disabled')) {
      setTimeValue(disabledValue);
    }
  };

  const conjureSelectedId = () => {
    if (disabledValue == timeValue) {
      return RADIO_BUTTONS[0].id;
    }
    // @ts-expect-error unset numbers can in fact be strings
    if (timeValue === '') {
      return '';
    }
    return RADIO_BUTTONS[1].id;
  };

  const selectedId = conjureSelectedId();
  const toggleSwitch = includeToggle ? (
    <EuiFormRow label={toggleTitle}>
      <EuiRadioGroup
        options={RADIO_BUTTONS}
        idSelected={selectedId}
        onChange={handleRadioToggle}
        name="enabled-disable-toggle"
      />
    </EuiFormRow>
  ) : null;

  return (
    <EuiErrorBoundary>
      {toggleSwitch}
      <EuiFormRow label={`${timeFieldTitle} (${localTimezoneAbbr})`}>
        <EuiDatePicker
          onBlur={onBlur}
          key={timeFieldTitle}
          disabled={isDisabledValue}
          showTimeSelect
          showTimeSelectOnly
          selected={selectedDate}
          onChange={handleTimeFieldOnChange}
          dateFormat="HH:mm"
          timeFormat="HH:mm"
        />
      </EuiFormRow>
    </EuiErrorBoundary>
  );
};
