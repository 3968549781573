import {
  EuiAvatar,
  EuiButtonEmpty,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiToolTip,
} from '@elastic/eui';
import type { ChildComponentProps as MapMarkerProps } from 'google-map-react';
import { useState } from 'react';
import './styles/trackerMarker.css';

type TrackerMarkerProps = MapMarkerProps & {
  title: string;
  details?: JSX.Element;
};

export const TrackerMarker = (props: TrackerMarkerProps) => {
  const [markerDataIsVisible, setMarkerDataIsVisible] = useState<boolean>(false);

  const toggleMarkerCard = () => {
    return setMarkerDataIsVisible(!markerDataIsVisible);
  };

  return (
    <>
      <EuiCard
        title={props.title}
        titleSize="xs"
        style={{
          display: markerDataIsVisible ? 'block' : 'none',
        }}
        className="trackerMarkerCard"
        textAlign="left"
        footer={
          <EuiFlexGroup
            justifyContent="flexEnd"
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                id="close-marker-card"
                size="s"
                iconType="cross"
                iconSide="right"
                onClick={toggleMarkerCard}
              >
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
      >
        {props.details ? props.details : ''}
      </EuiCard>
      <EuiToolTip
        position="top"
        content="Click for additional information"
        delay="long"
      >
        <EuiAvatar
          name={props.title}
          iconType="visMapCoordinate"
          iconSize="l"
          iconColor="#FFFFFF"
          className="trackerMarker"
          color="#FF7E62"
          onClick={toggleMarkerCard}
        />
      </EuiToolTip>
    </>
  );
};
