import { useCallback, useState } from 'react';
import { extractGraphQLError, handleApolloError } from 'utils/errorHandlers';
import { CONSTANTS } from 'utils/constants';
import { SelectGroupView } from 'layout/groups/select/groups/groups-select-view';
import { useGetGroupsQuery } from 'gqlHooks';
import type { EuiComboBoxOptionOption } from '@elastic/eui';
import type { ApolloError } from '@apollo/client';

export type SelectGroupProps = {
  onChange: (event: any) => void;
  selectedGroups: EuiComboBoxOptionOption[];
  organizationId: string;
  isInvalid?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
  isSingleSelect: boolean;
  helpText?: string;
};

export const SelectGroup = (props: SelectGroupProps) => {
  const [errorState, setErrorState] = useState<string | null>(null);
  const [options, setOptions] = useState<EuiComboBoxOptionOption[]>([]);

  const handleError = useCallback((error: ApolloError) => {
    const gqlError = extractGraphQLError(error);
    if (gqlError.statusCode === 404) {
      setErrorState(gqlError.errorMessage);
    } else {
      handleApolloError(error, true, gqlError => setErrorState(gqlError.errorMessage));
    }
  }, []);

  const { loading: organizationsLoading } = useGetGroupsQuery({
    variables: { organizationId: props.organizationId, limit: 1000 },
    pollInterval: CONSTANTS.SIXTY_SECONDS_IN_MILLISECONDS,
    onError: handleError,
    onCompleted: data => {
      // Check if there are organizations
      if (data?.groups?.items?.length > 0) {
        const groupOptions: EuiComboBoxOptionOption[] = data.groups.items.map(group => {
          return {
            label: group.label ?? '',
            key: group.label ?? '',
            value: group.identifier ?? '',
          };
        });
        setOptions(groupOptions);
      }
    },
  });

  return (
    <SelectGroupView
      helpText={props.helpText}
      onChange={props.onChange}
      disabled={props.disabled}
      selectedGroups={props.selectedGroups}
      options={options}
      label={props.label}
      isInvalid={errorState != null}
      errorMessage={errorState}
      isLoading={organizationsLoading}
      isSingleSelect={props.isSingleSelect}
    />
  );
};
