import { ApolloProvider } from '@apollo/client';
import { useAuthenticationDetails } from 'hooks/useAuthenticationDetails';
import { useConfig } from 'context/config';
import { getApolloClient } from 'app/apollo/apolloClient';
import type { ReactNode } from 'react';

type ApolloWrapperProps = {
  children: ReactNode;
};

export const ApolloWrapper = (props: ApolloWrapperProps) => {
  const { VITE_GRAPHQL_ENDPOINT } = useConfig();
  const { accessToken } = useAuthenticationDetails();
  const apolloClient = getApolloClient(accessToken, VITE_GRAPHQL_ENDPOINT);

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};
