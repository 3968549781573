import { useState } from 'react';
import { EuiErrorBoundary } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import {
  CommandConfigurationRequestRow,
  DeviceFotaRequestInvalidateInput,
  DeviceRequestInvalidateInput,
  FotaRequestRow,
  GenericRow,
  GetGroupConfigRequestsByGroupTokenQuery,
  GroupOperationType,
  RestartGroupOperationInput,
  StrawberryGroupOperationResponse,
  useCancelGroupRequestMutation,
  useDeleteDeviceCommandConfigRequestMutation,
  useDeleteDeviceFotaRequestMutation,
  useGetGroupCmdCfgProgressLazyQuery,
  useGetGroupConfigRequestsByGroupTokenQuery,
  useGetGroupFotaProgressLazyQuery,
  useGetGroupFotaRequestsByGroupTokenQuery,
  useRestartGroupOperationDevicesMutation,
} from 'app/generated/graphql';
import { errorToastMessage, successToastMessage } from 'app/utils/toast-messages';

import GroupRequestsView from './group-progress-view';
import { FotaRequestSummary } from './progress-details/fota/progress-details';
import { CmdCfgRequestSummary } from './progress-details/cmd-cfg/progress-details';

interface GroupsProgressProps {
  addToastMessage: (toasty: Toast) => void;
  /** ID of the organization the user is a part of */
  organizationId: string;
  /** Available groups */
  groups: GenericRow[];
}

/** Displays requests that have been posted to the different groups */
export const GroupsProgress = (props: GroupsProgressProps) => {
  const [selectedGroup, setSelectedGroup] = useState<GenericRow | undefined>(undefined);
  const [groupRequestId, setGroupRequestId] = useState<any>();
  const [configFlyoutOpen, setConfigFlyoutOpen] = useState(false);
  const [fotaFlyoutOpen, setFotaFlyoutOpen] = useState(false);
  const [fotaRequests, setFotaRequests] = useState<StrawberryGroupOperationResponse[]>([]);
  const [cmdCnfgRequests, setCmdCnfgRequests] = useState<StrawberryGroupOperationResponse[]>([]);

  // Loads group configuration and command requests
  const { loading: isConfigLoading } = useGetGroupConfigRequestsByGroupTokenQuery({
    variables: {
      groupToken: selectedGroup?.identifier ?? '',
      organizationId: props.organizationId,
      limit: 1000,
    },
    onCompleted: (data: GetGroupConfigRequestsByGroupTokenQuery) => {
      const operationRows = data.groupOperationByGroupToken.requests;
      //@ts-expect-error StrawberryGroupOperationResponse is not type StrawberryGroupOperationResponse according to this line
      setCmdCnfgRequests(operationRows);
    },
    onError: () => {
      setCmdCnfgRequests([]);
      props.addToastMessage(errorToastMessage('Failed to load config requests'));
    },
  });

  // Loads group fota requests
  const { loading: isFotaLoading } = useGetGroupFotaRequestsByGroupTokenQuery({
    variables: {
      groupToken: selectedGroup?.identifier ?? '',
      organizationId: props.organizationId,
    },
    onCompleted: data => {
      const operationRowToFota = data.groupOperationByGroupToken.requests;
      //@ts-expect-error type StrawberryGroupOperationResponse is not StrawberryGroupOperationResponse???
      setFotaRequests(operationRowToFota);
    },
    onError: () => {
      setFotaRequests([]);
      props.addToastMessage(errorToastMessage('Failed to load FOTA requests'));
    },
  });

  //Retreives FOTA progress details for a group request
  const [
    getFotaProgress,
    { refetch: refetchFota, loading: isLoadingGroupFotaProgress, data: fotaProgress },
  ] = useGetGroupFotaProgressLazyQuery({
    onCompleted: () => setFotaFlyoutOpen(true),
    onError: () => props.addToastMessage(errorToastMessage('Failed to load request details')),
  });

  //Retreives Command Config progress details for a group request
  const [
    getCmdCfgProgress,
    { refetch: refetchCmdCfg, loading: isLoadingGroupCmdCfgProgress, data: cmdCfgProgress },
  ] = useGetGroupCmdCfgProgressLazyQuery({
    onCompleted: () => setConfigFlyoutOpen(true),
    onError: () => props.addToastMessage(errorToastMessage('Failed to load request details')),
  });

  // Cancels a request for a group
  const [cancelGroupRequestMutation, { loading: isCancelling }] = useCancelGroupRequestMutation({
    onCompleted: () => props.addToastMessage(successToastMessage('Cancelled request')),
    onError: () => props.addToastMessage(errorToastMessage('Failed to cancel request')),
  });

  const handleGroupRequestCancel = (requestId: string, operationType: GroupOperationType) => {
    cancelGroupRequestMutation({
      variables: {
        organizationId: props.organizationId,
        operationType: operationType,
        groupRequestId: requestId,
      },
    });
  };

  const handleOnView = (requestId: string, operationType: GroupOperationType) => {
    setGroupRequestId(requestId);
    if (operationType === GroupOperationType.Fota) {
      getFotaProgress({
        variables: {
          groupRequestId: requestId,
        },
      });
    }
    if (operationType === GroupOperationType.CmdConfig) {
      getCmdCfgProgress({
        variables: {
          groupRequestId: requestId,
        },
      });
    }
  };

  // Mutator to request the cancel of a fota request
  const [deleteDeviceFotaRequestMutation, { loading: isDeleteLoading }] =
    useDeleteDeviceFotaRequestMutation();

  const handleCancelFotaRequest = (requestId: number, deviceId: string) => {
    const variables: DeviceFotaRequestInvalidateInput = {
      deviceId: deviceId,
      requestId: requestId,
    };
    deleteDeviceFotaRequestMutation({
      variables: variables,
      onCompleted: () => {
        props.addToastMessage(successToastMessage('Request cancelled'));
        refetchFota();
      },
      onError: () => {
        props.addToastMessage(errorToastMessage('Error occurred while cancelling'));
        refetchFota();
      },
    });
  };

  // Mutator to request the cancel of a fota request
  const [retryDeviceFotaRequestMutation, { loading: isRetryingFotaFailures }] =
    useRestartGroupOperationDevicesMutation();

  const handleRetryFotaRequest = (deviceIds: string[]) => {
    const variables: RestartGroupOperationInput = {
      groupRequestId: groupRequestId,
      operationType: GroupOperationType.Fota,
      deviceIds: deviceIds,
    };
    retryDeviceFotaRequestMutation({
      variables: variables,
      onCompleted: () => {
        props.addToastMessage(successToastMessage('Request resubmitted'));
        refetchFota();
      },
      onError: () => {
        props.addToastMessage(errorToastMessage('Error occurred while cancelling'));
        refetchFota();
      },
    });
  };

  // Mutator to request the cancel of a fota request
  const [retryDeviceCmdCfgRequestMutation, { loading: isRetryingCmdCfgFailures }] =
    useRestartGroupOperationDevicesMutation();

  const handleRetryCmdCfgRequest = (deviceIds: string[]) => {
    const variables: RestartGroupOperationInput = {
      groupRequestId: groupRequestId,
      operationType: GroupOperationType.CmdConfig,
      deviceIds: deviceIds,
    };
    retryDeviceCmdCfgRequestMutation({
      variables: variables,
      onCompleted: () => {
        props.addToastMessage(successToastMessage('Request resubmitted'));
        refetchCmdCfg();
      },
      onError: () => {
        props.addToastMessage(errorToastMessage('Error occurred while cancelling'));
        refetchCmdCfg();
      },
    });
  };

  // Mutator to request the cancel of a command config request
  const [deleteDeviceCmdCfgRequestMutation, { loading: isDeletingCmdCfRequest }] =
    useDeleteDeviceCommandConfigRequestMutation();

  const handleCancelCmdCfgRequest = (requestId: number, deviceId: string) => {
    const variables: DeviceRequestInvalidateInput = {
      deviceId: deviceId,
      requestId: requestId,
    };
    deleteDeviceCmdCfgRequestMutation({
      variables: variables,
      onCompleted: () => {
        props.addToastMessage(successToastMessage('Request cancelled'));
        refetchCmdCfg();
      },
      onError: () => {
        props.addToastMessage(errorToastMessage('Error occurred while cancelling'));
        refetchCmdCfg();
      },
    });
  };

  const isLoading =
    isFotaLoading ||
    isConfigLoading ||
    isCancelling ||
    isLoadingGroupFotaProgress ||
    isLoadingGroupCmdCfgProgress ||
    isDeleteLoading ||
    isDeletingCmdCfRequest;

  return (
    <EuiErrorBoundary>
      <FotaRequestSummary
        requests={
          (fotaProgress?.groupFotaRequestByGroupRequestId?.requests as FotaRequestRow[]) || []
        }
        onClose={() => {
          setFotaFlyoutOpen(false);
          setGroupRequestId(null);
        }}
        isOpen={fotaFlyoutOpen}
        onRequestCancel={handleCancelFotaRequest}
        isLoading={isRetryingFotaFailures}
        onRetryRequests={handleRetryFotaRequest}
      />
      <CmdCfgRequestSummary
        requests={
          (cmdCfgProgress?.groupCmdCfgRequestByGroupRequestId
            ?.requests as CommandConfigurationRequestRow[]) || []
        }
        onClose={() => setConfigFlyoutOpen(false)}
        isOpen={configFlyoutOpen}
        onRequestCancel={handleCancelCmdCfgRequest}
        isLoading={isRetryingCmdCfgFailures}
        onRetryRequests={handleRetryCmdCfgRequest}
      />
      <GroupRequestsView
        configRequests={cmdCnfgRequests}
        groups={props.groups}
        onCancel={handleGroupRequestCancel}
        isLoading={isLoading}
        fotaRequests={fotaRequests}
        setSelectedGroup={setSelectedGroup}
        selectedGroup={selectedGroup}
        onViewDetails={handleOnView}
      />
    </EuiErrorBoundary>
  );
};
