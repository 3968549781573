import { EuiComment, EuiText } from '@elastic/eui';
import { getStatusAvatar, getTagTypeIcon } from 'layout/install/utils';
import type { ITag } from 'models/install-verification-tags/types';

/** Displays the status of a single install check field */
export const InstallTag = ({ description, statusToken, tagText, tagType, title }: ITag) => {
  const statusAvatar = getStatusAvatar(statusToken);
  const tagTypeIcon = getTagTypeIcon(tagType);

  return (
    <EuiComment
      event={tagText}
      eventColor={statusToken}
      eventIcon={tagTypeIcon}
      timelineAvatar={statusAvatar}
      username={title}
    >
      <EuiText size="s">{description}</EuiText>
    </EuiComment>
  );
};
