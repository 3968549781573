import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'virtual:vite-plugin-sentry/sentry-config';
import type { BrowserTracingOptions } from '@sentry-internal/tracing/types/browser/browsertracing';
import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

const { VITE_SENTRY_DEBUG = false } = import.meta.env;

const browserTracingConfig: Partial<BrowserTracingOptions> = {
  routingInstrumentation: Sentry.reactRouterV6Instrumentation(
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
  ),
};

/**
 * https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/
 */
const initializionConfigs: Sentry.BrowserOptions = {
  debug: VITE_SENTRY_DEBUG, // set to true in .env.local for local debugging
  dsn: 'https://b42097b35ec04360aa18c00b33dde653@o311449.ingest.sentry.io/6751890',
  integrations: [new BrowserTracing(browserTracingConfig)],
  tracesSampleRate: 0.2,
  environment: APP_ENVIRONMENT,
  dist: APP_VERSION,
  release: `${APP_NAME}@${APP_VERSION}`,
};

export function sentryInit() {
  return Sentry.init(initializionConfigs);
}
