import { SetStateAction } from 'react';
import { Module } from 'gqlHooks';
import { EuiFormRow, EuiSelect } from '@elastic/eui';

interface SntCredModuleSelectorProps {
  /** Value of the currently selected module */
  selectedModule: Module;
  /** Setter for informing about selected module changes */
  setSelectedModule: (command: SetStateAction<Module>) => void;
}

// Available modules for a SnT device, with help text descriptions
const SNT_CRED_MODULES = [
  {
    value: Module.Fota,
    text: 'FOTA Certificate',
    description: 'Update MQTT Broker and Port',
  },
  {
    value: Module.Claim,
    text: 'AWS Credential Claim',
    description: 'AWS Credential Claim Credential set',
  },
];

/**
 * @return {React.Element} Selector for Snt specific modules.
 */
export function SntCredModuleSelector({
  setSelectedModule,
  selectedModule,
}: SntCredModuleSelectorProps) {
  const handleSelectCommandChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModule(event.target.value as Module);
  };

  return (
    <EuiFormRow label="Select Module">
      <EuiSelect
        id="commandSelect"
        onChange={handleSelectCommandChange}
        options={SNT_CRED_MODULES}
        value={selectedModule}
      />
    </EuiFormRow>
  );
}

export default SntCredModuleSelector;
