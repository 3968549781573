import { EuiTableFieldDataColumnType } from '@elastic/eui';
import { getLocalTime } from 'utils/dates';
import { StrawberryGroupOperationResponse } from 'gqlHooks';
import { renderGroupsStatus } from 'layout/config/snt/snt-config-utils';

// Date is being sent without the TZ on it. Need to manually convert from utc to our local tz
const renderDate = (date: string) => {
  return date ? getLocalTime(date) : '';
};

const groupsRequestStatus: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse> = {
  field: 'requestState',
  name: 'Status',
  dataType: 'string',
  truncateText: false,
  render: renderGroupsStatus,
};

const groupsCreatedAtTime: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse> = {
  field: 'createdAt',
  name: 'Created',
  dataType: 'string',
  truncateText: false,
  render: renderDate,
};

const groupsLastUpdateTime: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse> = {
  field: 'updatedAt',
  name: 'Most Recent Update',
  dataType: 'string',
  truncateText: false,
  render: renderDate,
};

export const GROUPS_CMD_CFG_SUMMARY_COLUMNS: EuiTableFieldDataColumnType<StrawberryGroupOperationResponse>[] =
  [groupsRequestStatus, groupsCreatedAtTime, groupsLastUpdateTime];
