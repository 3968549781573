import {
  EuiButton,
  EuiErrorBoundary,
  EuiFlyout,
  EuiFlyoutFooter,
  EuiSpacer,
  EuiSelectableOption,
} from '@elastic/eui';
import { useState } from 'react';
import { SelectDevices } from 'app/components/forms/select-devices';

export type AddDeviceToGroupProps = {
  /** True if the add device dialogue should be open */
  isAddDeviceOpen: boolean;
  /** Id of the organization the user belongs to, used to load devices */
  organizationId: string;
  /** Id token of the group the device is being added to */
  groupToken: string;
  /** Invoked when the user submits an add device to group request */
  onSubmit: (deviceIds: string[], groupId: string) => void;
  /** True if there is data loading or a device being added */
  isLoading?: boolean;
  /** Invoked when the flyout requests a close or exit */
  closeFlyout: () => void;
  /** All devices that are tied to a group and to be displayed in the table*/
  assignedDevices: any[];
};

export const AddDeviceToGroup = (props: AddDeviceToGroupProps) => {
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);

  const handleOnSubmit = (event: any) => {
    // Prevent default so the form doesnt refresh the page
    event.preventDefault();
    const deviceIds = selectedDevices.map(device => device.toString() ?? '');
    props.onSubmit(deviceIds, props.groupToken);
  };

  if (!props.isAddDeviceOpen) {
    return null;
  }

  const handleOnChange = (selectedOptions: EuiSelectableOption[]) => {
    // Extract labels of newly selected options
    const newlySelectedDeviceLabels = selectedOptions
      .filter(option => option.checked === 'on')
      .map(option => option.key)
      .filter(label => label !== undefined) as string[]; // Filter out undefined values

    // Update the state with the newly selected device labels
    setSelectedDevices(newlySelectedDeviceLabels);
  };

  return (
    <EuiErrorBoundary>
      <EuiFlyout
        ownFocus
        onClose={props.closeFlyout}
      >
        <SelectDevices
          assignedDevices={props.assignedDevices}
          label="Add device to group"
          inFlyout={true}
          onChange={handleOnChange}
          selectedDevice={selectedDevices}
          organizationId={props.organizationId}
          isRequired
          isSingleSelect={false}
        />
        <EuiSpacer size="xs" />
        <EuiFlyoutFooter>
          <EuiButton
            id="add-device-to-group-submit"
            onClick={handleOnSubmit}
            type="submit"
            color="primary"
            fill
            isLoading={props.isLoading}
            isDisabled={props.isLoading}
          >
            Add to group
          </EuiButton>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiErrorBoundary>
  );
};
