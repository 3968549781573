import { EuiTableFieldDataColumnType } from '@elastic/eui';
import {
  ROW_INDEX,
  DEVICE_ID,
  DeviceUploadRow,
  DEVICE_NAME,
} from 'app/layout/access-management/add-device-to-organization-form/upload/constants';

const INDEX_COLUMN: EuiTableFieldDataColumnType<DeviceUploadRow> = {
  field: ROW_INDEX,
  name: 'Row Index',
  width: '100px',
  sortable: true,
  mobileOptions: {
    show: false,
  },
};

const DEVICE_ID_COLUMN: EuiTableFieldDataColumnType<DeviceUploadRow> = {
  field: DEVICE_ID,
  name: 'Device IMEI',
  sortable: true,
  truncateText: false,
};

const NAME_COLUMN: EuiTableFieldDataColumnType<DeviceUploadRow> = {
  field: DEVICE_NAME,
  name: 'Device Name (Optional)',
  sortable: true,
  truncateText: false,
};

export const UPLOAD_COLUMNS = [INDEX_COLUMN, DEVICE_ID_COLUMN, NAME_COLUMN];
export const GROUP_UPLOAD_COLUMNS = [INDEX_COLUMN, DEVICE_ID_COLUMN];
