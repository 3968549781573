import { EuiAvatar, EuiDescriptionList, EuiComment, EuiErrorBoundary } from '@elastic/eui';
import { CONSTANTS } from 'app/utils/constants';
import { kebabCase } from 'lodash';

// Config keys are in camel case - splitting to make human readable
const configKeyToString = (configKey: string) => kebabCase(configKey);

export type OtaCredSummaryProps = {
  /** Title for the config summary section */
  label: string;
  /** Display icon for the cred section */
  icon: string;
  /** True if this should display the result summary for the cred request */
  isResponse: boolean;
  /** Response message to the ota cred */
  response?: undefined | null;
};

/** Creates a summary section for displaying what config values were changed */
export const SntOtaCredSummary = ({ label, icon, isResponse, response }: OtaCredSummaryProps) => {
  const listItems = [];

  if (isResponse && response) {
    // Iterate over all the key/values in the config object
    for (const [key, value] of Object.entries(response)) {
      if (key.slice(0, 2).includes('__')) {
        continue;
      }
      if (value) {
        let parsedValue = value;

        // Check if the value is a JSON string
        if (typeof value === 'string') {
          try {
            parsedValue = JSON.parse(value);
          } catch (error) {
            // Handle JSON parsing error if necessary
            console.error('Error parsing JSON:', error);
          }
        }

        listItems.push({
          title: configKeyToString(key),
          description: parsedValue?.toString() ?? CONSTANTS.TEXT_PLACEHOLDER,
        });
      }
    }
  }

  // If there were non-null config values in the section create the section for display
  const avatar = (
    <EuiAvatar
      name={label}
      iconType={icon}
      iconColor="success"
      color="plain"
      size="l"
    />
  );
  return (
    <EuiErrorBoundary id="cred-summary">
      <EuiComment
        event={isResponse ? 'request response' : `request sent`}
        username={label}
        timelineAvatar={avatar}
      >
        {listItems.length > 0 ? <EuiDescriptionList listItems={listItems} /> : null}
      </EuiComment>
    </EuiErrorBoundary>
  );
};
