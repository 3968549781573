import { Power } from 'app/generated/graphql';
import { MlEuiCommentType, buildEuiComment } from 'utils/configTranslator';

export const OFF_ENABLE_DEFAULT = false;
export const RESET_ENABLE_DEFAULT = true;

export const MODE_RESET = 'reset';
export const MODE_POWER = 'power_off';
export const MODE_DISABLED = 'disable';

export const POWER_MODE_OPTIONS = [
  {
    id: MODE_RESET,
    label: 'Reset Device',
  },
  {
    id: MODE_POWER,
    label: 'Power On/Off Device',
  },
  {
    id: MODE_DISABLED,
    label: 'Disabled',
  },
];

export const POWER_CONFIG_DEFAULTS: Power = {
  offEnable: OFF_ENABLE_DEFAULT,
  resetEnable: RESET_ENABLE_DEFAULT,
};

export const POWER_GROUP_CONFIG_DEFAULTS: Power = {
  //@ts-expect-error form fields accept empty string for unset number fields
  offEnable: '',
  //@ts-expect-error form fields accept empty string for unset number fields
  resetEnable: '',
};

// Status is a combination of the two booleans. This translates it to the human friendly version
export const getDerivedPowerStatus = (
  offEnabled: boolean | null | undefined,
  resetEnabled: boolean | null | undefined
) => {
  //@ts-expect-error form fields accept empty string for unset number fields
  if (offEnabled === '' || resetEnabled === '') {
    return '';
  }
  if (offEnabled == true && resetEnabled == true) {
    return MODE_POWER;
  }
  if (resetEnabled == true && !offEnabled) {
    return MODE_RESET;
  }
  if (!offEnabled && !resetEnabled) {
    return MODE_DISABLED;
  }
  return '';
};

// Builds items for an EUI comment list, in translated human readable format
export const powerConfigTranslator = (powerConfig: Power | undefined | null) => {
  if (!powerConfig) {
    return undefined;
  }
  const builtItems: Array<MlEuiCommentType> = [];

  const derivedStatus = getDerivedPowerStatus(powerConfig.offEnable, powerConfig.resetEnable);
  const status = POWER_MODE_OPTIONS.find(item => item.id === derivedStatus);

  builtItems.push(buildEuiComment('Button Option', status!.label));

  return builtItems;
};
