import {
  EuiErrorBoundary,
  EuiHorizontalRule,
  EuiPageSection,
  EuiPanel,
  EuiShowFor,
} from '@elastic/eui';

interface PageSection {
  children: React.ReactNode;
  hasHorizontalRule?: boolean;
}

export default function PageSection({ children, hasHorizontalRule = false }: PageSection) {
  // Setting background image to None
  const bar = () => ({ backgroundImage: `none` });
  return (
    <EuiErrorBoundary id="page-section-error-boundary">
      <EuiPageSection
        paddingSize="s"
        alignment="center"
        css={bar}
      >
        <EuiShowFor sizes={['xs']}>
          {hasHorizontalRule ? <EuiHorizontalRule /> : null}
          {children}
        </EuiShowFor>
        <EuiShowFor sizes={['s', 'm', 'l', 'xl']}>
          <EuiPanel>{children}</EuiPanel>
        </EuiShowFor>
      </EuiPageSection>
    </EuiErrorBoundary>
  );
}
