import { useState, useMemo } from 'react';
import { EuiFlexItem, EuiFlexGrid, EuiTabs, EuiTab } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { SntConfig } from 'layout/config/snt/snt-config';
import { SntSendSms } from 'layout/sms/snt/send/snt_send_sms';
import { SntSendDeviceCommand } from 'app/layout/device-command/send-device-command';
import { OtaCredUpdate } from 'app/layout/ota-cred-update/ota-cred-update';

import { SntConfigRequests } from './requests/snt-config-requests';

const SMS_SEND_MESSAGE = 'send_sms_message';
const SEND_DEVICE_COMMAND = 'send_device_command';
const SET_CONFIG = 'config';
const CONFIG_REQUESTS = 'config_requets';
const SEND_OTA_CRED_REQUEST = 'cred';

interface SntExpandSectionProps {
  addToastMessage: (toasty: Toast) => void;
  /** Serial of the tracker whose config is being viewed/modified */
  deviceSerial: string;
}
export const SntConfigTabs = ({ addToastMessage, deviceSerial }: SntExpandSectionProps) => {
  const [selectedTabId, setSelectedTabId] = useState<keyof typeof tabContent>(CONFIG_REQUESTS);

  const onSelectedTabChanged = (id: keyof typeof tabContent) => {
    setSelectedTabId(id);
  };

  const tabContent = {
    [CONFIG_REQUESTS]: <SntConfigRequests deviceSerial={deviceSerial} />,
    [SMS_SEND_MESSAGE]: (
      <SntSendSms
        addToastMessage={addToastMessage}
        deviceSerial={deviceSerial}
      />
    ),
    [SEND_DEVICE_COMMAND]: (
      <SntSendDeviceCommand
        addToastMessage={addToastMessage}
        deviceSerial={deviceSerial}
      />
    ),
    [SEND_OTA_CRED_REQUEST]: (
      <OtaCredUpdate
        addToastMessage={addToastMessage}
        deviceSerial={deviceSerial}
      />
    ),
    [SET_CONFIG]: (
      <SntConfig
        deviceSerial={deviceSerial}
        addToastMessage={addToastMessage}
      />
    ),
  };

  const selectedTabContent = useMemo(() => {
    return tabContent[selectedTabId];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  return (
    <EuiFlexGrid
      columns={1}
      style={{ width: '100%' }}
    >
      <EuiFlexItem>
        <EuiTabs>
          <EuiTab
            key={CONFIG_REQUESTS}
            onClick={() => onSelectedTabChanged(CONFIG_REQUESTS)}
            isSelected={CONFIG_REQUESTS === selectedTabId}
          >
            View Requests
          </EuiTab>
          <EuiTab
            key={SMS_SEND_MESSAGE}
            onClick={() => onSelectedTabChanged(SMS_SEND_MESSAGE)}
            isSelected={SMS_SEND_MESSAGE === selectedTabId}
          >
            Send SMS
          </EuiTab>
          <EuiTab
            key={SEND_DEVICE_COMMAND}
            onClick={() => onSelectedTabChanged(SEND_DEVICE_COMMAND)}
            isSelected={SEND_DEVICE_COMMAND === selectedTabId}
          >
            Send Command
          </EuiTab>
          <EuiTab
            key={SEND_OTA_CRED_REQUEST}
            onClick={() => onSelectedTabChanged(SEND_OTA_CRED_REQUEST)}
            isSelected={SEND_OTA_CRED_REQUEST === selectedTabId}
          >
            OTA Cred Update
          </EuiTab>
          <EuiTab
            key={SET_CONFIG}
            onClick={() => onSelectedTabChanged(SET_CONFIG)}
            isSelected={SET_CONFIG === selectedTabId}
          >
            Configure
          </EuiTab>
        </EuiTabs>
      </EuiFlexItem>
      <EuiFlexItem>{selectedTabContent}</EuiFlexItem>
    </EuiFlexGrid>
  );
};
