import type { EuiPinnableListGroupItemProps } from '@elastic/eui';
import type { CurrentPath } from 'hooks/useCurrentPath';

export function isItemActive(currentPath: CurrentPath, pathName: string, useBase = false): boolean {
  return pathName === (!useBase ? currentPath?.pathname : currentPath?.pathnameBase);
}

export function alterLinksWithCurrentState(
  links: EuiPinnableListGroupItemProps[],
  showPinned = false
): EuiPinnableListGroupItemProps[] {
  return links.map(link => {
    const { pinned, ...rest } = link;
    return {
      pinned: showPinned ? pinned : false,
      ...rest,
    };
  });
}

export function addLinkNameToPinTitle(listItem: EuiPinnableListGroupItemProps) {
  return `Pin ${listItem.label} to top`;
}

export function addLinkNameToUnpinTitle(listItem: EuiPinnableListGroupItemProps) {
  return `Unpin ${listItem.label}`;
}
