import PageTemplate from 'layout/page/page';
import { EuiCallOut, EuiErrorBoundary, EuiLink, EuiSpacer, EuiText } from '@elastic/eui';
import { useUserInfo } from 'context/userInfo';
import { ErrorMessage } from 'layout/utility/errorMessage/errorMessage';
import type { UserInfoContextData } from 'context/userInfo';

export type DeveloperFormProps = {
  isLoading: boolean;
  isRestricted: boolean;
};

export const Developer = () => {
  const {
    queryData: { error: userInfoError },
  }: UserInfoContextData = useUserInfo();

  /**
   * A list of user permissions is available in the Auhto0 dashboard
   * via Applications > APIs `device-manager-{env}` > Permissions tab
   */

  return (
    <PageTemplate
      documentTitle="Metrics"
      title="Metrics"
    >
      {userInfoError ? (
        <ErrorMessage
          isHidden={false}
          message={userInfoError}
        />
      ) : (
        <EuiErrorBoundary>
          <EuiCallOut
            title="Proceed with caution"
            color="warning"
            iconType="warning"
          >
            <p>The links below only work for equipmentshare employees.</p>
          </EuiCallOut>
          <EuiSpacer />
          <EuiText>
            <p>
              <EuiLink
                href="https://app.datadoghq.com/apm/services/machinelink-api/operations/"
                target="_blank"
              >
                Datadog APM
              </EuiLink>{' '}
            </p>
            <p>
              <EuiLink
                href="https://app.datadoghq.com/dashboard/4bm-miv-4u5?env=stage&refresh_mode=sliding&view=spans&from_ts=1705609103779&to_ts=1705612703779&live=true"
                target="_blank"
              >
                Datadog Dashboard
              </EuiLink>{' '}
            </p>
            <p>
              <EuiLink
                href="https://www.notion.so/equipmentshare/Machinelink-Runbooks-2a4eceffe3124078b02261b1a4828fc6"
                target="_blank"
              >
                Runbooks
              </EuiLink>{' '}
            </p>
          </EuiText>
        </EuiErrorBoundary>
      )}
    </PageTemplate>
  );
};
