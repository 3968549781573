import { useState } from 'react';

/** use state hook that checks for a local cache value to use as the initial useState value */
export function useLocalStorage(key: string, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    // Wrap in try/catch in case something goes wrong reading the cache
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If something goes wrong, return the initial value
      console.log(error);

      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      // Save state
      setStoredValue(valueToStore);

      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

// List of keys for the different state hooks
// Devoce messages auto refresh state and auto refresh interval
export const MESSAGES_REFRESH_INTERVAL = 'device-message-refresh-interval';
export const MESSAGES_REFRESH_ENABLED = 'device-message-refresh-enabled';
export const INSTALL_CHECK_INTERVAL = 'install-check-refresh-interval';
export const INSTALL_CHECK_REFRESH_ENABLED = 'install-check-refresh-enabled';

// Device Sim messages auto refresh state and auto refresh interval
export const SIM_MESSAGES_REFRESH_INTERVAL = 'device-sim-message-refresh-interval';
export const SIM_MESSAGES_REFRESH_ENABLED = 'device-sim-message-refresh-enabled';

// Configuration related constants
export const VIEW_CONFIG_REQUESTS_REFRESH_INTERVAL = 'view-config-requests-refresh-interval';
export const VIEW_CONFIG_REQUESTS_REFRESH_ENABLED = 'view-config-requests-refresh-enabled';
