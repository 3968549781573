import { withAuthenticationRequired } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import { Router } from 'router/Router';
import { ApolloWrapper } from 'app/apollo/ApolloWrapper';
import { UserInfoProvider } from 'context/userInfo';

const App = () => {
  return (
    <ApolloWrapper>
      <UserInfoProvider>
        <RouterProvider router={Router} />
      </UserInfoProvider>
    </ApolloWrapper>
  );
};

export default withAuthenticationRequired(App);
