import { EuiFlexItem, EuiPanel, EuiShowFor, EuiHorizontalRule } from '@elastic/eui';
interface ResponsivePanelProp {
  children: React.ReactNode;
  hasHorizontalRule?: boolean;
  shouldGrow?: boolean;
}

/* Does different sizing layouts based on screen size. When larger, the data is
put into panels. When small, the data is given a horizontal rule and not put in a panel */
const ResponsivePanel = ({
  children,
  hasHorizontalRule = false,
  shouldGrow = true,
}: ResponsivePanelProp) => {
  return (
    <EuiFlexItem grow={shouldGrow}>
      <EuiShowFor sizes={['xs']}>
        {hasHorizontalRule ? <EuiHorizontalRule /> : null}
        {children}
      </EuiShowFor>
      <EuiShowFor sizes={['s', 'm', 'l', 'xl']}>
        <EuiPanel>{children}</EuiPanel>
      </EuiShowFor>
    </EuiFlexItem>
  );
};

export default ResponsivePanel;
