import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { StrictTypedTypePolicies } from 'generated/apollo-helpers';
import { AppEnvironment } from 'services/host';

const getAuthorizationLink = (accessToken: string) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  });

const getApolloUri = (endpointUri: string) =>
  createHttpLink({
    uri: endpointUri,
  });

/**
 * Configure Type Policies here as needed
 *
 * Reference:
 * https://www.the-guild.dev/graphql/codegen/plugins/typescript/typescript-apollo-client-helpers
 * https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicy-fields
 */
const typePolicies: StrictTypedTypePolicies = {};

export const getApolloClient = (accessToken: string, apiUri: string) => {
  const authLink = getAuthorizationLink(accessToken);
  const connectToDevTools = () => {
    if (typeof APP_ENVIRONMENT !== 'undefined') {
      return APP_ENVIRONMENT !== AppEnvironment.PRODUCTION;
    }
    return false;
  };
  return new ApolloClient({
    link: authLink.concat(getApolloUri(apiUri)),
    cache: new InMemoryCache({ typePolicies }),
    connectToDevTools: connectToDevTools(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};
