import { useState } from 'react';
import {
  EuiButton,
  EuiErrorBoundary,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
} from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { useUpdateDeviceConfigMutation, useGetDeviceShadowQuery } from 'gqlHooks';
import type { Configuration, GetDeviceShadowQuery } from 'gqlHooks';
import { errorToastMessage, infoToastMessage, successToastMessage } from 'app/utils/toast-messages';
import {
  DEFAULT_GROUP_SNT_CONFIGURATION,
  keysToCamel,
  getDirtyConfigValues,
} from 'layout/config/snt/snt-config-utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SntGroupSummarySection } from 'app/layout/groups/crud-management/edit/config/snt-group-config-summary';

import { SntConfigView } from './snt-config-view';

interface SntConfigProps {
  /** Serial of the device whose config is being modified */
  deviceSerial: string;
  /** Adds a toast to the global message queue */
  addToastMessage: (message: Toast) => void;
}

/** Configuration options for the SnT devices */
export const SntConfig = ({ deviceSerial, addToastMessage }: SntConfigProps) => {
  // Track if the user is reviewing the configuration or editing
  const [isEditing, setIsEditing] = useState(true);
  const [valuesToSubmit, setValuesToSubmit] = useState<Configuration | undefined>(undefined);

  // When the current config was from
  const [configTimestamp, setConfigTimestamp] = useState<string | undefined>(undefined);

  const [updateDeviceConfigMutation, { loading: isSubmittingConfig }] =
    useUpdateDeviceConfigMutation({
      onCompleted: () => {
        addToastMessage(successToastMessage('Config changes sent'));
        setIsEditing(true);
      },
      onError: () => {
        addToastMessage(errorToastMessage('Failed to send changes'));
      },
    });

  // Loads the current configuration of the device
  const { error, loading: isShadowLoading } = useGetDeviceShadowQuery({
    variables: {
      deviceId: deviceSerial,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: GetDeviceShadowQuery) => {
      const deviceShadow = data?.deviceShadow;

      if (!deviceShadow?.config?.data) {
        return;
      }

      // If there is a config on the device shadow
      // Convert the json data string to a JS object
      const confingJson = JSON.parse(deviceShadow.config.data);

      // Convert the json from snake_case to camelCase
      const camelConfig = keysToCamel(confingJson);

      // Reset the form to have the parsed config as its new defaults
      reset(camelConfig);

      // Pull the configurtion timestamp so users know when the values were applied
      setConfigTimestamp(deviceShadow.config.timestamp);
    },
    onError: () => {
      addToastMessage(errorToastMessage('Failed to load configuration'));
    },
  });

  const {
    control,
    formState: { dirtyFields },
    getValues,
    handleSubmit,
    reset,
  } = useForm<Configuration>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: DEFAULT_GROUP_SNT_CONFIGURATION,
  });

  const onSubmit: SubmitHandler<Configuration> = () => {
    const configValues = getValues();

    const dirty = getDirtyConfigValues(dirtyFields, configValues);
    if (Object.keys(dirty).length === 0) {
      addToastMessage(infoToastMessage(`No configuration changes to submit`));
      return;
    }

    updateDeviceConfigMutation({
      variables: {
        deviceId: deviceSerial,
        config: dirty,
      },
    });
  };

  const onReview = () => {
    const configValues = getValues();

    const dirty = getDirtyConfigValues(dirtyFields, configValues);
    setValuesToSubmit(dirty);
    setIsEditing(false);
  };

  const onBack = () => {
    setIsEditing(true);
  };

  return (
    <EuiErrorBoundary>
      {isEditing ? (
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton
              id="snt-review-config-changes"
              color="primary"
              onClick={() => onReview()}
            >
              Review changes
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              id="snt-reset-config-changes"
              color="text"
              onClick={() => reset()}
            >
              Reset form
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton
              id="snt-return-to-editing-config"
              color="text"
              onClick={() => onBack()}
            >
              Return to editing
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              id="snt-confirm-config-submission"
              color="primary"
              fill
              isLoading={isSubmittingConfig}
              isDisabled={isSubmittingConfig}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      <EuiHorizontalRule margin="s" />
      {isEditing ? (
        <SntConfigView
          control={control}
          hasError={error ? true : false}
          isLoading={isShadowLoading || isSubmittingConfig}
          currentConfigDate={configTimestamp}
        />
      ) : (
        <SntGroupSummarySection config={valuesToSubmit} />
      )}
    </EuiErrorBoundary>
  );
};
