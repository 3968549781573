import { EuiAvatar, EuiDescriptionList, EuiComment } from '@elastic/eui';
import { Comm, Motion, Periodic, Power, StolenAsset, Update } from 'gqlHooks';
import { kebabCase } from 'lodash';

// Config keys are in camel case - splitting to make human readable
const configKeyToString = (configKey: string) => kebabCase(configKey);

export interface ConfigSummaryProps {
  /** Config object whose values shoud be displayed */
  config?: Comm | Motion | Periodic | Power | StolenAsset | Update;
  /** Title for the config summary section */
  label: string;
  /** Display icon for the config section */
  icon: string;
  /** True if fields should display even with null values */
  showAll?: boolean;
  /** If provided, supplies the list items to be displayed translated to be human readable */
  translation?: Array<any> | undefined;
}

/** Creates a summary section for displaying what config values were changed */
export const SntConfigSummary = ({
  config,
  translation,
  label,
  icon,
  showAll = false,
}: ConfigSummaryProps) => {
  // Check for config object (potential that if the request failed there will be none)
  if (!config && !translation) {
    return null;
  }

  const listItems = [];

  if (translation) {
    listItems.push(...translation);
  }
  // Iterate over all the key/values in the config object
  else if (config) {
    for (const [key, value] of Object.entries(config)) {
      if (key.slice(0, 2).includes('__')) {
        continue;
      }
      if (showAll || (value !== undefined && value !== null)) {
        listItems.push({ title: configKeyToString(key), description: value?.toString() ?? '--' });
      }
    }
  }

  // If there were non-null config values in the section create the section for display
  if (listItems.length > 0) {
    const avatar = (
      <EuiAvatar
        name={label}
        iconType={icon}
        iconColor="success"
        color="plain"
        size="l"
      />
    );
    return (
      <EuiComment
        event={`${listItems.length} fields`}
        username={label}
        timelineAvatar={avatar}
      >
        <EuiDescriptionList listItems={listItems} />
      </EuiComment>
    );
  }

  return null;
};
