import { useEffect, useState } from 'react';
import PageTemplate from 'layout/page/page';
import { useUserInfo } from 'context/userInfo';
import { EuiEmptyPrompt, EuiGlobalToastList, EuiLoadingChart } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import type { UserInfoContextData } from 'context/userInfo';
import { GroupsTabs } from 'app/layout/groups/groups-tabs';
import { SelectOrganization } from 'app/components/forms/select-organization';

/* Page for managing groups and the devices in them */
export const GroupsManagement = () => {
  const {
    queryData: { loading: userInfoLoading, userInfo },
  }: UserInfoContextData = useUserInfo();

  const [toasts, setToasts] = useState<Toast[]>([]);
  const addToastMessage = (message: Toast) => setToasts(toasts.concat(message));
  const [selectedOrganization, setSelectedOrganization] = useState<string | undefined>(undefined);
  const removeToast = (removedToast: { id: string }) => {
    setToasts(toasts.filter((toast: Toast) => toast.id !== removedToast.id));
  };

  useEffect(() => {
    setSelectedOrganization(userInfo?.organizationId ?? undefined);
  }, [userInfo?.organizationId]);

  return (
    <PageTemplate
      documentTitle="Groups"
      title="Groups"
    >
      <EuiGlobalToastList
        dismissToast={removeToast}
        toastLifeTimeMs={10000}
        toasts={toasts}
      />
      {userInfo?.role === 'administrator' ? (
        <SelectOrganization
          label="Select organization"
          onChange={setSelectedOrganization}
          selectedOrganization={selectedOrganization}
        />
      ) : null}
      {userInfoLoading ? (
        <EuiEmptyPrompt
          icon={
            <EuiLoadingChart
              size="xl"
              mono
            />
          }
          title={<h2>Loading groups</h2>}
        />
      ) : (
        <GroupsTabs
          isUserAdmin={userInfo?.role === 'administrator'}
          addToastMessage={addToastMessage}
          organizationId={selectedOrganization ?? ''}
        />
      )}
    </PageTemplate>
  );
};
