import { useEffect, useState } from 'react';
import { EuiErrorBoundary } from '@elastic/eui';
import { useForm } from 'react-hook-form';
import { CreateUserMutationVariables, useCreateUserMutation } from 'gqlHooks';
import type { SubmitHandler } from 'react-hook-form';
import { noop } from 'lodash';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { errorToastMessage } from 'app/utils/toast-messages';
import { extractGraphQLError } from 'app/utils/errorHandlers';

import ManageUserFormView from '../manage-users/manage-users-form';
import ConfirmDiscardDialog from '../manage-users/confirm-discard';

const DEFAULT_VALUES: CreateUserMutationVariables = {
  name: '',
  emailAddress: '',
  organizationId: '',
  role: undefined,
};

type CreateUserFormProps = {
  /** Method for adding toasts to display status messages to the user */
  addToastMessage: (toasty: Toast) => void;
  /** Organization the new user will be added to, used if the user is not an admin */
  organizationId: string;
  /** True if the user should have admin specific actions available (such as setting the organization) */
  isUserAdmin: boolean;
  /** Invoked when the user wants to close out the add user view */
  onClose: () => void;
  /** Informs the parent about changes in the forms dirty state */
  setIsDirty: (isDirty: boolean) => void;
};

/** Form for creating a new user */
export const CreateUserForm = (props: CreateUserFormProps) => {
  const [defaults, setDefaults] = useState(DEFAULT_VALUES);
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] = useState(false);

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
  } = useForm<CreateUserMutationVariables>({
    mode: 'all',
    shouldFocusError: true,
    defaultValues: defaults,
  });

  // If the user has an organization ID, user it as the default org selected
  useEffect(() => {
    const newDefaults = { ...defaults, organizationId: props.organizationId };
    setDefaults(newDefaults);
    reset(newDefaults);
    // Disabling as it wants defaults
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organizationId]);

  const [createUserMutation, { loading: isSubmitting }] = useCreateUserMutation({
    onCompleted: () => {
      // Set the last used user role and org to drop user pain a little on multi-entry
      const { organizationId, role } = getValues();
      const newDefaults = { ...defaults, organizationId, role };
      setDefaults(newDefaults);
      reset(newDefaults);
    },
    onError: error => {
      const gqlError = extractGraphQLError(error);
      if (gqlError.errorMessage && gqlError.errorMessage.length > 0) {
        props.addToastMessage(errorToastMessage(gqlError.errorMessage));
      } else {
        props.addToastMessage(errorToastMessage('Failed to create user'));
      }
    },
    refetchQueries: ['GetUsersByOrganizationId'],
  });

  const onSubmit: SubmitHandler<CreateUserMutationVariables> = () => {
    const { role, organizationId, name, emailAddress } = getValues(); // Access react-hook-form values
    createUserMutation({
      variables: {
        role,
        organizationId: props.isUserAdmin ? organizationId : props.organizationId,
        name,
        emailAddress,
      },
    });
  };

  // Check dirty state in case there are unsaved changes
  const handleOnClose = () => {
    isDirty ? setIsConfirmCancelOpen(true) : props.onClose();
  };

  const handleOnCloseDialog = () => {
    setIsConfirmCancelOpen(false);
  };

  return (
    <EuiErrorBoundary>
      <ConfirmDiscardDialog
        isOpen={isConfirmCancelOpen}
        onConfirm={props.onClose}
        onClose={handleOnCloseDialog}
      />
      <ManageUserFormView
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isSubmitting}
        isAdmin={props.isUserAdmin}
        isEditMode={false}
        canDeleteUser={false}
        onDeleteUser={noop}
        onReset={reset}
        onCloseAction={handleOnClose}
      />
    </EuiErrorBoundary>
  );
};
