import { useState, useMemo } from 'react';
import { EuiFlexItem, EuiFlexGrid, EuiTabs, EuiTab } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { GroupsCrud } from 'layout/groups/crud-management/groups-crud';
import { DevicesCrud } from 'layout/groups/devices';
import { GenericRow, useGetGroupsQuery } from 'app/generated/graphql';
import { errorToastMessage } from 'app/utils/toast-messages';

import { GroupsProgress } from './progress/group-progress';

const MANAGE_DEVICES = 'manage_group_devices';
const MANAGE_GROUPS = 'manage_groups';
const VIEW_ACTIVE_COMMANDS = 'view_group_commands';

/* Tabs for the different actions users can take for managing groups */
interface GroupsTabsProps {
  /* toaster strudel */
  addToastMessage: (toasty: Toast) => void;
  /** ID of the organization the user is a part of */
  organizationId: string;
  /* True if the user has admin role */
  isUserAdmin: boolean;
}
export const GroupsTabs = (props: GroupsTabsProps) => {
  const [selectedTabId, setSelectedTabId] = useState<keyof typeof tabContent>(MANAGE_GROUPS);
  const [groups, setGroups] = useState<GenericRow[]>([]);

  // Loading groups here so swapping between tabs is more seamless
  const { loading: isLoadingGroups } = useGetGroupsQuery({
    variables: { organizationId: props.organizationId, limit: 1000 },
    onError: () => props.addToastMessage(errorToastMessage('Failed to load groups')),
    onCompleted: data => {
      // Check if there are groups
      data?.groups?.items?.length > 0 ? setGroups(data.groups.items) : setGroups([]);
    },
  });

  const onSelectedTabChanged = (id: keyof typeof tabContent) => {
    setSelectedTabId(id);
  };

  const tabContent = {
    [MANAGE_DEVICES]: (
      <DevicesCrud
        isUserAdmin={props.isUserAdmin}
        groups={groups}
        isLoadingGroups={isLoadingGroups}
        addToastMessage={props.addToastMessage}
        organizationId={props.organizationId}
      />
    ),
    [MANAGE_GROUPS]: (
      <GroupsCrud
        addToastMessage={props.addToastMessage}
        organizationId={props.organizationId}
        groups={groups}
        isLoadingGroups={isLoadingGroups}
      />
    ),
    [VIEW_ACTIVE_COMMANDS]: (
      <GroupsProgress
        groups={groups}
        addToastMessage={props.addToastMessage}
        organizationId={props.organizationId}
      />
    ),
  };

  const selectedTabContent = useMemo(() => {
    return tabContent[selectedTabId];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId, groups]);

  return (
    <EuiFlexGrid columns={1}>
      <EuiFlexItem>
        <EuiTabs>
          <EuiTab
            key={MANAGE_GROUPS}
            onClick={() => onSelectedTabChanged(MANAGE_GROUPS)}
            isSelected={MANAGE_GROUPS === selectedTabId}
          >
            Manage groups
          </EuiTab>
          <EuiTab
            key={MANAGE_DEVICES}
            onClick={() => onSelectedTabChanged(MANAGE_DEVICES)}
            isSelected={MANAGE_DEVICES === selectedTabId}
          >
            Add or remove devices
          </EuiTab>
          <EuiTab
            key={VIEW_ACTIVE_COMMANDS}
            onClick={() => onSelectedTabChanged(VIEW_ACTIVE_COMMANDS)}
            isSelected={VIEW_ACTIVE_COMMANDS === selectedTabId}
          >
            View group requests
          </EuiTab>
        </EuiTabs>
      </EuiFlexItem>
      <EuiFlexItem>{selectedTabContent}</EuiFlexItem>
    </EuiFlexGrid>
  );
};
