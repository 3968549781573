import { useState } from 'react';
import { Command } from 'gqlHooks';
import {
  EuiErrorBoundary,
  EuiForm,
  EuiSpacer,
  EuiFlexItem,
  EuiFlexGroup,
  EuiButton,
} from '@elastic/eui';

import { DeviceCommandSelector } from './command/select-command';

interface SntSendSmsViewProps {
  /** True if the sms send is loading/sending data */
  isLoading: boolean;
  /** Calls for the page to reload the current set of data using the existing query variables */
  onSendCommand: (command: Command) => void;
}

/**
 * @return {React.Element} Input fields for selecting/sending an sms command.
 */
export function SntSendDeviceCommandView({ isLoading, onSendCommand }: SntSendSmsViewProps) {
  const [selectedCommand, setSelectedCommand] = useState(Command.Claim);

  const handleSubmit = () => {
    onSendCommand(selectedCommand);
  };

  return (
    <EuiErrorBoundary>
      <EuiForm>
        <EuiFlexGroup>
          <EuiFlexItem style={{ maxWidth: 300 }}>
            <DeviceCommandSelector
              selectedCommand={selectedCommand}
              setSelectedCommand={setSelectedCommand}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiButton
              id="snt-send-device-command"
              color="primary"
              type="submit"
              fill
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? 'Sending...' : 'Send Message'}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiForm>
    </EuiErrorBoundary>
  );
}

export default SntSendDeviceCommandView;
