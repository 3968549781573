import { Moment } from 'moment';
import { FotaType, FotaRequestRow } from 'gqlHooks';
import { EuiErrorBoundary, EuiFormRow, EuiSelectOption, EuiSpacer } from '@elastic/eui';
import { SyntheticEvent } from 'react';
import DatePicker from 'app/components/forms/date-picker/date-picker';

import SntFotaRequestsTable from './requests/fota-requests-table';
import { TABLE_COLUMNS } from './requests/table-columns';
import SntFotaSelect from './select/snt-fota-select';

interface SntFotaProps {
  /** End of date range historical fota requests should be loaded in */
  endDate: Moment;
  /** Available firmware options the device may be updated to */
  firmwareOptions: EuiSelectOption[];
  /** Type of firmware that should be loaded */
  firmwareType: FotaType;
  /** Historical fota requests */
  fotaRequests: FotaRequestRow[];
  /** True if the historical fota requests had an error while loading */
  fotaRequestsLoadError: boolean;
  /** True if a loading state should display */
  isLoading: boolean;
  /** Handler for when the user requests to cancel a fota request */
  onCancelFota: (requestId: number) => void;
  /** Handler for the user requesting a firmaware update */
  onUpdateFirmware: (event: SyntheticEvent | null) => void;
  /** ID of the currently selected type, may be null if no type has been selected*/
  selectedFirmware?: string | number | undefined;
  /** Setter for the historical fota date range */
  setEndDate: (end: Moment) => void;
  /**Handler for change in selected firmware*/
  setSelectedFirmware: (selectedType: string) => void;
  /** Handler for changing the type of firmware being displayed */
  setSelectedFirmwareType: (selectedType: FotaType) => void;
  /** Setter for the historical fota date range */
  setStartDate: (start: Moment) => void;
  /** Start of date range historical fota requests should be loaded in*/
  startDate: Moment;
}

/** SnT Fota send/cancel/view requests */
export const SntFotaView = (props: SntFotaProps) => {
  return (
    <EuiErrorBoundary>
      <SntFotaSelect
        onUpdateFirmware={props.onUpdateFirmware}
        selectedFirmware={props.selectedFirmware}
        firmwareType={props.firmwareType}
        setSelectedFirmware={props.setSelectedFirmware}
        setSelectedFirmwareType={props.setSelectedFirmwareType}
        isSubmitting={props.isLoading}
        firmwareOptions={props.firmwareOptions}
      />
      <EuiSpacer />
      <EuiFormRow>
        <DatePicker
          startDate={props.startDate}
          endDate={props.endDate}
          setStartDate={props.setStartDate}
          setEndDate={props.setEndDate}
        />
      </EuiFormRow>
      <EuiSpacer />
      <SntFotaRequestsTable
        onRequestCancel={props.onCancelFota}
        requests={props.fotaRequests}
        isLoading={props.isLoading}
        tableColumns={TABLE_COLUMNS}
        errorText={props.fotaRequestsLoadError ? 'Failed to load request history' : undefined}
      />
    </EuiErrorBoundary>
  );
};

export default SntFotaView;
