import {
  EuiErrorBoundary,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSpacer,
  EuiTab,
  EuiTabs,
} from '@elastic/eui';
import {
  GenericRow,
  GroupOperationType,
  StrawberryGroupOperationResponse,
} from 'app/generated/graphql';
import { useMemo, useState } from 'react';
import GroupFacetSelector from 'layout/groups/select/facet/facet-selector';
import GroupsSntFotaRequestsTable from 'app/layout/groups/progress/fota/group-fota-requests-table';
import GroupsSntConfigRequestsTable from 'app/layout/groups/progress/config/groups-snt-config-requests-table';

export type GroupRequestsViewProps = {
  /** Command/configuration type requests that have been made for the group */
  configRequests: StrawberryGroupOperationResponse[];
  /** Requests for fota updates */
  fotaRequests: StrawberryGroupOperationResponse[];
  /** Available groups to be viewed */
  groups: GenericRow[];
  /** True if a loading indicator should be visible */
  isLoading: boolean;
  /** Invoked when the group wants to cancel a group request */
  onCancel: (requestId: string, operationType: GroupOperationType) => void;
  /** If provided, will open up a details view to see more information about the request */
  onViewDetails: (requestId: string, operationType: GroupOperationType) => void;
  /** Selected group whose requests should be in context.  May be undefined if there are no groups */
  selectedGroup: GenericRow | undefined;
  /** Invoked when the user selects a different group to. */
  setSelectedGroup: (selection: GenericRow | undefined) => void;
};

const FOTA_TAB = 'fota';
const CMD_TAB = 'cmd';

export default function GroupRequestsView(props: GroupRequestsViewProps) {
  const [selectedTabId, setSelectedTabId] = useState<keyof typeof tabContent>(FOTA_TAB);
  const handleFotaCancel = (requestId: number) => {
    // Typing issue, request IDs are numbers but the query wants a string
    props.onCancel(requestId.toString(), GroupOperationType.Fota);
  };

  const handleConfigCancel = (requestId: number) => {
    // Typing issue, request IDs are numbers but the query wants a string
    props.onCancel(requestId.toString(), GroupOperationType.CmdConfig);
  };

  const handleFotaViewDetails = (requestId: number) => {
    // Typing issue, request IDs are numbers but the query wants a string
    props.onViewDetails(requestId.toString(), GroupOperationType.Fota);
  };

  const handleCmdCfgViewDetails = (requestId: number) => {
    // Typing issue, request IDs are numbers but the query wants a string
    props.onViewDetails(requestId.toString(), GroupOperationType.CmdConfig);
  };

  const tabContent = {
    [FOTA_TAB]: (
      <GroupsSntFotaRequestsTable
        isLoading={props.isLoading}
        requests={props.fotaRequests}
        onRequestCancel={handleFotaCancel}
        onViewDetails={handleFotaViewDetails}
      />
    ),
    [CMD_TAB]: (
      <GroupsSntConfigRequestsTable
        requests={props.configRequests ?? []}
        isLoading={props.isLoading}
        onRequestCancel={handleConfigCancel}
        onViewDetails={handleCmdCfgViewDetails}
      />
    ),
  };

  const onSelectedTabChanged = (id: keyof typeof tabContent) => {
    setSelectedTabId(id);
  };

  const selectedTabContent = useMemo(() => {
    return tabContent[selectedTabId];
    // Reset on data change, or stashed data will display
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId, props.fotaRequests, props.configRequests]);

  return (
    <EuiErrorBoundary id="group-progress-view">
      <EuiFlexGroup style={{ width: '100%', minHeight: '50vh' }}>
        <EuiFlexItem grow={1}>
          <GroupFacetSelector
            groups={props.groups}
            selectedGroup={props.selectedGroup}
            setSelectedGroup={props.setSelectedGroup}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={4}>
          <EuiFlexGrid columns={1}>
            <EuiFlexItem>
              <EuiTabs>
                <EuiTab
                  key={FOTA_TAB}
                  onClick={() => onSelectedTabChanged(FOTA_TAB)}
                  isSelected={FOTA_TAB === selectedTabId}
                >
                  FOTA requests
                </EuiTab>
                <EuiTab
                  key={CMD_TAB}
                  onClick={() => onSelectedTabChanged(CMD_TAB)}
                  isSelected={CMD_TAB === selectedTabId}
                >
                  CMD/Config requests
                </EuiTab>
              </EuiTabs>
            </EuiFlexItem>
            <EuiFlexItem>
              {props.isLoading ? <EuiProgress size="s" /> : <EuiSpacer size="xs" />}
            </EuiFlexItem>
            <EuiFlexItem>{selectedTabContent}</EuiFlexItem>
          </EuiFlexGrid>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiErrorBoundary>
  );
}
