import { useState } from 'react';
import moment, { Moment } from 'moment';
import { EuiErrorBoundary } from '@elastic/eui';
import {
  useGetDeviceCommandConfigRequestQuery,
  CommandConfigurationRequestRow,
  useDeleteDeviceCommandConfigRequestMutation,
  GetDeviceCommandConfigRequestQueryVariables,
} from 'gqlHooks';
import {
  useLocalStorage,
  VIEW_CONFIG_REQUESTS_REFRESH_INTERVAL,
  VIEW_CONFIG_REQUESTS_REFRESH_ENABLED,
} from 'hooks/use-state-local-cache';
import { getAppSyncQueryDate } from 'app/utils/dates';
import { SntConfigRequestsView } from 'layout/config/snt/requests/snt-config-requests-view';

const FIFTEEN_SECONDS_IN_MILLISECONDS = 15000;

interface DeviceMessageQueryProps {
  deviceSerial: string;
}

/** Displays sim network messages returned by the trackers */
export const SntConfigRequests = (props: DeviceMessageQueryProps) => {
  const [requests, setRequests] = useState<CommandConfigurationRequestRow[]>([]);

  const [refreshInterval, setRefreshInterval] = useLocalStorage(
    VIEW_CONFIG_REQUESTS_REFRESH_INTERVAL,
    FIFTEEN_SECONDS_IN_MILLISECONDS
  );
  const [isPaused, setIsPaused] = useLocalStorage(VIEW_CONFIG_REQUESTS_REFRESH_ENABLED, true);

  // Initial query vars. Set to the 24 hours before and after "NOW" by default
  // If users have issues, check that they have their browser/computer set to their
  // actual currrent time
  const [queryVar, setQueryVar] = useState<GetDeviceCommandConfigRequestQueryVariables>({
    deviceId: props.deviceSerial,
    start: getAppSyncQueryDate(moment().subtract(7, 'days').startOf('day').toDate()),
    limit: 50000,
    end: getAppSyncQueryDate(moment().add(1, 'days').endOf('day').toDate()),
    nextToken: null,
  });

  const { loading, error, refetch } = useGetDeviceCommandConfigRequestQuery({
    variables: queryVar,
    onCompleted: data => {
      setRequests(data.deviceCommandConfigRequest.requests);
    },
    pollInterval: isPaused ? 0 : refreshInterval,
    notifyOnNetworkStatusChange: true, // loading status will be updated when doing polling with this set
  });

  const [deleteDeviceCommandConfigRequestMutation] = useDeleteDeviceCommandConfigRequestMutation();

  const deleteRequest = (requestId: number) => {
    deleteDeviceCommandConfigRequestMutation({
      variables: {
        deviceId: props.deviceSerial,
        requestId: requestId,
      },
      onCompleted: () => {
        refetch(); // Refresh the table after successful deletion
      },
    });
  };

  // Checks if any variables have changed since the last time the search was run
  const compareQueryVars = (startDate: string, endDate: string) => {
    if (!queryVar) {
      return null;
    }
    const { start, end } = queryVar;
    return startDate === start && endDate === end;
  };

  // Handler for parsing date/time and updating search variables to get new data
  const handleSearch = (startDate: Moment, endDate: Moment) => {
    // Parse the dates into the format the API is expecting
    const parsedStartDate = getAppSyncQueryDate(startDate.toDate());
    const parsedEndDate = getAppSyncQueryDate(endDate.toDate());

    // If no query variables have changed, then need to call a refetch
    // as apollo will normally only trigger a reload on query variable change
    if (compareQueryVars(parsedStartDate, parsedEndDate)) {
      refetch();
      return;
    }

    // Update the query variables so apollo will fetch the new data
    setQueryVar({
      deviceId: props.deviceSerial,
      start: parsedStartDate,
      limit: 50000,
      end: parsedEndDate,
      nextToken: null,
    });
  };

  return (
    <EuiErrorBoundary id="snt-config-requests">
      <SntConfigRequestsView
        isLoading={loading}
        requests={requests}
        messageError={error ? 'Failed to load message data' : undefined}
        onSearch={handleSearch}
        refreshInterval={refreshInterval}
        setRefreshInterval={setRefreshInterval}
        isPaused={isPaused}
        setIsPaused={setIsPaused}
        onRequestCancel={deleteRequest}
      />
    </EuiErrorBoundary>
  );
};

export default SntConfigRequests;
