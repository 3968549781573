import { EuiIcon } from '@elastic/eui';

export const installItemsInfo = [
  {
    title: 'Battery',
    description: (
      <>
        <div>
          <EuiIcon
            type="faceHappy"
            color="success"
          />
          <span> Battery voltage is greater than 6.0</span>
        </div>
        <div>
          <EuiIcon
            type="faceNeutral"
            color="warning"
          />
          <span> Battery voltage is between 4.5 and 6.0</span>
        </div>
        <div>
          <EuiIcon
            type="faceSad"
            color="danger"
          />
          <span> Battery voltage is less than 4.5 or non-existent</span>
        </div>
      </>
    ),
  },
  {
    title: 'Location',
    description: (
      <>
        <div>
          <EuiIcon
            type="faceHappy"
            color="success"
          />
          <span> Valid latitude/longitude</span>
        </div>
        <div>
          <EuiIcon
            type="faceNeutral"
            color="warning"
          />
          <span>
            {' '}
            Invalid latitue and longitude. Longitude has to be between -180 and 180 and Latitude has
            to be between -90 and 90
          </span>
        </div>
        <div>
          <EuiIcon
            type="faceSad"
            color="danger"
          />
          <span>
            {' '}
            No Location found. If the tracker has no cell signal it will not have gps coordinates.
          </span>
        </div>
      </>
    ),
  },
  {
    title: 'Firmware',
    description: (
      <>
        <div>
          <EuiIcon
            type="faceHappy"
            color="success"
          />
          <span> Firmware is present</span>
        </div>
        <div>
          <EuiIcon
            type="faceSad"
            color="danger"
          />
          <span> No firmware found</span>
        </div>
      </>
    ),
  },
];
