import { htmlIdGenerator } from '@elastic/eui';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';

export const errorToastMessage = (title: string) => {
  const toast: Toast = {
    title,
    color: 'danger', // Toast gets mad if we give it the value via the enum
    id: htmlIdGenerator()(),
    iconType: 'alert',
  };
  return toast;
};

export const infoToastMessage = (title: string) => {
  const toast: Toast = {
    title,
    color: 'primary', // Toast gets mad if we give it the value via the enum
    id: htmlIdGenerator()(),
    iconType: 'help',
  };
  return toast;
};

export const successToastMessage = (title: string) => {
  const toast: Toast = {
    title,
    color: 'success', // Toast gets mad if we give it the value via the enum
    id: htmlIdGenerator()(),
    iconType: 'help',
  };
  return toast;
};
