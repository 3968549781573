import { EuiErrorBoundary, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui';
import PageSection from 'layout/section/section';
import { DeviceListTable } from 'layout/device-list/table/table';
import { GenericRow } from 'app/generated/graphql';
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list';
import { useGetOrgs } from 'app/components/forms/select-organization/organization-pretty-print';

type DevicesByOrgProps = {
  organization: string;
  groups: GenericRow[];
  addToastMessage: (message: Toast) => void;
};

export const DevicesByOrg = (props: DevicesByOrgProps) => {
  const { idMatch } = useGetOrgs({
    id: props.organization,
    addToastMessage: props.addToastMessage,
  });

  return (
    <EuiErrorBoundary>
      <PageSection key={props.organization}>
        <EuiTitle size="s">
          <EuiFlexGroup
            alignItems="center"
            gutterSize="s"
          >
            <EuiFlexItem>
              <h2>{idMatch?.label}</h2>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiTitle>
        <EuiSpacer />
        <DeviceListTable
          groups={props.groups}
          organization={props.organization}
          addToastMessage={props.addToastMessage}
        />
      </PageSection>
    </EuiErrorBoundary>
  );
};
