import { createContext, useCallback, useContext, ReactElement, useState } from 'react';
import { useGetUserInfoQuery } from 'gqlHooks';
import { extractGraphQLError, handleApolloError } from 'utils/errorHandlers';
import type { ApolloError } from '@apollo/client';
import type { UserInfo } from 'gqlHooks';

export type UserInfoContextData = {
  queryData: {
    userInfo: UserInfo | undefined;
    error: string | undefined | null;
    loading: boolean;
  };
};

type UserInfoProviderProps = {
  children: ReactElement;
};

const UserInfoContext = createContext<UserInfoContextData | undefined>(undefined);

function UserInfoProvider(props: UserInfoProviderProps) {
  const [error, setErrorState] = useState<string | null>(null);
  const handleError = useCallback((error: ApolloError) => {
    const gqlError = extractGraphQLError(error);
    if (gqlError.statusCode === 404) {
      setErrorState(gqlError.errorMessage);
    } else {
      handleApolloError(error, true, gqlError => setErrorState(gqlError.errorMessage));
    }
  }, []);
  const { data, loading, refetch } = useGetUserInfoQuery({
    onError: handleError,
  });

  const queryData = {
    userInfo: data?.userInfo,
    error,
    loading,
  };

  const updatedValue = { queryData, refetch };

  return (
    <UserInfoContext.Provider value={updatedValue}> {props.children}</UserInfoContext.Provider>
  );
}

function useUserInfo() {
  const context = useContext(UserInfoContext);

  if (context === undefined) {
    throw new Error('useUserInfo must be used within `UserInfoProvider`');
  }
  return context;
}

export { UserInfoProvider, useUserInfo };
