import { CONSTANTS } from 'utils/constants';

interface GPSData {
  latitude?: string;
  lat?: string;
  longitude?: string;
  lon?: string;
  lng?: string;
  accuracy?: number | string;
  altitude?: number | string;
}

export interface LocationData {
  location?: GPSData;
  gps?: GPSData;
}

/**
 * Location model
 */
class Location {
  readonly lat: number;
  readonly lng: number;
  readonly altitude?: number | string;
  readonly accuracy?: number | string;

  constructor(data: LocationData) {
    const gpsData = (data.location || data.gps) ?? {
      latitude: 0,
      lat: 0,
      lon: 0,
      lng: 0,
      longitude: 0,
      accuracy: 0,
      altitude: 0,
    };

    //make separate function for checking which lat or long
    const latitude = gpsData?.lat || gpsData?.latitude;
    const longitude = gpsData?.lon || gpsData?.longitude || gpsData?.lng;
    this.lat = Number(latitude);
    this.lng = Number(longitude);
    this.accuracy = gpsData?.accuracy ? gpsData.accuracy : CONSTANTS.TEXT_PLACEHOLDER;
    this.altitude = gpsData?.altitude ? gpsData.altitude : CONSTANTS.TEXT_PLACEHOLDER;
  }

  public isValidLatLng() {
    return this.lat !== 0 && this.lng !== 0;
  }

  /**
   * Shorthand call to get the {lat,lng} object
   *
   * @getter center
   */
  get center() {
    return {
      lat: this.lat,
      lng: this.lng,
    };
  }

  /**
   * Returns Latitude/Longitude fixed to two decimal places
   * as {shortenedLat, shortenedLng}
   *
   * @getter shortenedCoords
   */
  get shortenedCoords() {
    return {
      shortenedLat: this.lat.toFixed(2),
      shortenedLng: this.lng.toFixed(2),
    };
  }

  /**
   * Returns a Google Maps URL using this.lat and this.lng
   *
   * @getter googleMapsUrl
   */
  get googleMapsUrl() {
    return `${CONSTANTS.GOOGLE_MAPS_URL}${this.lat},${this.lng}`;
  }
}

export { Location };
