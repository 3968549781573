/**
 * Converts a temperature from Celsius to Fahrenheit
 * @returns {number} The temperature in Fahrenheit
 */
export function celsiusToFahrenheit(celsius: number): number {
  return (celsius * 9) / 5 + 32;
}

/**
 * Capitalizes the first letter of a string
 * @returns {string} The capitalized string
 *
 */
export function capitalize(str: string): string {
  // Lowercases the string
  const strLowercased = str.toLowerCase();
  // Capitalizes the first letter of the string
  return strLowercased.charAt(0).toUpperCase() + strLowercased.slice(1);
}

/**
 * Takes a value and returns a boolean if possible otherwise returns false
 * Boolean strings are: 'true', 'false', 'yes', 'no', '1', '0'
 *
 * An optional valueExistsInString parameter can be passed
 * in to check if the value exists in the string
 *
 * @returns {boolean} The boolean value of the input
 */
export function convertToBoolean(
  value: string | number | boolean | null | undefined,
  valueExistsInString?: string
): boolean {
  if (typeof value === 'boolean') {
    return value;
  }

  if ((typeof value === 'number' && value === 0) || value === 1) {
    return !!value;
  }

  if (typeof value === 'string') {
    value = value.toLowerCase().trim();
    if (value === 'true' || value === 'yes' || value === '1') {
      return true;
    }
    if (value === 'false' || value === 'no' || value === '0') {
      return false;
    }
  }

  if (valueExistsInString) {
    return valueExistsInString.includes(value as string);
  }

  return false;
}
