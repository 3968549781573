import { useState } from 'react';
import { noop } from 'lodash';
import { EuiErrorBoundary, EuiFormRow, EuiSelect, useGeneratedHtmlId } from '@elastic/eui';
import { useGetOrganizationsQuery } from 'gqlHooks';
import type { GetOrganizationsQueryVariables } from 'gqlHooks';
import type { EuiSelectOption } from '@elastic/eui';

export type SelectOrganizationProps = {
  /** Invoked on change */
  onChange: (orgId: string) => void;
  /** Handler used by from react-hook-forms */
  onBlur?: (event: string | React.ChangeEvent<Element>) => void;
  /** ID of the selected organization which is selected */
  selectedOrganization?: string;
  /** True if the controller should be disabled */
  disabled?: boolean;
  /** Label to display for the selector */
  label: string;
  /** True if the field should display an invalid indicator */
  isInvalid?: boolean;
  /** Error message to display in relation to the invalid indicator */
  errorMessage?: string | undefined;
};

/** Single select for selecting an organization */
export const SelectOrganization = (props: SelectOrganizationProps) => {
  const [options, setOptions] = useState<EuiSelectOption[]>([]);

  const queryVars: GetOrganizationsQueryVariables = {
    limit: 1000,
    nextToken: null,
  };

  const { loading: organizationsLoading, error } = useGetOrganizationsQuery({
    variables: queryVars,
    onCompleted: data => {
      const opts = data?.organizations?.items?.map(org => ({
        text: org.label ?? '',
        value: org.organizationId ?? '',
      }));

      setOptions(opts);
    },
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange(event.target.value);
  };

  const errorLoading = error ? 'Failed to load organizations' : '';
  const errors = [errorLoading, props.errorMessage];

  return (
    <EuiErrorBoundary>
      <EuiFormRow
        label={props.label}
        error={errors}
        isInvalid={props.isInvalid || errorLoading !== ''}
      >
        <EuiSelect
          id={useGeneratedHtmlId({ prefix: 'selectOrganization' })}
          options={options}
          value={props.selectedOrganization}
          onChange={handleOnChange}
          onBlur={props.onBlur ?? noop}
          hasNoInitialSelection
          aria-label={`Select ${props.label ?? 'organization'}`}
          disabled={props.disabled}
          isLoading={organizationsLoading}
        />
      </EuiFormRow>
    </EuiErrorBoundary>
  );
};
