import { EuiErrorBoundary, EuiProgress, EuiStat, EuiHorizontalRule } from '@elastic/eui';
import SntStolenConfig from 'layout/config/snt/stolen/stolen-config';
import { SntPowerConfig } from 'layout/config/snt/power/power-config';
import { SntCommConfig } from 'layout/config/snt/comm/comm-config';
import { SntMotionConfig } from 'layout/config/snt/motion/motion-config';
import { SntPeriodicConfig } from 'layout/config/snt/periodic/periodic-config';
import { getLocalTime } from 'utils/dates';
import { Configuration } from 'app/generated/graphql';
import { Control } from 'react-hook-form';

export interface SntConfigViewProps {
  control: Control<Configuration>;
  /** True if the view should display an error message */
  hasError: boolean;
  /** True if the view is loading/reloading */
  isLoading?: boolean;
  /** Date the most recent config values were applied on */
  currentConfigDate?: string;
  /** True if the "configuration last applied on" section should be hidden */
  hideMessage?: boolean;
}

/** View for the SnT configuration */
export const SntConfigView = (props: SntConfigViewProps) => {
  let message = 'Using default values';
  let messageDescription = 'Last reported configuration';
  let messageColor = 'accent';

  if (props.hasError) {
    messageDescription = '';
    message = 'Failed to load configuration';
    messageColor = 'danger';
  } else if (!props.currentConfigDate) {
    messageDescription = '';
    message = 'No saved config found';
    messageColor = 'accent';
  } else if (props.currentConfigDate) {
    message = getLocalTime(props.currentConfigDate);
    messageColor = 'primary';
  }

  return (
    <EuiErrorBoundary>
      {props.hideMessage ? null : (
        <EuiStat
          title={message}
          description={messageDescription}
          titleSize="s"
          titleColor={messageColor}
        />
      )}
      {props.isLoading ? <EuiProgress size="s" /> : null}
      {props.hideMessage ? null : <EuiHorizontalRule margin="s" />}
      <SntStolenConfig
        control={props.control}
        isExpanded={false}
      />
      <SntPowerConfig
        control={props.control}
        isExpanded={false}
      />
      <SntPeriodicConfig
        control={props.control}
        isExpanded={false}
      />
      <SntMotionConfig
        control={props.control}
        isExpanded={false}
      />
      <SntCommConfig
        control={props.control}
        isExpanded={false}
      />
    </EuiErrorBoundary>
  );
};
