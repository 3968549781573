import { useCallback, useEffect, useState } from 'react';
import { extractGraphQLError, handleApolloError } from 'utils/errorHandlers';
import { CONSTANTS } from 'utils/constants';
import { useGetDevicesByOrganizationQuery } from 'gqlHooks';
import type { EuiSelectableOption } from '@elastic/eui';
import type { ApolloError } from '@apollo/client';
import { noop } from 'lodash';

import { SelectDevicesView } from './devices-select-view';
export type SelectDevicesProps = {
  onChange: (event: any) => void;
  selectedDevice: string | string[] | undefined;
  organizationId: string;
  isInvalid?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
  isSingleSelect?: boolean;
  isRequired: boolean;
  // pass onBlur if validating mode = onBlur or all
  onBlur?: (event: string | React.ChangeEvent<Element>) => void;
  inFlyout?: boolean;
  /** All devices that are tied to a group and to be displayed in the table*/
  assignedDevices?: any[];
};

export const SelectDevices = (props: SelectDevicesProps) => {
  const [errorState, setErrorState] = useState<string | null>(null);
  const [options, setOptions] = useState<EuiSelectableOption[]>([]);
  const [assignedDevices, setAssignedDevices] = useState<EuiSelectableOption[]>([]);

  useEffect(() => {
    if (props.assignedDevices) {
      // Map assigned devices to options and set the checked property accordingly
      const mappedOptions: EuiSelectableOption[] = props.assignedDevices.map(device => {
        return {
          label: device.label ? `${device.label} - ${device.identifier}` : `${device.identifier}`,
          key: `${device.identifier}`,
          value: device.identifier,
          checked: 'on', // Set as checked since it's in assignedDevices
        };
      });
      setAssignedDevices(mappedOptions);
    }
  }, [props.assignedDevices]);

  const handleOnChange = (newOptions: EuiSelectableOption[]) => {
    setOptions(newOptions);
    props.onChange(newOptions);
  };

  const handleError = useCallback((error: ApolloError) => {
    const gqlError = extractGraphQLError(error);
    if (gqlError.statusCode === 404) {
      setErrorState(gqlError.errorMessage);
    } else {
      handleApolloError(error, true, gqlError => setErrorState(gqlError.errorMessage));
    }
  }, []);

  const { loading: organizationsLoading } = useGetDevicesByOrganizationQuery({
    variables: { organizationId: props.organizationId, limit: 5000 },
    pollInterval: CONSTANTS.SIXTY_SECONDS_IN_MILLISECONDS,
    onError: handleError,
    onCompleted: data => {
      // Check if there are organizations
      if (data?.devices?.items.length > 0) {
        const deviceOptions: EuiSelectableOption[] = data.devices.items.map(device => {
          const label = device.label
            ? `${device.label} - ${device.identifier}`
            : `${device.identifier}`;
          return {
            label,
            key: `${device.identifier}`, // Ensure this key is unique
            value: device.identifier,
            checked: undefined, // Initially set as unchecked
          };
        });
        const mergedOptions = [...assignedDevices, ...deviceOptions];
        setOptions(mergedOptions);
      }
    },
  });

  return (
    <SelectDevicesView
      inFlyout={props.inFlyout}
      onChange={handleOnChange}
      disabled={props.disabled}
      options={options}
      label={props.label}
      onBlur={props.onBlur ?? noop}
      isInvalid={errorState != null}
      errorMessage={errorState}
      isLoading={organizationsLoading}
      isSingleSelect={props.isSingleSelect}
      isRequired={props.isRequired}
    />
  );
};
