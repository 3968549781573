import { TagType } from 'models/install-verification-tags/types';
import { Tag } from 'models/install-verification-tags/tag/Tag';
import type { IInstallVerificationTagData } from 'models/install-verification-tags/types';

export abstract class Location extends Tag {
  protected location: IInstallVerificationTagData['location'];
  protected validLatLng: boolean;

  constructor(type: TagType, tagData: IInstallVerificationTagData) {
    super(type, tagData);

    this.location = tagData.location;

    this.validLatLng = (tagData.location && tagData.location.isValidLatLng()) ?? false;
  }
}
