import { EuiCallOut, EuiLink, EuiSpacer, EuiText } from '@elastic/eui';
import { useDSLink } from '@equipmentshare/ds2-link';
import { useRouteError } from 'react-router-dom';
import PageTemplate from 'layout/page/page';
import PageSection from 'layout/section/section';

type ErrorResponse = {
  status?: string | number;
  statusText?: string;
  data?: unknown;
};

export const Error = () => {
  const error = useRouteError() as ErrorResponse;

  return (
    <PageTemplate
      documentTitle="Error"
      title="An unexpected error has occurred"
    >
      <PageSection>
        <EuiCallOut
          title={error?.statusText || 'Error'}
          color="danger"
          iconType="alert"
        >
          {error?.status && <EuiText size="s">Error Code: {error?.status}</EuiText>}
          <EuiSpacer size="l" />
          <p>
            <EuiLink {...useDSLink('/')}>Return Home</EuiLink>
          </p>
        </EuiCallOut>
      </PageSection>
    </PageTemplate>
  );
};
